import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  filtersContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
  },
  searchField: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  searchInputField: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  // SELECT INPUT FILTERS
  secondaryFiltersContainer: {
    marginLeft: "auto",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
      marginTop: theme.spacing(2),
      display: "flex",
      gap: 2,
      flexGrow: 0,
      maxWidth: "100%",
      flexBasis: "100%",
      justifyContent: "flex-start",
    },
  },
  selectInputContainer: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "176px",
      width: "176px",
    },
  },
  selectInput: {
    fontSize: "16px",
    fontWeight: 400,
    width: "100%",
    "& .MuiSelect-select": {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  sortByInput: {
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
}));

export default useStyles;
