import { useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useRouteMatch } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectGradingStep,
  setPrevStep,
} from "src/store/reducers/grading/gradingSlice";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import {
  GradeResult,
  isApprovalGrade,
  isCardioApprovalGrade,
  isRepGrade,
  isTimeGrade,
} from "../../models";
import { ReviewApprovalCard } from "../ReviewApprovalCard/ReviewApprovalCard";
import { ReviewApprovalCardio } from "../ReviewApprovalCardioCard/ReviewApprovalCardioCard";
import { ReviewRepsCard } from "../ReviewRepsCard/ReviewRepsCard";
import { ReviewTimeCard } from "../ReviewTimeCard/ReviewTimeCard";

type GradeTestReviewProps = {
  grades: GradeResult[];
  test?: TestScore;
  setStep: (step: number) => void;
  step: number;
  testScore: TestScore;
  testScoreLoading: boolean;
  onTestCertify: (
    results: GradeResult[],
    uid: string,
    certified?: "true" | "false" | "pending",
  ) => Promise<void>;
};

export function GradeTestReview({
  grades,
  test,
  setStep,
  step,
  testScore,
  testScoreLoading,
  onTestCertify,
}: GradeTestReviewProps) {
  const dispatch = useAppDispatch();
  const gradingStep = useAppSelector(selectGradingStep);
  const match = useRouteMatch<{ userId: string; testId: string }>();
  const handleRegradeClick = (step: number) => {
    dispatch(setPrevStep(gradingStep));
    setStep(step);
  };

  useEffect(() => {
    const handleOnTestCertify = async () => {
      await onTestCertify(grades, match.params.userId, "pending");
    };
    handleOnTestCertify();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return testScoreLoading ? (
    <Spinner animation="border" style={{ left: "50%", position: "relative" }} />
  ) : (
    <div>
      <h2>Test Review</h2>
      <br />

      {grades.map((grade, idx) => {
        if (isRepGrade(grade)) {
          return (
            <ReviewRepsCard
              key={idx}
              {...grade}
              regradeClick={() => handleRegradeClick(idx)}
              fileKey={grade.userResult.fileKey as string}
              step={idx}
              testScore={testScore}
            />
          );
        } else if (isTimeGrade(grade)) {
          return (
            <ReviewTimeCard
              key={idx}
              {...grade}
              regradeClick={() => handleRegradeClick(idx)}
              fileKey={grade.userResult.fileKey as string}
              step={idx}
              testScore={testScore}
            />
          );
        } else if (
          isCardioApprovalGrade(grade) &&
          grade.userResult?.name !== "Body Composition"
        ) {
          return (
            <ReviewApprovalCardio
              key={idx}
              {...grade}
              regradeClick={() => handleRegradeClick(idx)}
              fileKey={grade.userResult.fileKey as string}
              step={idx}
              testScore={testScore}
            />
          );
        } else if (
          isApprovalGrade(grade) &&
          grade.userResult?.name !== "Body Composition"
        ) {
          return (
            <ReviewApprovalCard
              key={idx}
              {...grade}
              regradeClick={() => handleRegradeClick(idx)}
              fileKey={grade.userResult.fileKey as string}
              step={idx}
              testScore={testScore}
            />
          );
        } else {
          return <></>;
        }
      })}
    </div>
  );
}
