import { PaletteOptions, createTheme } from "@mui/material/styles";
import { TypographyOptions } from "@mui/material/styles/createTypography";

declare module "@mui/material/styles" {
  interface Palette {
    active: string;
    hover: string;
  }
  interface PaletteOptions {
    active: string;
    hover: string;
  }
}

export const palette: PaletteOptions = {
  primary: {
    light: "#C5D9CF",
    main: "#6FA088",
    dark: "#436052",
    contrastText: "#FFFFFF", // <--- this is the button color.
  },
  secondary: {
    // light: "#B9B9B9",
    light: "#8F8F8F8F",
    main: "#515151",
    dark: "#313131",
    contrastText: "#FFFFFF", // <--- this is the button color.
  },
  active: "#61778D",
  hover: "#90A3C2",
  success: {
    main: "#27AE60",
    contrastText: "#000000", // <--- this is the button color.
  },
  error: {
    main: "#EB5757",
    contrastText: "#000000", // <--- this is the button color.
  },
  warning: {
    main: "#F2C94C",
    contrastText: "#000000", // <--- this is the button color.
  },
  background: {
    default: "#F6F8FC",
    paper: "#FFFFFF",
  },
  text: {
    primary: "#354760",
    disabled: "#a2a2a2",
  },
};

export const typography: TypographyOptions = {
  allVariants: {
    fontFamily: "Avenir",
  },
  h5: {
    fontWeight: 800,
    fontSize: "24px",
  },
  h4: {
    fontSize: "20px",
    fontWeight: 800,
  },
  h3: {
    fontSize: "32px",
    fontWeight: 800,
  },
  h2: {
    fontSize: "40px",
    fontWeight: 800,
  },
  h1: {
    fontSize: "48px",
    fontWeight: 800,
  },
  caption: {
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  button: {
    fontWeight: 900,
    fontSize: "14px",
    lineHeight: "24px",
    textTransform: "uppercase",
  },
  subtitle1: {
    fontSize: "12px",
    color: palette.text?.disabled,
    fontWeight: 800,
  },
};

const theme = createTheme({
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          paddingBottom: "0.33rem",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
            {
              display: "none",
            },
          "input[type=number]": {
            MozAppearance: "textfield",
          },
        },
      },
    },
  },
  palette,
  typography,
});

export default theme;
