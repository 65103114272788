import { Grid, Typography } from "@mui/material";
import { RecruitmentProcessBadge } from "./RecruitmentProcessBadge";

export const FitnessTestCard = ({
  score,
  maxScore,
  status,
  date,
}: {
  score: number | "-";
  maxScore: number | "-";
  status: "pass" | "fail" | "pending";
  date: string;
}) => {
  const formatter = Intl.DateTimeFormat("en-US", { dateStyle: "medium" });
  return (
    <Grid container sx={{ flexGrow: 1 }}>
      <Grid container md={5} sx={{ padding: 2 }} flexDirection={"column"}>
        <Typography variant="subtitle1" textTransform={"uppercase"}>
          grade
        </Typography>
        <Grid container>
          <Typography>{score}/</Typography>
          <Typography fontWeight={800}>{maxScore}</Typography>
        </Grid>
        <Grid
          container
          sx={{ flexGrow: 1 }}
          flexDirection={"column"}
          justifyContent={"flex-end"}
        >
          <RecruitmentProcessBadge label={status} />
        </Grid>
      </Grid>
      <Grid container md={7} sx={{ padding: 2 }} flexDirection={"column"}>
        <Typography variant="subtitle1" textTransform={"uppercase"}>
          date
        </Typography>
        <Typography>
          {date === "" ? "-" : formatter.format(new Date(date))}
        </Typography>
      </Grid>
    </Grid>
  );
};
