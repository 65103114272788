import { Button, Grid, InputLabel, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { LoadingButton } from "src/grading/components";
import { inputError } from "src/grading/components/InputError/InputError";
import RFModal from "src/grading/components/RFModal/RFModal";
import { appendUnit } from "src/store/reducers/organizations/organizationSlice";
import { useAddUnitMutation } from "src/store/reducers/organizations/organizationsApiSlice";
import theme from "src/theme";
import * as Yup from "yup";
import useStyles from "./AddNewUnitModal.style";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
});

interface AddNewUnitModalProps {
  organizationId: string;
  open: boolean;
  onClose: () => void;
}

export interface AddNewUnitFormValues {
  name: string;
}

const AddNewUnitModal = (props: AddNewUnitModalProps) => {
  const classes = useStyles();

  const [addUnit, { isLoading }] = useAddUnitMutation();

  const dispatch = useDispatch();

  const saveUnit = async (values: AddNewUnitFormValues) => {
    const result = await addUnit({
      organizationId: props.organizationId,
      createUnitPayload: values,
    }).unwrap();

    if (result.success) {
      dispatch(appendUnit(result.data));
      props.onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: async (values: AddNewUnitFormValues) => {
      await saveUnit(values);
      formik.resetForm();
    },
  });

  const { values, handleSubmit, handleChange } = formik;

  return (
    <RFModal
      {...props}
      boxProps={{
        className: classes.container,
      }}
    >
      <Grid
        container
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <Typography variant="h5">Add New Unit</Typography>

        <Grid container item marginTop={theme.spacing(4)} spacing={2}>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Name</InputLabel>
            <TextField
              InputProps={{
                className: classes.input,
              }}
              variant="outlined"
              fullWidth
              value={values.name}
              onChange={handleChange("name")}
              error={inputError("name", formik.touched, formik.errors).error}
              helperText={
                inputError("name", formik.touched, formik.errors).display
              }
            />
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent={"space-between"}
            marginTop={theme.spacing(4)}
          >
            <Grid item>
              <Button
                color="secondary"
                className={`${classes.button} ${classes.backButton}`}
                onClick={props.onClose}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <LoadingButton
                variant="contained"
                color="primary"
                className={classes.button}
                loading={isLoading}
                label="Save"
                type="submit"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RFModal>
  );
};

export default AddNewUnitModal;
