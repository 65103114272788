import { Button, CircularProgress, Typography, styled } from "@mui/material";
import React from "react";

interface Props extends React.ComponentProps<typeof Button> {
  loading?: boolean;
  label: string;
}

const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.common.white,
}));

const LoadingButton = ({ label, loading, ...rest }: Props) => {
  return (
    <Button {...rest}>
      {loading ? (
        <StyledCircularProgress size={"1.4rem"} sx={{ fontSize: "14px" }} />
      ) : (
        <Typography variant={"button"}>{label}</Typography>
      )}
    </Button>
  );
};

export default LoadingButton;
