import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  unitButtonBorder: {
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
    height: theme.spacing(7),
  },
  unitButton: {
    marginLeft: 16,
    backgroundColor: "#000",
    borderRadius: "10px",
    height: theme.spacing(4),
  },
}));

export default useStyles;
