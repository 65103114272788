import { Divider, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import App from "src/App";
import { ApprovalButton } from "src/grading/approvalButton";
import { ExerciseChecklist } from "src/grading/exerciseChecklist";
import { ApprovalGradeResult, GradeResult } from "src/grading/models";
import { DisplayTimeFromSeconds, GetRestTime } from "src/grading/utils";
import { getLabelBasedOnGradeResult } from "src/grading/utils/testHelpers";
import { useAppSelector } from "src/store/hooks";
import {
  selectGradingStep,
  selectGradingTest,
} from "src/store/reducers/grading/gradingSlice";
import { TestStepDefinitionFormat } from "src/store/reducers/tests/testsApiSlice";

interface ApprovalStepperProps {
  onGrade: (stepGrade: GradeResult) => void;

  currentGrade: GradeResult;
  stepMetadata: TestStepDefinitionFormat | undefined;
  prevEnd: number | null; // The end time of the previous step, for rest time displays
}

const ApprovalStepper = ({
  onGrade,
  currentGrade,
  stepMetadata,
  prevEnd,
}: ApprovalStepperProps) => {
  const test = useAppSelector(selectGradingTest);
  const step = useAppSelector(selectGradingStep);
  const [approved, setApproved] = useState(true);
  const exercise = test?.exercises[step];

  if (!test || !stepMetadata) {
    return null;
  }

  return (
    <Grid container height={"80vh"} overflow={"scroll"}>
      <Grid item p={4} width={"100%"}>
        {stepMetadata.testing?.restTime !== undefined && (
          <>
            <Typography variant="subtitle1" textTransform={"uppercase"}>
              Rest Between Exercises
            </Typography>
            <Grid container justifyContent={"space-between"} mt={2.5}>
              <Typography color={"#666"} variant="caption">
                Rest Allowed
              </Typography>

              <Typography variant="caption">
                {" "}
                {DisplayTimeFromSeconds(
                  stepMetadata?.testing?.restTime?.maxSeconds || 0,
                ) === "00:00"
                  ? "-"
                  : DisplayTimeFromSeconds(
                      stepMetadata?.testing?.restTime?.maxSeconds || 0,
                    )}
              </Typography>
            </Grid>
            <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

            <Grid container justifyContent={"space-between"}>
              <Typography color={"#666"} variant="caption">
                Rest Taken
              </Typography>

              <Typography variant="caption">
                {" "}
                {GetRestTime(prevEnd, exercise?.metadata?.startTime || 0)}
              </Typography>
            </Grid>

            <ApprovalButton
              onClick={(state) => {
                setApproved(state);
                const gradeResult = {
                  ...(currentGrade as ApprovalGradeResult),
                  approved: state,
                  userResult: {
                    ...currentGrade.userResult,
                    fileKey: test?.exercises[step]?.fileKey as string,
                  },
                  exceededRestTime: !state,
                };

                onGrade(gradeResult);
              }}
              default={!currentGrade?.exceededRestTime}
            />
          </>
        )}

        <Typography variant="subtitle1" textTransform={"uppercase"} mt={4}>
          {getLabelBasedOnGradeResult(currentGrade)}
        </Typography>

        <ApprovalButton
          onClick={(state) => {
            setApproved(state);
            const gradeResult = {
              ...currentGrade,
              approved: state,
              userResult: {
                ...currentGrade.userResult,
                fileKey: test?.exercises[step]?.fileKey as string,
                passFail: state,
              },
            } as ApprovalGradeResult;

            onGrade(gradeResult);
          }}
          approvedLabel={"Passed"}
          denyLabel={"Failed"}
          default={(currentGrade as ApprovalGradeResult)?.userResult?.passFail}
        />

        {/* NOTES */}
        <Typography variant="subtitle1" textTransform={"uppercase"} mt={4}>
          Notes
        </Typography>

        <Grid container>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Enter notes here..."
            value={currentGrade?.userResult?.graderNotes ?? ""}
            onChange={(e) => {
              const updatedCurrentGrade = {
                ...currentGrade,
                userResult: {
                  ...currentGrade.userResult,
                  fileKey: test?.exercises[step]?.fileKey as string,
                  graderNotes: e.target.value,
                },
              };
              onGrade(updatedCurrentGrade);
            }}
          />
        </Grid>

        {/* DISQUALIFIERS */}
        <Typography
          variant="subtitle1"
          textTransform={"uppercase"}
          mt={4}
          mb={1}
        >
          Disqualifiers
        </Typography>
        {stepMetadata !== undefined && (
          <ExerciseChecklist
            stepMetadata={stepMetadata}
            exerciseName={stepMetadata.displayName!}
            onChange={(selections) => {
              const updatedCurrentGrade = {
                ...currentGrade,
                userResult: {
                  ...currentGrade.userResult,
                  fileKey: test?.exercises[step]?.fileKey as string,
                },
              };

              const currGrades = {
                ...updatedCurrentGrade,
                discrepancies: selections,
              };

              if (currGrades) {
                onGrade(currGrades);
              }
            }}
            selections={currentGrade?.discrepancies}
            key={stepMetadata.exerciseId}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default ApprovalStepper;
