import { ArrowBack } from "@mui/icons-material";
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { inputError } from "src/grading/components/InputError/InputError";
import { DEFAULT_ORGANIZATION } from "src/grading/models";
import { useAppSelector } from "src/store/hooks";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import { setMembers } from "src/store/reducers/organizations/organizationSlice";
import {
  Member,
  Role,
  Unit,
  useLazyGetRolesQuery,
  useLazyGetUnitMembersQuery,
  useLazyGetUnitQuery,
  useUpdateUnitMutation,
} from "src/store/reducers/organizations/organizationsApiSlice";
import theme from "src/theme";
import * as Yup from "yup";
import MembersTab from "../MembersTab/MembersTab";
import useStyles from "./UnitDetails.styles";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
});

export interface UpdateUnitFormValues {
  name: string;
}

const UnitDetails = () => {
  const classes = useStyles();

  const [state, setState] = useState<{
    isLoading: boolean;
    isEditing: boolean;
    isUpdating: boolean;
    unit?: Unit;
    members?: Member[];
    roles?: Role[];
  }>({
    isLoading: true,
    isEditing: false,
    isUpdating: false,
    unit: undefined,
    members: undefined,
    roles: undefined,
  });

  const { unitId } = useParams<{ unitId: string }>();

  const user = useAppSelector(selectCurrentUser);

  const [getUnit] = useLazyGetUnitQuery();

  const [getUnitMembers] = useLazyGetUnitMembersQuery();

  const [getRoles] = useLazyGetRolesQuery();

  const history = useHistory();

  const [updateUnit] = useUpdateUnitMutation();

  const dispatch = useDispatch();

  const saveUnit = async (value: UpdateUnitFormValues) => {
    setState((s) => ({ ...s, isUpdating: true }));

    const result = await updateUnit({
      organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
      unitId,
      updateUnitPayload: value,
    }).unwrap();

    if (result.success) {
      formik.setValues({ name: values.name });
      setState((s) => ({
        ...s,
        isEditing: false,
        isUpdating: false,
        unit: s.unit ? { ...s.unit, name: value.name } : undefined,
      }));
    }
  };

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema,
    onSubmit: async (values: UpdateUnitFormValues) => {
      await saveUnit(values);
    },
  });

  const { values, handleSubmit, handleChange, setValues } = formik;

  useEffect(() => {
    const fetchUnit = async () => {
      const [{ unit }, { members }, { roles }] = await Promise.all([
        getUnit({
          organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
          unitId,
        }).unwrap(),

        getUnitMembers({
          organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
          unitId,
        }).unwrap(),

        getRoles().unwrap(),
      ]);

      dispatch(setMembers({ members }));

      setState((s) => ({
        ...s,
        isLoading: false,
        unit: unit,
        members: members,
        roles: roles,
      }));

      setValues({ name: unit.name });
    };

    fetchUnit();
  }, [
    getUnit,
    getUnitMembers,
    getRoles,
    setValues,
    dispatch,
    unitId,
    user.organizationId,
  ]);

  return state.isLoading ? (
    <Spinner
      animation="border"
      style={{ left: "50%", top: "50%", position: "relative" }}
    />
  ) : (
    <Grid item container>
      <Grid container item>
        <ArrowBack />
        <Typography
          color={"#000"}
          onClick={() => history.push("/grading/organization")}
          variant="button"
          className={classes.navigationButton}
        >
          ORGANIZATION
        </Typography>
        <Typography color={"#000"} variant="button">
          / UNIT DETAILS
        </Typography>
      </Grid>

      <Grid item xs={12} mt={1}>
        <Typography variant="h2">Unit Details</Typography>
      </Grid>

      <Grid item container xs={12} className={classes.bannerContainer}>
        {!state.isEditing ? (
          <>
            <Grid
              container
              item
              flex={1}
              alignItems={"center"}
              ml={theme.spacing(4)}
            >
              <Grid container item flexDirection={"column"}>
                <Typography
                  color={theme.palette.primary.contrastText}
                  variant={"subtitle1"}
                  textTransform={"uppercase"}
                >
                  Name
                </Typography>
                <Typography
                  color={theme.palette.primary.contrastText}
                  variant={"h3"}
                  textTransform={"uppercase"}
                >
                  {state.unit?.name}
                </Typography>
              </Grid>
            </Grid>

            <Divider orientation={"vertical"} className={classes.divider} />

            <Grid
              container
              item
              xs={2}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                color={theme.palette.primary.contrastText}
                variant={"subtitle1"}
                textTransform={"uppercase"}
              >
                Members
              </Typography>
              <Typography
                color={theme.palette.primary.contrastText}
                variant={"h4"}
                textTransform={"uppercase"}
              >
                {state.members?.length}
              </Typography>
            </Grid>

            <Divider orientation={"vertical"} className={classes.divider} />

            <Grid
              container
              item
              xs={1}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              className={classes.bannerButtonsContainer}
            >
              <Button
                variant="outlined"
                size="small"
                className={classes.bannerButtons}
                fullWidth
                onClick={() => setState((s) => ({ ...s, isEditing: true }))}
              >
                Edit
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              item
              flex={1}
              alignItems={"center"}
              ml={theme.spacing(4)}
            >
              <Grid container item flexDirection={"column"}>
                <Typography
                  color={theme.palette.primary.contrastText}
                  variant={"subtitle1"}
                  textTransform={"uppercase"}
                >
                  Name
                </Typography>
                <TextField
                  fullWidth
                  InputProps={{ className: classes.input }}
                  value={values.name}
                  onChange={handleChange("name")}
                  error={
                    inputError("name", formik.touched, formik.errors).error
                  }
                  helperText={
                    inputError("name", formik.touched, formik.errors).display
                  }
                />
              </Grid>
            </Grid>

            <Divider orientation={"vertical"} className={classes.divider} />

            <Grid
              container
              item
              xs={1}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              className={classes.bannerButtonsContainer}
            >
              <Button
                variant="outlined"
                size="small"
                className={classes.bannerButtons}
                fullWidth
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                className={classes.bannerButtons}
                fullWidth
                onClick={() => {
                  formik.setValues({ name: state.unit ? state.unit.name : "" });
                  setState((s) => ({ ...s, isEditing: false }));
                }}
              >
                Cancel
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      <Grid item container className={classes.memberTableContainer}>
        <MembersTab unitId={unitId} />
      </Grid>
    </Grid>
  );
};

export default UnitDetails;
