import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    maxHeight: "90%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
    boxshadow: "-2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
    overflow: "scroll",
    scrollbarWidth: "none",
  },

  button: {
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
    // width: "100%",
    justifyContent: "flex-start",
    gap: theme.spacing(1.5),
    color: theme.palette.primary.contrastText,
    backgroundColor: "#000",
    marginTop: theme.spacing(4),
    marginLeft: "auto",
    marginRight: "auto",
  },
  canvasClearButton: {
    cursor: "pointer",
  },
  closeIconButton: {
    marginLeft: "auto",
  },
  closeIcon: {
    color: "#000",
  },
}));

export default useStyles;
