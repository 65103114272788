import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
    boxshadow: "-2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
    zIndex: 99,
    scrollbarWidth: "none",
    overflowY: "scroll",
  },
  videoContainer: {
    borderRadius: theme.spacing(1),
    overflow: "hidden",
    marginTop: theme.spacing(3),
  },
  video: {
    width: "100%",
    height: "100%",
    borderRAdius: theme.spacing(1),
  },
  closeIconButton: {
    marginLeft: "auto",
  },
  closeIcon: {
    color: "#000",
  },
}));

export default useStyles;
