/* eslint-disable prefer-const */
import {
  FormControlLabel,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Profile, isDistanceInRepExercise } from "@readyfit-common/models";
import { memo, useEffect, useState } from "react";
import AthleteIcon from "src/grading/components/AthleteIcon/AthleteIcon";
import { RFSwitch } from "src/grading/components/RFSwitch/RFSwitch";
import {
  DecimalStepperSlider,
  IntegerStepperSlider,
  TimeStepperSlider,
  isCardioType,
  isPassFailType,
  isRepsType,
  isTimeExercise,
  isTimeMilliseconds,
  parseTime,
} from "src/grading/components/Stepper/RepsStepper/StepperInput";
import {
  ApprovalGradeResult,
  CardioApprovalGradeResult,
  GradeResult,
  RepCountGradeResult,
  RepTimeGradeResult,
} from "src/grading/models";
import { getSecondsFromTime } from "src/grading/utils";
import { getExerciseIndex } from "src/grading/utils/testHelpers";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  LiveTestStep,
  selectCurrentLiveTest,
  selectCurrentLiveTestAthletesResultRanges,
  selectLiveTestGradeResults,
  selectLiveTestStep,
  setAthletesLiveTestGradeResults,
} from "src/store/reducers/liveTest/liveTestSlice";
import {
  ExerciseFormat,
  TestStepDefinitionFormat,
} from "src/store/reducers/tests/testsApiSlice";
import theme from "src/theme";
import useStyles from "./GradeAthleteCard.styles";
import LiveTestApprovalButton from "./LiveTestApprovalButton/LiveTestApprovalButton";

interface GradeAthletesCardProps {
  athlete: Profile;
  // currentExercise: TestStepDefinitionFormat;
}

const GradeAthleteCard = memo(function InternalGradeAthleteCard({
  athlete, // currentExercise,
}: GradeAthletesCardProps) {
  const [choicedExerciseId, setChoicedExerciseId] = useState("");
  const [updatedCurrentGrade, setUpdatedCurrentGrade] = useState<
    | RepTimeGradeResult
    | RepCountGradeResult
    | CardioApprovalGradeResult
    | undefined
  >(undefined);
  const gradeResults = useAppSelector(selectLiveTestGradeResults);
  const liveTestStep = useAppSelector(selectLiveTestStep);
  const liveTest = useAppSelector(selectCurrentLiveTest);
  const exerciseStepIndex = getExerciseIndex(liveTestStep, liveTest?.steps);
  const currentExercise = liveTest?.steps[exerciseStepIndex];
  const currentAthleteLiveTestResultRanges = useAppSelector(
    selectCurrentLiveTestAthletesResultRanges,
  )?.find((resultRange) => resultRange.id === athlete.id);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const classes = useStyles({
    hasChoices: currentExercise?.choices !== undefined,
  })();

  const getCurrentGrade = (id: string) => {
    const currentGrade = gradeResults?.find(
      (currentGrade) => currentGrade.profile.id === id,
    );

    const stepVal = liveTestStep - 2;

    if (currentGrade?.gradeResults[stepVal]) {
      return currentGrade?.gradeResults[stepVal];
    }
  };
  const currentGrade = getCurrentGrade(athlete.id);

  const [choicedExercise, setChoicedExercise] = useState<
    ExerciseFormat | TestStepDefinitionFormat | undefined
  >(currentExercise);

  const startTime = Date.now();
  const dispatch = useAppDispatch();

  const onStepGrade = (
    stepGrade: GradeResult,
    stepOverride?: number,
    profile?: Profile,
  ) => {
    // eslint-disable-next-line prefer-const
    let results = gradeResults.slice();
    const stepVal = stepOverride ?? liveTestStep - 2;

    const stepGradeIndex = results.findIndex(
      (currentGrade) => currentGrade.profile.id === profile?.id,
    );

    if (stepGradeIndex === -1 && profile) {
      results.push({
        gradeResults: [stepGrade],
        profile,
        athleteSignatureDetails: {
          agreed: false,
          disagreementReason: "",
          signature: "",
        },
        graderSignatureDetails: {
          agreed: false,
          disagreementReason: "",
          signature: "",
        },
      });
      const isFirstEntryToGradeResults = stepGradeIndex === -1;
      dispatch(
        setAthletesLiveTestGradeResults({
          athleteLiveTestGradeResults: results,
          isFirstEntryToGradeResults,
        }),
      );
    } else {
      const updatedResults = results.map((result, index) => {
        const newGradeResults = result.gradeResults.slice();
        newGradeResults[stepVal] = stepGrade;
        const newValue = {
          ...result,
          gradeResults: newGradeResults,
        };
        if (index === stepGradeIndex) {
          return newValue;
        }

        return result;
      });

      dispatch(
        setAthletesLiveTestGradeResults({
          athleteLiveTestGradeResults: updatedResults,
        }),
      );
    }
  };

  useEffect(() => {
    let exercise = { ...currentExercise };
    if (currentExercise?.choices) {
      if (currentGrade?.userResult?.exerciseId) {
        const ex = currentExercise?.choices.find(
          (choice) => choice.exerciseId === currentGrade.userResult.exerciseId,
        );
        setChoicedExercise(ex!);
        exercise = ex!;
        setChoicedExerciseId(currentGrade.userResult.exerciseId);
      } else if (currentExercise?.choices[0].exerciseId) {
        setChoicedExercise(currentExercise?.choices[0]);
        exercise = currentExercise?.choices[0];
        setChoicedExerciseId(currentExercise?.choices[0].exerciseId);
      }
    }

    const initialGradeResultValues: GradeResult = {
      discrepancies: [],
      notes: "",
      exceededRestTime: currentGrade?.exceededRestTime || false,
      userResult: {
        startTime,
        endTime: Date.now(),
        exerciseId: exercise.exerciseId!,
        name: exercise.displayName!,
      },
    };
    let updatedValues: any = {};

    if (isTimeExercise(exercise?.exerciseType)) {
      updatedValues = {
        ...initialGradeResultValues,
        graderTime: {
          minutes:
            (currentGrade as RepTimeGradeResult)?.graderTime?.minutes || 0,
          seconds:
            (currentGrade as RepTimeGradeResult)?.graderTime?.seconds || 0,
        },
        userResult: {
          ...initialGradeResultValues.userResult,
          reps: "0",
          fileKey: "",
          filename: "",
        },
      } as RepTimeGradeResult;
      setUpdatedCurrentGrade(updatedValues);
    }

    if (isRepsType(exercise?.exerciseType)) {
      updatedValues = {
        ...initialGradeResultValues,
        graderCount: (currentGrade as RepCountGradeResult)?.graderCount || 0,
        userResult: {
          ...initialGradeResultValues.userResult,
          reps: "0",
          fileKey: "",
          filename: "",
        },
      } as RepCountGradeResult;
    }

    if (isCardioType(choicedExercise?.exerciseType)) {
      updatedValues = {
        ...initialGradeResultValues,
        approved: (currentGrade as CardioApprovalGradeResult)?.approved,
        graderTime: {
          minutes:
            (currentGrade as RepTimeGradeResult)?.graderTime?.minutes || 0,
          seconds:
            (currentGrade as RepTimeGradeResult)?.graderTime?.seconds || 0,
        },
        userResult: {
          ...initialGradeResultValues.userResult,
          distance: choicedExercise?.testing?.distance?.value
            ? String(choicedExercise?.testing?.distance?.value)
            : "0",
          units: "miles",
          duration: (currentGrade as CardioApprovalGradeResult)?.userResult
            ?.duration,
          // fileKey: "",
        },
      } as CardioApprovalGradeResult;
      setUpdatedCurrentGrade(updatedValues);
    }

    if (
      isTimeExercise(exercise?.exerciseType) &&
      isTimeMilliseconds(choicedExercise)
    ) {
      updatedValues = {
        ...initialGradeResultValues,
        graderCount: (currentGrade as RepCountGradeResult)?.graderCount || 0,
        userResult: {
          ...initialGradeResultValues.userResult,
          reps: "0",
        },
      } as RepCountGradeResult;
      setUpdatedCurrentGrade(updatedValues);
    }

    onStepGrade(updatedValues, undefined, athlete);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect to determine the type of gradeResult for the current exerciseType;
  useEffect(() => {
    if (choicedExercise?.exerciseType) {
      const initialGradeResultValues: GradeResult = {
        discrepancies: [],
        notes: "",
        exceededRestTime: currentGrade?.exceededRestTime || false,
        userResult: {
          startTime,
          endTime: Date.now(),
          exerciseId: choicedExercise.exerciseId!,
          name: choicedExercise.displayName!,
        },
      };
      let updatedValues: any = {};

      // SET GRADE RESULT AS RepTimeGradeResult
      if (isTimeExercise(choicedExercise?.exerciseType)) {
        updatedValues = {
          ...initialGradeResultValues,
          graderTime: {
            minutes:
              (currentGrade as RepTimeGradeResult)?.graderTime?.minutes || 0,
            seconds:
              (currentGrade as RepTimeGradeResult)?.graderTime?.seconds || 0,
          },
          userResult: {
            ...initialGradeResultValues.userResult,
            reps: "0",
            fileKey: "",
            filename: "",
          },
        } as RepTimeGradeResult;
        setUpdatedCurrentGrade(updatedValues);
      }

      if (isRepsType(choicedExercise?.exerciseType)) {
        updatedValues = {
          ...initialGradeResultValues,
          graderCount: (currentGrade as RepCountGradeResult)?.graderCount || 0,
          userResult: {
            ...initialGradeResultValues.userResult,
            reps: "0",
            fileKey: "",
            filename: "",
          },
        } as RepCountGradeResult;
        setUpdatedCurrentGrade(updatedValues);
      }

      if (isCardioType(choicedExercise.exerciseType)) {
        updatedValues = {
          ...initialGradeResultValues,
          approved: (currentGrade as CardioApprovalGradeResult)?.approved,
          graderTime: {
            minutes:
              (currentGrade as RepTimeGradeResult)?.graderTime?.minutes || 0,
            seconds:
              (currentGrade as RepTimeGradeResult)?.graderTime?.seconds || 0,
          },
          userResult: {
            ...initialGradeResultValues.userResult,
            distance: choicedExercise?.testing?.distance?.value
              ? String(choicedExercise?.testing?.distance?.value)
              : "0",
            units: "miles",
            duration: (currentGrade as CardioApprovalGradeResult)?.userResult
              ?.duration,
            // fileKey: "",
          },
        } as CardioApprovalGradeResult;
        setUpdatedCurrentGrade(updatedValues);
      }

      if (
        isTimeExercise(choicedExercise?.exerciseType) &&
        isTimeMilliseconds(choicedExercise)
      ) {
        updatedValues = {
          ...initialGradeResultValues,
          graderCount: (currentGrade as RepCountGradeResult)?.graderCount || 0,
          userResult: {
            ...initialGradeResultValues.userResult,
            reps: "0",
          },
        } as RepCountGradeResult;
        setUpdatedCurrentGrade(updatedValues);
      }

      // onStepGrade(updatedValues, undefined, athlete);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choicedExercise, currentGrade]);

  const handleChangedExercise = (id: string) => {
    setChoicedExerciseId(id);
    if (currentExercise?.choices) {
      const ex = currentExercise?.choices.find(
        (choice) => choice.exerciseId === id,
      );
      setChoicedExercise(ex!);
      setChoicedExerciseId(id);

      const gradeResult = {
        ...updatedCurrentGrade,
        userResult: {
          ...updatedCurrentGrade?.userResult,
          exerciseId: id,
          name: ex?.displayName,
          exerciseType: ex?.exerciseType,
        },
      } as GradeResult;

      onStepGrade(gradeResult, undefined, athlete);
    }
  };

  const disableGradeInputs =
    !currentGrade?.userResult?.exerciseId && !choicedExercise?.exerciseId;

  const exerciseResultRanges =
    currentAthleteLiveTestResultRanges?.resultRanges[
      choicedExercise?.exerciseId as string
    ];

  // const maxSliderValue =
  //   (isTimeExercise(choicedExercise?.exerciseType) &&
  //   !isMaxTimeExercise(choicedExercise?.exerciseType)
  //     ? exerciseResultRanges?.min
  //     : exerciseResultRanges?.max) || 100;

  const isFirstExercise = liveTestStep === LiveTestStep.TEST_OVERVIEW + 1;

  const maxSliderValue = exerciseResultRanges?.max || 100;

  return (
    <Grid item container xs={12}>
      {currentExercise?.choices && (
        <Grid item container className={classes.alternateExerciseRowContainer}>
          <FormControlLabel
            control={
              <RFSwitch
                onChange={(event, checked) => {
                  if (checked && currentExercise.choices !== undefined) {
                    handleChangedExercise(
                      currentExercise?.choices[1].exerciseId as string,
                    );
                  } else if (currentExercise.choices !== undefined) {
                    handleChangedExercise(
                      currentExercise?.choices[0].exerciseId as string,
                    );
                  }
                }}
                checked={
                  choicedExerciseId === currentExercise?.choices[1]?.exerciseId
                }
                activeColor={theme.palette.primary.main}
                inactiveColor={theme.palette.primary.contrastText}
                inactiveTrackColor={"gray"}
              />
            }
            label={"Alternate Exercise"}
            labelPlacement="end"
            className={classes.alternateExerciseRow}
          />
        </Grid>
      )}
      <Grid item container xs={12} className={classes.athleteRow} gap={2}>
        {/* ALTERNATE EXERCISE SELECTOR*/}

        <Grid item container className={classes.athleteFirstRow}>
          <Grid
            item
            container
            xs={3}
            alignItems={"center"}
            className={classes.athleteInfoContainer}
          >
            <AthleteIcon profile={athlete} />
            <Grid
              item
              container
              flex={1}
              marginLeft={isSmallScreen ? 1.5 : 2.5}
            >
              <Typography variant="h4">
                {athlete.firstName} {athlete.lastName}
              </Typography>
            </Grid>
          </Grid>
          {/* {currentExercise?.choices && (
          <Grid
            item
            container
            xs={3}
            className={classes.alternateExerciseOptionContainer}
            marginLeft={"auto"}
          >
            <FormControl fullWidth>
              <InputLabel>Alternate exercise option</InputLabel>
              <Select
                value={
                  currentGrade?.userResult?.exerciseId || choicedExerciseId
                }
                onChange={handleChangedExercise}
                label="Alternate exercise option"
              >
                {currentExercise.choices.map((choice) => (
                  <MenuItem value={choice.exerciseId} key={choice.exerciseId}>
                    {choice.displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )} */}
          <Grid
            item
            container
            flex={1}
            justifyContent={"flex-end"}
            alignItems={"center"}
            spacing={2}
          >
            {/* REST TIME SWITCH */}
            {!isFirstExercise && (
              <Grid
                item
                container
                xs={3.5}
                justifyContent={"flex-end"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Typography
                  variant="subtitle1"
                  color={"#666666"}
                  textTransform={"uppercase"}
                >
                  Rest Time
                </Typography>
                <FormControlLabel
                  control={
                    <RFSwitch
                      onChange={(event, checked) => {
                        const gradeResult = {
                          ...updatedCurrentGrade,
                          exceededRestTime: checked,
                        } as GradeResult;

                        onStepGrade(gradeResult, undefined, athlete);
                      }}
                      color={
                        currentGrade?.exceededRestTime !== true
                          ? "primary"
                          : "error"
                      }
                      checked={currentGrade?.exceededRestTime}
                    />
                  }
                  label={
                    <Typography variant={"subtitle1"}>
                      {currentGrade?.exceededRestTime !== true
                        ? "On Time"
                        : "Over Time"}
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </Grid>
            )}

            {/* SLIDER */}
            <Grid item container flex={1} paddingLeft={1} paddingRight={2}>
              {!isPassFailType(choicedExercise?.exerciseType) && (
                <Typography
                  variant="subtitle1"
                  color={"#666666"}
                  textTransform={"uppercase"}
                >
                  {choicedExercise?.testing?.userEnteredResult?.label ||
                    (choicedExercise?.results &&
                      isDistanceInRepExercise(choicedExercise?.results) &&
                      "Distance (In Feet)") ||
                    (isTimeExercise(choicedExercise?.exerciseType) && "Time") ||
                    "Repetitions"}
                </Typography>
              )}

              {!isTimeExercise(choicedExercise?.exerciseType) &&
                isRepsType(choicedExercise?.exerciseType) &&
                choicedExercise?.testing?.userEnteredResult?.type !==
                  "decimal" && (
                  // <IntegerStepperInput
                  //   placeholder="0"
                  //   value={
                  //     (updatedCurrentGrade as RepCountGradeResult)?.graderCount ||
                  //     0
                  //   }
                  //   onChange={(value) => {
                  //     const gradeResult = {
                  //       ...currentGrade,
                  //       graderCount: value,
                  //       userResult: {
                  //         ...currentGrade?.userResult,
                  //         exerciseId: choicedExercise?.exerciseId,
                  //         name: choicedExercise?.displayName,
                  //         fileKey: "",
                  //       },
                  //     } as RepCountGradeResult;

                  //     onStepGrade(gradeResult, undefined, athlete);
                  //   }}
                  //   isDecrementDisabled={disableGradeInputs}
                  //   isIncrementDisabled={disableGradeInputs}
                  // />
                  <>
                    <IntegerStepperSlider
                      placeholder="0"
                      value={
                        (updatedCurrentGrade as RepCountGradeResult)
                          ?.graderCount || 0
                      }
                      onChange={(value) => {
                        const gradeResult = {
                          ...currentGrade,
                          graderCount: value,
                          userResult: {
                            ...currentGrade?.userResult,
                            exerciseId: choicedExercise?.exerciseId,
                            name: choicedExercise?.displayName,
                            fileKey: "",
                          },
                        } as RepCountGradeResult;

                        onStepGrade(gradeResult, undefined, athlete);
                      }}
                      isDecrementDisabled={disableGradeInputs}
                      isIncrementDisabled={disableGradeInputs}
                      max={maxSliderValue}
                    />
                  </>
                )}
              {(isTimeExercise(choicedExercise?.exerciseType) &&
                isTimeMilliseconds(choicedExercise)) ||
                (choicedExercise?.testing?.userEnteredResult?.type ===
                  "decimal" && (
                  // <DecimalStepperInput
                  //   placeholder="0.0"
                  //   precision={0.1}
                  //   step={0.1}
                  //   value={
                  //     (updatedCurrentGrade as RepCountGradeResult)
                  //       ?.graderCount || 0
                  //   }
                  //   onChange={(value) => {
                  //     const graderResult = {
                  //       ...currentGrade,
                  //       graderCount: value,
                  //       userResult: {
                  //         ...currentGrade?.userResult,
                  //         exerciseId: choicedExercise?.exerciseId,
                  //         name: choicedExercise?.displayName,
                  //       },
                  //     } as RepCountGradeResult;

                  //     onStepGrade(graderResult, liveTestStep - 2, athlete);
                  //   }}
                  // />
                  <DecimalStepperSlider
                    placeholder="0.0"
                    precision={0.1}
                    step={0.1}
                    value={
                      (updatedCurrentGrade as RepCountGradeResult)
                        ?.graderCount || 0
                    }
                    onChange={(value) => {
                      const graderResult = {
                        ...currentGrade,
                        graderCount: value,
                        userResult: {
                          ...currentGrade?.userResult,
                          exerciseId: choicedExercise?.exerciseId,
                          name: choicedExercise?.displayName,
                        },
                      } as RepCountGradeResult;

                      onStepGrade(graderResult, liveTestStep - 2, athlete);
                    }}
                    max={maxSliderValue}
                  />
                ))}
              {isTimeExercise(choicedExercise?.exerciseType) &&
                !isTimeMilliseconds(choicedExercise) &&
                isCardioType(choicedExercise?.exerciseType) && (
                  // <TimeStepperInput
                  //   placeholder="00:00"
                  //   value={
                  //     (updatedCurrentGrade as CardioApprovalGradeResult)
                  //       ?.graderTime
                  //   }
                  //   onChange={(value) => {
                  //     let gradeResult = {
                  //       ...currentGrade,
                  //       graderTime: value,
                  //       // approved: !updatedCurrentGrade?.exceededRestTime,
                  //       userResult: {
                  //         ...currentGrade?.userResult,
                  //         exerciseId: choicedExercise?.exerciseId,
                  //         name: choicedExercise?.displayName,
                  //         fileKey: "",
                  //         distance: choicedExercise?.testing?.distance?.value
                  //           ? String(choicedExercise?.testing?.distance?.value)
                  //           : "0",
                  //         units: "miles",
                  //         duration: updatedCurrentGrade?.userResult?.duration,
                  //         filename: "",
                  //       },
                  //     } as CardioApprovalGradeResult;

                  //     onStepGrade(gradeResult, undefined, athlete);
                  //   }}
                  // />

                  <TimeStepperSlider
                    placeholder="00:00"
                    value={
                      (updatedCurrentGrade as CardioApprovalGradeResult)
                        ?.graderTime
                    }
                    onChange={(value) => {
                      let gradeResult = {
                        ...currentGrade,
                        graderTime: value,
                        // approved: !updatedCurrentGrade?.exceededRestTime,
                        userResult: {
                          ...currentGrade?.userResult,
                          exerciseId: choicedExercise?.exerciseId,
                          name: choicedExercise?.displayName,
                          fileKey: "",
                          distance: choicedExercise?.testing?.distance?.value
                            ? String(choicedExercise?.testing?.distance?.value)
                            : "0",
                          units: "miles",
                          duration: updatedCurrentGrade?.userResult?.duration,
                          filename: "",
                        },
                      } as CardioApprovalGradeResult;

                      onStepGrade(gradeResult, undefined, athlete);
                    }}
                    max={
                      //we need to parse a time string to a totaal seconds number to get the max value
                      // ex: "01:00" => 60
                      getSecondsFromTime(
                        parseTime(
                          maxSliderValue ? maxSliderValue.toString() : "01:00",
                        ),
                        true,
                      )
                    }
                  />
                )}
              {isTimeExercise(choicedExercise?.exerciseType) &&
                !isTimeMilliseconds(choicedExercise) &&
                !isCardioType(choicedExercise?.exerciseType) && (
                  // <TimeStepperInput
                  //   placeholder="00:00"
                  //   value={
                  //     (updatedCurrentGrade as RepTimeGradeResult)?.graderTime
                  //   }
                  //   onChange={(value) => {
                  //     let gradeResult = {
                  //       ...currentGrade,
                  //       graderTime: value,
                  //       userResult: {
                  //         ...currentGrade?.userResult,
                  //         exerciseId: choicedExercise?.exerciseId,
                  //         name: choicedExercise?.displayName,
                  //         fileKey: "",
                  //       },
                  //     } as RepTimeGradeResult;

                  //     onStepGrade(gradeResult, undefined, athlete);
                  //   }}
                  // />

                  <TimeStepperSlider
                    placeholder="00:00"
                    value={
                      (updatedCurrentGrade as RepTimeGradeResult)?.graderTime
                    }
                    onChange={(value) => {
                      let gradeResult = {
                        ...currentGrade,
                        graderTime: value,
                        userResult: {
                          ...currentGrade?.userResult,
                          exerciseId: choicedExercise?.exerciseId,
                          name: choicedExercise?.displayName,
                          fileKey: "",
                        },
                      } as RepTimeGradeResult;

                      onStepGrade(gradeResult, undefined, athlete);
                    }}
                    max={
                      //we need to parse a time string to a totaal seconds number to get the max value
                      // ex: "01:00" => 60
                      getSecondsFromTime(
                        parseTime(
                          maxSliderValue ? maxSliderValue.toString() : "01:00",
                        ),
                        true,
                      )
                    }
                  />
                )}

              {isPassFailType(choicedExercise?.exerciseType) && (
                <>
                  <LiveTestApprovalButton
                    default={
                      (currentGrade as CardioApprovalGradeResult)?.approved
                    }
                    onClick={(status) => {
                      const gradeResult = {
                        ...currentGrade,
                        approved: status,
                        userResult: {
                          ...currentGrade?.userResult,
                          exerciseId: choicedExercise?.exerciseId,
                          name: choicedExercise?.displayName,
                          passFail: status,
                        },
                      } as ApprovalGradeResult;

                      onStepGrade(gradeResult, undefined, athlete);
                    }}
                  />
                </>
              )}
            </Grid>

            {/* STANDARDS */}
            <Grid
              item
              container
              xs={2}
              className={classes.standarsContainer}
              marginLeft={currentExercise?.choices ? 0 : "auto"}
              textAlign={"center"}
            >
              <Typography
                variant="subtitle1"
                color={"#666666"}
                textTransform={"uppercase"}
              >
                Standards
              </Typography>
              {choicedExercise?.testing?.distance?.value ? (
                <Typography
                  variant="subtitle1"
                  textTransform={"uppercase"}
                  marginRight={theme.spacing(2)}
                >
                  {exerciseResultRanges?.min}
                  {" min / "}
                  {exerciseResultRanges?.max} max
                </Typography>
              ) : (
                <Typography variant="subtitle1" textTransform={"uppercase"}>
                  {exerciseResultRanges?.min || 0} min /{" "}
                  {exerciseResultRanges?.max || 100} max
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default GradeAthleteCard;
