import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = ({
  color,
  hoverColor,
}: {
  color: string;
  hoverColor: string;
}) =>
  makeStyles((theme: Theme) => ({
    buttonContainer: {
      marginTop: theme.spacing(2.5),
    },
    button: {
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: theme.spacing(1),
      width: "100%",
      justifyContent: "flex-start",
      gap: theme.spacing(1.5),
    },
    passButton: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    failButton: {
      borderColor: theme.palette.error.main,
      color: theme.palette.success.contrastText,
    },
    fillSelected: {
      backgroundColor: color,
      border: `1px solid ${color}`,
      color: "black",
      "&:hover": {
        background: hoverColor,
      },
    },
  }));

export default useStyles;
