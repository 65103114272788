import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: "10px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.15)",
    marginTop: "-10px",
    padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
  },
  titleContainer: {
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  },
  stepCount: {
    textTransform: "uppercase",
    backgroundColor: "#D6D6D6",
    color: "#333333",
    borderRadius: "5px",
    marginLeft: theme.spacing(2),
    display: "flex",
    padding: `0 ${theme.spacing(1)}`,
    alignItems: "center",
    justifyContent: "center",
  },
  bannerContainer: {
    marginTop: theme.spacing(4),
    backgroundColor: "#262F34",
    overflow: "hidden",
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  icon: {
    color: theme.palette.primary.contrastText,
    fontSize: theme.spacing(3),
  },
  stepCircle: {
    backgroundColor: "black",
    borderRadius: "50%",
    width: theme.spacing(3),
    height: theme.spacing(3),
    position: "relative",
  },
  circleText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#fff",
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: "1px solid #3F4E57",
  },
  toolsContainer: {
    marginTop: theme.spacing(4),
  },
  searchField: {},
  searchInputField: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    "&:placeholder": {
      color: "red",
    },
  },
  addAthleteButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.error.contrastText,
    heightt: "100%",
    border: "1px solid #DBDBDB",
    borderRadius: theme.spacing(1.25),
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  selectedRow: {
    backgroundColor: theme.palette.primary.light,
  },
}));

export default useStyles;
