export type Permission = {
  action: string;
  subject: string;
  description?: string;
};

// export const permission: Permission[] = [
//   {
//     action: "read",
//     subject: "grading-app:pending-tests",
//     description: "Read pending tests",
//   },
//   {
//     action: "read",
//     subject: "grading-app:certified-tests",
//     description: "Read certified tests",
//   },
//   {
//     action: "grade",
//     subject: "grading-app:pending-tests",
//     description: "grade pending-tests",
//   },
//   {
//     action: "re-grade",
//     subject: "grading-app:certified-tests",
//     description: "re-grade certified tests",
//   },
//   {
//     action: "grade",
//     subject: "grading-app:live-tests",
//     description: "grade live-tests",
//   },
//   {
//     action: "create",
//     subject: "grading-app:athletes",
//     description: "create athletes",
//   },
//   {
//     action: "read",
//     subject: "grading-app:organizations",
//     description: "read organization",
//   },
//   {
//     action: "create",
//     subject: "grading-app:organizations:members",
//     description: "create organization members",
//   },
// ];

export const permissions: { [key: string]: Permission } = {
  READ_PENDING_TESTS: {
    action: "read",
    subject: "grading-app:pending-tests",
    description: "Read pending tests",
  },
  READ_CERTIFIED_TESTS: {
    action: "read",
    subject: "grading-app:certified-tests",
    description: "Read certified tests",
  },
  READ_LIVE_TESTS: {
    action: "read",
    subject: "grading-app:live-tests",
    description: "Read Live tests",
  },
  READ_ORGANIZATION: {
    action: "read",
    subject: "grading-app:organization",
    description: "Read organization",
  },
  READ_DEMO_TESTS: {
    action: "read",
    subject: "grading-app:demo-tests",
    description: "Read demo tests",
  },
  UPDATE_ORGANIZATION_RP: {
    action: "update",
    subject: "account-service:organizations:own:recruitment-process",
    description: "Update own organization recruitment process",
  },
  UPDATE_ATHLETE_RP: {
    action: "update",
    subject: "grading-app:athlete-recruitment-process",
    description: "Update athlete recruitment process",
  },
  READ_ATHLETES: {
    action: "read",
    subject: "grading-app:athletes",
    description: "Read athletes",
  },
};

export const checkRoles = (allowedRoles: string[], roles: string[]) => {
  return roles.some((v) => {
    return allowedRoles.indexOf(v) >= 0;
  });
};

export const allowedRoles = [
  "admins",
  "master_graders",
  "organization_owners",
  "organization_super_admins",
  "organization_admins",
  "organization_graders",
];
