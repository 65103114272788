import { Search } from "@mui/icons-material";
import { TabContext, TabList } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { InputError } from "src/grading/components/InputError";
import { useAppDispatch } from "src/store/hooks";
import { clearBreadcrumbs } from "src/store/reducers/navigation/navigationSlice";
import {
  TestScore,
  useLazyGetScoresQuery,
} from "src/store/reducers/scores/scoresApiSlice";
import { ResultPage } from "../../tests";
import InPersonTestsGrid from "./InPersonTestsGrid/InPersonTestsGrid";
import useStyles from "./TestGrid.styles";
import VirtualTestsGrid from "./VirtualTestsGrid/VirtualTestsGrid";

enum TabValues {
  Virtual = "0",
  InPerson = "1",
}

export function TestGrid(props: TestGridProps) {
  const [data, setData] = useState([] as TestScore[]);
  const [loadState, setLoadState] = useState(
    "NEW" as "NEW" | "LOADING" | "DONE",
  );
  const [nextPage, setNextPage] = useState(
    undefined as Promise<ResultPage<TestScore>> | undefined,
  );
  const [getScores] = useLazyGetScoresQuery();
  const dispatch = useAppDispatch();
  const classes = useStyles();

  const [tabValue, setTabValue] = useState(TabValues.Virtual);
  const location = useLocation();

  const isCertifiedPage = location.pathname.includes("/grading/certified");
  const isPendingPage = location.pathname.includes("/grading/pending");

  const formik = useFormik({
    initialValues: {
      search: "",
      test: -1,
      activity: -1,
    },
    // validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      console.log("values", values);
    },
  });

  const { touched, values, errors, setFieldValue } = formik;

  useEffect(() => {
    setLoadState("NEW");
    dispatch(clearBreadcrumbs());
  }, [props.certified, props.userId, dispatch]);

  if (loadState === "NEW") {
    setLoadState("LOADING");
    getScores({ certified: props.certified, userId: props.userId })
      .unwrap()
      .then((results) => {
        setData(results.testScores);
        setLoadState("DONE");
      });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((searchValue) => {
      setLoadState("LOADING");
      getScores({
        certified: props.certified,
        userId: props.userId,
        searchValue,
      })
        .unwrap()
        .then((results) => {
          setData(results.testScores);
          setLoadState("DONE");
        });
    }, 500),
    [],
  );

  // if (true) {
  if (loadState === "NEW" || loadState === "LOADING") {
    return <CircularProgress className="center-spinner" />;
  } else {
    const gridData = data.map((item) => ({
      id: item.testResultId,
      userId: item.profileSnapshot.id,
      testTitle: item.testTitle,
      email: item.profileSnapshot.email,
      date: new Date(+item.timestamp),
      scoreStatus: item.certified,
      score:
        item.score && item.maxScore
          ? `${item.score}/${item.maxScore}`
          : "Not Scored",
      timestamp: item.timestamp,
      profileSnapshot: item.profileSnapshot,
      branch: item.branch,
      liveTestId: item.liveTestId,
    }));

    return (
      <Grid container classes={classes.container}>
        {!props.hideTitle && (
          <Grid item xs={12} mt={1}>
            <Typography variant="h2">
              {props.certified === "pending"
                ? "Virtual Tests"
                : "Certified Tests"}{" "}
            </Typography>
          </Grid>
        )}
        {isCertifiedPage && (
          <Grid item container xs={12} mt={5}>
            <Box className={classes.tabsContainer}>
              <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList
                    onChange={(event, newValue) => setTabValue(newValue)}
                    aria-label="lab API tabs example"
                    TabIndicatorProps={{
                      style: {
                        color: "white",
                        height: "100%",
                      },
                      className: classes.tabIndicator,
                    }}
                  >
                    <Tab
                      title="Virtual"
                      value={TabValues.Virtual}
                      label={"Virtual"}
                      className={classes.tab}
                    />
                    <Tab
                      title="In Person"
                      value={TabValues.InPerson}
                      label={"In Person"}
                      className={classes.tab}
                    />
                  </TabList>
                </Box>
              </TabContext>
            </Box>
          </Grid>
        )}

        {/* FILTERS */}
        <Grid item container className={classes.filtersContainer}>
          <TextField
            label="Search"
            name="search"
            type="text"
            value={values.search}
            onChange={(e) => {
              setFieldValue("search", e.target.value);
              debounceSearch(e.target.value);
            }}
            style={{ paddingBottom: "20px" }}
            InputProps={{
              className: "text-field",
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
            placeholder="Search by user"
            error={InputError("search", touched, errors).error}
            helperText={InputError("search", touched, errors).helperText}
          />

          <Grid item sx={{ flex: 1 }}>
            <Box display="flex" justifyContent="flex-end" gap={1} />
          </Grid>
        </Grid>

        {tabValue === TabValues.Virtual || isPendingPage ? (
          <VirtualTestsGrid gridData={gridData} />
        ) : (
          <InPersonTestsGrid
            certified={props.certified}
            athleteId={props.userId}
          />
        )}

        {nextPage !== undefined && (
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ marginLeft: 16 }}
            onClick={async () => {
              if (nextPage !== undefined) {
                setLoadState("LOADING");
                const results = await nextPage;
                setNextPage(
                  results.nextPage !== undefined
                    ? results.nextPage()
                    : undefined,
                );
                setData(results.data);
                setLoadState("DONE");
              }
            }}
          >
            Next Page
          </Button>
        )}
      </Grid>
    );
  }
}

export type TestGridProps = {
  certified?: TestScore["certified"];
  userId?: string;
  hideTitle?: boolean;
};
