import { Checkbox, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Profile } from "@readyfit-common/models";
import { capitalize } from "lodash";
import AthleteIcon from "src/grading/components/AthleteIcon/AthleteIcon";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectLiveTestGradeResults,
  selectSelectedAthletes,
  setAthletesLiveTestGradeResults,
  setSelectedAthletes,
} from "src/store/reducers/liveTest/liveTestSlice";
import theme from "src/theme";
import useStyles from "./SelectAthleteTableColumns.style";

export type AthleteRow = { row: Profile };

const AthleteColumn = ({ row }: AthleteRow) => {
  const classes = useStyles();
  return (
    <div className={classes.profileIconContainer}>
      {/* <div className={classes.profileIcon} /> */}
      <AthleteIcon profile={row} />
      <Grid container flex={1} className={classes.profileText}>
        <Typography variant="subtitle1">Athlete</Typography>
        <Typography variant="h4" fontWeight={"bold"} whiteSpace={"initial"}>
          {row.firstName && row.lastName
            ? row.firstName + " " + row.lastName
            : row.email}
        </Typography>
      </Grid>
    </div>
  );
};

const SelectAthleteCheckbox = ({ row }: AthleteRow) => {
  // const classes = useStyles();
  const dispatch = useAppDispatch();
  const selectedAthletes = useAppSelector(selectSelectedAthletes);
  const updatedSelectedAthletes = [...selectedAthletes];
  const athleteLiveTestGradeResults = useAppSelector(
    selectLiveTestGradeResults,
  );
  const updatedAthleteLiveTestGradeResults = [...athleteLiveTestGradeResults];

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      updatedSelectedAthletes.push(row);
    } else {
      const index = updatedSelectedAthletes.findIndex(
        (athlete) => athlete.id === row.id,
      );
      updatedSelectedAthletes.splice(index, 1);

      const athleteGradeResultIndex =
        updatedAthleteLiveTestGradeResults.findIndex(
          (athlete) => athlete.profile.id === row.id,
        );

      if (athleteGradeResultIndex !== -1) {
        updatedAthleteLiveTestGradeResults.splice(athleteGradeResultIndex, 1);
      }
    }
    dispatch(setSelectedAthletes(updatedSelectedAthletes));
    dispatch(
      setAthletesLiveTestGradeResults({
        athleteLiveTestGradeResults: updatedAthleteLiveTestGradeResults,
      }),
    );
  };

  return (
    <Grid ml={"auto"}>
      <Checkbox
        checked={selectedAthletes.some((athlete) => athlete.id === row.id)}
        onChange={(event) => {
          handleCheckboxChange(event);
        }}
        inputProps={{ "aria-label": "controlled" }}
      />
    </Grid>
  );
};

const SelectAthleteTableColumns = (): GridColDef[] => [
  {
    field: "firstName",
    headerName: "Athlete",
    // headerAlign: "center",
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    renderCell: ({ row }: AthleteRow) => <AthleteColumn row={row} />,
  },
  // {
  //   field: "applicantId",
  //   headerName: "Applicant Id",
  //   headerAlign: "center",
  //   flex: 1,
  //   // width: 350,
  //   // maxWidth: 350,
  //   editable: false,
  //   sortable: true,
  //   renderCell: ({ row }: AthleteRow) => (
  //     <div>
  //       <Typography variant="subtitle1">Applicant ID</Typography>
  //       <Typography variant="h4" fontWeight={"bold"}>
  //         {capitalize(row.branchOfService) || "-"}
  //       </Typography>
  //     </div>
  //   ),
  // },
  {
    field: "branchOfService",
    headerName: "Unit",
    headerAlign: "center",
    flex: 1,
    // width: 400,
    // maxWidth: 400,
    editable: false,
    sortable: true,
    renderCell: ({ row }: AthleteRow) => (
      <Grid
        width={"100%"}
        borderRight={`1px solid ${theme.palette.secondary.light}`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="subtitle1">Unit</Typography>
        <Typography variant="h4" fontWeight={"bold"}>
          {capitalize(row.branchOfService) || "-"}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "",
    headerName: "",
    headerAlign: "center",
    flex: 1,
    maxWidth: 80,
    width: 80,
    editable: false,
    // sortable: true,
    renderCell: ({ row }: AthleteRow) => <SelectAthleteCheckbox row={row} />,
  },
];

export default SelectAthleteTableColumns;
