import { Grid } from "@mui/material";
import React, { ReactNode } from "react";
import useStyles from "./AthletesLayout.styles";

export interface AthletesLayoutProps {
  children: ReactNode;
}

const AthletesLayout = ({ children }: AthletesLayoutProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      {children}
    </Grid>
  );
};

export default AthletesLayout;
