import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  bannerContainer: {
    marginTop: theme.spacing(5),
    backgroundColor: "#262F34",
    overflow: "hidden",
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: "1px solid #3F4E57",
  },
  tabsContainer: {
    marginTop: theme.spacing(2),
    width: "fit-content",
    typography: "body1",
    backgroundColor: "#F2F2F2",
    borderRadius: theme.spacing(1),
  },
  tab: {
    width: theme.spacing(32),
    maxWidth: theme.spacing(32),
    borderRadius: theme.spacing(1),
    backgroundColor: "transparent",
    zIndex: 1,
    "&[aria-selected=true]": {
      color: "white",
    },
  },
  tabIndicator: {
    // transition: "all .5s ease-in-out",
    backgroundColor: "black",
    color: "white",
    borderRadius: theme.spacing(1),
  },
}));

export default useStyles;
