import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100vh",
    width: "80%",
    padding: "10px",
  },
  paperContainer: {
    dispaly: "flex",
    width: "100%",
  },
  profileIconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  profileIcon: {
    display: "flex",
    width: theme.spacing(5),
    height: theme.spacing(5),
    padding: theme.spacing(0.1),
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  profileText: {
    marginLeft: theme.spacing(2.5),
    flexDirection: "column",
  },
  filtersContainer: {
    dispaly: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(4),
    flex: 1,
  },
  selectFilters: {
    width: "fit-content",
    borderRadius: theme.spacing(1),
  },
  gradeButtonBorder: {
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
    height: theme.spacing(7),
  },
  root: {
    border: 0,
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-colCellTitle": {
      overflow: "unset",
    },
  },
}));

export default useStyles;
