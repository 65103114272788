import { Delete } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { Profile } from "common/models/Profile";
import AthleteIcon from "src/grading/components/AthleteIcon/AthleteIcon";
import { DemoTest } from "src/store/reducers/demoTests/demoTestsSlice";
import useStyles from "./DemoTestsTableColumns.styles";

type DemoTestRow = { row: DemoTest };

const AthleteColumn = ({ row }: DemoTestRow) => {
  const classes = useStyles();

  const profile = row.score?.profileSnapshot as Profile;

  return (
    <div className={classes.profileIconContainer}>
      {/* <div className={classes.profileIcon} /> */}
      <AthleteIcon profile={profile} />
      <Grid container flex={1} className={classes.profileText}>
        <Typography variant="subtitle1">Athlete</Typography>
        <Typography variant="h4" fontWeight={"bold"} whiteSpace={"initial"}>
          {row.score?.profileSnapshot.firstName &&
          row.score?.profileSnapshot.lastName
            ? row.score?.profileSnapshot.firstName +
              " " +
              row.score?.profileSnapshot.lastName
            : row.score?.profileSnapshot.email}
        </Typography>
      </Grid>
    </div>
  );
};

const DemoTestsTableColumns = (
  onRemove: (ids: string[], organizationId: string) => void,
): GridColDef[] => [
  {
    field: "id",
    headerName: "Test",
    flex: 1,
    editable: false,
    sortable: true,
    renderCell: ({ row }: DemoTestRow) => (
      <div>
        <Typography variant="subtitle1">{`Id: ${row.id}`}</Typography>
        <Typography variant="h4" fontWeight={"bold"}>
          {row.score?.testTitle}
        </Typography>
      </div>
    ),
  },
  {
    field: "athlete",
    headerName: "Athlete",
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    renderCell: ({ row }: DemoTestRow) => <AthleteColumn row={row} />,
  },
  {
    field: "organizationId",
    headerName: "Organization",
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    renderCell: ({ row }: DemoTestRow) => (
      <div>
        <Typography variant="h4" fontWeight={"bold"}>
          {row.organizationId}
        </Typography>
      </div>
    ),
  },
  {
    field: "action",
    headerName: "Action",
    flex: 1,
    editable: false,
    sortable: false,
    filterable: false,
    renderCell: ({ row }: DemoTestRow) => (
      <IconButton
        size="small"
        color="error"
        style={{
          borderRadius: "5px",
        }}
        onClick={() => {
          onRemove([row.id], row.organizationId);
        }}
      >
        <Delete />
      </IconButton>
    ),
  },
];

export default DemoTestsTableColumns;
