import { Divider, Grid, IconButton, Paper, Typography } from "@mui/material";
import { Profile } from "@readyfit-common/models";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { LinkIcon, RestTimeDotIcon, VideoIcon } from "src/grading/assets";
import AthleteIcon from "src/grading/components/AthleteIcon/AthleteIcon";
import GradingHeader from "src/grading/components/GradingHeader/GradingHeader";
import LiveTestStepper from "src/grading/components/LiveTestStepper/LiveTestStepper";
import { DisplayTimeFromSeconds } from "src/grading/utils";
import { parseTestDuration } from "src/grading/utils/testHelpers";
import { useAppSelector } from "src/store/hooks";
import {
  selectCurrentLiveTest,
  selectCurrentLiveTestInfo,
  selectLiveTestStep,
  selectSelectedAthletes,
} from "src/store/reducers/liveTest/liveTestSlice";
import { TestStepDefinitionFormat } from "src/store/reducers/tests/testsApiSlice";
import theme from "src/theme";
import TestRulesModal from "../TestRulesModal/TestRulesModal";
import useStyles from "./LiveTestOverview.styles";

const LiveTestOverview = ({
  setWithBanner,
  users,
  setCanNavigateAway,
}: {
  setWithBanner?: React.Dispatch<React.SetStateAction<boolean>>;
  users: Profile[];
  setCanNavigateAway: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const classes = useStyles();
  const liveTest = useAppSelector(selectCurrentLiveTest);
  const liveTestInfo = useAppSelector(selectCurrentLiveTestInfo);
  const liveTestStep = useAppSelector(selectLiveTestStep);
  const selectedAthletes = useAppSelector(selectSelectedAthletes);
  const [testRulesModalOpen, setTestRulesModalOpen] = useState(false);
  const [selectedExercise, setSelectedExercise] =
    useState<TestStepDefinitionFormat | null>(null);
  const history = useHistory();

  useEffect(() => {
    if (setWithBanner) {
      setWithBanner(false);
    }
  }, [setWithBanner]);

  return (
    <Grid container position={"relative"}>
      <Grid
        container
        className={classes.headerContainer}
        component={Paper}
        elevation={1}
        spacing={1}
      >
        <Grid item container xs={12} className={classes.titleContainer}>
          <GradingHeader
            withBreadcrumbs
            onClick={() => history.push("/live")}
            containerStyles={{
              marginTop: 0,
              marginBottom: theme.spacing(2),
            }}
          />
          <Typography variant="h3">Overview</Typography>

          <Typography className={classes.stepCount} variant="subtitle1">
            {`Step ${liveTestStep + 1} of 2`}
          </Typography>

          <Grid item marginLeft={"auto"} marginRight={1}>
            <LiveTestStepper
              athletes={users}
              mini={true}
              setCanNavigateAway={setCanNavigateAway}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid
          item
          container
          xs={5}
          className={classes.testInfoContainer}
          marginTop={theme.spacing(5)}
        >
          <Grid
            container
            className={classes.testInfoContent}
            flexDirection={"column"}
          >
            <Typography
              variant="subtitle1"
              textTransform={"uppercase"}
              color={"white"}
            >
              Test Name
            </Typography>
            <Typography
              variant="h3"
              color={"white"}
              marginTop={theme.spacing(1)}
            >
              {liveTestInfo?.displayName}
            </Typography>
            <Typography
              variant="caption"
              color={"white"}
              marginTop={theme.spacing(1)}
            >
              {/* {liveTestInfo?.description} */}
              {`The official test to qualify for the ${liveTestInfo?.displayName}.`}
            </Typography>
          </Grid>
          <Divider className={classes.divider} />

          <Grid container className={classes.testInfoContainer}>
            <Typography
              variant="subtitle1"
              textTransform={"uppercase"}
              color={"white"}
            >
              Rules
            </Typography>
            <IconButton
              onClick={() => console.log("clicked")}
              style={{ backgroundColor: "transparent" }}
            >
              <Grid
                container
                style={{
                  alignItems: "center",
                  marginTop: theme.spacing(1),
                  gap: theme.spacing(1),
                }}
              >
                <Typography
                  variant="h4"
                  color={"white"}
                  style={{ textDecoration: "underline" }}
                >
                  Official Rules
                </Typography>
                {/* <IconButton onClick={() => console.log("clicked")}> */}
                <LinkIcon />
                {/* </IconButton> */}
              </Grid>
            </IconButton>
          </Grid>
          <Divider className={classes.divider} />

          <Grid container className={classes.testInfoContainer}>
            <Typography
              variant="subtitle1"
              textTransform={"uppercase"}
              color={"white"}
            >
              Duration
            </Typography>

            <Typography
              variant="h3"
              marginTop={theme.spacing(1)}
              color={"white"}
            >
              {parseTestDuration(liveTestInfo?.estimatedTime as string)}
            </Typography>
          </Grid>
          <Divider className={classes.divider} />

          <Grid container className={classes.testInfoContainer}>
            <Typography
              variant="subtitle1"
              textTransform={"uppercase"}
              color={"white"}
            >
              Exercises
            </Typography>

            {liveTest?.steps.map((step, i) => {
              let stepNumber;
              if (liveTest?.steps[0].exerciseType === "bodyComp") {
                stepNumber = i;
              } else {
                stepNumber = i + 1;
              }

              if (step?.exerciseType === "bodyComp") return;

              if (step?.choices) {
                return (
                  <Grid key={i + (step.exerciseId || Math.random().toString())}>
                    <Grid container item key={i} marginTop={1.5}>
                      <Grid item className={classes.stepCircle}>
                        <Typography
                          variant="subtitle1"
                          className={classes.circleText}
                        >
                          {stepNumber}
                        </Typography>
                      </Grid>

                      <Grid
                        container
                        marginLeft={2}
                        item
                        flex={1}
                        alignItems={"center"}
                      >
                        <Grid container item xs={9}>
                          <Typography variant="caption" color={"white"}>
                            {step.choices[0].displayName} Or{" "}
                            {step.choices[1].displayName}
                          </Typography>
                        </Grid>
                        <Grid container item xs={3} justifyContent={"flex-end"}>
                          <IconButton
                            onClick={() => {
                              setSelectedExercise(step);
                              setTestRulesModalOpen(true);
                            }}
                          >
                            <VideoIcon className={classes.videoIcon} />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>

                    {i !== liveTest.steps.length - 1 && (
                      <Grid container item key={i} marginTop={1.5}>
                        <Grid item>
                          <RestTimeDotIcon />
                        </Grid>

                        <Grid marginLeft={2} item flex={1}>
                          <Typography variant="caption" color={"white"}>
                            Rest time:{" "}
                            {DisplayTimeFromSeconds(
                              step.choices[0].testing?.restTime?.minSeconds ||
                                0,
                            )}{" "}
                            -{" "}
                            {DisplayTimeFromSeconds(
                              step.choices[0].testing?.restTime?.maxSeconds ||
                                0,
                            )}{" "}
                            min
                          </Typography>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                );
              }

              return (
                <>
                  <Grid container item key={i} marginTop={2}>
                    <Grid item className={classes.stepCircle}>
                      <Typography
                        variant="subtitle1"
                        className={classes.circleText}
                      >
                        {stepNumber}
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      marginLeft={2}
                      item
                      flex={1}
                      alignItems={"center"}
                    >
                      <Grid container item xs={9}>
                        <Typography variant="caption" color={"white"}>
                          {step.displayName}
                        </Typography>
                      </Grid>
                      <Grid container item xs={3} justifyContent={"flex-end"}>
                        <IconButton
                          onClick={() => {
                            setSelectedExercise(step);
                            setTestRulesModalOpen(true);
                          }}
                        >
                          <VideoIcon className={classes.videoIcon} />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </Grid>

                  {i !== liveTest.steps.length - 1 && (
                    <Grid container item key={i} marginTop={1.5}>
                      <Grid item>
                        <RestTimeDotIcon />
                      </Grid>

                      <Grid marginLeft={2} item flex={1}>
                        <Typography variant="caption" color={"white"}>
                          Rest time:{" "}
                          {DisplayTimeFromSeconds(
                            step.testing?.restTime?.minSeconds || 0,
                          )}{" "}
                          -{" "}
                          {DisplayTimeFromSeconds(
                            step.testing?.restTime?.maxSeconds || 0,
                          )}{" "}
                          min
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
        </Grid>

        <Grid item container flex={1} className={classes.athletesContainer}>
          <Typography variant="subtitle1" textTransform={"uppercase"}>
            Athletes Taking Test
          </Typography>

          <Grid container marginTop={theme.spacing(2)}>
            {selectedAthletes.map((athlete, i) => (
              <>
                <Grid
                  item
                  container
                  key={`${athlete.id}-${i}`}
                  className={classes.athleteRow}
                  flexDirection={"column"}
                >
                  <AthleteIcon profile={athlete} />
                  <Grid item marginLeft={theme.spacing(2.5)}>
                    <Typography variant="h4">
                      {athlete.firstName} {athlete.lastName}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {selectedExercise && (
        <TestRulesModal
          exercise={
            selectedExercise?.choices
              ? selectedExercise.choices[0]
              : selectedExercise
          }
          onClose={() => setTestRulesModalOpen(false)}
          open={testRulesModalOpen}
          noText
        />
      )}
    </Grid>
  );
};

export default LiveTestOverview;
