import { CancelRounded, CheckCircle, Close, Error } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  Snackbar,
  SnackbarProps,
  Typography,
} from "@mui/material";
import React from "react";
import theme from "src/theme";
import useStyles from "./RFSnackbar.styles";

interface RFSnackbarProps extends SnackbarProps {
  message: string;
  type: "success" | "error";
}

const RFSnackbar = ({ message, type, ...props }: RFSnackbarProps) => {
  const classes = useStyles();
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={() => props.onClose}
      >
        <Close
          fontSize="small"
          style={{
            color: "#000",
          }}
        />
      </IconButton>
    </React.Fragment>
  );
  const messageNode = (
    <Grid container>
      {type === "success" ? (
        <>
          <CheckCircle
            style={{
              marginRight: theme.spacing(1),
            }}
          />
          <Typography variant="body1">{message}</Typography>
        </>
      ) : (
        <>
          <CancelRounded
            style={{
              marginRight: theme.spacing(1),
            }}
          />
          <Typography variant="body1">{message}</Typography>
        </>
      )}
    </Grid>
  );

  return (
    <Snackbar
      {...props}
      open={props.open}
      autoHideDuration={6000}
      onClose={props.onClose}
      message={messageNode}
      action={action}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      ContentProps={{
        classes: {
          root: type === "success" ? classes.successRoot : classes.errorRoot,
        },
      }}
    />
  );
};

export default RFSnackbar;
