import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Divider, Grid, IconButton, MenuItem, Typography } from "@mui/material";
import { ReactNode, useState } from "react";
import { useHistory } from "react-router-dom";
import GradingHeader from "src/grading/components/GradingHeader/GradingHeader";
import RFMenu from "src/grading/components/RFMenu/RFMenu";
import { parseTestDuration } from "src/grading/utils/testHelpers";
import { useAppSelector } from "src/store/hooks";
import {
  selectCurrentLiveTest,
  selectCurrentLiveTestInfo,
  selectLiveTestStep,
} from "src/store/reducers/liveTest/liveTestSlice";
import theme from "src/theme";
import useStyles from "./LiveTestLayout.styles";

interface LiveTestLayoutProps {
  children: ReactNode;
  exerciseContent?: ReactNode;

  subHeader?: ReactNode | JSX.Element;
  stepperContent?: ReactNode | JSX.Element;
  withGradingHeaderInLayout?: boolean;
}

const LiveTestLayout = ({
  children,
  subHeader,
  stepperContent,
  withGradingHeaderInLayout,
}: LiveTestLayoutProps) => {
  const history = useHistory();
  const liveTestStep = useAppSelector(selectLiveTestStep);
  const classes = useStyles({ liveTestStep })();
  const liveTestInfo = useAppSelector(selectCurrentLiveTestInfo);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const liveTest = useAppSelector(selectCurrentLiveTest);
  const [withBanner, setWithBanner] = useState<boolean>(false);

  const handleTestSequenceClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTestSequenceClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container paddingLeft={1}>
      <Grid container item className={classes.contentContainer}>
        {withGradingHeaderInLayout && (
          <GradingHeader
            label={"Live Test / Select Athlete"}
            onClick={() => history.push("/live")}
            withBreadcrumbs
          />
        )}

        {/* PAGE BANNER */}
        {withBanner && (
          <Grid item container xs={12} className={classes.bannerContainer}>
            <Grid
              container
              item
              flex={1}
              alignItems={"center"}
              ml={theme.spacing(4)}
            >
              <Typography
                color={theme.palette.primary.contrastText}
                variant={"h3"}
              >
                {liveTestInfo?.displayName}
              </Typography>
            </Grid>
            <Divider orientation={"vertical"} className={classes.divider} />

            <Grid
              container
              item
              xs={2}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                color={theme.palette.primary.contrastText}
                variant={"subtitle1"}
                textTransform={"uppercase"}
              >
                Duration
              </Typography>
              <Typography
                color={theme.palette.primary.contrastText}
                variant={"caption"}
                textTransform={"uppercase"}
              >
                {parseTestDuration(
                  liveTestInfo?.estimatedTime as string,
                ).replace("Minutes", "")}
              </Typography>
              <Typography variant={"subtitle1"} color={"#B2B8BC"}>
                Minutes
              </Typography>
            </Grid>
            <Divider orientation={"vertical"} className={classes.divider} />

            <Grid
              container
              item
              xs={3}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <IconButton onClick={handleTestSequenceClick}>
                <Typography
                  variant="button"
                  color={theme.palette.primary.contrastText}
                  textTransform={"uppercase"}
                  marginRight={theme.spacing(1)}
                >
                  See Test Sequence
                </Typography>
                {anchorEl ? (
                  <KeyboardArrowUp className={classes.icon} />
                ) : (
                  <KeyboardArrowDown className={classes.icon} />
                )}
              </IconButton>
              <RFMenu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                handleClose={handleTestSequenceClose}
              >
                {liveTest?.steps.map((step, i) => {
                  let stepNumber;
                  if (liveTest?.steps[0].exerciseType === "bodyComp") {
                    stepNumber = i;
                  } else {
                    stepNumber = i + 1;
                  }
                  if (step?.exerciseType === "bodyComp") return;
                  if (step?.choices) {
                    return (
                      <Grid
                        container
                        key={i}
                        alignItems={"center"}
                        flexDirection={"row"}
                        padding={theme.spacing(2)}
                      >
                        <Grid item className={classes.stepCircle}>
                          <Typography
                            variant="subtitle1"
                            className={classes.circleText}
                          >
                            {stepNumber}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <MenuItem style={{ whiteSpace: "normal" }}>
                            {step.choices[0].displayName} Or{" "}
                            {step.choices[1].displayName}
                          </MenuItem>
                        </Grid>
                      </Grid>
                    );
                  }
                  return (
                    <Grid
                      container
                      key={i}
                      alignItems={"center"}
                      padding={theme.spacing(2)}
                    >
                      <div className={classes.stepCircle}>
                        <Typography
                          variant="subtitle1"
                          className={classes.circleText}
                        >
                          {stepNumber}
                        </Typography>
                      </div>
                      <MenuItem>{step.displayName}</MenuItem>
                    </Grid>
                  );
                })}
              </RFMenu>
            </Grid>
          </Grid>
        )}

        {children}
      </Grid>

      <Grid container item className={classes.stepperContainer}>
        {stepperContent}
      </Grid>
    </Grid>
  );
};

export default LiveTestLayout;
