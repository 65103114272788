import React from "react";
import { Spinner } from "react-bootstrap";

interface LoaderProps {
  loading: boolean;

  children: JSX.Element;
}

const Loader = ({ children, loading }: LoaderProps) => {
  return loading ? (
    <Spinner
      animation="border"
      style={{ left: "50%", top: "50%", position: "relative" }}
    />
  ) : (
    children
  );
};

export default Loader;
