import { apiSlice } from "../../apiSlice";

export const documentsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getFileUrl: builder.query({
      query: (objectKey) => ({
        url: `/api/document-worker/test-files/${objectKey}`,
        method: "GET",
      }),
      transformResponse: (response: { data: { url: string } }) => response.data,
    }),
    exportLiveTest: builder.query<string, string>({
      query: (liveTestId: string) => ({
        url: `/api/document-worker/live-tests/${liveTestId}`,
        method: "GET",
      }),
      transformResponse: (response: { data: { url: string } }) =>
        response.data.url,
    }),
    exportUsers: builder.query<
      string,
      {
        organizationId: string;
        search?: string;
        unit?: string;
        status?: string;
        sortBy?: string;
      }
    >({
      query: (payload) => {
        const { organizationId, search, unit, status, sortBy } = payload;
        let url = `/api/document-worker/organizations/${organizationId}/members?`;
        if (search) url += `&search=${search}`;
        if (unit) url += `&unit=${unit}`;
        if (status) url += `&status=${status}`;
        if (sortBy) url += `&sortBy=${sortBy}`;

        return {
          url,
          method: "GET",
        };
      },
      transformResponse: (response: { data: { url: string } }) =>
        response.data.url,
    }),
    exportTestScores: builder.query<
      string,
      {
        userId?: string;
        certified?: string;
        email?: string;
        testId?: string;
        search?: string;
        type?: string;
        sortBy?: string;
      }
    >({
      query: (payload) => {
        const { userId, certified, email, testId, search, type, sortBy } =
          payload;
        let url = `/api/document-worker/scores?`;
        if (userId) url += `&userId=${userId}`;
        if (certified) url += `&certified=${certified}`;
        if (email) url += `&email=${email}`;
        if (testId) url += `&testId=${testId}`;
        if (search) url += `&search=${search}`;
        if (type) url += `&type=${type}`;
        if (sortBy && sortBy !== "-1") url += `&sortBy=${sortBy}`;

        return {
          url,
          method: "GET",
        };
      },
      transformResponse: (response: { data: { url: string } }) =>
        response.data.url,
    }),
  }),
});

export const {
  useGetFileUrlQuery,
  useLazyGetFileUrlQuery,
  useExportLiveTestQuery,
  useLazyExportLiveTestQuery,
  useExportUsersQuery,
  useLazyExportUsersQuery,
  useExportTestScoresQuery,
  useLazyExportTestScoresQuery,
} = documentsApiSlice;
