interface LocalAsset<TName extends LocalAssetName = LocalAssetName> {
  name: TName;
}

interface RemoteAsset {
  uri: string;
}
export type ImageAsset = LocalAsset<LocalImageAssetName> | RemoteAsset;
// export type VideoAsset = LocalAsset<LocalVideoAssetName> | RemoteAsset;
// export type AudioAsset = LocalAsset<LocalAudioAssetName> | RemoteAsset;
type Asset = ImageAsset; // | VideoAsset | AudioAsset;

function isLocalAsset<T extends Asset>(
  asset: T,
): asset is Extract<T, LocalAsset> {
  return !!(asset as LocalAsset).name;
}

export default class AssetService {
  private static instance: AssetService;
  public static get Instance() {
    return this.instance || (this.instance = new this());
  }
  public get(asset: string) {
    return LOCAL_IMAGE_ASSETS[asset as LocalImageAssetName]; //||
    // LOCAL_VIDEO_ASSETS[asset as LocalVideoAssetName] ||
    // LOCAL_AUDIO_ASSETS[asset as LocalAudioAssetName]
  }
}

// export type LocalVideoAssetName = keyof typeof LOCAL_VIDEO_ASSETS;
// const LOCAL_VIDEO_ASSETS = {
//   "PLACEHOLDER FOR TYPESCRIPT - REMOVE WHEN ASSET IS ADDED": undefined,
// };

export type LocalImageAssetName = keyof typeof LOCAL_IMAGE_ASSETS;
const LOCAL_IMAGE_ASSETS = {
  "readyfit-logo-army": require("../assets/images/readyfit-logo-army.png"),
  "readyfit-logo-navy": require("../assets/images/readyfit-logo-navy.png"),
  "readyfit-logo-airforce": require("../assets/images/readyfit-logo-airforce.png"),
  "readyfit-logo-marine": require("../assets/images/readyfit-logo-marine.png"),
  "readyfit-airforce-button": require("../assets/images/readyfit-airforce-button.png"),
  "readyfit-airforce-banner": require("../assets/images/readyfit-airforce-overview.png"),
  "readyfit-airforce-category-banner": require("../assets/images/readyfit-airforce-category-overview.png"),
  "readyfit-army-button": require("../assets/images/readyfit-army-button.png"),
  "readyfit-army-banner": require("../assets/images/readyfit-army-overview.png"),
  "readyfit-army-acf-banner": require("../assets/images/readyfit-army-acf-banner.png"),
  "readyfit-army-category-banner": require("../assets/images/readyfit-army-category-overview.png"),
  "readyfit-demo-button": null,
  "readyfit-demo-banner": require("../assets/images/readyfit-demo-overview.jpg"),
  "readyfit-jrotc-button": require("../assets/images/readyfit-jrotc-button.png"),
  "readyfit-navyseal-jrotc-button": require("../assets/images/readyfit-navyseal-jrotc-button.png"),
  "readyfit-army-jrotc-button": require("../assets/images/readyfit-army-jrotc-button.png"),
  "readyfit-airforce-jrotc-button": require("../assets/images/readyfit-airforce-jrotc-button.png"),
  "readyfit-marine-jrotc-button": require("../assets/images/readyfit-marine-jrotc-button.png"),
  "readyfit-jrotc-banner": require("../assets/images/readyfit-jrotc-overview.png"),
  "readyfit-marine-button": require("../assets/images/readyfit-marine-button.png"),
  "readyfit-marine-banner": require("../assets/images/readyfit-marine-overview.jpg"),
  "readyfit-marine-category-banner": require("../assets/images/readyfit-marine-category-overview.png"),
  "readyfit-navyseal-button": require("../assets/images/readyfit-navyseal-button.png"),
  "readyfit-navyseal-banner": require("../assets/images/readyfit-navyseal-overview.jpg"),
  "readyfit-navy-button": require("../assets/images/readyfit-navy-button.png"),
  "readyfit-navy-banner": require("../assets/images/readyfit-navy-overview.png"),
  "readyfit-navy-category-banner": require("../assets/images/readyfit-navy-category-overview.png"),
  "readyfit-serviceacademy-button": require("../assets/images/readyfit-serviceacademy-button.png"),
  "readyfit-navy-serviceacademy-button": require("../assets/images/readyfit-navy-serviceacademy-button.png"),
  "readyfit-military-serviceacademy-button": require("../assets/images/readyfit-military-serviceacademy-button.png"),
  "readyfit-airforce-serviceacademy-button": require("../assets/images/readyfit-airforce-serviceacademy-button.png"),
  "readyfit-marine-serviceacademy-button": require("../assets/images/readyfit-navy-serviceacademy-button.png"),
  "readyfit-militaryacademy-banner": require("../assets/images/readyfit-militaryacademy-overview.png"),
  "readyfit-airforceacademy-banner": require("../assets/images/readyfit-airforceacademy-overview.png"),
  "readyfit-navalacademy-banner": require("../assets/images/readyfit-navalacademy-overview.png"),
  "readyfit-airforce-warfare-button": require("../assets/images/readyfit-airforce-warfare-button.png"),
  "readyfit-army-rotcafa-overview": require("../assets/images/readyfit-army-rotcafa-overview.png"),
  "readyfit-army-rotcafa-button": require("../assets/images/readyfit-army-rotcafa-button.png"),
  "readyfit-navy-rotcafa-overview": require("../assets/images/readyfit-navy-rotcafa-overview.png"),
  "readyfit-navy-rotcafa-button": require("../assets/images/readyfit-navy-rotcafa-button.png"),
};

// export type LocalAudioAssetName = keyof typeof LOCAL_AUDIO_ASSETS;
// const LOCAL_AUDIO_ASSETS = {
//   "10-go": require("../assets/audio/10-go.wav"),
//   "3-2-1-go": require("../assets/audio/3-2-1-go.wav"),
//   "3-2-1-when-ready-go": require("../assets/audio/3-2-1-exercise-when-ready.wav"),
//   "1:00-finished": require("../assets/audio/1;00-finished.wav"),
//   "5-4-3-2-1-finished": require("../assets/audio/5-4-3-2-1-finished.wav"),
//   "0-max-time-finished": require("../assets/audio/max-time-reached-exercise-complete.wav"),
//   "max-goal-achieved": require("../assets/audio/max-goal-achieved.wav"),
//   "1:00-rest-finished": require("../assets/audio/1;00-rest-finished.wav"),
//   "begin-exercise-now": require("../assets/audio/begin-exercise-now.wav"),
//   "max-rest-exceeded": require("../assets/audio/max-rest-exceeded.wav"),
//   "3-2-1-mount-go": require("../assets/audio/3-2-1-mount-go.wav"),
//   "3-2-1-mount": require("../assets/audio/3-2-1-mount-up-go.wav"),
//   "1:09-up": require("../assets/audio/up.wav"),
//   "1:12-up": require("../assets/audio/up.wav"),
//   "1:15-up": require("../assets/audio/up.wav"),
//   "1:18-up": require("../assets/audio/up.wav"),
//   "1:21-up": require("../assets/audio/up.wav"),
//   "1:24-up": require("../assets/audio/up.wav"),
//   "1:27-up": require("../assets/audio/up.wav"),
//   "1:30-up": require("../assets/audio/up.wav"),
//   "1:33-up": require("../assets/audio/up.wav"),
//   "1:36-up": require("../assets/audio/up.wav"),
//   "1:39-up": require("../assets/audio/up.wav"),
//   "1:42-up": require("../assets/audio/up.wav"),
//   "1:45-up": require("../assets/audio/up.wav"),
//   "1:48-up": require("../assets/audio/up.wav"),
//   "1:51-up": require("../assets/audio/up.wav"),
//   "1:54-up": require("../assets/audio/up.wav"),
//   "1:57-up": require("../assets/audio/up.wav"),
//   "2:00-up": require("../assets/audio/up.wav"),
//   "0:15": require("../assets/audio/0;15.wav"),
//   "0:30": require("../assets/audio/0;30.wav"),
//   "0:45": require("../assets/audio/0;45.wav"),
//   "1:00": require("../assets/audio/1;00.wav"),
//   "1:15": require("../assets/audio/1;15.wav"),
//   "1:30": require("../assets/audio/1;30.wav"),
//   "1:45": require("../assets/audio/1;45.wav"),
//   "2:00": require("../assets/audio/2;00.wav"),
//   "2:15": require("../assets/audio/2;15.wav"),
//   "2:30": require("../assets/audio/2;30.wav"),
//   "2:45": require("../assets/audio/2;45.wav"),
//   "3:00": require("../assets/audio/3;00.wav"),
//   "3:15": require("../assets/audio/3;15.wav"),
//   "3:30": require("../assets/audio/3;30.wav"),
//   "3:45": require("../assets/audio/3;45.wav"),
//   "4:00": require("../assets/audio/4;00.wav"),
//   "4:15": require("../assets/audio/4;15.wav"),
//   "4:30": require("../assets/audio/4;30.wav"),
//   "4:45": require("../assets/audio/4;45.wav"),
//   "5:00": require("../assets/audio/5;00.wav"),
// };

type LocalAssetName = keyof typeof LOCAL_ASSETS;
const LOCAL_ASSETS = {
  ...LOCAL_IMAGE_ASSETS,
  // ...LOCAL_VIDEO_ASSETS,
  // ...LOCAL_AUDIO_ASSETS,
};
