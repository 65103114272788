import { Add, RestartAlt } from "@mui/icons-material";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import {
  DemoTest,
  useLazyGetDemoTestsQuery,
  useRemoveDemoTestsMutation,
} from "src/store/reducers/demoTests/demoTestsSlice";
import useStyles from "./DemoTests.styles";
import DemoTestsTableColumns from "./DemoTestsTableColumns/DemoTestsTableColumns";
import RegisterDemoTestsModal from "./RegisterDemoTestsModal/RegisterDemoTestsModal";
import ResetDemoTestsModal from "./ResetDemoTestsModal/ResetDemoTestsModal";

interface DemoTestsState {
  isLoading: boolean;
  registerDemoTestsModalOpen: boolean;
  resetDemoTestsModalOpen: boolean;
  demoTests: DemoTest[];
}

const DemoTests = () => {
  const classes = useStyles();

  const [state, setState] = useState<DemoTestsState>({
    isLoading: true,
    registerDemoTestsModalOpen: false,
    resetDemoTestsModalOpen: false,
    demoTests: [],
  });

  const [getDemoTests] = useLazyGetDemoTestsQuery();

  const [removeDemoTests] = useRemoveDemoTestsMutation();

  const fetchDemoTests = useCallback(async () => {
    const { demoTests } = await getDemoTests({}).unwrap();

    setState((prev) => ({ ...prev, isLoading: false, demoTests }));
  }, [getDemoTests]);

  const handleRemoveDemoTests = async (
    ids: string[],
    organizationId: string,
  ) => {
    await removeDemoTests({ ids, organizationId }).unwrap();
    fetchDemoTests();
  };

  useEffect(() => {
    fetchDemoTests();
  }, [fetchDemoTests]);

  const toggleRegisterDemoTests = () => {
    setState((prev) => ({
      ...prev,
      registerDemoTestsModalOpen: !prev.registerDemoTestsModalOpen,
    }));
  };

  const toggleResetDemoTests = () => {
    setState((prev) => ({
      ...prev,
      resetDemoTestsModalOpen: !prev.resetDemoTestsModalOpen,
    }));
  };

  return state.isLoading ? (
    <Spinner
      animation="border"
      style={{ left: "50%", top: "50%", position: "relative" }}
    />
  ) : (
    <>
      <Grid item xs={12} mt={1}>
        <Typography variant="h2">Demo Tests</Typography>
      </Grid>

      <Grid item container gap={2} className={classes.toolbarContainer}>
        <IconButton
          className={classes.toolbarButton}
          onClick={toggleRegisterDemoTests}
        >
          <Typography variant="button">Register Demo Test</Typography>
          <Add />
        </IconButton>

        <IconButton
          className={classes.toolbarButton}
          onClick={toggleResetDemoTests}
        >
          <Typography variant="button">Reset Demo Tests</Typography>
          <RestartAlt />
        </IconButton>
      </Grid>

      <Grid item container>
        <Paper className={classes.paperContainer}>
          <DataGrid
            rows={state.demoTests}
            hideFooterSelectedRowCount={true}
            headerHeight={50}
            pageSize={15}
            sx={{ border: "none" }}
            rowHeight={60}
            autoHeight
            columns={DemoTestsTableColumns(handleRemoveDemoTests)}
            getRowId={(row) => row.id}
          />
        </Paper>
      </Grid>

      <RegisterDemoTestsModal
        open={state.registerDemoTestsModalOpen}
        onClose={toggleRegisterDemoTests}
        fetchDemoTests={fetchDemoTests}
      />

      <ResetDemoTestsModal
        open={state.resetDemoTestsModalOpen}
        onClose={toggleResetDemoTests}
      />
    </>
  );
};

export default DemoTests;
