import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import React from "react";
import useStyles from "./RFDataGrid.styles";

type RFDataGridProps = DataGridProps;

const RFDataGrid = (props: RFDataGridProps) => {
  const classes = useStyles();
  return (
    <DataGrid
      hideFooterSelectedRowCount={true}
      headerHeight={50}
      pageSize={15}
      sx={{
        border: "none",
      }}
      rowHeight={108}
      className={classes.root}
      autoHeight
      {...props}
    />
  );
};

export default RFDataGrid;
