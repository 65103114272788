import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100vh",
    width: "80%",
    padding: "10px",
  },
  paperContainer: {
    dispaly: "flex",
    width: "100%",
  },
  logoTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    height: "80%",
    borderRight: `1px solid ${theme.palette.secondary.light}`,
  },
  testLogo: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      width: theme.spacing(6),
      height: theme.spacing(6),
    },
  },
  profileIcon: {
    display: "flex",
    width: theme.spacing(5),
    height: theme.spacing(5),
    padding: theme.spacing(0.1),
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  profileText: {
    marginLeft: theme.spacing(2.5),
  },
  filtersContainer: {
    dispaly: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(4),
    flex: 1,
  },
  selectFilters: {
    width: "fit-content",
    borderRadius: theme.spacing(1),
  },
  gradeButtonBorder: {
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
    height: theme.spacing(7),
  },
  root: {
    border: 0,
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-colCellTitle": {
      overflow: "unset",
    },
    "& .MuiDataGrid-cell": {
      padding: 0,
    },
    padding: 0,
  },
  tabsContainer: {
    width: "fit-content",
    typography: "body1",
    backgroundColor: "#F2F2F2",
    borderRadius: theme.spacing(1),
  },
  tab: {
    width: theme.spacing(32),
    maxWidth: theme.spacing(32),
    borderRadius: theme.spacing(1),
    backgroundColor: "transparent",
    zIndex: 1,
    "&[aria-selected=true]": {
      color: "white",
    },
  },
  tabIndicator: {
    // transition: "all .5s ease-in-out",
    backgroundColor: "black",
    color: "white",
    borderRadius: theme.spacing(1),
  },
  tableColumn: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "80%",
    borderRight: `1px solid ${theme.palette.secondary.light}`,
  },
  forwardIcon: {
    backgroundColor: "black",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  testTitle: {
    textWrap: "wrap",
    justifyContent: "flex-start",
  },
  // borderedCell: {
  //   border: `1px solid ${theme.palette.secondary.light}`,
  // },
}));

export default useStyles;
