import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  profileIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(1),
    backgroundColor: "#6FA088",
    border: "1px solid #436052",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default useStyles;
