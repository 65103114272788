import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import RFSnackbar from "src/grading/components/RFSnackbar/RFSnackbar";
import LiveTestLayout from "src/grading/layout/LiveTestLayout/LiveTestLayout";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import {
  LiveTestStep,
  resetLiveTestSlice,
  selectLiveTestStep,
  setAthletesLiveTestGradeResults,
  setLiveTestStep,
  setSelectedAthletes,
} from "src/store/reducers/liveTest/liveTestSlice";
import {
  clearBreadcrumbs,
  selectSnackbar,
  setSnackbar,
} from "src/store/reducers/navigation/navigationSlice";
import {
  TestDefinitionFormat,
  useLazyGetAllTestDefinitionsQuery,
} from "src/store/reducers/tests/testsApiSlice";
import LiveTestCard from "../LiveTestCard/LiveTestCard";

const SelectTestForLiveTest = () => {
  const [getAllTestDefinitions, { isFetching }] =
    useLazyGetAllTestDefinitionsQuery();
  const step = useAppSelector(selectLiveTestStep);
  const user = useAppSelector(selectCurrentUser);
  const dispatch = useAppDispatch();
  const liveTestSnackbar = useAppSelector(selectSnackbar);

  const [testDefinitions, setTestDefinitions] =
    useState<TestDefinitionFormat[]>();

  useEffect(() => {
    dispatch(setLiveTestStep(LiveTestStep.SELECT_TEST));
    dispatch(setSelectedAthletes([]));
    dispatch(clearBreadcrumbs());
    dispatch(
      setAthletesLiveTestGradeResults({ athleteLiveTestGradeResults: [] }),
    );
    dispatch(resetLiveTestSlice());

    const loadData = async () => {
      const result = await getAllTestDefinitions();
      setTestDefinitions(result.data?.data.testDefinitions);
    };

    loadData();

    return () => {
      dispatch(setSnackbar({ open: false, message: "" }));
    };
  }, [getAllTestDefinitions, dispatch]);

  return isFetching ? (
    <Spinner
      animation="border"
      style={{ left: "50%", top: "50%", position: "relative" }}
    />
  ) : (
    <LiveTestLayout>
      {/* SELECT TEST STEP*/}
      {step === LiveTestStep.SELECT_TEST && (
        <Grid item xs={12} mt={1} mb={5}>
          <Typography variant="h2">Live Test</Typography>

          <Grid container mt={1}>
            {testDefinitions?.map((testDefinition, testDefinitionIndex) => {
              return (
                !testDefinition?.isDemo &&
                testDefinition.infos.map((info, i) => {
                  const orgBranch = user.organization?.branch;

                  if (!orgBranch || orgBranch === info.branch)
                    return (
                      <LiveTestCard
                        key={`${testDefinitionIndex}-${i}`}
                        testDefinition={testDefinition}
                        testDefinitionInfo={info}
                      />
                    );

                  return <></>;
                })
              );
            })}
          </Grid>
        </Grid>
      )}
      <RFSnackbar
        open={liveTestSnackbar.open}
        onClose={() => dispatch(setSnackbar({ open: false, message: "" }))}
        message={liveTestSnackbar.message}
        type="success"
      />
    </LiveTestLayout>
  );
};

export default SelectTestForLiveTest;
