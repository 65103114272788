export function CalculateBmi(heightInches: number, weightLbs: number) {
  const weightKg = weightLbs * 0.4535;
  const heightMeters = heightInches * 2.54;
  return Math.round((weightKg / heightMeters / heightMeters) * 10000);
}

export function getAge(birthday: Date) {
  const ageDifMs = Date.now() - birthday.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function formatHeight(heightInches: number) {
  const feet = Math.floor(heightInches / 12);
  const inches = heightInches % 12;
  return `${feet}'${inches}"`;
}
