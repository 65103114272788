import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { genderOptions } from "@readyfit-common/models";
import { useFormik } from "formik";
import moment from "moment";
import { useDispatch } from "react-redux";
import { LoadingButton } from "src/grading/components";
import { inputError } from "src/grading/components/InputError/InputError";
import RFModal from "src/grading/components/RFModal/RFModal";
import { DEFAULT_ORGANIZATION } from "src/grading/models";
import { useAppSelector } from "src/store/hooks";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import {
  selectCurrentRoles,
  selectCurrentUnits,
  setMembers,
} from "src/store/reducers/organizations/organizationSlice";
import {
  useAddMemberMutation,
  useLazyGetMembersQuery,
} from "src/store/reducers/organizations/organizationsApiSlice";
import theme from "src/theme";
import * as Yup from "yup";
import useStyles from "./AddNewMemberModal.style";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  birthday: Yup.string().required("Birthday is required"),
  gender: Yup.string().required("Gender is required"),
  role: Yup.string().required("Role is required"),
  unit: Yup.string().required("Unit is required"),
});

interface AddNewMemberModalProps {
  open: boolean;
  onClose: () => void;
}

export interface AddNewMemberFormValues {
  firstName: string;
  lastName: string;
  email: string;
  birthday: string;
  gender: string;
  role: string;
  unit: string;
}

const AddNewMemberModal = (props: AddNewMemberModalProps) => {
  const classes = useStyles();

  const user = useAppSelector(selectCurrentUser);

  const roles = useAppSelector(selectCurrentRoles);

  const units = useAppSelector(selectCurrentUnits);

  const [addMember, { isLoading }] = useAddMemberMutation();

  const [getMembers] = useLazyGetMembersQuery();

  const dispatch = useDispatch();

  const saveMember = async (values: AddNewMemberFormValues) => {
    const result = await addMember({
      organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
      addMemberPayload: values,
    }).unwrap();

    if (result.success) {
      const response = await getMembers({
        organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
      }).unwrap();

      if (response.members) {
        dispatch(setMembers(response));
      }

      props.onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      birthday: "",
      gender: "",
      role: "",
      unit: "default",
    },
    validationSchema,
    onSubmit: async (values: AddNewMemberFormValues) => {
      await saveMember(values);
      formik.resetForm();
    },
  });

  const { values, handleSubmit, setFieldValue, handleChange, errors } = formik;

  return (
    <RFModal {...props} boxProps={{ className: classes.container }}>
      <Grid
        container
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <Typography variant="h5">Add New Member</Typography>

        <Grid container item marginTop={theme.spacing(4)} spacing={2}>
          <Grid item xs={12} md={6}>
            <InputLabel className={classes.inputLabel}>First Name</InputLabel>
            <TextField
              InputProps={{
                className: classes.input,
              }}
              variant="outlined"
              fullWidth
              value={values.firstName}
              onChange={handleChange("firstName")}
              error={
                inputError("firstName", formik.touched, formik.errors).error
              }
              helperText={
                inputError("firstName", formik.touched, formik.errors).display
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel className={classes.inputLabel}>Last Name</InputLabel>
            <TextField
              InputProps={{
                className: classes.input,
              }}
              variant="outlined"
              fullWidth
              value={values.lastName}
              onChange={handleChange("lastName")}
              error={
                inputError("lastName", formik.touched, formik.errors).error
              }
              helperText={
                inputError("lastName", formik.touched, formik.errors).display
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel className={classes.inputLabel}>Gender</InputLabel>
            <Select
              className={classes.input}
              variant="outlined"
              fullWidth
              value={values.gender}
              onChange={(e) => setFieldValue("gender", e.target.value)}
              error={inputError("gender", formik.touched, formik.errors).error}
            >
              {genderOptions.map((genderOption, i) => (
                <MenuItem key={i} value={genderOption}>
                  {genderOption}
                </MenuItem>
              ))}
            </Select>
            {errors?.gender && (
              <FormHelperText
                style={{ marginLeft: 14, color: "red", fontSize: "14px" }}
                error
              >
                {errors?.gender}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel className={classes.inputLabel}>Birthday</InputLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                value={values.birthday}
                onChange={(date) => {
                  if (date) {
                    setFieldValue(
                      "birthday",
                      moment(date).format("YYYY-MM-DD"),
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    error={
                      inputError("birthday", formik.touched, formik.errors)
                        .error
                    }
                    helperText={
                      inputError("birthday", formik.touched, formik.errors)
                        .display
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Email</InputLabel>
            <TextField
              InputProps={{
                className: classes.input,
              }}
              variant="outlined"
              fullWidth
              value={values.email}
              onChange={handleChange("email")}
              error={inputError("email", formik.touched, formik.errors).error}
              helperText={
                inputError("email", formik.touched, formik.errors).display
              }
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Role</InputLabel>
            <Select
              className={classes.input}
              variant="outlined"
              fullWidth
              value={values.role}
              onChange={(e) => setFieldValue("role", e.target.value)}
            >
              {[...roles]
                .sort((a, b) => b.level - a.level)
                .map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.description}
                  </MenuItem>
                ))}
            </Select>
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Unit</InputLabel>
            <Select
              className={classes.input}
              variant="outlined"
              fullWidth
              value={values.unit}
              onChange={(e) => setFieldValue("unit", e.target.value)}
            >
              {units.map((unit) => (
                <MenuItem key={unit.id} value={unit.id}>
                  {unit.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent={"space-between"}
            marginTop={theme.spacing(4)}
          >
            <Grid item>
              <Button
                color="secondary"
                className={` ${classes.button} ${classes.backButton}`}
                onClick={props.onClose}
              >
                Cancel
              </Button>
            </Grid>

            <Grid item>
              <LoadingButton
                variant="contained"
                color="primary"
                className={classes.button}
                loading={isLoading}
                label="Save"
                type="submit"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RFModal>
  );
};

export default AddNewMemberModal;
