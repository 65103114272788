import {
  CardioExerciseResultMetadata,
  ExerciseResultMetadata,
  ExerciseScore,
  PassFailExerciseResultMetadata,
  RepExerciseResultMetadata,
  TestScore,
  isCardio,
} from "@readyfit-common/models";
import { GradedExerciseTime } from "../utils/timeHelpers";

export type GradeResult = {
  userResult: ExerciseResultMetadata;
  accuracyScore?: number;
  notes: string;
  discrepancies: string[];
  exceededRestTime?: boolean;
};

export interface RepCountGradeResult extends GradeResult {
  graderCount?: number;
  userResult: RepExerciseResultMetadata;
  // approved?: boolean;
}

export interface RepTimeGradeResult extends GradeResult {
  graderTime: GradedExerciseTime;
  userResult: RepExerciseResultMetadata;
  // approved?: boolean;
}

export interface ApprovalGradeResult extends GradeResult {
  approved?: boolean;
  userResult: PassFailExerciseResultMetadata;
}

export interface CardioApprovalGradeResult extends GradeResult {
  approved?: boolean;
  graderTime?: GradedExerciseTime;

  // Allows the grading platform to override cardio distances if needed
  userResult: CardioExerciseResultMetadata;
}

export interface PassFailGradeResult extends GradeResult {
  userResult: PassFailExerciseResultMetadata;
}

export function isRepGrade(result: GradeResult): result is RepCountGradeResult {
  return "graderCount" in result;
}

export function isRepGradeInCertifiedTest(
  result: GradeResult,
): result is RepCountGradeResult {
  return "reps" in result;
}

export function isTimeGrade(result: GradeResult): result is RepTimeGradeResult {
  return "graderTime" in result;
}

export function isApprovalGrade(
  result: GradeResult,
): result is ApprovalGradeResult {
  return "approved" in result;
}

export function isCardioApprovalGrade(
  result: GradeResult,
): result is CardioApprovalGradeResult {
  return isCardio(result.userResult);
}

export function isPassFailGrade(
  result: GradeResult,
): result is PassFailGradeResult {
  if (!result.userResult) return false;
  return "passFail" in result.userResult;
}

export function isPassFailGradeInCertifiedTest(
  result: ExerciseResultMetadata,
): result is PassFailExerciseResultMetadata {
  if (!result) return false;
  return "passFail" in result;
}
