import { Grid, Typography } from "@mui/material";
import { useAppSelector } from "src/store/hooks";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";

const Admin = () => {
  const profile = useAppSelector(selectCurrentUser);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "90vh" }}
    >
      <Grid item xs={12} mt={1}>
        <Typography variant="h2">Welcome</Typography>
      </Grid>
      <Grid item xs={12} mt={1}>
        <Typography variant="h2">{`${profile.firstName} ${profile.lastName}`}</Typography>
      </Grid>
    </Grid>
  );
};

export default Admin;
