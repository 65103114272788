import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  profileIconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  profileText: {
    marginLeft: theme.spacing(2.5),
    flexDirection: "column",
  },
  memberButtonBorder: {
    borderLeft: `1px solid ${theme.palette.secondary.light}`,
    height: theme.spacing(7),
  },
  memberButton: {
    marginLeft: 16,
    backgroundColor: "#000",
    borderRadius: "10px",
    height: theme.spacing(4),
  },
  admitButton: {
    marginLeft: 16,
    borderRadius: "10px",
    height: theme.spacing(4),
  },
}));

export default useStyles;
