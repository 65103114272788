import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  averageScore: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    backgroundColor: "#F2F2F2",
    gap: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
}));

export default useStyles;
