import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  profileIconContainer: {
    marginTop: theme.spacing(5),
    display: "flex",
    flexDirection: "row",
    // justifyContent: "center",
    alignItems: "center",
  },
  profileIcon: {
    height: "72px",
    width: "72px",
  },
  profileInfoContainer: {
    marginLeft: 3,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  profileBadge: {
    color: "white",
    padding: `0 ${theme.spacing(1)}`,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
  },
  actionButtonContainer: {
    marginLeft: "auto",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginTop: theme.spacing(2),
    },
  },
  actionButton: {
    color: "white",
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    backgroundColor: theme.palette.success.contrastText,
    borderRadius: theme.spacing(1),
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  bannerContainer: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(5),
    backgroundColor: "#3F4E57",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
      marginTop: theme.spacing(3),
    },
  },
  seeDetailsButton: {
    backgroundColor: theme.palette.primary.main,
    color: "white",
    padding: `0 ${theme.spacing(2)}`,
  },
  profileInformationContainer: {
    padding: theme.spacing(5),
    border: `1px solid #DBDBDB`,
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  profileInformationColumnContainer: {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flex: 1,
    },
  },
  profileInformationRow: {
    padding: `${theme.spacing(1.5)} 0`,
    borderBottom: `1px solid #666666`,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    maxHeight: "50px",
    height: "50px",
  },
  testsProgressContainer: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(1),
    border: `1px solid #DBDBDB`,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  recruitmentProcessContainer: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(1),
    border: `1px solid #DBDBDB`,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default useStyles;
