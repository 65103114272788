import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store/rootStore";

interface NavigationSliceState {
  showDrawer: boolean;
  breadcrumbs: BreadcrumbItem[];
  snackbar: {
    open: boolean;
    message: string;
    type?: string;
  };
}

export interface BreadcrumbItem {
  label: string;
  index?: number;
  path?: string;
}

const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    showDrawer: true,
    breadcrumbs: [],
    snackbar: {
      open: false,
      message: "",
    },
  } as NavigationSliceState,
  reducers: {
    toggleDrawer: (state) => {
      state.showDrawer = !state.showDrawer;
    },
    showDrawer: (state, action: PayloadAction<boolean>) => {
      state.showDrawer = action.payload;
    },
    addBreadcrumb: (state, action: PayloadAction<BreadcrumbItem>) => {
      state.breadcrumbs.push(action.payload);
    },
    removeBreadcrumb: (state, action: PayloadAction<number>) => {
      state.breadcrumbs.splice(action.payload, state.breadcrumbs.length);
    },
    clearBreadcrumbs: (state) => {
      state.breadcrumbs = [];
    },
    setSnackbar: (
      state,
      action: PayloadAction<{ open: boolean; message: string; type?: string }>,
    ) => {
      state.snackbar = {
        open: action.payload.open,
        message: action.payload.message,
        type: action.payload.type,
      };
    },
  },
});

export const selectShowDrawer = (state: RootState) =>
  state.navigation.showDrawer;
export const selectBreadcrumbs = (state: RootState) =>
  state.navigation.breadcrumbs;
export const selectSnackbar = (state: RootState) => state.navigation.snackbar;

export const {
  toggleDrawer,
  showDrawer,
  addBreadcrumb,
  removeBreadcrumb,
  clearBreadcrumbs,
  setSnackbar,
} = navigationSlice.actions;

export default navigationSlice.reducer;
