import { Add } from "@mui/icons-material";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { usePermissions } from "src/grading/hooks/usePermissions";
import { permissions } from "src/grading/utils/permissionsHelpers";
import useStyles from "../RecruitmentProcessWidget.styles";

export const CardHeader = ({
  title,
  progress,
  onAddClick,
}: {
  title: string;
  progress: number;
  onAddClick?: () => void;
}) => {
  const classes = useStyles();
  const { hasPermissions } = usePermissions();

  return (
    <Grid container className={classes.cardHeader}>
      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        color={"black"}
        textTransform={"uppercase"}
      >
        {title}
      </Typography>

      {onAddClick &&
      hasPermissions(
        permissions.UPDATE_ORGANIZATION_RP.action,
        permissions.UPDATE_ORGANIZATION_RP.subject,
      ) ? (
        <Grid container sm={2} className={classes.addButtonContainer}>
          <Button
            variant="contained"
            size="small"
            className={classes.addButton}
            onClick={onAddClick}
          >
            <Add className={classes.addIcon} />
          </Button>
        </Grid>
      ) : null}

      <Grid item marginLeft={"auto"}>
        <Grid container alignItems={"center"}>
          <Typography
            variant="subtitle1"
            fontWeight={"bold"}
            color={"black"}
          >{`${progress}%`}</Typography>
          <CircularProgress
            variant="determinate"
            value={progress}
            size={"1rem"}
            thickness={8}
            className={classes.circularProgress}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
