import { OrganizationInfo } from "@readyfit-common/models";
import { AddNewMemberFormValues } from "src/grading/pages/Organization/MembersTab/AddNewMemberModal/AddNewMemberModal";
import { UpdateUnitFormValues } from "src/grading/pages/Organization/UnitDetails/UnitDetails";
import { AddNewUnitFormValues } from "src/grading/pages/Organization/UnitsTab/AddNewUnitModal/AddNewUnitModal";
import { apiSlice } from "src/store/apiSlice";

export interface Organization {
  id: string;
  name: string;
  branch: string;
}

export interface Unit {
  id: string;
  name: string;
}

export interface Member {
  memberId: string;
  email: string;
  firstName: string;
  lastName: string;
  displayName: string;
  branchOfService: string;
  gender: string;
  birthday: string;
  roles: Role[];
  organizationId: string;
  organization: OrganizationInfo;
  units: Unit[];
  membershipStatus: "pending" | "approved" | "rejected";
  recruitmentProcess: {
    status: string;
  };
  createdAt?: string;
}

export interface Role {
  id: string;
  description: string;
  level: number;
}

export interface OrganizationRecruitmentProcess {
  organizationId: string;
  preQualify: { [key: string]: string };
  preQualifyOrder: string[];
  qualifiedForService: { [key: string]: string };
  qualifiedForServiceOrder: string[];
}

export const organizationsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query<{ organizations: Organization[] }, void>({
      query: () => ({
        url: "/api/account/organizations",
        method: "GET",
      }),
      transformResponse: (response: {
        data: { organizations: Organization[] };
      }) => response.data,
    }),
    getUnit: builder.query<
      { unit: Unit },
      { organizationId: string; unitId: string }
    >({
      query: ({ organizationId, unitId }) => ({
        url: `/api/account/organizations/${organizationId}/units/${unitId}`,
        method: "GET",
      }),
      transformResponse: (response: { data: { unit: Unit } }) => response.data,
    }),
    getUnits: builder.query<
      { units: Unit[] },
      { organizationId: string; search?: string }
    >({
      query: ({ organizationId, search }) => {
        let url = `/api/account/organizations/${organizationId}/units?`;
        if (search) url += `&search=${search}`;
        return {
          url,
          method: "GET",
        };
      },
      transformResponse: (response: { data: { units: Unit[] } }) =>
        response.data,
    }),
    addUnit: builder.mutation<
      { success: boolean; message: string; data: { unit: Unit } },
      { organizationId: string; createUnitPayload: AddNewUnitFormValues }
    >({
      query: ({ organizationId, createUnitPayload }) => ({
        url: `/api/account/organizations/${organizationId}/units`,
        method: "POST",
        body: { ...createUnitPayload },
      }),
    }),
    updateUnit: builder.mutation<
      { success: boolean },
      {
        organizationId: string;
        unitId: string;
        updateUnitPayload: UpdateUnitFormValues;
      }
    >({
      query: ({ organizationId, unitId, updateUnitPayload }) => ({
        url: `/api/account/organizations/${organizationId}/units/${unitId}`,
        method: "PATCH",
        body: { ...updateUnitPayload },
      }),
    }),
    getMember: builder.query<
      { member: Member },
      { organizationId: string; memberId: string }
    >({
      query: ({ organizationId, memberId }) => ({
        url: `/api/account/organizations/${organizationId}/members/${memberId}`,
        method: "GET",
      }),
      transformResponse: (response: { data: { member: Member } }) =>
        response.data,
    }),
    getMembers: builder.query<
      { members: Member[] },
      {
        organizationId: string;
        search?: string;
        unit?: string;
        status?: string;
        sortBy?: string;
      }
    >({
      query: ({ organizationId, search, unit, status, sortBy }) => {
        let url = `/api/account/organizations/${organizationId}/members?`;
        if (search) url += `&search=${search}`;
        if (unit) url += `&unit=${unit}`;
        if (status) url += `&status=${status}`;
        if (sortBy) url += `&sortBy=${sortBy}`;

        return {
          url,
          method: "GET",
        };
      },
      transformResponse: (response: { data: { members: Member[] } }) =>
        response.data,
      providesTags: ["Members"],
    }),
    getUnitMembers: builder.query<
      { members: Member[] },
      { organizationId: string; unitId: string; search?: string }
    >({
      query: ({ organizationId, unitId, search }) => {
        let url = `/api/account/organizations/${organizationId}/units/${unitId}/members?`;
        if (search) url += `&search=${search}`;
        return {
          url,
          method: "GET",
        };
      },
      transformResponse: (response: { data: { members: Member[] } }) =>
        response.data,
    }),
    addMember: builder.mutation<
      { success: boolean; message: string; data: { member: Member } },
      { organizationId: string; addMemberPayload: AddNewMemberFormValues }
    >({
      query: ({ organizationId, addMemberPayload }) => {
        const { role, unit, ...payload } = addMemberPayload;
        return {
          url: `/api/account/organizations/${organizationId}/members`,
          method: "POST",
          body: { ...payload, roles: [role], units: [unit] },
        };
      },
    }),
    updateMember: builder.mutation<
      { success: boolean },
      { organizationId: string; memberId: string; updateMemberPayload: any }
    >({
      query: ({ organizationId, memberId, updateMemberPayload }) => {
        const { role, unit } = updateMemberPayload;
        return {
          url: `/api/account/organizations/${organizationId}/members/${memberId}`,
          method: "PATCH",
          body: { roles: [role], units: [unit] },
        };
      },
    }),
    admitMember: builder.mutation<
      { success: boolean },
      {
        organizationId: string;
        memberId: string;
        membershipStatus: "pending" | "approved" | "rejected";
      }
    >({
      query: ({ organizationId, memberId, membershipStatus }) => {
        return {
          url: `/api/account/organizations/${organizationId}/members/${memberId}/admit`,
          body: { membershipStatus },
          method: "PATCH",
        };
      },
      invalidatesTags: ["Members"],
    }),
    getRoles: builder.query<{ roles: Role[] }, void>({
      query: () => ({
        url: "/api/account/roles",
        method: "GET",
      }),
      transformResponse: (response: { data: { roles: Role[] } }) =>
        response.data,
    }),
    getOrganizationRecruitmentProcess: builder.query<
      { recruitmentProcess: OrganizationRecruitmentProcess },
      { organizationId: string }
    >({
      query: ({ organizationId }) => ({
        url: `/api/account/organizations/${organizationId}/recruitment-process`,
        method: "GET",
      }),
      transformResponse: (response: {
        data: { recruitmentProcess: OrganizationRecruitmentProcess };
      }) => response.data,
      providesTags: ["OrganizationRecruitmentProcess"],
    }),
    updateOrganizationRecruitmentProcess: builder.mutation<
      { success: boolean },
      {
        organizationId: string;
        preQualify?: { [key: string]: string };
        preQualifyOrder?: string[];
        qualifiedForService?: { [key: string]: string };
        qualifiedForServiceOrder?: string[];
      }
    >({
      query: (payload) => {
        const {
          organizationId,
          preQualify,
          preQualifyOrder,
          qualifiedForService,
          qualifiedForServiceOrder,
        } = payload;

        const body: {
          preQualify?: { [key: string]: string };
          preQualifyOrder?: string[];
          qualifiedForService?: { [key: string]: string };
          qualifiedForServiceOrder?: string[];
        } = {};

        if (preQualify) body.preQualify = preQualify;
        if (preQualifyOrder) body.preQualifyOrder = preQualifyOrder;
        if (qualifiedForService) body.qualifiedForService = qualifiedForService;
        if (qualifiedForServiceOrder)
          body.qualifiedForServiceOrder = qualifiedForServiceOrder;

        return {
          url: `/api/account/organizations/${organizationId}/recruitment-process`,
          method: "PATCH",
          body: body,
        };
      },
      invalidatesTags: ["OrganizationRecruitmentProcess"],
    }),
    transferAthlete: builder.mutation<
      { success: boolean },
      { organizationId: string; memberId: string; newOrganizationId: string }
    >({
      query: (payload) => {
        const { organizationId, memberId, newOrganizationId } = payload;

        const body = {
          organizationId: newOrganizationId,
        };

        return {
          url: `/api/account/organizations/${organizationId}/members/${memberId}/transfer`,
          method: "PATCH",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useLazyGetOrganizationsQuery,
  useGetUnitQuery,
  useLazyGetUnitQuery,
  useGetUnitsQuery,
  useLazyGetUnitsQuery,
  useAddUnitMutation,
  useUpdateUnitMutation,
  useGetMemberQuery,
  useLazyGetMemberQuery,
  useGetMembersQuery,
  useLazyGetMembersQuery,
  useAddMemberMutation,
  useUpdateMemberMutation,
  useGetRolesQuery,
  useLazyGetRolesQuery,
  useGetUnitMembersQuery,
  useLazyGetUnitMembersQuery,
  useAdmitMemberMutation,
  useGetOrganizationRecruitmentProcessQuery,
  useLazyGetOrganizationRecruitmentProcessQuery,
  useUpdateOrganizationRecruitmentProcessMutation,
  useTransferAthleteMutation,
} = organizationsApiSlice;
