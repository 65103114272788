import * as React from "react";

export interface SvgComponentProps {
  fill?: string;
  stroke?: string;
}

const SvgComponent = ({ fill, stroke, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill={fill || "none"}
    {...props}
  >
    <circle cx="12" cy="11.5" r="5" fill="black" />
  </svg>
);
export default SvgComponent;
