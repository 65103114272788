import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  birthday: Yup.string().test(
    "birthday",
    "Birthday is required",
    function (value) {
      if (!value) {
        return this.createError({ message: "Birthday is required" });
      }
      const date = new Date(value);
      return date.toString() !== "Invalid Date";
    },
  ),
  joinRequestReason: Yup.string().required("Join Request Reason is required"),
});

export default validationSchema;
