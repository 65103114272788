import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  useTheme,
} from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { usePermissions } from "src/grading/hooks/usePermissions";
import { useAppSelector } from "src/store/hooks";
import { selectShowDrawer } from "src/store/reducers/navigation/navigationSlice";
import useStyles from "./NavigationLink.styles";
import { NavLink } from "./links";

interface NavigationLinkProps {
  link: NavLink;
}

const NavigationLink = ({ link }: NavigationLinkProps) => {
  const theme = useTheme();
  const location = useLocation();
  const showDrawer = useAppSelector(selectShowDrawer);
  const classes = useStyles(showDrawer)();
  const history = useHistory();
  const { hasPermissions } = usePermissions();
  const { permission, ...rest } = link;

  if (permission && !hasPermissions(permission.action, permission.subject)) {
    return <></>;
  }

  return (
    <>
      <Tooltip title={link.name}>
        <ListItemButton
          {...rest}
          className={`${classes.link} ${classes.topLink} ${
            location.pathname.includes(link.path) && classes.selectedLink
          }`}
          onClick={link.onClick || (() => history.push(link.path))}
          style={!showDrawer ? { width: "fit-content" } : {}}
        >
          <ListItemIcon
            style={!showDrawer ? { width: "fit-content" } : {}}
            classes={!showDrawer ? { root: classes.listIconWidth } : {}}
          >
            {link.icon}
          </ListItemIcon>
          <ListItemText
            primary={link.name}
            sx={{
              color: theme.palette.primary.contrastText,
            }}
            style={!showDrawer ? { display: "none" } : {}}
          />
        </ListItemButton>
      </Tooltip>
    </>
  );
};

export default NavigationLink;
