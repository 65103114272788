import React from "react";
import { Route, useRouteMatch } from "react-router-dom";
import Login from "../pages/Login/Login";

interface GradingRoutesProps {
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

export const GRADING_ROUTES = {
  LOGIN: "/grading/login",
  TEST_GRID: "/grading/tests",
  TEST: "/grading/tests/:userId/:testId",
  PENDING_TESTS: "/grading/pending",
  CERTFIED_TESTS: "/grading/certified",
  ORGANIZATION: "/grading/organization",
};

const GradingRoutes = ({ error, setError }: GradingRoutesProps) => {
  const match = useRouteMatch();
  return (
    <>
      <Route path={match.path}>
        <Login error={error} setError={setError} />
      </Route>
    </>
  );
};

export default GradingRoutes;
