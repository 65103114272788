import {
  Add,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from "@mui/icons-material";
import {
  Checkbox,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import { Profile } from "@readyfit-common/models";
import { useFormik } from "formik";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import GradingHeader from "src/grading/components/GradingHeader/GradingHeader";
import LiveTestStepper from "src/grading/components/LiveTestStepper/LiveTestStepper";
import RFDataGrid from "src/grading/components/RFDataGrid/RFDataGrid";
import RFMenu from "src/grading/components/RFMenu/RFMenu";
import { parseTestDuration } from "src/grading/utils/testHelpers";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectCurrentLiveTest,
  selectCurrentLiveTestInfo,
  selectLiveTestStep,
  selectSelectedAthletes,
  setAthletesLiveTestGradeResults,
  setSelectedAthletes,
} from "src/store/reducers/liveTest/liveTestSlice";
import { useLazyGetUsersQuery } from "src/store/reducers/users/usersApiSlice";
import theme from "src/theme";
import AddNewAthleteModal from "./AddNewAthleteModal/AddNewAthleteModal";
import useStyles from "./SelectAthlete.styles";
import SelectAthleteTableColumns, {
  AthleteRow,
} from "./SelectAthleteTableColumns/SelectAthleteTableColumns";

interface SelectAthleteProps {
  users: Profile[];
  setUsers: React.Dispatch<React.SetStateAction<Profile[]>>;
  withGradingHeaderInLayout?: boolean;
  setWithGradingHeaderInLayout?: React.Dispatch<React.SetStateAction<boolean>>;
}

const SelectAthlete = ({
  users,
  setUsers,
  setWithGradingHeaderInLayout,
  withGradingHeaderInLayout,
}: SelectAthleteProps) => {
  const liveTest = useAppSelector(selectCurrentLiveTest);
  const liveTestInfo = useAppSelector(selectCurrentLiveTestInfo);
  const liveTestStep = useAppSelector(selectLiveTestStep);
  const classes = useStyles();

  const [getAthletes, { isLoading }] = useLazyGetUsersQuery();

  const selectedAthletes = useAppSelector(selectSelectedAthletes);
  // const { testId, branch } = useParams<{ testId: string; branch: string }>();
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [newAthleteModalOpen, setNewAthleteModalOpen] = useState(false);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "firstName", sort: "asc" },
  ]);

  useEffect(() => {
    if (setWithGradingHeaderInLayout) {
      setWithGradingHeaderInLayout(false);
    }

    const fetchUsers = async () => {
      const response = await getAthletes({
        role: "users,organization_athletes",
      }).unwrap();
      if (response?.data?.users) {
        setUsers(response?.data?.users);
      }
    };

    fetchUsers();
  }, [getAthletes, setUsers, setWithGradingHeaderInLayout]);

  const formik = useFormik({
    initialValues: {
      search: "",
      test: -1,
      activity: -1,
    },
    onSubmit: async (values) => {
      console.log("values", values);
    },
  });

  const { values, setFieldValue } = formik;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((searchValue) => {
      getAthletes({
        role: "users,organization_athletes",
        search: searchValue,
      })
        .unwrap()
        .then((results) => {
          setUsers(results.data.users);
        });
    }, 500),
    [],
  );

  const handleTestSequenceClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleTestSequenceClose = () => {
    setAnchorEl(null);
  };

  const handleAddNewAthlete = () => {
    setNewAthleteModalOpen(true);
  };

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      dispatch(setSelectedAthletes(users));
    } else {
      dispatch(setSelectedAthletes([]));
      dispatch(
        setAthletesLiveTestGradeResults({ athleteLiveTestGradeResults: [] }),
      );
    }
  };

  return isLoading ? (
    <Spinner
      animation="border"
      style={{ left: "50%", top: "50%", position: "relative" }}
    />
  ) : (
    <Grid container>
      <Grid
        container
        className={classes.headerContainer}
        component={Paper}
        elevation={1}
      >
        {/* PAGE TITLE */}
        <Grid item container xs={12} className={classes.titleContainer}>
          <GradingHeader
            withBreadcrumbs
            containerStyles={{
              marginTop: 0,
              marginBottom: theme.spacing(2),
            }}
          />
          <Typography variant="h3">Select Athlete</Typography>

          <Typography className={classes.stepCount} variant="subtitle1">
            {`Step ${liveTestStep + 1} of 2`}
          </Typography>

          <Grid item marginLeft={"auto"} marginRight={1}>
            <LiveTestStepper athletes={users} mini={true} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={classes.bannerContainer}>
        <Grid
          container
          item
          flex={1}
          alignItems={"center"}
          ml={theme.spacing(4)}
        >
          <Typography color={theme.palette.primary.contrastText} variant={"h3"}>
            {liveTestInfo?.displayName}
          </Typography>
        </Grid>
        <Divider orientation={"vertical"} className={classes.divider} />

        <Grid
          container
          item
          xs={2}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            color={theme.palette.primary.contrastText}
            variant={"subtitle1"}
            textTransform={"uppercase"}
          >
            Duration
          </Typography>
          <Typography
            color={theme.palette.primary.contrastText}
            variant={"caption"}
            textTransform={"uppercase"}
          >
            {parseTestDuration(liveTestInfo?.estimatedTime as string).replace(
              "Minutes",
              "",
            )}
          </Typography>
          <Typography variant={"subtitle1"} color={"#B2B8BC"}>
            Minutes
          </Typography>
        </Grid>
        <Divider orientation={"vertical"} className={classes.divider} />

        <Grid
          container
          item
          flex={1}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <IconButton onClick={handleTestSequenceClick}>
            <Typography
              variant="button"
              color={theme.palette.primary.contrastText}
              textTransform={"uppercase"}
              marginRight={theme.spacing(1)}
            >
              See Test Sequence
            </Typography>
            {anchorEl ? (
              <KeyboardArrowUp className={classes.icon} color="primary" />
            ) : (
              <KeyboardArrowDown className={classes.icon} color="primary" />
            )}
          </IconButton>
          <RFMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            handleClose={handleTestSequenceClose}
          >
            {liveTest?.steps.map((step, i) => {
              let stepNumber;
              if (liveTest?.steps[0].exerciseType === "bodyComp") {
                stepNumber = i;
              } else {
                stepNumber = i + 1;
              }
              if (step?.exerciseType === "bodyComp") return;
              if (step?.choices) {
                return (
                  <Grid
                    container
                    key={i}
                    alignItems={"center"}
                    flexDirection={"row"}
                    padding={theme.spacing(2)}
                  >
                    <Grid item className={classes.stepCircle}>
                      <Typography
                        variant="subtitle1"
                        className={classes.circleText}
                      >
                        {stepNumber}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <MenuItem style={{ whiteSpace: "normal" }}>
                        {step.choices[0].displayName} Or{" "}
                        {step.choices[1].displayName}
                      </MenuItem>
                    </Grid>
                  </Grid>
                );
              }
              return (
                <Grid
                  container
                  key={i}
                  alignItems={"center"}
                  padding={theme.spacing(2)}
                >
                  <div className={classes.stepCircle}>
                    <Typography
                      variant="subtitle1"
                      className={classes.circleText}
                    >
                      {stepNumber}
                    </Typography>
                  </div>
                  <MenuItem>{step.displayName}</MenuItem>
                </Grid>
              );
            })}
          </RFMenu>
        </Grid>
      </Grid>

      {/* PAGE TOOLS */}
      <Grid item container className={classes.toolsContainer} gap={2}>
        <TextField
          // label="Search"
          name="search"
          type="text"
          value={values.search}
          onChange={(e) => {
            setFieldValue("search", e.target.value);
            debounceSearch(e.target.value);
          }}
          className={classes.searchField}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          inputProps={{
            className: classes.searchInputField,
          }}
          // fullWidth
          placeholder="Search by user"
          // error={InputError("search", touched, errors).error}
          // helperText={InputError("search", touched, errors).helperText}
        />

        <IconButton
          className={classes.addAthleteButton}
          onClick={handleAddNewAthlete}
        >
          <Typography variant="caption" textTransform={"none"}>
            Add Athlete
          </Typography>
          <Add />
        </IconButton>

        <Grid
          flexDirection={"row"}
          marginLeft={"auto"}
          marginRight={theme.spacing(1)}
        >
          <Grid container alignItems={"center"}>
            <Typography
              variant="subtitle1"
              fontWeight={"bold"}
              textTransform={"uppercase"}
              color={theme.palette.success.contrastText}
            >
              Select All
            </Typography>
            <Checkbox
              checked={selectedAthletes.length === users.length}
              onChange={(event) => handleSelectAll(event)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Grid>
        </Grid>
      </Grid>

      {/* ATHLETES GRID */}
      {users?.length > 0 && (
        <RFDataGrid
          rows={users}
          columns={SelectAthleteTableColumns()}
          loading={isLoading}
          getRowClassName={({ row }: AthleteRow) => {
            if (selectedAthletes.some((athlete) => athlete.id === row.id)) {
              return classes.selectedRow;
            }
            return "";
          }}
          disableSelectionOnClick
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => {
            setSortModel(newSortModel);
          }}
        />
      )}

      <AddNewAthleteModal
        onClose={() => setNewAthleteModalOpen(false)}
        open={newAthleteModalOpen}
      />
    </Grid>
  );
};

export default SelectAthlete;
