import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import { DEFAULT_ORGANIZATION } from "src/grading/models";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import { addBreadcrumb } from "src/store/reducers/navigation/navigationSlice";
import {
  Member,
  Role,
  Unit,
  useLazyGetMemberQuery,
  useLazyGetRolesQuery,
  useLazyGetUnitsQuery,
  useUpdateMemberMutation,
} from "src/store/reducers/organizations/organizationsApiSlice";
import theme from "src/theme";
import * as Yup from "yup";
import { TestGrid } from "../../TestGrid/TestGrid";
import useStyles from "./MemberDetails.styles";

const validationSchema = Yup.object().shape({
  role: Yup.string().required("Role is required"),
  unit: Yup.string().required("Unit is required"),
});

export interface UpdateMemberFormValues {
  role: string;
  unit: string;
}

const MemberDetails = () => {
  const classes = useStyles();

  const [state, setState] = useState<{
    isLoading: boolean;
    isEditing: boolean;
    isUpdating: boolean;
    member?: Member;
    units?: Unit[];
    roles?: Role[];
  }>({
    isLoading: true,
    isEditing: false,
    isUpdating: false,
  });

  const { memberId } = useParams<{ memberId: string }>();

  const history = useHistory();

  const user = useAppSelector(selectCurrentUser);

  const [getMember] = useLazyGetMemberQuery();

  const [getUnits] = useLazyGetUnitsQuery();

  const [getRoles] = useLazyGetRolesQuery();

  const [updateMember] = useUpdateMemberMutation();

  const dispatch = useAppDispatch();

  const saveMember = async (values: UpdateMemberFormValues) => {
    setState((s) => ({ ...s, isUpdating: true }));

    const result = await updateMember({
      organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
      memberId,
      updateMemberPayload: values,
    }).unwrap();

    if (result.success) {
      formik.setValues({ role: values.role, unit: values.unit });
      setState((s) => ({
        ...s,
        isEditing: false,
        isUpdating: false,
        member: s.member
          ? {
              ...s.member,
              roles: [
                s.roles?.find((r) => r.id === values.role) ?? {
                  id: "",
                  description: "",
                  level: 0,
                },
              ],
              units: [
                s.units?.find((u) => u.id === values.unit) ?? {
                  id: "",
                  name: "",
                },
              ],
            }
          : undefined,
      }));
    }
  };

  const formik = useFormik({
    initialValues: {
      role: "",
      unit: "",
    },
    validationSchema,
    onSubmit: async (values: UpdateMemberFormValues) => {
      await saveMember(values);
    },
  });

  const { values, handleSubmit, setValues, setFieldValue } = formik;

  useEffect(() => {
    const fetchMember = async () => {
      const [{ member }, { units }, { roles }] = await Promise.all([
        getMember({
          organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
          memberId,
        }).unwrap(),

        getUnits({
          organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
        }).unwrap(),

        getRoles().unwrap(),
      ]);

      setState((s) => ({ ...s, isLoading: false, member, units, roles }));

      setValues({
        role: member.roles.at(0)?.id ?? "",
        unit: member.units.at(0)?.id ?? "",
      });
    };

    fetchMember();
  }, [getMember, getUnits, getRoles, setValues, user.organizationId, memberId]);

  return state.isLoading ? (
    <Spinner
      animation="border"
      style={{ left: "50%", top: "50%", position: "relative" }}
    />
  ) : (
    <Grid item container>
      <Grid container item>
        <ArrowBack />
        <Typography
          color={"#000"}
          onClick={() => history.push("/grading/organization")}
          variant="button"
          className={classes.navigationButton}
        >
          ORGANIZATION
        </Typography>
        <Typography color={"#000"} variant="button">
          / MEMBER DETAILS
        </Typography>
      </Grid>

      <Grid item xs={12} mt={1}>
        <Typography variant="h2">Member Details</Typography>
      </Grid>

      <Grid item container xs={12} className={classes.bannerContainer}>
        {!state.isEditing ? (
          <>
            <Grid
              container
              item
              flex={1}
              alignItems={"center"}
              ml={theme.spacing(4)}
            >
              <Grid container item flexDirection={"column"}>
                <Typography
                  color={theme.palette.primary.contrastText}
                  variant={"subtitle1"}
                  textTransform={"uppercase"}
                >
                  Name
                </Typography>
                <Typography
                  color={theme.palette.primary.contrastText}
                  variant={"h3"}
                  textTransform={"uppercase"}
                >
                  {`${state.member?.firstName} ${state.member?.lastName}`}
                </Typography>
              </Grid>
            </Grid>

            <Divider orientation={"vertical"} className={classes.divider} />

            <Grid
              container
              item
              flex={1}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                color={theme.palette.primary.contrastText}
                variant={"subtitle1"}
                textTransform={"uppercase"}
              >
                Units
              </Typography>
              {state.member?.units.map((unit, idx) => (
                <Typography
                  key={idx}
                  color={theme.palette.primary.contrastText}
                  textTransform={"uppercase"}
                  fontSize={12}
                >
                  {unit.name}
                </Typography>
              ))}
            </Grid>

            <Divider orientation={"vertical"} className={classes.divider} />

            <Grid
              container
              item
              // xs={2}
              // md={3}
              flex={1}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                color={theme.palette.primary.contrastText}
                variant={"subtitle1"}
                textTransform={"uppercase"}
              >
                Roles
              </Typography>
              {state.member?.roles.map((role, idx) => (
                <Typography
                  key={idx}
                  color={theme.palette.primary.contrastText}
                  textTransform={"uppercase"}
                  fontSize={12}
                >
                  {role.description}
                </Typography>
              ))}
            </Grid>

            <Divider
              orientation={"vertical"}
              className={classes.divider}
              style={{
                // forcing not marging right here because bannerButtonsCOntainer already has padding
                marginRight: 0,
              }}
            />

            <Grid
              container
              item
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              className={classes.bannerButtonsContainer}
              xs={2}
            >
              <Button
                variant="outlined"
                size="small"
                className={classes.bannerButtons}
                fullWidth
                onClick={() => setState((s) => ({ ...s, isEditing: true }))}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                size="small"
                className={classes.bannerButtons}
                fullWidth
                onClick={() => {
                  dispatch(
                    addBreadcrumb({
                      label: "Member Details",
                      path: `/grading/organization/members/${memberId}`,
                    }),
                  );
                  history.push(`/grading/athletes/${memberId}`);
                }}
              >
                See profile
              </Button>
            </Grid>
          </>
        ) : (
          <>
            <Grid
              container
              item
              flex={1}
              alignItems={"center"}
              ml={theme.spacing(4)}
            >
              <Grid container item flexDirection={"column"}>
                <Typography
                  color={theme.palette.primary.contrastText}
                  variant={"subtitle1"}
                  textTransform={"uppercase"}
                >
                  Name
                </Typography>
                <Typography
                  color={theme.palette.primary.contrastText}
                  variant={"h3"}
                  textTransform={"uppercase"}
                >
                  {`${state.member?.firstName} ${state.member?.lastName}`}
                </Typography>
              </Grid>
            </Grid>

            <Divider orientation={"vertical"} className={classes.divider} />

            <Grid
              container
              item
              xs={2}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                color={theme.palette.primary.contrastText}
                variant={"subtitle1"}
                textTransform={"uppercase"}
              >
                Units
              </Typography>
              <Select
                className={classes.input}
                fullWidth
                value={values.unit}
                onChange={(e) => setFieldValue("unit", e.target.value)}
              >
                {state.units?.map((unit) => (
                  <MenuItem key={unit.id} value={unit.id}>
                    {unit.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Divider orientation={"vertical"} className={classes.divider} />

            <Grid
              container
              item
              xs={3}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <Typography
                color={theme.palette.primary.contrastText}
                variant={"subtitle1"}
                textTransform={"uppercase"}
              >
                Roles
              </Typography>
              <Select
                className={classes.input}
                fullWidth
                value={values.role}
                onChange={(e) => setFieldValue("role", e.target.value)}
              >
                {state.roles &&
                  [...state.roles]
                    .sort((a, b) => b.level - a.level)
                    .map((role) => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.description}
                      </MenuItem>
                    ))}
              </Select>
            </Grid>

            <Divider orientation={"vertical"} className={classes.divider} />

            <Grid
              container
              item
              xs={1}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              className={classes.bannerButtonsContainer}
            >
              <Button
                variant="outlined"
                size="small"
                className={classes.bannerButtons}
                fullWidth
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save
              </Button>
              <Button
                variant="outlined"
                size="small"
                color="secondary"
                className={classes.bannerButtons}
                fullWidth
                onClick={() => {
                  formik.setValues({
                    role: state.member?.roles.at(0)?.id ?? "",
                    unit: state.member?.units.at(0)?.id ?? "",
                  });
                  setState((s) => ({ ...s, isEditing: false }));
                }}
              >
                Cancel
              </Button>
            </Grid>
          </>
        )}
      </Grid>

      <TestGrid userId={memberId} certified="true" hideTitle={true} />
    </Grid>
  );
};

export default MemberDetails;
