import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: "10px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.15)",
    marginTop: "-10px",
  },
  titleContainer: {
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  },
  stepCount: {
    textTransform: "uppercase",
    backgroundColor: "#D6D6D6",
    color: "#333333",
    borderRadius: "5px",
    marginLeft: theme.spacing(2),
    display: "flex",
    padding: `0 ${theme.spacing(1)}`,
    alignItems: "center",
    justifyContent: "center",
  },
  bannerContainer: {
    marginTop: theme.spacing(1),
    backgroundColor: "#262F34",
    overflow: "hidden",
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    border: `1px solid ${theme.palette.secondary.light}`,
    // position: "sticky",
    // top: 0,
    // zIndex: 9999,
  },
  divider: {
    borderRight: "1px solid #3F4E57",
  },
  rulesButton: {
    textTransform: "uppercase",
    borderRadius: theme.spacing(1),
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  },
  athletesContainer: {
    marginTop: theme.spacing(4),
  },
  athleteRow: {
    alignItems: "center",
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
  },
  athleteFirstRow: {
    padding: theme.spacing(2),
  },
  athleteInfoContainer: {
    alignItems: "center",
  },
  standarsContainer: {
    flexDirection: "column",
    alignItems: "center",
  },
  alternateExerciseOptionContainer: {
    borderRadius: theme.spacing(1),
  },
  actionButtonContainer: {
    justifyContent: "space-evenly",
  },
  actionButton: {
    width: theme.spacing(15),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  passButton: {
    borderColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  failButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.success.contrastText,
  },
  gradeInputsContainer: {
    backgroundColor: "#F2F2F2",
  },
  gradeInputsContainerContent: {
    padding: theme.spacing(2),
  },
}));

export default useStyles;
