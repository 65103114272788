import { Button, Grid, Typography } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import { Profile } from "@readyfit-common/models";
import { capitalize } from "lodash";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AssignTestIcon, TransferIcon } from "src/grading/assets";
import AthleteIcon from "src/grading/components/AthleteIcon/AthleteIcon";
import GradingHeader from "src/grading/components/GradingHeader/GradingHeader";
import AthletesLayout from "src/grading/layout/AthletesLayout/AthletesLayout";
import { DEFAULT_ORGANIZATION } from "src/grading/models";
import { useAppDispatch } from "src/store/hooks";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import { useLazyExportTestScoresQuery } from "src/store/reducers/documents/documentsApiSlice";
import {
  addBreadcrumb,
  clearBreadcrumbs,
} from "src/store/reducers/navigation/navigationSlice";
import {
  OrganizationRecruitmentProcess,
  useLazyGetOrganizationRecruitmentProcessQuery,
} from "src/store/reducers/organizations/organizationsApiSlice";
import {
  TestScore,
  useLazyGetScoresQuery,
} from "src/store/reducers/scores/scoresApiSlice";
import { useLazyGetUserByIdQuery } from "src/store/reducers/users/usersApiSlice";
import theme from "src/theme";
import useStyles from "./AthleteDetails.styles";
import LastTestScoreModal from "./LastTestScoreModal/LastTestScoreModal";
import PastTestsDataGrid from "./PastTestsDataGrid/PastTestsDataGrid";
import RecruitmentProcessWidget from "./RecruitmentProcessWidget/RecruitmentProcessWidget";
import TestProgressChart from "./TestProgressChart/TestProgressChart";
import TransferAthleteModal from "./TransferAthleteModal/TransferAthleteModal";

export const formatDate = (date: string) => {
  if (!date) {
    return "";
  }
  const newDate = new Date(date);
  const formattedDate =
    (newDate.getMonth() + 1 + "").padStart(2, "0") +
    "/" +
    (newDate.getDate() + "").padStart(2, "0") +
    "/" +
    newDate.getFullYear();

  return formattedDate;
};

const AthleteDetails = () => {
  const [athlete, setAthlete] = useState<Profile | null>(null);
  const [scores, setScores] = useState<TestScore[]>([]);
  const [organizationRecruitmentProcess, setOrganizationRecruitmentProcess] =
    useState<OrganizationRecruitmentProcess | null>(null);
  const { athleteId } = useParams<{ athleteId: string }>();
  const [getUserById, { isLoading: isUserLoading }] = useLazyGetUserByIdQuery();
  const [getScores, { isFetching: areScoreLoading }] = useLazyGetScoresQuery();
  const [getOrgRP] = useLazyGetOrganizationRecruitmentProcessQuery();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "timestamp", sort: "asc" },
  ]);
  const [sortBy, setSortBy] = useState("-1");
  const lastTestScore =
    scores[sortBy?.toLowerCase().includes("asc") ? scores.length - 1 : 0];
  const [lastTestScoreModalOpen, setLastTestScoreModalOpen] = useState(false);
  const user = useSelector(selectCurrentUser);
  const [exportTestScores] = useLazyExportTestScoresQuery();
  const [transferAthleteModalOpen, setTransferAthleteModalOpen] =
    useState(false);

  const getAthleteScores = async () => {
    const response = await getScores({
      userId: athleteId,
      ...(sortBy !== "-1" && {
        sortBy,
      }),
    }).unwrap();

    if (response.testScores) {
      setScores(response.testScores);
    }
  };

  const getOrganizationRecruitmentProcess = async () => {
    const response = await getOrgRP({
      organizationId: user.organizationId
        ? user.organizationId
        : DEFAULT_ORGANIZATION.id,
    }).unwrap();

    if (response?.recruitmentProcess) {
      setOrganizationRecruitmentProcess(response.recruitmentProcess);
    }
  };

  useEffect(() => {
    const fetchAthlete = async () => {
      const response = await getUserById(athleteId).unwrap();
      if (response?.profile) {
        setAthlete(response.profile);
      }
    };

    dispatch(
      addBreadcrumb({
        label: "Athlete Profile",
        path: `/grading/athletes`,
      }),
    );

    Promise.all([
      fetchAthlete(),
      getAthleteScores(),
      getOrganizationRecruitmentProcess(),
    ]);

    return () => {
      dispatch(clearBreadcrumbs());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy]);

  const handleExportPastTests = async () => {
    let url = "";
    try {
      url = await exportTestScores({ userId: athleteId, sortBy }).unwrap();
    } catch (error) {
      console.log(error);
    }

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <AthletesLayout>
      {isUserLoading ? (
        <Spinner
          animation="border"
          style={{ left: "50%", top: "50%", position: "relative" }}
        />
      ) : athlete ? (
        <Grid container>
          <GradingHeader
            // label={"Athletes / Athletes Profile"}
            onClick={() => history.push("/athletes")}
            withBreadcrumbs
          />

          <Grid container className={classes.profileIconContainer}>
            <AthleteIcon profile={athlete} customStyles={classes.profileIcon} />

            <Grid item className={classes.profileInfoContainer}>
              <Grid container>
                <Typography variant="h3">
                  {athlete.firstName} {athlete.lastName}
                </Typography>

                <Grid container flexDirection={"row"} gap={1}>
                  <Typography variant="h5" className={classes.profileBadge}>
                    {athlete.organization?.name || "-"}
                  </Typography>

                  <Typography variant="h5" className={classes.profileBadge}>
                    {capitalize(athlete.branchOfService) || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            {/* Action Buttons */}
            <Grid item className={classes.actionButtonContainer}>
              <Grid container flexDirection={"row"} gap={1}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {}}
                  className={classes.actionButton}
                >
                  Assign Test
                  <AssignTestIcon style={{ marginLeft: theme.spacing(1) }} />
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  onClick={() => {
                    setTransferAthleteModalOpen(true);
                  }}
                  className={classes.actionButton}
                >
                  Transfer Athlete
                  <TransferIcon style={{ marginLeft: theme.spacing(1) }} />
                </Button>
              </Grid>
            </Grid>

            {/* Athlete Banner */}
            <Grid container className={classes.bannerContainer}>
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  textTransform={"uppercase"}
                  color={"white"}
                >
                  Overview
                </Typography>
              </Grid>

              <Grid container marginTop={4} xs={12} sm={3} lg={2.4}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color={"#B2B8BC"}
                    textTransform={"uppercase"}
                  >
                    Primary Test
                  </Typography>
                </Grid>

                <Typography variant="h4" color={"white"}>
                  Not Assign
                </Typography>
              </Grid>

              <Grid container marginTop={4} xs={12} sm={3} lg={2.4}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color={"#B2B8BC"}
                    textTransform={"uppercase"}
                  >
                    Current Training Plan
                  </Typography>
                </Grid>

                <Typography variant="h4" color={"white"}>
                  Not Assign
                </Typography>
              </Grid>

              <Grid container marginTop={4} xs={12} sm={3} lg={2.4}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color={"#B2B8BC"}
                    textTransform={"uppercase"}
                  >
                    Coach
                  </Typography>
                </Grid>

                <Typography variant="h4" color={"white"}>
                  Roy Hohenheim
                </Typography>
              </Grid>

              <Grid container marginTop={4} xs={12} sm={3} lg={2.4}>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    color={"#B2B8BC"}
                    textTransform={"uppercase"}
                  >
                    Last Test Score
                  </Typography>
                </Grid>

                <Typography variant="h4" color={"white"}>
                  {lastTestScore?.testId
                    ? `${lastTestScore?.score} / ${lastTestScore?.maxScore}`
                    : "-"}
                </Typography>
              </Grid>

              <Grid container marginTop={4} xs={12} sm={3} lg={2.4}>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setLastTestScoreModalOpen(true)}
                  className={classes.seeDetailsButton}
                  disabled={!lastTestScore?.testResultId}
                >
                  See Details
                </Button>
              </Grid>
            </Grid>

            {/* Athlete Profile Information */}
            <Grid
              container
              className={classes.profileInformationContainer}
              gap={3}
            >
              <Grid item xs={12}>
                <Typography variant="h5">Profile</Typography>
              </Grid>

              <Grid
                item
                container
                marginTop={2}
                xs={12}
                className={classes.profileInformationColumnContainer}
              >
                {/* <Grid container> */}
                <Grid container>
                  <Typography
                    variant="subtitle1"
                    fontWeight={"bold"}
                    color={"black"}
                    textTransform={"uppercase"}
                  >
                    Personal Data
                  </Typography>
                </Grid>

                <Grid container className={classes.profileInformationRow}>
                  <Typography variant="caption" color={"#666666"}>
                    Birthday
                  </Typography>

                  <Typography variant="caption">
                    {formatDate(athlete.birthday || "")}
                  </Typography>
                </Grid>

                <Grid container className={classes.profileInformationRow}>
                  <Typography variant="caption" color={"#666666"}>
                    Height
                  </Typography>

                  <Typography variant="caption">
                    {athlete.height ? `${athlete.height} in` : "-"}
                  </Typography>
                </Grid>

                <Grid container className={classes.profileInformationRow}>
                  <Typography variant="caption" color={"#666666"}>
                    Weight
                  </Typography>

                  <Typography variant="caption">
                    {athlete.weight ? `${athlete.weight} lb` : "-"}
                  </Typography>
                </Grid>

                <Grid
                  container
                  className={classes.profileInformationRow}
                  style={{ borderBottom: "0px" }}
                >
                  <Typography variant="caption" color={"#666666"}>
                    Gender
                  </Typography>

                  <Typography variant="caption">
                    {athlete.gender ? athlete.gender : "-"}
                  </Typography>
                </Grid>
                {/* </Grid> */}
              </Grid>

              {/* ADDRESS DETAILS */}
              <Grid
                item
                container
                marginTop={2}
                xs={12}
                className={classes.profileInformationColumnContainer}
              >
                {/* <Grid container> */}
                <Grid container>
                  <Typography
                    variant="subtitle1"
                    fontWeight={"bold"}
                    color={"black"}
                    textTransform={"uppercase"}
                  >
                    Address
                  </Typography>
                </Grid>

                <Grid container className={classes.profileInformationRow}>
                  <Typography variant="caption" color={"#666666"}>
                    Address 1
                  </Typography>

                  <Typography variant="caption">
                    {athlete.shippingAddress?.addressLine1 || "-"}
                  </Typography>
                </Grid>

                <Grid container className={classes.profileInformationRow}>
                  <Typography variant="caption" color={"#666666"}>
                    Address 2
                  </Typography>

                  <Typography variant="caption">
                    {athlete.shippingAddress?.addressLine2 || "-"}
                  </Typography>
                </Grid>

                <Grid container className={classes.profileInformationRow}>
                  <Typography variant="caption" color={"#666666"}>
                    City
                  </Typography>

                  <Typography variant="caption">
                    {athlete.shippingAddress?.city || "-"}
                  </Typography>
                </Grid>

                <Grid
                  container
                  className={classes.profileInformationRow}
                  style={{ borderBottom: "0px" }}
                >
                  <Typography variant="caption" color={"#666666"}>
                    State
                  </Typography>

                  <Typography variant="caption">
                    {athlete.shippingAddress?.state || "-"}
                  </Typography>
                </Grid>

                {/* <Grid
                  container
                  className={classes.profileInformationRow}
                  style={{ borderBottom: "0px" }}
                >
                  <Typography variant="caption" color={"#666666"}>
                    Zip Code
                  </Typography>

                  <Typography variant="caption">
                    {athlete.shippingAddress?.postalCode || "-"}
                  </Typography>
                </Grid> */}
                {/* </Grid> */}
              </Grid>
            </Grid>

            {/* Recruitment process */}
            {athlete.recruitmentProcess && organizationRecruitmentProcess ? (
              <Grid
                container
                marginTop={3}
                className={classes.recruitmentProcessContainer}
              >
                <RecruitmentProcessWidget
                  recruitmentProcess={athlete.recruitmentProcess}
                  organizationRecruitmentProcess={
                    organizationRecruitmentProcess
                  }
                />
              </Grid>
            ) : null}

            {/* Tests progress */}

            <Grid
              container
              marginTop={3}
              className={classes.testsProgressContainer}
            >
              <TestProgressChart scores={scores} />
            </Grid>

            {/* PAST TESTS */}
            <Grid container marginTop={3}>
              <PastTestsDataGrid
                testScores={scores}
                isLoading={areScoreLoading}
                sortModel={sortModel}
                setSortModel={setSortModel}
                getAthleteScores={getAthleteScores}
                sortBy={sortBy}
                setSortBy={setSortBy}
                exportPastTests={handleExportPastTests}
              />
            </Grid>
          </Grid>
          <LastTestScoreModal
            open={lastTestScoreModalOpen}
            onClose={() => setLastTestScoreModalOpen(false)}
            lastTestScore={lastTestScore}
          />
          <TransferAthleteModal
            open={transferAthleteModalOpen}
            onClose={() => setTransferAthleteModalOpen(false)}
            athlete={athlete}
          />
        </Grid>
      ) : null}
    </AthletesLayout>
  );
};

export default AthleteDetails;
