import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: "10px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.15)",
    marginTop: "-10px",
    padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
  },
  titleContainer: {
    marginTop: theme.spacing(2),
    alignItems: "center",
    padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  },
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: "1px solid #3F4E57",
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  profileText: {
    marginLeft: theme.spacing(2.5),
  },
  stepCircle: {
    backgroundColor: "#DBDBDB",
    borderRadius: "50%",
    width: theme.spacing(3),
    height: theme.spacing(3),
    position: "relative",
  },
  circleText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#333333",
  },
  secondRowContainer: {
    borderBottomLeftRadius: theme.spacing(0),
    borderBottomRightRadius: theme.spacing(0),
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
}));

export default useStyles;
