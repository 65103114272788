import { apiSlice } from "src/store/apiSlice";

export interface GroupMember {
  memberId: string;
  groupId: string;
  name: string;
  applyDate: Date;
  managerApproved: boolean;
  userApproved: boolean;
  joinDate?: Date;
}

export interface Group {
  id: string;
  managerId: string;
  name: string;
  members?: GroupMember[];
}

export const groupsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getGroup: builder.query({
      query: (params) => {
        const { managerId, limit, offset, members } = params;
        let url = "api/grading/groups?";
        if (managerId) url += `&managerId=${managerId}`;
        if (limit) url += `&limit=${limit}`;
        if (offset) url += `&offset=${offset}`;
        if (members) url += `&members=${members}`;
        return {
          url,
          method: "GET",
        };
      },
      transformResponse: (response: { data: { groups: Group[] } }) =>
        response.data,
    }),
    joinGroup: builder.mutation({
      query: (payload) => {
        const { groupId, memberId, name } = payload;
        return {
          url: `api/grading/groups/${groupId}/members`,
          method: "POST",
          body: { memberId, name },
        };
      },
      transformResponse: (response: {
        data: { success: boolean; message: string };
      }) => response.data,
    }),
    approveMembership: builder.mutation({
      query: (payload) => {
        const { groupId, memberId, approved } = payload;
        return {
          url: `api/grading/groups/${groupId}/members/${memberId}`,
          method: "PATCH",
          body: { approved },
        };
      },
      transformResponse: (response: {
        data: { success: boolean; message: string };
      }) => response.data,
    }),
  }),
});

export const {
  useGetGroupQuery,
  useLazyGetGroupQuery,
  useJoinGroupMutation,
  useApproveMembershipMutation,
} = groupsApiSlice;
