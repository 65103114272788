import { Button, Grid, Typography } from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import AthleteIcon from "src/grading/components/AthleteIcon/AthleteIcon";
import RFDataGrid from "src/grading/components/RFDataGrid/RFDataGrid";
import { addBreadcrumb } from "src/store/reducers/navigation/navigationSlice";
import { Member } from "src/store/reducers/organizations/organizationsApiSlice";
import theme from "src/theme";
import useStyles from "./AthletesTable.styles";

export type AthleteRow = { row: Member };

interface AthleteTableProps {
  members: Member[];
  isLoading: boolean;
  sortModel: GridSortModel;
  setSortModel: (sortModel: GridSortModel) => void;
}

const AthleteColumn = ({ row }: AthleteRow) => {
  const classes = useStyles();
  return (
    <div className={classes.profileIconContainer}>
      <AthleteIcon profile={row} />
      <Grid container flex={1} className={classes.profileText}>
        <Typography variant="subtitle1">ATHLETE</Typography>
        <Typography variant="h4" fontWeight={"bold"} whiteSpace={"initial"}>
          {row.firstName && row.lastName
            ? row.firstName + " " + row.lastName
            : row.email}
        </Typography>
      </Grid>
    </div>
  );
};

const AthletesTableButton = ({ row }: AthleteRow) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = async () => {
    dispatch(
      addBreadcrumb({
        label: "Athletes",
        path: "/grading/athletes",
      })
    );
    history.push(`/grading/athletes/${row.memberId}`);
  };

  return (
    <Grid container alignItems={"center"}>
      <Grid container gap={1}>
        <Button
          variant="contained"
          size="small"
          onClick={handleClick}
          fullWidth
          className={classes.tableActionButton}
        >
          See Profile
        </Button>
      </Grid>
    </Grid>
  );
};

const SelectAthleteTableColumns = (): GridColDef[] => [
  {
    field: "firstName",
    headerName: "Athlete",
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    minWidth: 250,
    width: 250,
    renderCell: ({ row }: AthleteRow) => <AthleteColumn row={row} />,
  },
  {
    field: "recruitmentProcess",
    headerName: "Status",
    headerAlign: "center",
    flex: 1,
    editable: false,
    sortable: true,
    renderCell: ({ row }: AthleteRow) => (
      <Grid
        width={"100%"}
        borderLeft={`1px solid ${theme.palette.secondary.light}`}
        borderRight={`1px solid ${theme.palette.secondary.light}`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="subtitle1">STATUS</Typography>
        <Typography
          variant="caption"
          fontWeight={"bold"}
          style={{
            padding: `0 ${theme.spacing(2)}`,
            backgroundColor:
              row.recruitmentProcess.status === "qualified"
                ? theme.palette.primary.main
                : row.recruitmentProcess.status === "not qualified"
                ? "#EB5757"
                : "#F2C94C",
            borderRadius: theme.spacing(2),
            color:
              row.recruitmentProcess.status === "pending" ? "black" : "white",
          }}
        >
          {row.recruitmentProcess.status}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    minWidth: 250,
    width: 250,
    renderCell: ({ row }: AthleteRow) => (
      <Grid
        width={"100%"}
        borderRight={`1px solid ${theme.palette.secondary.light}`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="subtitle1">EMAIL</Typography>
        <Typography variant="body1" fontWeight={"bold"}>
          {row.email}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "createdAt",
    headerName: "Profile Created",
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    minWidth: 250,
    width: 250,
    renderCell: ({ row }: AthleteRow) => (
      <Grid
        width={"100%"}
        borderRight={`1px solid ${theme.palette.secondary.light}`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="subtitle1">CREATED AT</Typography>
        <Typography variant="h4" fontWeight={"bold"}>
          {row.createdAt
            ? new Date(Date.parse(row.createdAt)).toLocaleDateString()
            : "-"}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "units",
    headerName: "Unit",
    headerAlign: "center",
    flex: 1,
    editable: false,
    sortable: true,
    renderCell: ({ row }: AthleteRow) => (
      <Grid
        width={"100%"}
        borderRight={`1px solid ${theme.palette.secondary.light}`}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography variant="subtitle1">UNIT</Typography>
        <Typography variant="h4" fontWeight={"bold"}>
          {row.units.map((u) => u.name).join("") || "-"}
        </Typography>
      </Grid>
    ),
  },
  {
    field: "",
    headerName: "",
    headerAlign: "center",
    flex: 1,
    maxWidth: 150,
    width: 150,
    editable: false,
    renderCell: ({ row }: AthleteRow) => <AthletesTableButton row={row} />,
  },
];

const AthleteTable = ({
  members,
  isLoading,
  sortModel,
  setSortModel,
}: AthleteTableProps) => {
  return (
    <RFDataGrid
      rows={members}
      columns={SelectAthleteTableColumns()}
      loading={isLoading}
      disableSelectionOnClick
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => {
        setSortModel(newSortModel);
      }}
      getRowId={(row) => row.memberId}
    />
  );
};

export default AthleteTable;
