import { FormikErrors, FormikTouched } from "formik";

export const InputError = (
  inputName: string,
  touched: FormikTouched<any>,
  errors: FormikErrors<any>,
): {
  error: boolean;
  helperText: string;
  // display: ReactNode | null;
} => {
  const error =
    touched && !!touched[inputName] && errors && !!errors[inputName];
  const helperText =
    touched && !!touched[inputName] && errors && errors[inputName]
      ? (errors[inputName] as string)
      : "";

  return {
    error: error,
    helperText: helperText,
    // display: error ? <FormHelperText style={{ marginLeft: "14px" }} error>{helperText}</FormHelperText> : null
  };
};
