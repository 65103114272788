import { Divider, Grid, TextField, Typography } from "@mui/material";
import { Profile } from "@readyfit-common/models";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "src/store/hooks";
import { selectGradingStep } from "src/store/reducers/grading/gradingSlice";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import theme from "src/theme";
import LineItem from "../../LineItem/LineItem";
import useStyles from "./ResultsStepper.styles";

interface ResultsStepperProps {
  graderProfile?: Profile;
  testScore: TestScore;
}

const ResultsStepper = ({
  testScore,
  graderProfile, // testScore,
}: ResultsStepperProps) => {
  const step = useAppSelector(selectGradingStep);
  const classes = useStyles();
  const isResultStepper = step > testScore.exercises.length;
  const location = useLocation();
  const isCertifiedPage = location.pathname.includes("certified");

  return (
    <Grid>
      <Grid p={4}>
        {!isResultStepper && !isCertifiedPage && (
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Typography variant="caption">
              Make sure you have reviewed all the exercises before certifying
              the test.
            </Typography>
          </Grid>
        )}

        <Grid mt={1.5} container item flexDirection={"column"}>
          <LineItem label={"Type of Test"} value={testScore.testTitle} />
          <Divider className={classes.divider} />

          <LineItem
            label={"Overall Ranking"}
            value={testScore?.grades.join("-")}
            sx={{ marginTop: theme.spacing(1) }}
          />
          <Divider className={classes.divider} />
        </Grid>

        <Grid container item mt={3} flexDirection={"column"}>
          <Typography variant="subtitle1">Total Score</Typography>

          <Typography variant="h1">
            {testScore?.score}
            <span style={{ color: "#8F8F8F" }}>/{testScore?.maxScore}</span>
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        <Grid container item mt={3} flexDirection={"column"}>
          <Typography
            color={"#666"}
            variant="button"
            maxWidth={"80%"}
            flexWrap={"wrap"}
            fontWeight={"400"}
            textTransform={"none"}
          >
            Grader
          </Typography>

          <Typography variant="caption">{`${graderProfile?.firstName} ${graderProfile?.lastName}`}</Typography>
        </Grid>

        {/* NOTES */}
        <Typography variant="subtitle1" textTransform={"uppercase"} mt={4}>
          Overall notes
        </Typography>

        <Grid container>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder={
              isResultStepper || isCertifiedPage ? "N/A" : "Enter notes here..."
            }
            disabled={isResultStepper || isCertifiedPage}
            value={testScore?.overallNotes}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ResultsStepper;
