import { Profile, StatusResponse } from "@readyfit-common/models";
import { apiSlice } from "src/store/apiSlice";

export type PageContent = {
  testTitle: string;
  testBody: string;
  organizationName: string;
  organizationImageUrl: string;
  organizationBody: string;
};

export interface PageContentResponse extends StatusResponse {
  data: PageContent;
}

export interface JoinOrganizationRequest {
  organizationId: OrganizationId;
  firstName: string;
  lastName: string;
  email: string;
  gender: string;
  birthday: string;
  joinRequestReason: string;
}

type OrganizationId = string;

export interface UpdateRecruitmentProcess {
  userId: string;
  status?: "qualified" | "not qualified" | "pending";
  preQualify?: {
    [key: string]: boolean;
  };
  fitnessTest?: {
    id?: string;
    branch?: string;
    name?: string;
    assigned?: string;
    date?: string;
    score?: number;
    status?: "pass" | "fail" | "pending";
    grades?: string[];
  };
  training?: {
    id?: string;
    name?: string;
    coachId?: string;
    coachName?: string;
    assigned?: boolean;
    completed?: boolean;
  };
  qualifiedForService?: {
    [key: string]: boolean;
  };
}

export const usersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserById: builder.query({
      query: (userId) => ({
        url: `/api/account/users/${userId}`,
        method: "GET",
      }),
      transformResponse: (response: { data: { profile: Profile } }) =>
        response.data,
    }),
    getUsers: builder.query<
      { data: { users: Profile[] } },
      {
        role?:
          | "users"
          | "graders"
          | "athletes"
          | "organization_athletes"
          | "organization_graders"
          | "users,organization_athletes";
        search?: string;
      }
    >({
      query: ({ role, search }) => {
        let url = "api/account/users?";
        if (role) url += `&role=${role}`;
        if (search) url += `&search=${search}`;
        return {
          url,
          method: "GET",
        };
      },
      providesTags: ["Users"],
    }),
    getOrganizationContent: builder.query<PageContentResponse, OrganizationId>({
      query: (organizationId) => {
        const url = `/api/account/organizations/${organizationId}/join-page-content`;
        return {
          url,
          method: "GET",
        };
      },
    }),
    joinOrganization: builder.mutation<StatusResponse, JoinOrganizationRequest>(
      {
        query: (payload) => {
          const { organizationId, ...rest } = payload;
          return {
            url: `/api/account/organizations/${organizationId}/join`,
            method: "POST",
            body: {
              ...rest,
            },
          };
        },
      },
    ),
    updateRecruitmentProcess: builder.mutation<
      StatusResponse,
      UpdateRecruitmentProcess
    >({
      query: (payload) => {
        const { userId, ...rest } = payload;
        return {
          url: `/api/account/users/${userId}/recruitment-process`,
          method: "PATCH",
          body: { ...rest },
        };
      },
    }),
  }),
});

export const {
  useGetUserByIdQuery,
  useLazyGetUserByIdQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetOrganizationContentQuery,
  useLazyGetOrganizationContentQuery,
  useJoinOrganizationMutation,
  useUpdateRecruitmentProcessMutation,
} = usersApiSlice;
