import { Divider, Grid, Paper, Typography } from "@mui/material";
import {
  BodyCompositionResultMetadata,
  ExerciseResultMetadata,
  Profile,
  isBodyComp,
  isReps,
} from "@readyfit-common/models";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import { TestDefinitionFormat } from "src/store/reducers/tests/testsApiSlice";
import theme from "src/theme";
import useStyles from "./GradeTestOverview.styles";
import { GetRestTime, getAge } from "./utils";
import { intFromString } from "./utils/stringHelpers";

export function GradeTestOverview({
  test,
  userProfile,
  testMetaData,
}: GradeTestOverviewProps) {
  const classes = useStyles();

  // Initialize body comp with current values, replace with the snapshot values from the test if available
  let bodyComp: BodyCompositionResultMetadata = {
    gender: userProfile.gender,
    height: intFromString(userProfile.height),
    weight: intFromString(userProfile.weight),
    neckCircumference: intFromString(userProfile.neckCircumference),
    abdomenCircumference: intFromString(userProfile.abdomenCircumference),
    hipCircumference: intFromString(userProfile.hipCircumference),
    waistCircumference: intFromString(userProfile.waistCircumference),
    name: `${userProfile.firstName} ${userProfile.lastName}`,
    startTime: Date.now(),
    endTime: Date.now(),
    exerciseId: "bodyComp",
  };

  const testInfo =
    testMetaData.infos.find((info) => info.branch === test.branch) ??
    testMetaData.infos[0];

  for (let i = 0; i < test.exercises.length; i++) {
    const exercise = test.exercises[i];
    if (isBodyComp(exercise.metadata as ExerciseResultMetadata)) {
      bodyComp = exercise.metadata as BodyCompositionResultMetadata;
      break;
    }
  }

  const overviewRows = [];
  for (let i = 0; i < test.exercises.length; i++) {
    const exercise = test.exercises[i];
    if (
      i >= 1 &&
      !isBodyComp(test.exercises[i - 1].metadata as ExerciseResultMetadata)
    ) {
      overviewRows.push({
        name: "Rest",
        time: GetRestTime(
          test.exercises[i - 1].metadata?.endTime as number,
          exercise.metadata?.startTime as number,
        ),
        reps: "",
        score: "",
      });
    }

    overviewRows.push({
      name: exercise.metadata?.name as string,
      time: GetRestTime(
        exercise.metadata?.startTime as number,
        exercise.metadata?.endTime as number,
      ),
      reps: isReps(exercise.metadata as ExerciseResultMetadata)
        ? exercise.metadata?.reps
        : "",
      score:
        (test.exercises.length ?? -1 > i) &&
        `${test.exercises[i].score}/${test.exercises[i].maxScore}`,
    });
  }

  const displayRequirements = () => {
    return (
      <>
        <Grid
          mt={3.5}
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          {testInfo.equipment?.split(",").map((equipment, i) => (
            <Grid key={i} flexDirection={"column"} width={"100%"}>
              {i > 0 && <Divider sx={{ marginTop: 1, marginBottom: 1 }} />}
              <Typography variant="caption" color={"#000"}>
                {equipment}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </>
    );
  };

  return (
    <Grid container lg={12}>
      <Grid item xs={12} mt={1}>
        <Typography variant="h2">Test Overview</Typography>
      </Grid>
      {/* Overview Container */}
      <Grid container item flex={1} ml={theme.spacing(10)}>
        {/* First Box Container */}
        <Grid
          mt={5}
          container
          // border={"1px solid #000"}
          xs={12}
          padding={5}
          bgcolor={theme.palette.primary.light}
          borderRadius={2}
          component={Paper}
        >
          <Grid item xs={6}>
            <div className={classes.profileIconContainer}>
              <div className={classes.profileIcon} />
              <div className={classes.profileText}>
                <Typography variant="subtitle1">Name</Typography>
                <Typography variant="h4" fontWeight={"bold"}>
                  {userProfile?.lastName}, {userProfile?.firstName}
                </Typography>
              </div>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.profileIconContainer}>
              <Grid className={classes.profileText} padding={2}>
                <Typography variant="subtitle1">Unit</Typography>
                <Typography variant="h4" fontWeight={"bold"}>
                  {userProfile?.branchOfService}
                </Typography>
              </Grid>
            </div>
          </Grid>
        </Grid>

        {/* Second row with two boxes */}
        <Grid container item xs={12}>
          <Grid
            mt={2}
            container
            // border={"1px solid #000"}
            xs={12}
            gap={1}
            // padding={5}
            // bgcolor={theme.palette.primary.light}
            borderRadius={2}
          >
            <Grid xs={12} sm={5.8} lg={5.9} component={Paper} padding={5}>
              <Typography
                variant="subtitle1"
                textTransform="uppercase"
                color="#000"
              >
                Personal Data
              </Typography>

              <Grid
                mt={3.5}
                container
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Typography variant="caption" color={"#666"}>
                  First Name
                </Typography>
                <Typography variant="caption" color={"#000"}>
                  {userProfile.firstName}
                </Typography>
              </Grid>
              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

              <Grid
                container
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Typography variant="caption" color={"#666"}>
                  Last Name
                </Typography>
                <Typography variant="caption" color={"#000"}>
                  {userProfile.lastName}
                </Typography>
              </Grid>
              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

              <Grid
                container
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Typography variant="caption" color={"#080505"}>
                  Gender
                </Typography>
                <Typography variant="caption" color={"#000"}>
                  {userProfile.gender}
                </Typography>
              </Grid>
              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />

              <Grid
                container
                flexDirection={"row"}
                justifyContent={"space-between"}
              >
                <Typography variant="caption" color={"#666"}>
                  Age
                </Typography>
                <Typography variant="caption" color={"#000"}>
                  <Typography variant="caption" color={"#000"}>
                    {getAge(new Date(userProfile.birthday || ""))}
                  </Typography>
                </Typography>
              </Grid>
              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            </Grid>

            <Grid
              xs={12}
              sm={5.8}
              lg={5.9}
              flex={1}
              component={Paper}
              padding={5}
            >
              <Typography
                variant="subtitle1"
                textTransform="uppercase"
                color="#000"
              >
                Test Equipment Required
              </Typography>
              {displayRequirements()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

type GradeTestOverviewProps = {
  test: TestScore;
  userProfile: Profile;
  testMetaData: TestDefinitionFormat;
};
