import { CheckCircleOutline } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import {
  useApproveMembershipMutation,
  useLazyGetGroupQuery,
} from "src/store/reducers/groups/groupsApiSlice";
import * as Icons from "../assets";

export function GroupPage(props: never) {
  const [data, setData] = useState<any | null | undefined>(undefined);
  const [loadState, setLoadState] = useState("NEW");

  const user = useSelector(selectCurrentUser) as any;
  const [getGroup] = useLazyGetGroupQuery();
  const [approveMembership] = useApproveMembershipMutation();

  if (loadState === "NEW") {
    setLoadState("LOADING");
    getGroup({ managerId: user.id, limit: 1, offset: 0, members: "true" })
      .unwrap()
      .then((res) => {
        if (res.groups.length > 0) setData(res.groups[0]);
        setLoadState("DONE");
      });
  }

  if (loadState === "NEW" || loadState === "LOADING") {
    return <CircularProgress className="center-spinner" />;
  } else if (data !== undefined && data !== null) {
    const iconSize = 25;
    return (
      <div style={{ height: "100%" }}>
        <h3>{data.name}</h3>
        <div>
          <a
            href={`https://${window.location.hostname}/joinGroup?gid=${data.id}`}
          >
            Join link
          </a>
        </div>
        <DataGrid
          rows={data.members}
          hideFooterSelectedRowCount={true}
          pageSize={15}
          getRowId={(row) => row.memberId}
          columns={[
            {
              field: "name",
              headerName: "Member",
              width: 200,
              editable: false,
              resizable: false,
              sortable: false,
              filterable: false,
            },
            {
              field: "applyDate",
              headerName: "Date Applied",
              width: 200,
              editable: false,
              sortable: true,
              renderCell: (params) => {
                if (params.row.applyDate) {
                  const applyDate = new Date(params.row.applyDate);
                  return `${
                    applyDate.getMonth() + 1
                  }/${applyDate.getDate()}/${applyDate.getFullYear()} ${applyDate
                    .getHours()
                    .toString()
                    .padStart(2, "0")}:${applyDate
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")}`;
                }
                return "-";
              },
            },
            {
              field: "joinDate",
              headerName: "Date Joined",
              width: 200,
              editable: false,
              sortable: true,
              renderCell: (params) => {
                if (params.row.joinDate) {
                  const joinDate = new Date(params.row.joinDate);
                  return `${
                    joinDate.getMonth() + 1
                  }/${joinDate.getDate()}/${joinDate.getFullYear()} ${joinDate
                    .getHours()
                    .toString()
                    .padStart(2, "0")}:${joinDate
                    .getMinutes()
                    .toString()
                    .padStart(2, "0")}`;
                }
                return "-";
              },
            },
            {
              field: "approved",
              headerName: "Approval State",
              width: 150,
              editable: false,
              sortable: false,
              filterable: false,
              renderCell: (params) => {
                if (params.row.managerApproved) {
                  return (
                    <CheckCircleOutline
                      height={iconSize}
                      width={iconSize}
                      color="success"
                    />
                  );
                } else {
                  return (
                    <Icons.Clock
                      height={iconSize}
                      width={iconSize}
                      fill="#649FDB"
                    />
                  );
                }
              },
            },
            {
              field: "",
              headerName: "",
              width: 150,
              editable: false,
              resizable: false,
              sortable: false,
              filterable: false,
              renderCell: (params) =>
                params.row.managerApproved ? (
                  <div />
                ) : (
                  <strong>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      style={{ marginLeft: 16 }}
                      onClick={async () => {
                        await approveMembership({
                          groupId: data.id,
                          memberId: params.row.memberId,
                          approved: true,
                        });
                        setLoadState("NEW");
                      }}
                    >
                      Approve
                    </Button>
                  </strong>
                ),
            },
          ]}
        />
      </div>
    );
  } else {
    return <div />;
  }
}
