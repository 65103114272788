import { Divider, Grid, TextField, Typography } from "@mui/material";
import GpxParser, { Point } from "gpxparser";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { ApprovalButton } from "src/grading/approvalButton";
import { ExerciseChecklist } from "src/grading/exerciseChecklist";
import { CardioApprovalGradeResult, GradeResult } from "src/grading/models";
import {
  CalculateSplits,
  CalculateTerminalPos,
  DisplayTimeFromSeconds,
  GetRestTime,
  Split,
  convertDistance,
  getRuntime,
  kmToMile,
  stringFromTime,
} from "src/grading/utils";
import { useAppSelector } from "src/store/hooks";
import { useLazyGetFileUrlQuery } from "src/store/reducers/documents/documentsApiSlice";
import {
  selectGradingStep,
  selectGradingTest,
} from "src/store/reducers/grading/gradingSlice";
import { TestStepDefinitionFormat } from "src/store/reducers/tests/testsApiSlice";
import LineItem from "../../LineItem/LineItem";
import useStyles from "./CardioStepper.styles";

interface CardioStepperProps {
  onGrade: (stepGrade: GradeResult) => void;

  currentGrade: CardioApprovalGradeResult;
  stepMetadata: TestStepDefinitionFormat | undefined;
  prevEnd: number | null; // The end time of the previous step, for rest time displays
}

const CardioStepper = ({
  onGrade,
  currentGrade,
  stepMetadata,
  prevEnd,
}: CardioStepperProps) => {
  const classes = useStyles();
  const test = useAppSelector(selectGradingTest);
  const step = useAppSelector(selectGradingStep);
  const exercise = test?.exercises[step];
  const [discrepancies, setDiscrepancies] = useState<string[]>([
    ...(currentGrade.discrepancies ?? []),
  ]);

  const [gpxData, setGpxData] = useState<GpxParser | "pending" | "not_started">(
    "not_started",
  );

  const [getFileUrl] = useLazyGetFileUrlQuery();

  if (gpxData === "not_started" && test) {
    setGpxData("pending");
    getFileUrl(test.exercises[step].fileKey)
      .unwrap()
      .then((response: any) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = (event) => {
          const parser = new GpxParser();
          parser.parse(xhr.response);
          setGpxData(parser);
        };
        xhr.open("GET", response.url);
        xhr.send();
      });
  }

  if (gpxData === "pending" || gpxData === "not_started") {
    return (
      <Spinner
        animation="border"
        style={{ left: "50%", position: "relative" }}
      />
    );
  }

  const fieldStep =
    stepMetadata?.testing?.userEnteredResult?.step !== undefined
      ? parseFloat(stepMetadata?.testing?.userEnteredResult?.step.toString())
      : 1;
  const fieldUnits = stepMetadata?.testing?.userEnteredResult?.unit;
  const totalDist =
    Math.round(parseFloat(currentGrade?.userResult?.distance) * 100) / 100;
  let positions: [number, number][] = [];
  let gradedPositions = null;
  let computedEndPoint: Point = { time: new Date(), lat: 0, lon: 0, ele: 0 };
  let startPoint: Point = { time: new Date(), lat: 0, lon: 0, ele: 0 };
  let activityEndPoint: Point = { time: new Date(), lat: 0, lon: 0, ele: 0 };
  let computedDistance = 0;
  let netElevationGain = 0;
  let pacePerMile = 0;
  let goalDist = 0;
  let splits: Split[] = [];
  let graphSplits: Split[] = [];
  let computedMinutes = 0;

  if (gpxData.tracks[0]) {
    positions = gpxData.tracks[0].points.map((p) => [p.lat, p.lon]);
    goalDist = convertDistance(
      stepMetadata?.testing?.distance?.value ?? 1,
      stepMetadata?.testing?.distance?.units ?? "miles",
      "meters",
    );
    const [termPos, termDistKm] = CalculateTerminalPos(positions, goalDist);

    if (termPos !== positions.length) {
      gradedPositions = positions.slice(0, termPos);
    }

    let splitDist = 1;
    if (goalDist <= 3218.69) {
      splitDist = 0.401336;
    } else if (goalDist <= 4828.032) {
      splitDist = 0.804772;
    } else {
      splitDist = 1608.34;
    }
    splits = CalculateSplits(
      gpxData.tracks[0].points.slice(0, termPos),
      splitDist,
    );
    graphSplits = CalculateSplits(
      gpxData.tracks[0].points.slice(0, termPos),
      0.1,
    );

    computedDistance =
      Math.round(
        (currentGrade?.userResult?.units === "miles"
          ? kmToMile(termDistKm)
          : termDistKm / 1000) * 100,
      ) / 100;
    computedEndPoint =
      gradedPositions !== null
        ? gpxData.tracks[0].points[termPos]
        : gpxData.tracks[0].points[positions.length - 1];
    if (!computedEndPoint)
      computedEndPoint = { time: new Date(), lat: 0, lon: 0, ele: 0 };
    activityEndPoint =
      gpxData.tracks[0].points[gpxData.tracks[0].points.length - 1];
    if (!activityEndPoint)
      activityEndPoint = { time: new Date(), lat: 0, lon: 0, ele: 0 };
    startPoint = gpxData.tracks[0].points[0];
    if (!startPoint) startPoint = { time: new Date(), lat: 0, lon: 0, ele: 0 };
    computedMinutes =
      (Math.abs(computedEndPoint.time.valueOf() - startPoint.time.valueOf()) *
        0.001) /
      60;
    pacePerMile = Math.round((computedMinutes / computedDistance) * 100) / 100;
    netElevationGain =
      gpxData.tracks[0].elevation.pos - gpxData.tracks[0].elevation.neg;
  }

  return (
    test && (
      <Grid container height={"80vh"} overflow={"scroll"}>
        <Grid item p={4} width={"100%"}>
          {stepMetadata?.testing?.restTime !== undefined && (
            <>
              <Typography variant="subtitle1" textTransform={"uppercase"}>
                Rest Between Exercises
              </Typography>
              <Grid container justifyContent={"space-between"} mt={2.5}>
                <Typography color={"#666"} variant="caption">
                  Rest Allowed
                </Typography>

                <Typography variant="caption">
                  {" "}
                  {DisplayTimeFromSeconds(
                    stepMetadata?.testing?.maxDurationSeconds || 0,
                  )}
                </Typography>
              </Grid>
              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
              <Grid container justifyContent={"space-between"}>
                <Typography color={"#666"} variant="caption">
                  Rest Taken
                </Typography>

                <Typography variant="caption">
                  {" "}
                  {GetRestTime(prevEnd, exercise?.metadata?.startTime || 0)}
                </Typography>
              </Grid>

              <ApprovalButton
                onClick={(status) => {
                  const userResult = {
                    ...(currentGrade as CardioApprovalGradeResult).userResult,
                    fileKey: test.exercises[step].fileKey,
                  };
                  userResult.distance = computedDistance.toFixed(1).toString();
                  userResult.endTime = new Date(
                    +userResult.startTime + computedMinutes * 60000,
                  ).valueOf();
                  const updatedCurrentGrade = {
                    ...currentGrade,
                    approved: status,
                    userResult,
                    discrepancies,
                    exceededRestTime: !status,
                  };
                  onGrade(updatedCurrentGrade);
                }}
                default={undefined}
              />
            </>
          )}

          <Grid
            container
            item
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Typography variant="subtitle1" textTransform={"uppercase"} mt={4}>
              Calculated Run Time
            </Typography>

            <Typography variant="h1">
              {getRuntime(
                new Date(currentGrade?.userResult?.endTime).getTime(),
                new Date(currentGrade?.userResult?.startTime).getTime(),
              )}
            </Typography>
          </Grid>

          <Grid
            container
            item
            flexDirection={"column"}
            justifyContent={"center"}
          >
            <Typography variant="subtitle1" textTransform={"uppercase"} mt={4}>
              Distance
            </Typography>

            <Typography variant="h1">
              {currentGrade?.userResult?.distance}
            </Typography>
          </Grid>

          <Grid container mt={4} flexDirection={"column"}>
            <LineItem
              label="Total Run Time"
              value={getRuntime(
                new Date(currentGrade?.userResult?.endTime).getTime(),
                new Date(currentGrade?.userResult?.startTime).getTime(),
              )}
            />
            <Divider className={classes.divider} />

            <LineItem
              label={`Total Distance Completed ${currentGrade?.userResult?.units}`}
              value={totalDist}
            />
            <Divider className={classes.divider} />

            <LineItem
              label={`Total Elevation Change ${currentGrade?.userResult?.units}`}
              value={netElevationGain.toFixed(1)}
            />
            <Divider className={classes.divider} />

            <LineItem
              label={`Pace per ${currentGrade?.userResult?.units}`}
              value={
                ("00" + Math.floor(pacePerMile)).slice(-2) +
                ":" +
                ("00" + Math.floor((pacePerMile % 1) * 60)).slice(-2)
              }
            />
            <Divider className={classes.divider} />

            <LineItem
              label={`Rest time taken ${currentGrade?.userResult?.units}`}
              value={GetRestTime(prevEnd, exercise?.metadata?.startTime || 0)}
            />

            <Grid container xs={12} mt={1}>
              <LineItem label={`Splits`} />

              {splits.map((split, i) => {
                if (split.splitDistanceKm > 0.1) {
                  return (
                    <Grid
                      container
                      mt={1}
                      key={i}
                      className="grading-step-item"
                    >
                      <Typography variant={"caption"} fontSize={14}>
                        {Math.round(kmToMile(split.totalDistanceKm) * 100) /
                          100}{" "}
                        mile
                      </Typography>
                      <Typography variant={"caption"} fontSize={14}>
                        {stringFromTime({
                          minutes: 0,
                          seconds: split.splitTimeSeconds,
                        })}
                      </Typography>
                    </Grid>
                  );
                } else {
                  return <></>;
                }
              })}
            </Grid>
          </Grid>

          {/* DISQUALIFIERS */}
          <Typography
            variant="subtitle1"
            textTransform={"uppercase"}
            mt={4}
            mb={1}
          >
            Disqualifiers
          </Typography>
          {stepMetadata !== undefined && (
            <ExerciseChecklist
              stepMetadata={stepMetadata}
              exerciseName={stepMetadata.displayName!}
              key={stepMetadata.exerciseId}
              onChange={(selections) => {
                const updatedCurrentGrade = {
                  ...currentGrade,
                  userResult: {
                    ...currentGrade.userResult,
                    fileKey: test?.exercises[step]?.fileKey as string,
                  },
                };

                const currGrades = {
                  ...updatedCurrentGrade,
                  discrepancies: selections,
                };
                setDiscrepancies(selections);

                if (currGrades) {
                  onGrade(currGrades);
                }
              }}
              selections={currentGrade.discrepancies}
            />
          )}

          {/* NOTES */}
          <Typography variant="subtitle1" textTransform={"uppercase"}>
            Notes
          </Typography>

          <Grid container>
            <TextField
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              placeholder="Enter notes here..."
              onChange={(e) => {
                const updatedCurrentGrade = {
                  ...currentGrade,
                  userResult: {
                    ...currentGrade.userResult,
                    fileKey: test?.exercises[step]?.fileKey as string,
                    graderNotes: e.target.value,
                  },
                };
                onGrade(updatedCurrentGrade);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    )
  );
};

export default CardioStepper;
