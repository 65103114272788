import { Divider, Grid, Paper, Typography } from "@mui/material";
import { Profile } from "@readyfit-common/models";
import AthleteIcon from "src/grading/components/AthleteIcon/AthleteIcon";
import GradingHeader from "src/grading/components/GradingHeader/GradingHeader";
import LiveTestStepper from "src/grading/components/LiveTestStepper/LiveTestStepper";
import { useAppSelector } from "src/store/hooks";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import {
  selectCurrentLiveTestInfo,
  selectLiveTestGradeResults,
  selectLiveTestScores,
} from "src/store/reducers/liveTest/liveTestSlice";
import theme from "src/theme";
import useStyles from "./LiveTestCertify.styles";

export const RowItem = ({
  label,
  value,
  spacing = 2,
  maxValue,
  scoreValue,
  flex,
  alignItems,
  ...props
}: {
  label?: string;
  value: string | number;
  spacing?: number;
  maxValue?: string | number;
  scoreValue?: boolean;
  flex?: number;
  alignItems?: string;
}) => {
  return (
    <Grid
      item
      container
      xs={!flex ? spacing : undefined}
      flex={flex}
      alignItems={alignItems || "center"}
      flexDirection={"column"}
      {...props}
    >
      {label && (
        <Typography
          variant="subtitle1"
          color={"#666666"}
          textTransform={"uppercase"}
        >
          {label}
        </Typography>
      )}

      {scoreValue ? (
        <Typography variant="h5" fontWeight={"bold"}>
          {value}/<span style={{ color: "#666666" }}>{maxValue}</span>
        </Typography>
      ) : (
        <Typography variant="h5" fontWeight={"bold"}>
          {value}
        </Typography>
      )}
    </Grid>
  );
};

const LiveTestCertify = ({
  setCanNavigateAway,
}: {
  setCanNavigateAway: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const classes = useStyles();
  const liveTestInfo = useAppSelector(selectCurrentLiveTestInfo);
  const liveTestGradeResults = useAppSelector(selectLiveTestGradeResults);
  const date = new Date(Date.now());
  const liveTestScores = useAppSelector(selectLiveTestScores);

  // const selectedAthletes = useAppSelector(selectSelectedAthletes);
  const user = useAppSelector(selectCurrentUser) as Profile;
  return (
    <Grid container>
      <Grid
        container
        className={classes.headerContainer}
        component={Paper}
        elevation={1}
      >
        <Grid item container xs={12} className={classes.titleContainer}>
          <GradingHeader
            withBreadcrumbs
            containerStyles={{
              marginTop: 0,
              marginBottom: theme.spacing(2),
            }}
          />
          <Typography variant="h3">Certify Test</Typography>

          <Grid item marginLeft={"auto"} marginRight={1}>
            <LiveTestStepper
              mini={true}
              setCanNavigateAway={setCanNavigateAway}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={classes.athletesContainer}>
        {liveTestGradeResults.map((gradeResult, gradeResultIndex) => {
          const athleteScores = liveTestScores?.scores.find(
            (score) => score.profile.id === gradeResult.profile.id,
          );

          return (
            <Grid
              container
              xs={12}
              key={gradeResultIndex}
              className={classes.rowContainer}
            >
              <Grid
                container
                item
                xs={1}
                className={classes.dividerContainer}
                bgcolor={
                  athleteScores?.score?.state.toLowerCase() === "pass"
                    ? "#00A86B"
                    : "#FF0000"
                }
              >
                <Divider orientation="vertical" />
              </Grid>
              <Grid item container xs={11}>
                <Grid item container xs={12}>
                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.firstRowContainer}
                    gap={3}
                  >
                    <AthleteIcon profile={gradeResult?.profile} />
                    <RowItem
                      alignItems="flex-start"
                      label="Athlete"
                      value={`${gradeResult?.profile?.firstName} ${gradeResult?.profile?.lastName}`}
                      flex={1}
                    />

                    <RowItem
                      alignItems="flex-start"
                      label="Unit"
                      value={gradeResult?.profile?.branchOfService || "-"}
                      flex={1}
                    />

                    <RowItem
                      alignItems="flex-start"
                      label="Type of Test"
                      value={liveTestInfo?.displayName || "-"}
                      flex={1}
                    />

                    <RowItem
                      alignItems="flex-start"
                      label={"Score"}
                      scoreValue
                      value={athleteScores?.score?.score || 0}
                      maxValue={athleteScores?.score?.maxScore || 0}
                      flex={1}
                    />

                    <Grid item container flex={1}>
                      <Typography
                        variant="subtitle1"
                        fontWeight={"bold"}
                        color={
                          gradeResult?.athleteSignatureDetails?.agreed
                            ? "#fff"
                            : "#000"
                        }
                        textTransform={"uppercase"}
                        padding={`${theme.spacing(0.25)} ${theme.spacing(2)}`}
                        borderRadius={theme.spacing(7)}
                        maxWidth={theme.spacing(13)}
                        bgcolor={
                          gradeResult?.athleteSignatureDetails?.agreed
                            ? "#00A86B"
                            : "yellow"
                        }
                        width={theme.spacing(13)}
                        textAlign={"center"}
                      >
                        {gradeResult?.athleteSignatureDetails?.agreed
                          ? "Agreed"
                          : "Disagreed"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    container
                    xs={12}
                    className={classes.secondRowContainer}
                    mt={3}
                    justifyContent={"space-evenly"}
                  >
                    <Grid container item xs={6} alignItems={"center"}>
                      <Typography
                        variant="subtitle1"
                        textTransform={"uppercase"}
                        fontWeight={"bold"}
                        marginRight={2}
                      >
                        Test Taken
                      </Typography>

                      <Typography fontWeight={"bold"} fontSize={"18px"}>
                        {date.toLocaleString("en-US", {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                        })}
                      </Typography>
                    </Grid>
                    <Grid container item xs={6} alignItems={"center"}>
                      <Typography
                        variant="subtitle1"
                        textTransform={"uppercase"}
                        fontWeight={"bold"}
                        marginRight={2}
                      >
                        Grader
                      </Typography>

                      <Typography fontWeight={"bold"} fontSize={"18px"}>
                        {`${user?.firstName} ${user?.lastName}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default LiveTestCertify;
