import { Grid } from "@mui/material";
import { useState } from "react";
import { useUpdateOrganizationRecruitmentProcessMutation } from "src/store/reducers/organizations/organizationsApiSlice";
import { useUpdateRecruitmentProcessMutation } from "src/store/reducers/users/usersApiSlice";
import { AddRecruitmentProcessRequirementModal } from "./AddRecruitmentProcessRequirementModal";
import { CardHeader } from "./CardHeader";
import { RecruitmentProcessCheckBox } from "./RecruitmentProcessCheckBox";

interface RecruitmentProcessCheckboxGroupProps {
  userId: string;
  organizationId: string;
  groupKey: string;
  groupTitle: string;
  data: {
    [key: string]: boolean;
  };
  dict: {
    [key: string]: string;
  };
  order: string[];
}

const calcProgress = (keys: string[], data: { [key: string]: boolean }) => {
  if (keys.length <= 0) return 0;

  const completed = keys.filter((k) => data[k]).length;

  return Math.floor((completed / keys.length) * 100);
};

export const RecruitmentProcessCheckboxGroup = ({
  userId,
  organizationId,
  groupKey,
  groupTitle,
  data,
  dict,
  order,
}: RecruitmentProcessCheckboxGroupProps) => {
  const [updateRecruitmentProcess] = useUpdateRecruitmentProcessMutation();
  const [updateOrganizationRP] =
    useUpdateOrganizationRecruitmentProcessMutation();
  const [groupState, setGroupState] = useState({
    progress: calcProgress(order, data),
    data,
    dict,
    order,
  });
  const [modalOpen, setModalOpen] = useState(false);

  const updateOrgRP = async (props: {
    dict: { [key: string]: string };
    order: string[];
  }) => {
    let payload: {
      preQualify?: { [key: string]: string };
      preQualifyOrder?: string[];
      qualifiedForService?: { [key: string]: string };
      qualifiedForServiceOrder?: string[];
    } = {};

    switch (groupKey) {
      case "preQualify":
        payload = {
          preQualify: props.dict,
          preQualifyOrder: props.order,
        };
        break;
      case "qualifiedForService":
        payload = {
          qualifiedForService: props.dict,
          qualifiedForServiceOrder: props.order,
        };
        break;
    }

    await updateOrganizationRP({ organizationId, ...payload });

    setGroupState((state) => {
      const data: { [key: string]: boolean } = {};
      const dict: { [key: string]: string } = {};
      let order: string[] = [];
      props.order.forEach((k) => {
        data[k] = !!state.data[k];
        dict[k] = props.dict[k];
        order = props.order;
      });
      const progress = calcProgress(order, data);
      return { progress, data, dict, order };
    });
  };

  const updateRP = async (key: string, value: boolean) => {
    setGroupState((state) => {
      const data = { ...state.data, [key]: value };
      const progress = calcProgress(order, data);
      return { ...state, progress, data };
    });
    await updateRecruitmentProcess({ userId, [groupKey]: { [key]: value } });
  };

  const handleAddRequirementClick = () => {
    setModalOpen(true);
  };

  return (
    <>
      <Grid
        container
        md={3}
        sx={{
          borderRight: 1,
          borderColor: "#DBDBDB",
          flexDirection: "column",
        }}
      >
        <CardHeader
          title={groupTitle}
          progress={groupState.progress}
          onAddClick={handleAddRequirementClick}
        />
        {groupState.order.map((k, i, a) => {
          const important = groupState.dict[k].includes("*");
          const label = groupState.dict[k].replace("*", "");
          const border = !(i + 1 === a.length);
          return (
            <RecruitmentProcessCheckBox
              id={k}
              key={k}
              label={label}
              border={border}
              important={important}
              checked={groupState.data[k]}
              update={updateRP}
            />
          );
        })}

        <AddRecruitmentProcessRequirementModal
          dict={dict}
          order={order}
          open={modalOpen}
          groupTitle={groupTitle}
          onClose={() => setModalOpen(false)}
          onUpdateOrgRP={updateOrgRP}
        />
      </Grid>
    </>
  );
};
