import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    marginTop: theme.spacing(2),
  },
  stepCount: {
    textTransform: "uppercase",
    backgroundColor: "#D6D6D6",
    color: "#333333",
    borderRadius: "5px",
    marginLeft: theme.spacing(2),
    display: "flex",
    padding: `0 ${theme.spacing(1)}`,
    alignItems: "center",
    justifyContent: "center",
  },
  bannerContainer: {
    marginTop: theme.spacing(5),
    backgroundColor: "#262F34",
    overflow: "hidden",
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: "1px solid #3F4E57",
  },
  athletesContainer: {
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(1),
    border: `1px solid #DBDBDB`,
  },
  athleteInfoContainer: {
    alignItems: "center",
  },
  athleteRow: {
    alignItems: "center",
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
  },
  athleteFirstRow: {
    padding: theme.spacing(2),
    backgroundColor: "#F2F2F2",
    borderRadius: theme.spacing(1),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  signButton: {
    textTransform: "uppercase",
    width: theme.spacing(21),
    textAlign: "center",
    borderRadius: theme.spacing(1),
  },
  athletesSecondRow: {
    padding: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
  },
  athleteExerciseRow: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  regradeButton: {
    backgroundColor: "#000",
    color: "#fff",
    maxWidth: theme.spacing(16),
    width: theme.spacing(16),
    borderRadius: theme.spacing(1),
  },
}));

export default useStyles;
