import { Button, Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useHistory } from "react-router-dom";
import useStyles from "./UnitsTableColumns.style";

export type UnitRow = { row: { id: string; name: string } };

const UnitActionButton = ({ row }: UnitRow) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container className={classes.unitButtonBorder} alignItems={"center"}>
      <Button
        variant="contained"
        size="small"
        className={classes.unitButton}
        onClick={() => history.push(`/grading/organization/units/${row.id}`)}
        fullWidth
      >
        Details
      </Button>
    </Grid>
  );
};

const UnitsTableColumns = (): GridColDef[] => [
  {
    field: "id",
    headerName: "Id",
    headerAlign: "center",
    flex: 1,
    editable: false,
    sortable: true,
    renderCell: (params) => (
      <div>
        <Typography variant="subtitle1">Unit id</Typography>
        <Typography variant="h4" fontWeight={"bold"}>
          {params.row.id}
        </Typography>
      </div>
    ),
  },
  {
    field: "name",
    headerName: "Name",
    headerAlign: "center",
    // minWidth: 300,
    flex: 1,
    editable: false,
    sortable: true,
    renderCell: (params) => (
      <div>
        <Typography variant="subtitle1">Unit name</Typography>
        <Typography variant="h4" fontWeight={"bold"}>
          {params.row.name}
        </Typography>
      </div>
    ),
  },
  {
    field: "action",
    headerName: "Action",
    headerAlign: "center",
    width: 175,
    maxWidth: 175,
    editable: false,
    sortable: false,
    filterable: false,
    renderCell: ({ row }: UnitRow) => <UnitActionButton row={row} />,
  },
];

export default UnitsTableColumns;
