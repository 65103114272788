import { CheckCircleOutline, DoDisturbRounded } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { useState } from "react";
import theme from "src/theme";
import useStyles from "./LiveTestApprovalButton.styles";

interface LiveTestApprovalButtonProps {
  default?: boolean;
  onClick: (status: boolean) => void;
}

const LiveTestApprovalButton = (props: LiveTestApprovalButtonProps) => {
  const classes = useStyles({
    color: props?.default ? "#27ae60" : "#c0392b",
    hoverColor: props?.default ? "#2ecc71" : "#e74c3c",
  })();
  const [status, setStatus] = useState<boolean | undefined>(props.default);
  const handleClick = (newStatus: boolean) => {
    if (newStatus !== status) {
      setStatus(newStatus);
      props.onClick(newStatus);
    }
  };
  return (
    <Grid container className={classes.buttonsContainer}>
      <IconButton
        color="primary"
        className={`${classes.actionButton} ${classes.passButton}`}
        sx={status ? { backgroundColor: "#27AE60" } : {}}
        onClick={() => handleClick(true)}
      >
        <CheckCircleOutline
          sx={{
            color: status
              ? theme.palette.primary.contrastText
              : theme.palette.success.main,
          }}
        />
        <Typography
          variant="button"
          sx={status ? { color: theme.palette.primary.contrastText } : {}}
        >
          Pass
        </Typography>
      </IconButton>

      <IconButton
        className={`${classes.actionButton} ${classes.failButton}`}
        onClick={() => handleClick(false)}
        sx={status ? {} : { backgroundColor: "#EB5757" }}
      >
        <DoDisturbRounded
          sx={status ? {} : { color: theme.palette.primary.contrastText }}
        />
        <Typography
          variant="button"
          sx={status ? {} : { color: theme.palette.primary.contrastText }}
        >
          Fail
        </Typography>
      </IconButton>
    </Grid>
  );
};

export default LiveTestApprovalButton;
