import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { LiveTestStep } from "src/store/reducers/liveTest/liveTestSlice";

const useStyles = ({ liveTestStep }: { liveTestStep: number }) =>
  makeStyles((theme: Theme) => ({
    contentContainer: {
      // IF NOT IN SELECT_TEST STEP, ADD PADDING BOTTOM FOR STEPPER
      ...(liveTestStep !== LiveTestStep.SELECT_TEST && {
        paddingBottom: theme.spacing(12),
      }),
    },
    stepperContainer: {
      position: "fixed",
      bottom: 0,
      width: "70%",
    },
    bannerContainer: {
      marginTop: theme.spacing(5),
      backgroundColor: "#262F34",
      overflow: "hidden",
      borderRadius: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    divider: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      border: "1px solid #3F4E57",
    },
    icon: {
      color: theme.palette.primary.contrastText,
      fontSize: theme.spacing(3),
    },
    stepCircle: {
      backgroundColor: "black",
      borderRadius: "50%",
      width: theme.spacing(3),
      height: theme.spacing(3),
      position: "relative",
    },
    circleText: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "#fff",
    },
  }));

export default useStyles;
