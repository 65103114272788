import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { Profile } from "@readyfit-common/models";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "src/grading/components";
import RFModal from "src/grading/components/RFModal/RFModal";
import { DEFAULT_ORGANIZATION } from "src/grading/models";
import {
  useGetOrganizationsQuery,
  useTransferAthleteMutation,
} from "src/store/reducers/organizations/organizationsApiSlice";
import theme from "src/theme";
import * as Yup from "yup";
import useStyles from "./TransferAthleteModal.styles";

interface TransferAthleteModalProps {
  open: boolean;
  athlete: Profile;
  onClose: () => void;
}

export interface TransferAthleteFormValues {
  organizationId: string;
}

const validationSchema = Yup.object().shape({
  organizationId: Yup.string().required("Organization Id is required"),
});

const TransferAthleteModal = (props: TransferAthleteModalProps) => {
  const classes = useStyles();
  const history = useHistory();
  const { data: organizationsData } = useGetOrganizationsQuery();
  const [transferAthlete, { isLoading }] = useTransferAthleteMutation();

  const handleTransferAthlete = async (values: {
    memberId: string;
    organizationId: string;
    newOrganizationId: string;
  }) => {
    const result = await transferAthlete({ ...values }).unwrap();

    if (result.success) handleClose();
  };

  const handleClose = () => {
    formik.resetForm();
    props.onClose();
    history.push("/grading/athletes");
  };

  const formik = useFormik({
    initialValues: {
      organizationId: "",
    },
    validationSchema,
    onSubmit: async (values: TransferAthleteFormValues) => {
      await handleTransferAthlete({
        memberId: props.athlete.id,
        organizationId: props.athlete.organization.id,
        newOrganizationId: values.organizationId,
      });
    },
  });

  const { values, setFieldValue, handleSubmit, errors } = formik;

  return (
    <RFModal
      open={props.open}
      boxProps={{ className: classes.container }}
      onClose={handleClose}
    >
      <Grid
        container
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <Typography variant="h5">Transfer Athlete</Typography>

        <Grid container item marginTop={theme.spacing(2)} spacing={2}>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Organization</InputLabel>

            <Select
              className={classes.input}
              variant="outlined"
              fullWidth
              value={values.organizationId}
              onChange={(e) => setFieldValue("organizationId", e.target.value)}
            >
              {organizationsData?.organizations &&
              organizationsData?.organizations.length > 1 ? (
                organizationsData?.organizations?.map((o) => (
                  <MenuItem key={o.id} value={o.id}>
                    {o.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={DEFAULT_ORGANIZATION.id}>
                  {DEFAULT_ORGANIZATION.name}
                </MenuItem>
              )}
            </Select>

            {errors?.organizationId && (
              <FormHelperText
                style={{ marginLeft: 14, color: "red", fontSize: "14px" }}
                error
              >
                {errors?.organizationId}
              </FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          marginTop={theme.spacing(4)}
        >
          <Grid item>
            <Button
              color="secondary"
              className={` ${classes.button} ${classes.backButton}`}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              className={classes.button}
              label="Save"
              type="submit"
              loading={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
    </RFModal>
  );
};

export default TransferAthleteModal;
