import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = (showDrawer: boolean) =>
  makeStyles((theme: Theme) => ({
    appBar: {
      padding: "0.5rem 0.2rem",
      backgroundColor: theme.palette.primary.dark,
    },
    root: {
      backgroundColor: "#000000",
      display: "flex",
      flexDirection: "column",
      borderRadius: "16px",
      minWidth: showDrawer ? theme.spacing(35) : theme.spacing(10),
      width: showDrawer ? theme.spacing(35) : theme.spacing(10),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      height: "100%",
      [theme.breakpoints.down("md")]: {
        minWidth: showDrawer ? theme.spacing(20) : theme.spacing(10),
        width: showDrawer ? theme.spacing(20) : theme.spacing(10),
      },
      // width: "100%",
    },
    toggleButton: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5),
      position: "absolute",
      top: theme.spacing(2),
      right: theme.spacing(2),
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: "0",
    },
    bottomLinks: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
    nav: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  }));

export default useStyles;
