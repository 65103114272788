import { Button, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AthleteIcon from "src/grading/components/AthleteIcon/AthleteIcon";
import { getRelativeTime } from "src/grading/utils";
import theme from "src/theme";
import useStyles from "../TestGrid.styles";

interface VirtualTestsProps {
  gridData: any;
}

const VirtualTests = ({ gridData }: VirtualTestsProps) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const isCertified =
    location.pathname.includes("certified") ||
    location.pathname.includes("organization/members");
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "timestamp", sort: "desc" },
  ]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Grid item container>
      <Paper className={classes.paperContainer}>
        <DataGrid
          rows={gridData}
          hideFooterSelectedRowCount={true}
          headerHeight={50}
          pageSize={15}
          sx={{
            border: "none",
          }}
          rowHeight={108}
          className={classes.root}
          autoHeight
          columns={[
            {
              field: "",
              headerName: "Athlete",
              headerAlign: "center",
              // minWidth: 200,
              flex: 1,
              editable: false,
              sortable: false,
              filterable: false,
              renderCell: (params) => (
                <Grid container className={classes.profileIconContainer}>
                  <AthleteIcon profile={params.row.profileSnapshot} />
                  <Grid container item flex={1} className={classes.profileText}>
                    <Typography variant="subtitle1">Athlete</Typography>
                    <Typography
                      variant="h4"
                      fontWeight={"bold"}
                      className={classes.text}
                    >
                      {params.row.profileSnapshot?.firstName &&
                      params.row.profileSnapshot?.lastName
                        ? params.row.profileSnapshot?.firstName +
                          " " +
                          params.row.profileSnapshot?.lastName
                        : params.row.profileSnapshot?.email}
                    </Typography>
                  </Grid>
                </Grid>
              ),
            },
            {
              field: "liveTestId",
              headerName: "",
              headerAlign: "center",
              flex: 1,
              editable: false,
              // sortable: true,
              renderCell: (params) => {
                return (
                  <Grid
                    container
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="caption"
                      fontWeight={"bold"}
                      color={"white"}
                      bgcolor={
                        params?.row?.liveTestId
                          ? "black"
                          : theme.palette.primary.main
                      }
                      padding={`${theme.spacing(0.5)} ${theme.spacing(3)}`}
                      borderRadius={7}
                    >
                      {params.row.liveTestId ? "In Person" : "Virtual"}
                    </Typography>
                  </Grid>
                );
              },
            },
            {
              field: "testTitle",
              headerName: "Test",
              headerAlign: "center",
              // minWidth: 300,
              flex: 1,
              editable: false,
              sortable: true,
              renderCell: (params) => (
                <Grid container className={classes.profileText}>
                  <Typography variant="subtitle1">Type of Test</Typography>
                  <Typography
                    variant="h4"
                    fontWeight={"bold"}
                    className={classes.text}
                    style={{
                      fontSize: isSmallScreen ? "1rem" : "1.25rem",
                    }}
                  >
                    {params.row.testTitle}
                  </Typography>
                </Grid>
              ),
            },
            {
              field: "timestamp",
              headerName: "Test Taken",
              // minWidth: 250,
              flex: 1,
              headerAlign: "center",
              editable: false,
              sortable: true,
              filterable: false,
              valueGetter: (params) => {
                return params.row.timestamp;
              },
              renderCell: (params) => (
                <Grid container className={classes.profileText}>
                  <Typography variant="subtitle1">Test Taken</Typography>
                  <Typography
                    variant="h4"
                    fontWeight={"bold"}
                    className={classes.text}
                  >
                    {getRelativeTime(params.row.timestamp)}
                  </Typography>
                </Grid>
              ),
            },
            {
              field: "action",
              headerName: "Action",
              headerAlign: "center",
              width: 175,
              maxWidth: 175,
              editable: false,
              sortable: false,
              filterable: false,
              renderCell: (params) => (
                <Grid
                  container
                  className={classes.gradeButtonBorder}
                  alignItems={"center"}
                >
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      marginLeft: 16,
                      backgroundColor: "#000",
                      borderRadius: "10px",
                      height: theme.spacing(4),
                    }}
                    onClick={() =>
                      isCertified
                        ? history.push(
                            `/grading/tests/${params.row.userId}/${params.row.id}/certified`,
                          )
                        : history.push(
                            `/grading/tests/${params.row.userId}/${params.row.id}`,
                          )
                    }
                    fullWidth
                  >
                    {isCertified ? "Review" : "Grade"}
                  </Button>
                </Grid>
              ),
            },
          ]}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
        />
      </Paper>
    </Grid>
  );
};

export default VirtualTests;
