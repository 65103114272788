import {
  Add,
  ArrowDownward,
  ArrowUpward,
  Close,
  DeleteOutline,
} from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { LoadingButton } from "src/grading/components";
import RFModal from "src/grading/components/RFModal/RFModal";
import theme from "src/theme";
import { v4 as uuid } from "uuid";
import useStyles from "../RecruitmentProcessWidget.styles";

interface addRecruitmentProcessRequirementModalProps {
  open: boolean;
  groupTitle: string;
  dict: { [key: string]: string };
  order: string[];
  onUpdateOrgRP: (props: {
    dict: { [key: string]: string };
    order: string[];
  }) => void;
  onClose: () => void;
}

export const AddRecruitmentProcessRequirementModal = ({
  dict,
  order,
  onClose,
  onUpdateOrgRP,
  ...props
}: addRecruitmentProcessRequirementModalProps) => {
  const classes = useStyles();

  const [requirements, setRequirements] = useState<{
    dict: { [key: string]: string };
    order: string[];
  }>({ dict, order });

  const [addRequirement, setAddRequirement] = useState<string>("");

  const handleClose = () => {
    setRequirements({ dict, order });
    setAddRequirement("");
    onClose();
  };

  const handleSubmit = () => {
    onUpdateOrgRP({ ...requirements });
    onClose();
  };

  return (
    <RFModal
      {...props}
      onClose={handleClose}
      boxProps={{ className: classes.modalContainer }}
    >
      <Grid
        container
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <Grid container alignItems={"center"}>
          <Grid item xs={9}>
            <Typography variant="h5" fontWeight={"bold"}>
              {`Add ${props.groupTitle} requirement`}
            </Typography>
          </Grid>

          <IconButton
            className={classes.modalCloseIconButton}
            onClick={handleClose}
          >
            <Close style={{ color: "#000000" }} />
          </IconButton>
        </Grid>

        <Grid container marginTop={theme.spacing(2)}>
          {requirements.order.map((k, i) => (
            <Grid key={k} container margin={theme.spacing(1)}>
              <Grid item xs={9}>
                <TextField
                  InputProps={{ className: classes.modalInputLabel }}
                  variant="outlined"
                  fullWidth
                  value={requirements.dict[k]}
                  onChange={(e) =>
                    setRequirements((req) => ({
                      ...req,
                      dict: { ...req.dict, [k]: e.target.value },
                    }))
                  }
                />
              </Grid>

              <Grid item xs={1}>
                <IconButton
                  onClick={() => {
                    if (i <= 0) return;

                    const order = requirements.order;
                    const temp = order[i - 1];
                    order[i - 1] = order[i];
                    order[i] = temp;
                    setRequirements((req) => ({ ...req, order }));
                  }}
                >
                  <ArrowUpward />
                </IconButton>
              </Grid>

              <Grid item xs={1}>
                <IconButton
                  onClick={() => {
                    if (i >= requirements.order.length - 1) return;

                    const order = requirements.order;
                    const temp = order[i + 1];
                    order[i + 1] = order[i];
                    order[i] = temp;
                    setRequirements((req) => ({ ...req, order }));
                  }}
                >
                  <ArrowDownward />
                </IconButton>
              </Grid>

              <Grid item xs={1}>
                <IconButton
                  onClick={() => {
                    const dict = requirements.dict;
                    const order = requirements.order;
                    delete dict[k];
                    delete order[i];
                    setRequirements({ dict, order });
                  }}
                >
                  <DeleteOutline color="error" />
                </IconButton>
              </Grid>
            </Grid>
          ))}
          <Grid container margin={theme.spacing(1)}>
            <Grid item xs={12}>
              <InputLabel className={classes.modalInputLabel}>
                Add Requirement
              </InputLabel>

              <Grid container xs={12} justifyContent="space-between">
                <Grid item xs={9}>
                  <TextField
                    InputProps={{ className: classes.modalInputLabel }}
                    variant="outlined"
                    fullWidth
                    value={addRequirement}
                    onChange={(e) => setAddRequirement(e.target.value)}
                  />
                </Grid>

                <Grid item xs={2}>
                  <IconButton
                    onClick={() => {
                      if (addRequirement.length === 0) return;

                      const key = uuid();
                      setRequirements((req) => ({
                        dict: { ...req.dict, [key]: addRequirement },
                        order: [...req.order, key],
                      }));

                      setAddRequirement("");
                    }}
                  >
                    <Add />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          xs={12}
          justifyContent={"space-between"}
          marginTop={theme.spacing(4)}
        >
          <Grid item>
            <Button
              color="secondary"
              className={`${classes.modalButton} ${classes.modalBackButton}`}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              className={classes.modalButton}
              loading={false}
              label="Save"
              type="submit"
            />
          </Grid>
        </Grid>
      </Grid>
    </RFModal>
  );
};
