import { Button, Grid, Typography } from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import { capitalize } from "lodash";
import { useHistory } from "react-router-dom";
import RFDataGrid from "src/grading/components/RFDataGrid/RFDataGrid";
import { useAppDispatch } from "src/store/hooks";
import { addBreadcrumb } from "src/store/reducers/navigation/navigationSlice";
import { Member } from "src/store/reducers/organizations/organizationsApiSlice";
import theme from "src/theme";
import useStyles from "./AthleteMobileTable.styles";

interface AthleteMobileTableProps {
  members: Member[];
  isLoading: boolean;
  sortModel: GridSortModel;
  setSortModel: (sortModel: GridSortModel) => void;
}

const AthleteRow = ({ member }: { member: Member }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const handleClick = async () => {
    dispatch(
      addBreadcrumb({
        label: "Athletes",
        path: "/grading/athletes",
      }),
    );
    history.push(`/grading/athletes/${member.memberId}`);
  };

  return (
    <Grid container>
      <Grid container className={classes.nameContainer}>
        {/* Athlete Name */}
        <Grid item className={`${classes.profileIcon}`}>
          <Typography
            variant="caption"
            textTransform={"uppercase"}
            color={"white"}
          >
            {member?.firstName?.[0]}
          </Typography>
        </Grid>
        <Grid container item className={classes.profileDataColumn}>
          <Grid container>
            <Typography variant="caption" fontWeight={"bold"}>
              {`${member?.firstName} ${member?.lastName}`}
            </Typography>
          </Grid>

          <Grid container>
            {/* Organization */}
            <Typography
              variant="caption"
              fontWeight={"bold"}
              style={{
                padding: `0 ${theme.spacing(1)}`,
                backgroundColor: "#27AE60",
                borderRadius: theme.spacing(2),
                color: "white",
                textAlign: "center",
                width: "50%",
                maxWidth: "50%",
                marginTop: theme.spacing(2),
              }}
            >
              Status
            </Typography>

            {/* Unit name */}
            <Grid item xs={12}>
              {member.branchOfService && (
                <Typography
                  variant="caption"
                  fontWeight={"bold"}
                  display={"block"}
                >
                  {capitalize(member.branchOfService)}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Button
          variant="contained"
          size="small"
          onClick={handleClick}
          fullWidth
          className={classes.tableActionButton}
        >
          See Profile
        </Button>
      </Grid>
    </Grid>
  );
};

const SelectAthleteTableColumns = (): GridColDef[] => [
  {
    field: "athlete",
    headerName: "Athlete",
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    renderCell: ({ row }: { row: Member }) => <AthleteRow member={row} />,
  },
];

const AthleteMobileTable = ({
  members,
  isLoading,
  sortModel,
  setSortModel,
}: AthleteMobileTableProps) => {
  return (
    <RFDataGrid
      rows={members}
      columns={SelectAthleteTableColumns()}
      loading={isLoading}
      disableSelectionOnClick
      sortModel={sortModel}
      onSortModelChange={(newSortModel) => {
        setSortModel(newSortModel);
      }}
      rowHeight={150}
      getRowId={(row) => row.memberId}
    />
  );
};

export default AthleteMobileTable;
