import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
    boxshadow: "-2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  scoreBannerContainer: {
    padding: theme.spacing(2),
    backgroundColor: "#262F34",
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  exerciseListContainer: {
    padding: theme.spacing(2),
  },
  exerciseRowContainer: {
    display: "flex",
    justifyContent: "space-between",
    padding: `${theme.spacing(1.5)} 0`,
  },
  closeIconButton: {
    marginLeft: "auto",
  },
}));

export default useStyles;
