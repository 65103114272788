import { Add, Search } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { DEFAULT_ORGANIZATION } from "src/grading/models";
import { useAppSelector } from "src/store/hooks";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import { selectCurrentMembers } from "src/store/reducers/organizations/organizationSlice";
import {
  Member,
  useLazyGetMembersQuery,
  useLazyGetUnitMembersQuery,
} from "src/store/reducers/organizations/organizationsApiSlice";
import AddNewMemberModal from "./AddNewMemberModal/AddNewMemberModal";
import useStyles from "./MembersTab.styles";
import MembersTableColumns from "./MembersTableColumns/MembersTableColumns";

interface MembersTabProps {
  unitId?: string;
}

const MembersTab = ({ unitId }: MembersTabProps) => {
  const classes = useStyles();

  const [newMemberModalOpen, setNewMemberModalOpen] = useState(false);

  const [memberGridRows, setMemberGridRows] = useState<Member[]>([]);

  const user = useAppSelector(selectCurrentUser);

  const members = useAppSelector(selectCurrentMembers);

  const [getMembers, { isLoading }] = useLazyGetMembersQuery();

  const [getUnitMembers] = useLazyGetUnitMembersQuery();

  useEffect(() => {
    setMemberGridRows(members);
  }, [members]);

  useEffect(() => {
    const fetchMembers = async () => {
      if (unitId) {
        const { members } = await getUnitMembers({
          organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
          unitId,
        }).unwrap();

        setMemberGridRows(members.map((member) => ({ ...member })));
      }
    };

    fetchMembers();
  }, [getUnitMembers, unitId, user.organizationId]);

  const handleAddNewMember = () => {
    setNewMemberModalOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: async (values) => {
      console.log("values", values);
    },
  });

  const { values, setFieldValue } = formik;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((searchValue) => {
      if (!unitId) {
        getMembers({
          organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
          search: searchValue,
        })
          .unwrap()
          .then((response) => {
            setMemberGridRows(response.members);
          });
      } else {
        getUnitMembers({
          organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
          unitId,
          search: searchValue,
        })
          .unwrap()
          .then((response) => {
            setMemberGridRows(
              response.members.map((member) => ({
                ...member,
              })),
            );
          });
      }
    }, 500),
    [],
  );

  return isLoading ? (
    <Spinner
      animation="border"
      style={{ left: "50%", top: "50%", position: "relative" }}
    />
  ) : (
    <Grid item container>
      <Grid item container gap={2} className={classes.filtersContainer}>
        <TextField
          label="Search"
          name="search"
          type="text"
          value={values.search}
          onChange={(e) => {
            setFieldValue("search", e.target.value);
            debounceSearch(e.target.value);
          }}
          InputProps={{
            className: "text-field",
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          placeholder="Search by user"
        />
        <IconButton
          className={classes.addUnitButton}
          onClick={handleAddNewMember}
        >
          <Typography variant="button">Add Member</Typography>
          <Add />
        </IconButton>
      </Grid>

      <Grid item container>
        <Paper className={classes.paperContainer}>
          <DataGrid
            rows={memberGridRows}
            hideFooterSelectedRowCount={true}
            headerHeight={50}
            pageSize={15}
            sx={{
              border: "none",
            }}
            rowHeight={108}
            className={classes.root}
            autoHeight
            columns={MembersTableColumns(setMemberGridRows)}
            getRowId={(row) => row.memberId}
            // sortModel={sortModel}
            // onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          />
        </Paper>
      </Grid>

      <AddNewMemberModal
        onClose={() => setNewMemberModalOpen(false)}
        open={newMemberModalOpen}
      />
    </Grid>
  );
};

export default MembersTab;
