import { Add, Search } from "@mui/icons-material";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { DEFAULT_ORGANIZATION } from "src/grading/models";
import { useAppSelector } from "src/store/hooks";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import { selectCurrentUnits } from "src/store/reducers/organizations/organizationSlice";
import {
  Unit,
  useLazyGetUnitsQuery,
} from "src/store/reducers/organizations/organizationsApiSlice";
import AddNewUnitModal from "./AddNewUnitModal/AddNewUnitModal";
import useStyles from "./UnitsTab.styles";
import UnitsTableColumns from "./UnitsTableColumns/UnitsTableColumns";

const UnitsTab = () => {
  const classes = useStyles();

  const [newUnitModalOpen, setNewUnitModalOpen] = useState(false);

  const [unitGridRows, setUnitGridRows] = useState<Unit[]>([]);

  const user = useAppSelector(selectCurrentUser);

  const units = useAppSelector(selectCurrentUnits);

  const [getUnits, { isLoading }] = useLazyGetUnitsQuery();

  useEffect(() => {
    setUnitGridRows(units);
  }, [units]);

  const handleAddNewUnit = () => {
    setNewUnitModalOpen(true);
  };

  const formik = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: async (values) => {
      console.log("values", values);
    },
  });

  const { values, setFieldValue } = formik;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((searchValue) => {
      getUnits({
        organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
        search: searchValue,
      })
        .unwrap()
        .then((response) => {
          setUnitGridRows(response.units);
        });
    }, 500),
    [],
  );

  return isLoading ? (
    <Spinner
      animation="border"
      style={{ left: "50%", top: "50%", position: "relative" }}
    />
  ) : (
    <Grid item container>
      <Grid item container gap={2} className={classes.filtersContainer}>
        <TextField
          label="Search"
          name="search"
          type="text"
          value={values.search}
          onChange={(e) => {
            setFieldValue("search", e.target.value);
            debounceSearch(e.target.value);
          }}
          InputProps={{
            className: "text-field",
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          placeholder="Search by unit"
        />
        <IconButton
          className={classes.addUnitButton}
          onClick={handleAddNewUnit}
        >
          <Typography variant="button">Add Unit</Typography>
          <Add />
        </IconButton>
      </Grid>

      <Grid item container>
        <Paper className={classes.paperContainer}>
          <DataGrid
            rows={unitGridRows}
            hideFooterSelectedRowCount={true}
            headerHeight={50}
            pageSize={15}
            sx={{
              border: "none",
            }}
            rowHeight={108}
            className={classes.root}
            autoHeight
            columns={UnitsTableColumns()}
            // sortModel={sortModel}
            // onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          />
        </Paper>
      </Grid>

      <AddNewUnitModal
        organizationId={user.organizationId ?? DEFAULT_ORGANIZATION.id}
        onClose={() => setNewUnitModalOpen(false)}
        open={newUnitModalOpen}
      />
    </Grid>
  );
};

export default UnitsTab;
