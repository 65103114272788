import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  navigationButton: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
      opacity: 0.6,
    },
  },
}));

export default useStyles;
