import { Divider, Grid, TextField, Typography } from "@mui/material";
import { useAppSelector } from "src/store/hooks";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import { selectGradingStep } from "src/store/reducers/grading/gradingSlice";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import theme from "src/theme";
import LineItem from "../../LineItem/LineItem";
import useStyles from "./ReviewStepper.styles";

interface ReviewStepperProps {
  testScore: TestScore;
  overallNotes: string | undefined;
  setOverallNotes: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ReviewStepper = ({
  testScore,
  overallNotes,
  setOverallNotes,
}: ReviewStepperProps) => {
  const step = useAppSelector(selectGradingStep);
  const classes = useStyles();
  const user = useAppSelector(selectCurrentUser);
  const isResultStepper = step > testScore.exercises.length;

  return (
    <Grid>
      <Grid p={4}>
        {!isResultStepper && (
          <Grid
            container
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Typography variant="caption">
              Make sure you have reviewed all the exercises before certifying
              the test.
            </Typography>
          </Grid>
        )}

        <Grid mt={1.5} container item flexDirection={"column"}>
          <LineItem
            label={"Name"}
            value={
              testScore?.profileSnapshot?.firstName +
              " " +
              testScore?.profileSnapshot?.lastName
            }
          />
          <Divider className={classes.divider} />

          <LineItem label={"Unit"} value={user?.branch || "N/A"} />
          <Divider />

          <LineItem
            sx={{ marginTop: theme.spacing(1) }}
            label={"Type of Test"}
            value={testScore.testTitle}
          />
        </Grid>

        <Grid container item mt={4} flexDirection={"column"}>
          <Typography variant="subtitle1">Total Score</Typography>

          <Typography variant="h1">
            {`${testScore?.score}`.indexOf(".") === -1
              ? testScore?.score
              : testScore?.score.toFixed(2)}
            <span style={{ color: "#8F8F8F" }}>/{testScore?.maxScore}</span>
          </Typography>
        </Grid>

        {/* NOTES */}
        <Typography variant="subtitle1" textTransform={"uppercase"} mt={4}>
          Overall notes
        </Typography>

        <Grid container>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            placeholder="Enter notes here..."
            onChange={(e) => {
              setOverallNotes(e.target.value);
            }}
            value={overallNotes}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ReviewStepper;
