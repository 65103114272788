import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    cursor: "pointer",
    borderColor: theme.palette.secondary.light,
    "&:hover": {
      backgroundColor: theme.palette.primary.light,
      borderColor: theme.palette.primary.main,
    },
  },
  thumb: {
    height: theme.spacing(2.5),
    width: theme.spacing(1),
    borderRadius: theme.spacing(1),
  },
}));

export default useStyles;
