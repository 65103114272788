import { createSlice } from "@reduxjs/toolkit";
import { Permission } from "src/grading/utils/permissionsHelpers";
import { RootState } from "src/store/rootStore";

interface User {
  email: string;
  firstName?: string;
  lastName?: string;
  displayName?: string;
  profileImageUri?: string;
  birthday: string;
  gender: string;
  organization?: {
    id: string;
    name: string;
    branch: string;
  };
}

interface AuthSliceState {
  accessToken: string | null;
  refreshToken: string | null;
  user: User | null;
  permissions: Permission[] | null;
}

const authSlice = createSlice({
  name: "auth",
  initialState: {
    accessToken: null,
    refreshToken: null,
    user: null,
    permissions: null,
  } as AuthSliceState,
  reducers: {
    resetAuthSlice: (state, action) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      window.localStorage.removeItem("readyFitAuth");
    },
    setCredentials: (state, action) => {
      const { accessToken, refreshToken } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      window.localStorage.setItem("readyFitAuth", JSON.stringify({ ...state }));
    },
    setUser: (state, action) => {
      const { user } = action.payload;
      state.user = user;
      window.localStorage.setItem("readyFitAuth", JSON.stringify({ ...state }));
    },
    setPermissions: (state, action) => {
      const { permissions } = action.payload;
      state.permissions = permissions.map(
        ({ action, subject }: { action: string; subject: string }) => ({
          action,
          subject,
        }),
      );
      window.localStorage.setItem("readyFitAuth", JSON.stringify({ ...state }));
    },
    logOut: (state, action) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      window.localStorage.removeItem("readyFitAuth");
    },
  },
});

export const { setCredentials, setUser, logOut, setPermissions } =
  authSlice.actions;

export default authSlice.reducer;

export const selectCurrentAccessToken = (state: RootState) => {
  if (state.auth.accessToken) return state.auth.accessToken;
  const { accessToken } = JSON.parse(
    window.localStorage.getItem("readyFitAuth") || "{}",
  );
  return accessToken ?? null;
};
export const selectCurrentRefreshToken = (state: RootState) => {
  if (state.auth.refreshToken) return state.auth.refreshToken;
  const { refreshToken } = JSON.parse(
    window.localStorage.getItem("readyFitAuth") || "{}",
  );
  return refreshToken ?? null;
};
export const selectCurrentUser = (state: RootState) => {
  if (state.auth.user) return state.auth.user;
  const { user } = JSON.parse(
    window.localStorage.getItem("readyFitAuth") || "{}",
  );
  return user ?? null;
};
export const selectCurrentPermissions = (state: RootState) => {
  if (state.auth.permissions) return state.auth.permissions;
  const { permissions } = JSON.parse(
    window.localStorage.getItem("readyFitAuth") || "{}",
  );
  return permissions ?? null;
};
