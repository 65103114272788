import React from "react";
import { useLocation } from "react-router-dom";
import Spinner from 'react-bootstrap/Spinner';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export function GarminAuth(props) {
    let query = useQuery();
    let oauth_token = query.get("oauth_token");
    let oauth_verifier = query.get("oauth_verifier");
    if(window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(JSON.stringify({ oauth_token, oauth_verifier }));
    }

    return <Spinner animation="border" style={{ left: '50%', top: '50%', position: 'absolute', marginLeft: -8 }} />;
}