import { CheckCircleOutline } from "@mui/icons-material";
import { Button, Divider, Grid, Paper, Typography } from "@mui/material";
import { Profile } from "@readyfit-common/models";
import { CSSProperties } from "react";
import { useHistory, useLocation } from "react-router-dom";
import AthleteIcon from "src/grading/components/AthleteIcon/AthleteIcon";
import GradingHeader from "src/grading/components/GradingHeader/GradingHeader";
import LiveTestStepper from "src/grading/components/LiveTestStepper/LiveTestStepper";
import {
  getLabelBasedOnGradeResult,
  getValuesBasedOnGradeResult,
} from "src/grading/utils/testHelpers";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  regradingStep,
  selectCurrentLiveTestInfo,
  selectLiveTestGradeResults,
  selectLiveTestScores,
  selectLiveTestStep,
  setLiveTestStep,
  setSelectedAthleteToSign,
} from "src/store/reducers/liveTest/liveTestSlice";
import theme from "src/theme";
import useStyles from "./LiveTestResultsOverview.styles";

export const hasMoreThanTwoDecimalPlaces = (value: number) => {
  return value.toString().split(".")[1]?.length > 2;
};

export const RowItem = ({
  label,
  value,
  spacing = 2,
  maxValue,
  scoreValue,
  style,
  ...props
}: {
  label?: string;
  value: string | number;
  spacing?: number;
  maxValue?: string | number;
  scoreValue?: boolean;
  style?: CSSProperties;
}) => {
  return (
    <Grid
      item
      container
      alignItems={"center"}
      flexDirection={"column"}
      flex={1}
      style={style}
      {...props}
    >
      {label && (
        <Typography
          variant="subtitle1"
          color={"#666666"}
          textTransform={"uppercase"}
        >
          {label}
        </Typography>
      )}

      {scoreValue ? (
        <Typography variant="h5" fontWeight={"bold"}>
          {value}/<span style={{ color: "#666666" }}>{maxValue}</span>
        </Typography>
      ) : (
        <Typography variant="h5" fontWeight={"bold"}>
          {typeof value === "number" && hasMoreThanTwoDecimalPlaces(value)
            ? value.toFixed(1)
            : value}
        </Typography>
      )}
    </Grid>
  );
};

const LiveTestResultsOverview = ({
  setCanNavigateAway,
  setYOffset,
  yOffset,
}: {
  setCanNavigateAway: React.Dispatch<React.SetStateAction<boolean>>;
  setYOffset?: React.Dispatch<React.SetStateAction<number>>;
  yOffset?: number;
}) => {
  const classes = useStyles();
  const liveTestInfo = useAppSelector(selectCurrentLiveTestInfo);
  const liveTestGradeResults = useAppSelector(selectLiveTestGradeResults);
  const liveTestStep = useAppSelector(selectLiveTestStep);
  const date = new Date(Date.now());
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const liveTestScores = useAppSelector(selectLiveTestScores);

  const handleSignTest = (profile: Profile) => {
    if (setYOffset) {
      setYOffset(window.scrollY);
    }
    history.replace(`${location.pathname}/sign`);
    window.scrollTo(0, 0);

    dispatch(setSelectedAthleteToSign(profile));
    dispatch(setLiveTestStep(liveTestStep + 1));
  };

  return (
    <Grid container position={"relative"}>
      <Grid
        container
        className={classes.headerContainer}
        component={Paper}
        elevation={1}
      >
        <Grid item container xs={12} className={classes.titleContainer}>
          <GradingHeader
            withBreadcrumbs
            onClick={() => history.push("/live")}
            containerStyles={{
              marginTop: 0,
              marginBottom: theme.spacing(2),
            }}
          />

          <Typography variant="h3">Results Overview</Typography>

          <Grid item marginLeft={"auto"} marginRight={1}>
            <LiveTestStepper
              mini={true}
              setCanNavigateAway={setCanNavigateAway}
              yOffset={yOffset}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={classes.bannerContainer}>
        <Grid
          item
          flex={1}
          display={"flex"}
          align-items={"center"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"flex-start"}
        >
          <Typography
            color={theme.palette.primary.contrastText}
            variant={"h3"}
            textTransform={"uppercase"}
          >
            {liveTestInfo?.displayName}
          </Typography>
        </Grid>
        <Divider orientation={"vertical"} className={classes.divider} />

        <Grid item container xs={3} flexDirection={"column"}>
          <Typography
            variant="subtitle1"
            color={"#B2B8BC"}
            textTransform={"uppercase"}
          >
            Test taken on
          </Typography>

          <Typography variant="h3" color={theme.palette.primary.contrastText}>
            {date.toLocaleString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Typography>
        </Grid>
      </Grid>

      {liveTestGradeResults.map((liveTestGradeResult, index) => {
        const isTestSigned = Boolean(
          liveTestGradeResult.athleteSignatureDetails?.signature &&
            liveTestGradeResult.graderSignatureDetails?.signature,
        );

        const athleteScores = liveTestScores?.scores.find(
          (score) => score.profile.id === liveTestGradeResult.profile.id,
        );
        const athleteScoreExercises = athleteScores?.score?.exercises;
        return (
          <Grid
            key={index}
            item
            container
            xs={12}
            className={classes.athletesContainer}
          >
            <Grid item container className={classes.athleteFirstRow}>
              <Grid
                item
                container
                xs={3}
                alignItems={"center"}
                className={classes.athleteInfoContainer}
              >
                <AthleteIcon profile={liveTestGradeResult.profile} />
                <Grid item container flex={1}>
                  <Typography variant="h4" marginLeft={2.5}>
                    {liveTestGradeResult.profile.firstName}{" "}
                    {liveTestGradeResult.profile.lastName}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                item
                container
                xs={3}
                marginLeft={"auto"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                {isTestSigned ? (
                  <Grid
                    item
                    container
                    alignItems={"center"}
                    justifyContent={"flex-end"}
                    marginRight={3}
                  >
                    <CheckCircleOutline sx={{ color: "#27AE60" }} />
                    <Typography
                      variant="subtitle1"
                      textTransform={"uppercase"}
                      fontSize={"14"}
                      fontWeight={"bold"}
                      marginLeft={1}
                    >
                      Signed
                    </Typography>
                  </Grid>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.signButton}
                    onClick={() => handleSignTest(liveTestGradeResult.profile)}
                  >
                    <Typography variant="button">Sign Test</Typography>
                  </Button>
                )}
              </Grid>
            </Grid>

            <Grid item container className={classes.athletesSecondRow}>
              {liveTestGradeResult.gradeResults.map(
                (gradeResult, gradeResultIndex) => {
                  const exerciseScore = athleteScoreExercises?.find(
                    (exercise) =>
                      exercise.exerciseId ===
                      gradeResult.userResult?.exerciseId,
                  );
                  const label = getLabelBasedOnGradeResult(gradeResult);

                  const value = getValuesBasedOnGradeResult(gradeResult);
                  return (
                    <Grid
                      key={gradeResultIndex}
                      item
                      container
                      xs={12}
                      sx={
                        gradeResultIndex ===
                        liveTestGradeResult.gradeResults.length - 1
                          ? {
                              borderBottom: "0",
                              marginBottom: theme.spacing(1),
                              padding: theme.spacing(0.5),
                            }
                          : {
                              borderBottom: `1px solid ${theme.palette.secondary.light}`,
                              marginBottom: theme.spacing(1),
                              padding: theme.spacing(0.5),
                            }
                      }
                    >
                      <Grid
                        item
                        container
                        xs={3}
                        flex={1}
                        alignItems={"center"}
                      >
                        <Typography variant="h5">
                          {gradeResult.userResult?.name}
                        </Typography>
                      </Grid>

                      <RowItem label={label} value={value} />

                      <RowItem
                        label={"Score"}
                        scoreValue
                        value={exerciseScore?.score || 0}
                        maxValue={exerciseScore?.maxScore || 100}
                      />

                      <RowItem
                        label={"Ranking"}
                        value={exerciseScore?.grade as string}
                        style={{ marginRight: "8px" }}
                      />

                      <Grid
                        item
                        container
                        flex={1}
                        justifyContent={"flex-end"}
                        alignItems={"center"}
                        marginLeft={"auto"}
                      >
                        <Button
                          className={classes.regradeButton}
                          variant="contained"
                          onClick={() => {
                            dispatch(
                              regradingStep({
                                step: gradeResultIndex + 2,
                                profile: liveTestGradeResult.profile,
                              }),
                            );
                          }}
                          disabled={isTestSigned}
                        >
                          Re-Grade
                        </Button>
                      </Grid>
                    </Grid>
                  );
                },
              )}
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default LiveTestResultsOverview;
