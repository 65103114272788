import { Divider, Grid, Paper, Typography } from "@mui/material";
import { Profile } from "@readyfit-common/models";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import AthleteIcon from "src/grading/components/AthleteIcon/AthleteIcon";
import GradingHeader from "src/grading/components/GradingHeader/GradingHeader";
import LiveTestStepper from "src/grading/components/LiveTestStepper/LiveTestStepper";
import Loader from "src/grading/components/Loader/Loader";
import LiveTestLayout from "src/grading/layout/LiveTestLayout/LiveTestLayout";
import { LiveTest } from "src/store/reducers/liveTest/liveTestSlice";
import { useLazyGetUserByIdQuery } from "src/store/reducers/users/usersApiSlice";
import theme from "src/theme";
import { RowItem } from "../../LiveTest/CertifyTest/LiveTestCertify";
import useStyles from "./LiveTestReviewCertify.styles";

const LiveTestReviewCertify = () => {
  const classes = useStyles();
  const location = useLocation();
  const { scores } = location.state as LiveTest;
  const [grader, setGrader] = useState<Profile | null>(null);
  const [getUserById, { isLoading }] = useLazyGetUserByIdQuery();

  const getGraderInformation = async () => {
    const graderId = scores?.[0]?.graderId;
    if (graderId) {
      const response = await getUserById(graderId).unwrap();
      setGrader(response.profile);
    }
  };

  useEffect(() => {
    getGraderInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LiveTestLayout>
      <Loader loading={isLoading}>
        <Grid container>
          <Grid
            container
            className={classes.headerContainer}
            component={Paper}
            elevation={1}
          >
            <Grid item container xs={12} className={classes.titleContainer}>
              <GradingHeader
                withBreadcrumbs
                containerStyles={{
                  marginTop: 0,
                  marginBottom: theme.spacing(2),
                }}
              />

              <Typography variant="h3">Results Overview</Typography>

              <Grid item marginLeft={"auto"} marginRight={1}>
                <LiveTestStepper athletes={[]} mini={true} />
              </Grid>
            </Grid>
          </Grid>

          {scores?.map((score, scoreIndex) => {
            const date = new Date(score?.datetime).toLocaleString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            });
            return (
              <Grid
                container
                xs={12}
                key={scoreIndex}
                className={classes.rowContainer}
              >
                <Grid
                  container
                  item
                  xs={1}
                  className={classes.dividerContainer}
                  bgcolor={
                    score?.state.toLowerCase() === "pass"
                      ? "#00A86B"
                      : "#FF0000"
                  }
                >
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item container xs={11}>
                  <Grid item container xs={12}>
                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.firstRowContainer}
                      gap={3}
                    >
                      <AthleteIcon profile={score?.profileSnapshot} />
                      <RowItem
                        alignItems="flex-start"
                        label="Athlete"
                        value={`${score?.profileSnapshot.firstName} ${score?.profileSnapshot?.lastName}`}
                        flex={1}
                      />

                      <RowItem
                        alignItems="flex-start"
                        label="Unit"
                        value={score?.profileSnapshot?.branchOfService || "-"}
                        flex={1}
                      />

                      <RowItem
                        alignItems="flex-start"
                        label="Type of Test"
                        value={score?.testTitle || "-"}
                        flex={1}
                      />

                      <RowItem
                        alignItems="flex-start"
                        label={"Score"}
                        scoreValue
                        value={score?.score || 0}
                        maxValue={score?.maxScore || 100}
                        flex={1}
                      />

                      <Grid item container flex={1}>
                        <Typography
                          variant="subtitle1"
                          fontWeight={"bold"}
                          color="#fff"
                          textTransform={"uppercase"}
                          padding={`${theme.spacing(0.25)} ${theme.spacing(2)}`}
                          borderRadius={theme.spacing(7)}
                          maxWidth={theme.spacing(11)}
                          bgcolor={
                            score?.state.toLowerCase() === "pass"
                              ? "#00A86B"
                              : "#FF0000"
                          }
                          width={"100%"}
                          textAlign={"center"}
                        >
                          {score?.state.toLowerCase() === "pass"
                            ? "Pass"
                            : "Fail"}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      container
                      xs={12}
                      className={classes.secondRowContainer}
                      mt={3}
                    >
                      <Grid container item xs={5} alignItems={"center"}>
                        <Typography
                          variant="subtitle1"
                          textTransform={"uppercase"}
                          fontWeight={"bold"}
                          marginRight={2}
                        >
                          Test Taken
                        </Typography>

                        <Typography fontWeight={"bold"} fontSize={"18px"}>
                          {date}
                        </Typography>
                      </Grid>
                      <Grid container item xs={5} alignItems={"center"}>
                        <Typography
                          variant="subtitle1"
                          textTransform={"uppercase"}
                          fontWeight={"bold"}
                          marginRight={2}
                        >
                          Grader
                        </Typography>

                        <Typography fontWeight={"bold"} fontSize={"18px"}>
                          {`${grader?.firstName} ${grader?.lastName}`}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Loader>
    </LiveTestLayout>
  );
};

export default LiveTestReviewCertify;
