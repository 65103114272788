import { ExerciseResultMetadata } from "@readyfit-common/models";
import { apiSlice } from "../../apiSlice";

export interface ProfileSnapshot {
  id: string;
  email: string;
  birthday?: string;
  gender?: string;
  firstName?: string;
  lastName?: string;
}

export interface ExerciseScore {
  id: string;
  exerciseId: string;
  score: string;
  maxScore?: number;
  grade?: string;
  metadata: ExerciseResultMetadata;
  fileKey: string;
}

export interface TestScore {
  testResultId: string;
  testId: string;
  testVersion: number;
  testTitle: string;
  timestamp: number;
  userId: string;
  profileSnapshot: ProfileSnapshot;
  certified: "true" | "false" | "pending";
  state: "pass" | "fail";
  score: number;
  maxScore: number;
  grades: string[];
  description?: string;
  graderId?: string;
  exercises: ExerciseScore[];
  branch: string;
  liveTestId?: string;
  overallNotes?: string;
}

export const scoresApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getScores: builder.query({
      query: (params) => {
        const { certified, userId, searchValue, sortBy } = params;
        let url = "/api/grading/scores?";
        if (certified) url += `&certified=${certified}`;
        if (userId) url += `&userId=${userId}`;
        if (searchValue) url += `&search=${searchValue}`;
        if (sortBy) url += `&sortBy=${sortBy}`;
        return {
          url,
          method: "GET",
        };
      },
      transformResponse: (response: { data: { testScores: TestScore[] } }) =>
        response.data,
      providesTags: ["Scores"],
    }),
    getScoreById: builder.query({
      query: (scoreId: string) => ({
        url: `/api/grading/scores/${scoreId}`,
        method: "GET",
      }),
      transformResponse: (response: { data: { testScore: TestScore } }) =>
        response.data,
    }),
    scoreTest: builder.mutation({
      query: (payload) => {
        const { scoreId, certifiedTestResult } = payload;
        return {
          url: `/api/grading/scores/${scoreId}/score-test`,
          method: "POST",
          body: { ...certifiedTestResult },
        };
      },
    }),
  }),
});

export const {
  useGetScoresQuery,
  useLazyGetScoresQuery,
  useGetScoreByIdQuery,
  useLazyGetScoreByIdQuery,
  useScoreTestMutation,
} = scoresApiSlice;
