import { Grid, Typography } from "@mui/material";
import useStyles from "../RecruitmentProcessWidget.styles";

export const RecruitmentProcessBadge = ({
  label,
  right,
}: {
  label: "qualified" | "not qualified" | "pending" | "pass" | "fail";
  right?: boolean;
}) => {
  const backgroundColor =
    label === "qualified" || label === "pass"
      ? "#27AE60"
      : label === "pending"
      ? "#F2C94C"
      : "#EB5757";
  const classes = useStyles();
  return (
    <Grid item sx={right ? { marginLeft: "auto" } : null}>
      <Typography
        variant="subtitle1"
        className={classes.badge}
        style={{ backgroundColor }}
        textTransform={"uppercase"}
      >
        {label}
      </Typography>
    </Grid>
  );
};
