import {
  LiveTestScoreDetails,
  LiveTestScoreSignRequest,
  LiveTestScoreSubmitRequest,
  LiveTestScoreSubmitResponse,
  Profile,
  StatusResponse,
} from "@readyfit-common/models";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { GradeResult } from "src/grading/models";
import { AddNewAthleteFormValues } from "src/grading/pages/LiveTest/SelectAthlete/AddNewAthleteModal/AddNewAthleteModal";
import { getExerciseStepNumber } from "src/grading/utils/testHelpers";
import { apiSlice } from "src/store/apiSlice";
import { RootState } from "src/store/rootStore";
import {
  TestDefinitionFormat,
  TestDefinitionInfoType,
} from "../tests/testsApiSlice";

export const liveTestRoutes = {
  SELECT_LIVE_TEST: "/grading/live",
  LIVE_TEST_OVERVIEW: "/grading/live/overview",
  LIVE_TEST_GRADE: "/grading/live/grade",
  LIVE_TEST_SIGN: "/grading/live/sign",
  LIVE_TEST_CERTIFY: "/grading/live/certify",
};

export enum LiveTestStep {
  SELECT_TEST = -1,
  SELECT_ATHLETE = 0,
  TEST_OVERVIEW = 1,
  GRADING_STEPS = 2,
  SIGN_STEP = 3,
  CERTIFIY_STEP = 4,
}

export interface LiveTestSliceState {
  currentLiveTest: TestDefinitionFormat | null;
  currentLiveTestInfo: TestDefinitionInfoType | null;
  currentLiveTestAthletesResultRanges: ProfileResultRanges[] | null;
  selectedAthletes: Profile[];
  liveTestStep: number;
  athletesLiveTestGradeResults: LiveTestGradeResult[];
  tempSelectedAthletes: Profile[];
  regrading: boolean;
  selectedAthleteToSign: Profile | null;
  currentLiveTestScores: LiveTestScoreSubmitResponse | null;
}

export interface LiveTestSigningDetails {
  agreed: boolean;
  disagreementReason: string;
  signature: string;
}

export interface LiveTestGradeResult {
  gradeResults: GradeResult[];
  profile: Profile;
  athleteSignatureDetails: LiveTestSigningDetails;
  graderSignatureDetails: LiveTestSigningDetails;
}

export interface ProfileResultRanges extends Profile {
  resultRanges: {
    [key: string]: {
      min: number;
      max: number;
    };
  };
}

export interface LiveTest {
  athletes: Profile[];
  date: string;
  graderId: string;
  id: string;
  name: string;
  organizationId: string;
  scores: LiveTestScoreDetails[];
  testDefinition: TestDefinitionFormat;
}

export interface ResultRangeRequest {
  testId: string;
  testVersion: number;
  properties?: {
    // @TODO This should be a union of all possible variants
    // variant: "seal" | "swcc" | "eod" | "diver" | "airr";
    variant: string;
  };
  athletes: Profile[];
}

export interface ResultRangeResponse extends StatusResponse {
  data: {
    athletes: ProfileResultRanges[];
  };
}

export const liveTestApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNewAthlete: builder.mutation<StatusResponse, AddNewAthleteFormValues>({
      query: (payload) => {
        return {
          url: `/api/account/users`,
          method: "POST",
          body: { ...payload },
        };
      },
      invalidatesTags: ["Users", "Members"],
    }),
    scoreLiveTest: builder.mutation<
      { data: LiveTestScoreSubmitResponse; success: boolean },
      LiveTestScoreSubmitRequest
    >({
      query: (payload) => {
        return {
          url: `/api/grading/live-tests/score`,
          method: "POST",
          body: { ...payload },
        };
      },
    }),
    getAllLiveTestScores: builder.query<
      {
        data: { liveTests: LiveTest[] };
      },
      { certified?: "pending" | "true" | "false"; athleteId?: string }
    >({
      query: (params) => {
        const { certified, athleteId } = params;
        let url = "/api/grading/live-tests?";
        if (certified) url += `&certified=${certified}`;
        if (athleteId) url += `&athleteId=${athleteId}`;
        return {
          url,
          method: "GET",
        };
      },
    }),
    getLiveTestScore: builder.query<LiveTestScoreSubmitResponse, string>({
      query: (liveTestId) => {
        return {
          url: `/api/grading/live-tests/${liveTestId}`,
          method: "GET",
        };
      },
    }),
    signLiveTest: builder.mutation<StatusResponse, LiveTestScoreSignRequest>({
      query: ({
        agreed,
        liveTestScoreId,
        signature,
        type,
        userId,
        disagreementReason,
      }) => {
        const formData = new FormData();

        formData.append("signature", signature);
        formData.append("agreed", String(agreed));
        formData.append("type", type);
        formData.append("userId", userId);
        if (!agreed && disagreementReason) {
          formData.append("disagreementReason", disagreementReason);
        }

        return {
          url: `/api/grading/live-tests/${liveTestScoreId}/sign`,
          method: "PATCH",
          body: formData,
        };
      },
    }),
    getResultRanges: builder.mutation<ResultRangeResponse, ResultRangeRequest>({
      query: (body) => {
        return {
          url: `/api/grading/live-tests/get-result-ranges`,
          method: "POST",
          body: { ...body },
        };
      },
    }),
  }),
});

const initialState: LiveTestSliceState = {
  currentLiveTest: null,
  currentLiveTestInfo: null,
  currentLiveTestAthletesResultRanges: null,
  selectedAthletes: [],
  liveTestStep: LiveTestStep.SELECT_TEST,
  athletesLiveTestGradeResults: [],
  tempSelectedAthletes: [],
  regrading: false,
  selectedAthleteToSign: null,
  currentLiveTestScores: null,
};

const liveTestSlice = createSlice({
  name: "liveTest",
  initialState,
  reducers: {
    resetLiveTestSlice: (state) => Object.assign(state, initialState),
    setCurrentLiveTest: (
      state,
      action: PayloadAction<TestDefinitionFormat>,
    ) => {
      state.currentLiveTest = action.payload;
    },
    setCurrentLiveTestInfo: (
      state,
      action: PayloadAction<TestDefinitionInfoType>,
    ) => {
      state.currentLiveTestInfo = action.payload;
    },
    setSelectedAthletes: (state, action: PayloadAction<Profile[]>) => {
      state.selectedAthletes = action.payload;
    },
    setLiveTestStep: (state, action: PayloadAction<number>) => {
      state.liveTestStep = action.payload;
    },
    setAthletesLiveTestGradeResults: (
      state,
      action: PayloadAction<{
        athleteLiveTestGradeResults: LiveTestGradeResult[];
        isFirstEntryToGradeResults?: boolean;
      }>,
    ) => {
      if (action.payload.isFirstEntryToGradeResults) {
        state.athletesLiveTestGradeResults.push(
          action.payload.athleteLiveTestGradeResults[0],
        );
        return;
      }
      state.athletesLiveTestGradeResults =
        action.payload.athleteLiveTestGradeResults;
    },
    regradingStep: (
      state,
      action: PayloadAction<{ step: number; profile: Profile }>,
    ) => {
      state.regrading = true;
      state.tempSelectedAthletes = state.selectedAthletes;
      state.selectedAthletes = [action.payload.profile];
      state.liveTestStep = action.payload.step;
    },
    regradingStepComplete: (state) => {
      state.regrading = false;
      state.selectedAthletes = state.tempSelectedAthletes;
      state.liveTestStep = getExerciseStepNumber(
        state.currentLiveTest?.steps || [],
        true,
      );
    },
    setSelectedAthleteToSign: (
      state,
      action: PayloadAction<Profile | null>,
    ) => {
      state.selectedAthleteToSign = action.payload;
    },
    setLiveTestScores: (
      state,
      action: PayloadAction<LiveTestScoreSubmitResponse>,
    ) => {
      state.currentLiveTestScores = action.payload;
    },
    setCurrentLiveTestAthletesResultRanges: (
      state,
      action: PayloadAction<ProfileResultRanges[] | null>,
    ) => {
      state.currentLiveTestAthletesResultRanges = action.payload;
    },
  },
});

export const selectLiveTestState = (state: RootState) => state.liveTest;
export const selectCurrentLiveTest = (state: RootState) =>
  state.liveTest.currentLiveTest;
export const selectCurrentLiveTestInfo = (state: RootState) =>
  state.liveTest.currentLiveTestInfo;
export const selectSelectedAthletes = (state: RootState) =>
  state.liveTest.selectedAthletes;
export const selectLiveTestStep = (state: RootState) =>
  state.liveTest.liveTestStep;
export const selectLiveTestGradeResults = (state: RootState) =>
  state.liveTest.athletesLiveTestGradeResults;
export const selectRegradingLiveStep = (state: RootState) =>
  state.liveTest.regrading;
export const selectSelectedAthleteToSign = (state: RootState) =>
  state.liveTest.selectedAthleteToSign;
export const selectLiveTestScores = (state: RootState) =>
  state.liveTest.currentLiveTestScores;
export const selectCurrentLiveTestAthletesResultRanges = (state: RootState) =>
  state.liveTest.currentLiveTestAthletesResultRanges;

export const {
  resetLiveTestSlice,
  setCurrentLiveTest,
  setCurrentLiveTestInfo,
  setSelectedAthletes,
  setLiveTestStep,
  setAthletesLiveTestGradeResults,
  regradingStep,
  regradingStepComplete,
  setSelectedAthleteToSign,
  setLiveTestScores,
  setCurrentLiveTestAthletesResultRanges,
} = liveTestSlice.actions;

export const {
  useAddNewAthleteMutation,
  useScoreLiveTestMutation,
  useSignLiveTestMutation,
  useGetLiveTestScoreQuery,
  useLazyGetLiveTestScoreQuery,
  useLazyGetAllLiveTestScoresQuery,
  useGetResultRangesMutation,
} = liveTestApiSlice;

export default liveTestSlice.reducer;
