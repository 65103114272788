import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-25%, -50%)",
    width: "45%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
    boxshadow: "-2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
  },
  inputLabel: {
    fontSize: "12px",
  },
  input: {
    borderRadius: theme.spacing(1),
  },
  button: {
    width: theme.spacing(21),
    height: theme.spacing(5),
    textTransform: "uppercase",
    fontWeight: "bold",
    borderRadius: theme.spacing(1),
  },
  backButton: {
    backgroundColor: "#fff",
    color: "black",
    "&:hover": {
      backgroundColor: "#fff",
      color: "black",
      opacity: 0.6,
    },
  },
}));

export default useStyles;
