import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: "10px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.15)",
    marginTop: "-10px",
    padding: `${theme.spacing(1)} ${theme.spacing(5)}`,
  },
  titleContainer: {
    marginTop: theme.spacing(2),
  },
  athletesContainer: {
    marginTop: theme.spacing(8),
  },
  dividerContainer: {
    maxWidth: theme.spacing(1),
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  rowContainer: {
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  firstRowContainer: {
    alignItems: "center",
  },
  secondRowContainer: {
    backgroundColor: "#F2F2F2",
    borderRadius: theme.spacing(1),
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    alignItems: "center",
  },
}));

export default useStyles;
