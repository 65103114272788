import { Profile } from "@readyfit-common/models";
import { Location as LocationType } from "history";
import { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router-dom";
import GoAwayModal from "src/grading/components/GoAwayModal/GoAwayModal";
import LiveTestStepper from "src/grading/components/LiveTestStepper/LiveTestStepper";
import LiveTestLayout from "src/grading/layout/LiveTestLayout/LiveTestLayout";
import { getExerciseStepNumber } from "src/grading/utils/testHelpers";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  LiveTestStep,
  liveTestRoutes,
  resetLiveTestSlice,
  selectCurrentLiveTest,
  selectLiveTestStep,
} from "src/store/reducers/liveTest/liveTestSlice";
import CertifyTest from "./CertifyTest/LiveTestCertify";
import GradeLiveTest from "./GradeLiveTest/GradeLiveTest";
import LiveTestOverview from "./LiveTestOverview/LiveTestOverview";
import LiveTestResultsOverview from "./LiveTestResultsOverview/LiveTestResultsOverview";
import LiveTestSign from "./LiveTestSign/LiveTestSign";
import SelectAthlete from "./SelectAthlete/SelectAthlete";

const LiveTest = (props: any) => {
  const liveTestStep = useAppSelector(selectLiveTestStep);
  const [users, setUsers] = useState<Profile[]>([]);
  const [showGoAwayModal, setShowGoAwayModal] = useState(false);
  const [newLocation, setNewLocation] = useState("");
  const [canNavigateAway, setCanNavigateAway] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const [yOffset, setYOffset] = useState(0);
  const [withGradingHeaderInLayout, setWithGradingHeaderInLayout] =
    useState<boolean>(true);

  useEffect(() => {
    if (liveTestStep === LiveTestStep.SELECT_TEST) {
      history.push(liveTestRoutes.SELECT_LIVE_TEST);
    }
    const handleBeforeUnload = (event: any) => {
      event.preventDefault();
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const currentLiveTest = useAppSelector(selectCurrentLiveTest);

  const testSteps = getExerciseStepNumber(currentLiveTest?.steps || []);

  const handleNavigationGoAway = (location: LocationType) => {
    const pattern = /^\/grading\/live\/([^/]+\/){1,}[^/]*$/;
    const isNextRouteInsideCurrentLiveTest = pattern.test(location.pathname);

    if (
      !canNavigateAway && // if we can't navigate away
      !isNextRouteInsideCurrentLiveTest // and the next route is not inside the current live test
    ) {
      setShowGoAwayModal(true);

      setNewLocation(location.pathname);
      return false;
    }
    return true;
  };

  useEffect(() => {
    return () => {
      if (canNavigateAway && newLocation) {
        dispatch(resetLiveTestSlice());
      }
    };
  });

  useEffect(() => {
    if (
      yOffset !== 0 &&
      liveTestStep === LiveTestStep.GRADING_STEPS + testSteps
    ) {
      window.scrollTo(0, yOffset);
      setYOffset(0);
    }
  }, [yOffset, liveTestStep, testSteps]);

  return (
    <LiveTestLayout withGradingHeaderInLayout={withGradingHeaderInLayout}>
      {liveTestStep === LiveTestStep.SELECT_ATHLETE && (
        <SelectAthlete
          users={users}
          setUsers={setUsers}
          setWithGradingHeaderInLayout={setWithGradingHeaderInLayout}
          withGradingHeaderInLayout={withGradingHeaderInLayout}
        />
      )}

      {liveTestStep === LiveTestStep.TEST_OVERVIEW && (
        <LiveTestOverview
          users={users}
          setCanNavigateAway={setCanNavigateAway}
        />
      )}

      {liveTestStep > LiveTestStep.TEST_OVERVIEW &&
        liveTestStep < LiveTestStep.GRADING_STEPS + testSteps && (
          <GradeLiveTest
            users={users}
            setCanNavigateAway={setCanNavigateAway}
          />
        )}

      {liveTestStep === LiveTestStep.GRADING_STEPS + testSteps && (
        <LiveTestResultsOverview
          setCanNavigateAway={setCanNavigateAway}
          yOffset={yOffset}
          setYOffset={setYOffset}
        />
      )}

      {liveTestStep === LiveTestStep.SIGN_STEP + testSteps && (
        <LiveTestSign yOffset={yOffset} />
      )}

      {liveTestStep === LiveTestStep.CERTIFIY_STEP + testSteps && (
        <CertifyTest setCanNavigateAway={setCanNavigateAway} />
      )}

      <Prompt
        when={liveTestStep > LiveTestStep.SELECT_ATHLETE}
        message={handleNavigationGoAway}
      />

      <GoAwayModal
        open={showGoAwayModal}
        onClose={() => setShowGoAwayModal(false)}
        message="You are currently in the middle of a test. Are you sure you want to
        navigate away?"
        newLocation={newLocation}
        onNavigateAway={() => {
          setCanNavigateAway(true);
        }}
        canNavigateAway={canNavigateAway}
      />
    </LiveTestLayout>
  );
};

export default LiveTest;
