import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  profileIconContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  profileIcon: {
    display: "flex",
    width: theme.spacing(9),
    height: theme.spacing(9),
    padding: theme.spacing(0.1),
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.dark}`,
  },
  profileText: {
    marginLeft: theme.spacing(2.5),
  },
}));

export default useStyles;
