import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import "./grading.css";
import { selectCurrentUser } from "../store/reducers/auth/authSlice";
import { GroupPage } from "./groups/groupPage";
import MainLayout from "./layout/MainLayout";
import Admin from "./pages/Admin/Admin";
import DemoTests from "./pages/Admin/DemoTests/DemoTests";
import AthleteDetails from "./pages/Athletes/AthleteDetails/AthleteDetails";
import Athletes from "./pages/Athletes/Athletes";
import { CertifiedTest } from "./pages/CertifiedTest/CertifiedTest";
import { GradeTest } from "./pages/GradeTest/GradeTest";
import LiveTest from "./pages/LiveTest/LiveTest";
import SelectTestForLiveTest from "./pages/LiveTest/SelectTestForLiveTest/SelectTestForLiveTest";
import MemberDetails from "./pages/Organization/MemberDetails/MemberDetails";
import Organization from "./pages/Organization/Organization";
import UnitDetails from "./pages/Organization/UnitDetails/UnitDetails";
import LiveTestReviewCertify from "./pages/TestGrid/LiveTestReviewCertify/LiveTestReviewCertify";
import { TestGrid } from "./pages/TestGrid/TestGrid";
import PrivateRoute from "./privateRoute";
import GradingRoutes from "./routes/GradingRoutes";
import { UserPage } from "./userPage";

export function GradingHome(props) {
  const match = useRouteMatch();
  const [error, setError] = useState("");

  const user = useSelector(selectCurrentUser);

  return (
    <div className="grading-view">
      <div className="grading-body">
        <div className="grading-content">
          <Switch>
            <PrivateRoute path={`${match.path}/pending`}>
              <MainLayout>
                <TestGrid certified="pending" />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/certified/live-test/:testId`}>
              <MainLayout>
                <LiveTestReviewCertify />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/certified`}>
              <MainLayout>
                <TestGrid certified="true" />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/live/:testId/:branch`}>
              <MainLayout>
                <LiveTest />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/live`}>
              <MainLayout>
                <SelectTestForLiveTest />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/all`}>
              <TestGrid />
            </PrivateRoute>
            <PrivateRoute
              path={`${match.path}/tests/:userId/:testId/certified`}
            >
              <MainLayout>
                <CertifiedTest />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/tests/:userId/:testId`}>
              <MainLayout>
                <GradeTest />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/users/:userId`}>
              <UserPage />
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/groups`}>
              <GroupPage />
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/organization/units/:unitId`}>
              <MainLayout>
                <UnitDetails />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/organization/members/:memberId`}>
              <MainLayout>
                <MemberDetails />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/organization`}>
              <MainLayout>
                <Organization />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/admin/demo-tests`}>
              <MainLayout>
                <DemoTests />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/admin`}>
              <MainLayout>
                <Admin />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/athletes/:athleteId`}>
              <MainLayout>
                <AthleteDetails />
              </MainLayout>
            </PrivateRoute>
            <PrivateRoute path={`${match.path}/athletes`}>
              <MainLayout>
                <Athletes />
              </MainLayout>
            </PrivateRoute>
            <Route path={`${match.path}`}>
              {user === undefined ? (
                <Spinner
                  animation="border"
                  style={{
                    left: "50%",
                    top: "50%",
                    position: "absolute",
                    marginLeft: -8,
                  }}
                />
              ) : user === null ? (
                <GradingRoutes error={error} setError={setError} />
              ) : (
                <MainLayout>
                  <TestGrid certified="pending" />
                </MainLayout>
              )}
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}
