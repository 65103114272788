import React, { useEffect, useState } from "react";
import { useAppSelector } from "src/store/hooks";
import {
  selectGradingTest,
  selectGradingTestMetadata,
} from "src/store/reducers/grading/gradingSlice";
import { TestStepDefinitionFormat } from "src/store/reducers/tests/testsApiSlice";

const useStepMetadata = (step: number) => {
  const testMetadata = useAppSelector(selectGradingTestMetadata);
  const [stepMetadata, setStepMetadata] = useState<
    TestStepDefinitionFormat | undefined
  >();
  const test = useAppSelector(selectGradingTest);

  useEffect(() => {
    const getStepMetadata = () => {
      let stepMetadata = testMetadata?.steps.find((sm: any) => {
        if (sm.choices && sm.choices?.length > 0) {
          const choiceFound = sm.choices.find((choice: any) => {
            return choice.exerciseId === test?.exercises[step].exerciseId;
          });
          return choiceFound;
        } else {
          return sm.exerciseId === test?.exercises[step].exerciseId;
        }
      });

      if (stepMetadata?.choices) {
        stepMetadata = stepMetadata.choices.find((choice: any) => {
          return choice.exerciseId === test?.exercises[step].exerciseId;
        });
      }
      setStepMetadata(stepMetadata);
    };
    if (step > -1 && test && step < test.exercises.length) {
      getStepMetadata();
    }
  }, [step, testMetadata, test?.exercises, test, stepMetadata]);

  return {
    stepMetadata,
  };
};

export default useStepMetadata;
