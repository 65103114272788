import { Grid, TextField } from "@mui/material";
import { CardioExerciseResultMetadata } from "@readyfit-common/models";
import GpxParser, { Point } from "gpxparser";
import { useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { MapContainer, Polyline, TileLayer } from "react-leaflet";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useLazyGetFileUrlQuery } from "src/store/reducers/documents/documentsApiSlice";
import { ExerciseFormat } from "src/store/reducers/tests/testsApiSlice";
import { ApprovalButton } from "../../approvalButton";
import { ExerciseChecklist } from "../../exerciseChecklist";
import { CardioApprovalGradeResult, GradeResult } from "../../models";
import {
  CalculateSplits,
  CalculateTerminalPos,
  Split,
  convertDistance,
  kmToMile,
  stringFromTime,
} from "../../utils";
import useStyles from "./GradeTestExerciseCardio.styles";

interface MotionData {
  steps?: {
    time: number;
    stepsPerMinute: number;
  }[];
}

export function GradeTestExerciseCardio(props: GradeTestExerciseCardioProps) {
  const [gpxData, setGpxData] = useState<GpxParser | "pending" | "not_started">(
    "not_started",
  );
  const [motionData, setMotionData] = useState<
    MotionData | "pending" | "not_started"
  >("not_started");
  const classes = useStyles();

  const [getFileUrl] = useLazyGetFileUrlQuery();

  if (gpxData === "not_started") {
    setGpxData("pending");
    getFileUrl(props.fileKey)
      .unwrap()
      .then((response) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = (event) => {
          const parser = new GpxParser();
          parser.parse(xhr.response);
          setGpxData(parser);
        };
        xhr.open("GET", response.url);
        xhr.send();
      });
  }

  if (motionData === "not_started") {
    setMotionData("pending");
    getFileUrl(`${props.fileKey}-motion`)
      .unwrap()
      .then((response) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = (event) => {
          setMotionData(JSON.parse(xhr.response));
        };
        xhr.open("GET", response.url);
        xhr.send();
      })
      .catch(() => setMotionData({}));
  }

  if (gpxData === "pending" || gpxData === "not_started") {
    return (
      <Spinner
        animation="border"
        style={{ left: "50%", position: "relative" }}
      />
    );
  } else {
    let positions: [number, number][] = [];
    let gradedPositions = null;
    let computedEndPoint: Point = { time: new Date(), lat: 0, lon: 0, ele: 0 };
    let startPoint: Point = { time: new Date(), lat: 0, lon: 0, ele: 0 };
    let activityEndPoint: Point = { time: new Date(), lat: 0, lon: 0, ele: 0 };
    let computedDistance = 0;
    let totalDist = 0;
    let netElevationGain = 0;
    let pacePerMile = 0;
    let goalDist = 0;
    let splits: Split[] = [];
    let graphSplits: Split[] = [];
    let computedMinutes = 0;
    if (gpxData.tracks[0]) {
      positions = gpxData.tracks[0].points.map((p) => [p.lat, p.lon]);
      goalDist = convertDistance(
        props?.stepMetadata?.testing?.distance?.value ?? 1,
        props?.stepMetadata?.testing?.distance?.units ?? "miles",
        "meters",
      );
      const [termPos, termDistKm] = CalculateTerminalPos(positions, goalDist);

      if (termPos !== positions.length) {
        gradedPositions = positions.slice(0, termPos);
      }

      let splitDist = 1;
      if (goalDist <= 3218.69) {
        splitDist = 0.401336;
      } else if (goalDist <= 4828.032) {
        splitDist = 0.804772;
      } else {
        splitDist = 1608.34;
      }
      splits = CalculateSplits(
        gpxData.tracks[0].points.slice(0, termPos),
        splitDist,
      );
      graphSplits = CalculateSplits(
        gpxData.tracks[0].points.slice(0, termPos),
        0.1,
      );

      computedDistance =
        Math.round(
          (props.exercise.units === "miles"
            ? kmToMile(termDistKm)
            : termDistKm / 1000) * 100,
        ) / 100;
      totalDist = Math.round(parseFloat(props.exercise.distance) * 100) / 100;
      computedEndPoint =
        gradedPositions !== null
          ? gpxData.tracks[0].points[termPos]
          : gpxData.tracks[0].points[positions.length - 1];
      if (!computedEndPoint)
        computedEndPoint = { time: new Date(), lat: 0, lon: 0, ele: 0 };
      activityEndPoint =
        gpxData.tracks[0].points[gpxData.tracks[0].points.length - 1];
      if (!activityEndPoint)
        activityEndPoint = { time: new Date(), lat: 0, lon: 0, ele: 0 };
      startPoint = gpxData.tracks[0].points[0];
      if (!startPoint)
        startPoint = { time: new Date(), lat: 0, lon: 0, ele: 0 };
      computedMinutes =
        (Math.abs(computedEndPoint.time.valueOf() - startPoint.time.valueOf()) *
          0.001) /
        60;
      pacePerMile =
        Math.round((computedMinutes / computedDistance) * 100) / 100;
      netElevationGain =
        gpxData.tracks[0].elevation.pos - gpxData.tracks[0].elevation.neg;
    }

    return (
      <Grid container>
        <h1>{props.exercise.name}</h1>
        <MapContainer
          center={positions[Math.round(positions.length / 2)]}
          zoom={15}
          scrollWheelZoom={false}
          className={classes.gradingMap}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          <Polyline
            pathOptions={{ fillColor: "red", color: "red" }}
            positions={positions}
          />
          {gradedPositions !== null && (
            <Polyline
              pathOptions={{ fillColor: "red", color: "green" }}
              positions={gradedPositions}
            />
          )}
        </MapContainer>
        {/* <div className="grading-step-sidebar">
          <Card className="grading-step-card">
            <CardContent>
              <div className="grading-step-header">
                Calculated run time <br />
                <h1>
                  {GetRestTime(
                    computedEndPoint.time.valueOf(),
                    startPoint.time.valueOf(),
                  )}
                </h1>
                Distance (in {props.exercise.units}) <br />
                <h1>{computedDistance.toFixed(1)}</h1>
              </div>
              <div className="grading-step-info">
                <div className="grading-step-item">
                  <span>Total run time</span>
                  <span>
                    <b>
                      {GetRestTime(
                        activityEndPoint.time.valueOf(),
                        startPoint.time.valueOf(),
                      )}
                    </b>
                  </span>
                </div>
                <div className="grading-step-item">
                  <span>Total distance completed ({props.exercise.units})</span>
                  <span>
                    <b>{totalDist}</b>
                  </span>
                </div>
                <div className="grading-step-item">
                  <span>Total elevation change (meters)</span>
                  <span>
                    <b>{netElevationGain.toFixed(1)}</b>
                  </span>
                </div>
                <div className="grading-step-item">
                  <span>Pace per {props.exercise.units}</span>
                  <span>
                    <b>
                      {("00" + Math.floor(pacePerMile)).slice(-2)}:
                      {("00" + Math.floor((pacePerMile % 1) * 60)).slice(-2)}
                    </b>
                  </span>
                </div>
                <div className="grading-step-item">
                  <span>Rest time taken</span>
                  <span>
                    <b>
                      {GetRestTime(props.prevEnd, props.exercise.startTime)}
                    </b>
                  </span>
                </div>
              </div>
              <div className="grading-step-info">
                <div className="grading-step-item">
                  <span>Splits</span>
                </div>
                {splits.map((split, i) => {
                  if (split.splitDistanceKm > 0.1) {
                    return (
                      <div key={i} className="grading-step-item">
                        <span>
                          {Math.round(kmToMile(split.totalDistanceKm) * 10) /
                            10}{" "}
                          mile
                        </span>
                        <span>
                          <b>
                            {stringFromTime({
                              minutes: 0,
                              seconds: split.splitTimeSeconds,
                            })}
                          </b>
                        </span>
                      </div>
                    );
                  } else {
                    return <></>;
                  }
                })}
              </div>
            </CardContent>
          </Card>
        </div> */}
        {goalDist > 1608 && (
          <ResponsiveContainer height={300} width={"100%"}>
            <LineChart
              // width={850}
              // height={300}
              data={graphSplits.map((split) => {
                const miles =
                  Math.round(kmToMile(split.totalDistanceKm) * 100) / 100;
                const splitMiles = kmToMile(split.splitDistanceKm);
                const splitMinutes = split.splitTimeSeconds / 60;
                return {
                  miles,
                  pace: Math.round((splitMinutes / splitMiles) * 1000) / 1000,
                };
              })}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                label="Mile"
                dataKey="miles"
                interval="preserveEnd"
                tickCount={3}
                hide={true}
              />
              <YAxis
                type="number"
                domain={([dataMin, dataMax]) => {
                  return [Math.round(dataMin - 3), Math.round(dataMax + 3)];
                }}
              />
              <Tooltip
                formatter={(value, name) =>
                  stringFromTime({
                    minutes: Math.floor(value as number),
                    seconds: Math.round(((value as number) % 1) * 60),
                  })
                }
              />
              <Legend
                verticalAlign="top"
                wrapperStyle={{ lineHeight: "40px" }}
              />
              <ReferenceLine y={0} stroke="#000" />
              <Line
                name="Pace per mile"
                type="monotone"
                dataKey="pace"
                stroke="#3d544c"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
        {goalDist > 1608 && (
          <ResponsiveContainer height={300} width={"100%"}>
            <LineChart
              // width={850}
              // height={850}
              data={gpxData.tracks[0].points.map((point) => ({
                ele: point.ele,
              }))}
            >
              <XAxis dataKey="miles" interval="preserveStart" hide={true} />
              <YAxis
                type="number"
                domain={[
                  (dataMin: any) => Math.round(dataMin - 15),
                  (dataMax: any) => Math.round(dataMax + 15),
                ]}
              />
              <Tooltip />
              <Legend
                verticalAlign="top"
                wrapperStyle={{ lineHeight: "40px" }}
              />
              <ReferenceLine y={0} stroke="#000" />
              <Line
                name="Elevation"
                type="monotone"
                dataKey="ele"
                stroke="#3d544c"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        )}
        {/* {props.stepMetadata !== undefined && (
          <ExerciseChecklist
            stepMetadata={props.stepMetadata}
            exerciseName={props.exercise.name}
            onChange={(selections) => {
              const currentGrade = {
                ...props.currentGrade,
                userResult: {
                  ...props.currentGrade.userResult,
                  fileKey: props.fileKey,
                },
                discrepancies: selections,
              };
              props.onGrade(currentGrade);
            }}
          />
        )} */}
        {/* <TextField
          label="Notes"
          multiline
          fullWidth
          variant="outlined"
          helperText="Grading notes will be visible to the test-taker"
          value={props.currentGrade.notes}
          onChange={(event) => {
            const currentGrade = {
              ...props.currentGrade,
              userResult: {
                ...props.currentGrade.userResult,
                fileKey: props.fileKey,
              },
              notes: event.target.value,
            };
            props.onGrade(currentGrade);
          }}
        /> */}
        {/* <ApprovalButton
          onClick={(status) => {
            const userResult = {
              ...props.currentGrade.userResult,
              fileKey: props.fileKey,
            };
            userResult.distance = computedDistance.toFixed(1).toString();
            userResult.endTime = new Date(
              +userResult.startTime + computedMinutes * 60000,
            ).valueOf();
            const currentGrade = {
              ...props.currentGrade,
              approved: status,
              userResult,
            };
            props.onGrade(currentGrade);
          }}
          default={props.currentGrade.approved}
        /> */}
        {motionData === "pending" || motionData === "not_started" ? (
          <Spinner
            animation="border"
            style={{ left: "50%", position: "relative" }}
          />
        ) : motionData.steps ? (
          <ResponsiveContainer height={300} width={"100%"}>
            <LineChart
              data={motionData.steps.map((point) => ({
                stepsPerMinute: point.stepsPerMinute,
              }))}
            >
              <XAxis dataKey="time" interval="preserveStart" hide={true} />
              <YAxis
                type="number"
                domain={[
                  (dataMin: any) => Math.round(dataMin - 15),
                  (dataMax: any) => Math.round(dataMax + 15),
                ]}
              />
              <Tooltip />
              <Legend
                verticalAlign="top"
                wrapperStyle={{ lineHeight: "40px" }}
              />
              <ReferenceLine y={0} stroke="#000" />
              <Line
                name="Steps per Minute"
                type="monotone"
                dataKey="stepsPerMinute"
                stroke="#3d544c"
                dot={false}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : null}
      </Grid>
    );
  }
}

type GradeTestExerciseCardioProps = {
  exercise: CardioExerciseResultMetadata;
  prevEnd: number | null;
  currentGrade: CardioApprovalGradeResult;
  onGrade: (stepGrade: GradeResult) => void;
  stepMetadata: ExerciseFormat | undefined;
  fileKey: string | undefined;
};
