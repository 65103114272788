import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required("First Name is required"),
  lastName: Yup.string().required("Last Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  gender: Yup.string().required("Gender is required"),
  birthday: Yup.string().required("Birthday is required"),
  phone: Yup.string().required("Phone is required"),
  branchOfService: Yup.string().required("Unit is required"),
});

export default validationSchema;
