import { TabContext, TabList } from "@mui/lab";
import { Box, Divider, Grid, Tab, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import OrganizationLayout from "src/grading/layout/OrganizationLayout/OrganizationLayout";
import { DEFAULT_ORGANIZATION } from "src/grading/models";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import {
  setMembers,
  setRoles,
  setUnits,
} from "src/store/reducers/organizations/organizationSlice";
import {
  useLazyGetMembersQuery,
  useLazyGetRolesQuery,
  useLazyGetUnitsQuery,
} from "src/store/reducers/organizations/organizationsApiSlice";
import theme from "src/theme";
import MembersTab from "./MembersTab/MembersTab";
import useStyles from "./Organization.styles";
import UnitsTab from "./UnitsTab/UnitsTab";

enum TabValues {
  Members = "0",
  Units = "1",
}

const Organization = () => {
  const classes = useStyles();

  const [isLoading, setIsLoading] = useState(true);

  const [tabValue, setTabValue] = useState(TabValues.Members);

  const user = useSelector(selectCurrentUser);

  const [getRoles] = useLazyGetRolesQuery();

  const [getUnits] = useLazyGetUnitsQuery();

  const [getMembers] = useLazyGetMembersQuery();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const [roles, units, members] = await Promise.all([
        getRoles().unwrap(),
        getUnits({
          organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
        }).unwrap(),
        getMembers({
          organizationId: user.organizationId ?? DEFAULT_ORGANIZATION.id,
        }).unwrap(),
      ]);

      dispatch(setRoles(roles));
      dispatch(setUnits(units));
      dispatch(setMembers(members));

      setIsLoading(false);
    };

    fetchData();
  }, [getRoles, getUnits, getMembers, dispatch, user.organizationId]);

  return isLoading ? (
    <Spinner
      animation="border"
      style={{ left: "50%", top: "50%", position: "relative" }}
    />
  ) : (
    <OrganizationLayout>
      {/* PAGE TITLE */}
      <Grid item xs={12} mt={1}>
        <Typography variant="h2">Organization</Typography>
      </Grid>

      {/* PAGE BANNER */}
      <Grid item container xs={12} className={classes.bannerContainer}>
        <Grid
          container
          item
          flex={1}
          alignItems={"center"}
          ml={theme.spacing(4)}
        >
          <Grid container item flexDirection={"column"}>
            <Typography
              color={theme.palette.primary.contrastText}
              variant={"subtitle1"}
              textTransform={"uppercase"}
            >
              Name
            </Typography>
            <Typography
              color={theme.palette.primary.contrastText}
              variant={"h3"}
              textTransform={"uppercase"}
            >
              {user?.organization?.name ?? DEFAULT_ORGANIZATION.name}
            </Typography>
          </Grid>
        </Grid>

        <Divider orientation={"vertical"} className={classes.divider} />

        <Grid
          container
          item
          xs={2}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Typography
            color={theme.palette.primary.contrastText}
            variant={"subtitle1"}
            textTransform={"uppercase"}
          >
            Branch
          </Typography>
          <Typography
            color={theme.palette.primary.contrastText}
            variant={"h4"}
            textTransform={"uppercase"}
          >
            {user?.organization?.branch ?? DEFAULT_ORGANIZATION.branch}
          </Typography>
        </Grid>
      </Grid>

      {/* TABS */}
      <Grid item container xs={12}>
        <Box className={classes.tabsContainer}>
          <TabContext value={tabValue}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                onChange={(event, newValue) => setTabValue(newValue)}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  style: {
                    color: "white",
                    height: "100%",
                  },
                  className: classes.tabIndicator,
                }}
              >
                <Tab
                  label="Members"
                  className={classes.tab}
                  value={TabValues.Members}
                />
                <Tab
                  label="Units"
                  className={classes.tab}
                  value={TabValues.Units}
                />
              </TabList>
            </Box>
          </TabContext>
        </Box>

        {tabValue === TabValues.Units ? <UnitsTab /> : <MembersTab />}
      </Grid>
    </OrganizationLayout>
  );
};

export default Organization;
