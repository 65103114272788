/* eslint-disable react-hooks/exhaustive-deps */
import { Collapse, Grid, Theme, useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ReactNode, useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectShowDrawer,
  showDrawer,
} from "src/store/reducers/navigation/navigationSlice";
import theme from "src/theme";
import { AppDrawer } from "../components";

interface MainLayoutProps {
  children: ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  mainView: {
    width: "0rem",
    flexGrow: 1,
  },
}));

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const tabletWidth = theme.breakpoints.values.md;

  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
      if (window.innerWidth <= tabletWidth) {
        dispatch(showDrawer(false));
      }
    }
    window.addEventListener("resize", updateSize);

    updateSize();

    return () => window.removeEventListener("resize", updateSize);
  }, []);
  return size;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  const classes = useStyles();
  const drawerVisible = useAppSelector(selectShowDrawer);

  useWindowSize();

  const drawer = (
    <Grid
      component={Collapse}
      orientation={"horizontal"}
      item
      in={drawerVisible}
      collapsedSize={"5rem"}
      style={{
        height: "100vh",
        position: "sticky",
        top: theme.spacing(1),
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(1),
      }}
    >
      <AppDrawer />
    </Grid>
  );

  return (
    <>
      <Grid item container gap={1}>
        {drawer}
        <Grid item container direction={"column"} className={classes.mainView}>
          <Grid style={{ height: "100%" }}>{children}</Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MainLayout;
