import { Grid } from "@mui/material";
import { ReactNode } from "react";

interface OrganizationLayoutInterface {
  children: ReactNode;
}

export default function OrganizationLayout({
  children,
}: OrganizationLayoutInterface) {
  return <Grid container>{children}</Grid>;
}
