import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  gradingStepMain: {
    width: "100%",
    display: "inline-block",
    minHeight: "415px",
    maxHeight: "415px",
    "& video": {
      maxHeight: "inherit",
    },
    "&::before": {
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      content: "var(--video-timestamp)",
      fontFamily: "courier, monospace",
      fontSize: "12px",
      fontWeight: "bold",
      margin: "5px",
      outline: "#000000 solid 1px",
      position: "absolute",
      padding: "2px 5px 0 5px",
    },
  },
  gradingInputs: {
    width: "100%",
  },
  userNotesContainer: {
    display: "flex",
    flexDirection: "column",
  },
  userNotesHeader: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    backgroundColor: "#F0F0F0",
    borderRadius: "4px",
    fontWeight: "bold",
  },
}));

export default useStyles;
