import { Checkbox, FormControlLabel, Grid, Typography } from "@mui/material";
// import { useState } from "react";
import { usePermissions } from "src/grading/hooks/usePermissions";
import { permissions } from "src/grading/utils/permissionsHelpers";
import useStyles from "../RecruitmentProcessWidget.styles";

export const RecruitmentProcessCheckBox = ({
  id,
  label,
  checked,
  important,
  border,
  update,
}: {
  id: string;
  label: string;
  checked: boolean;
  important?: boolean;
  border?: boolean;
  update?: (key: string, value: boolean) => Promise<void>;
}) => {
  const classes = useStyles();
  const { hasPermissions } = usePermissions();

  const handleCheckboxChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    update && update(id, event.target.checked);
  };

  return (
    <Grid
      container
      className={classes.checkboxContainer}
      sx={
        border
          ? {
              borderBottom: 1,
              borderColor: "#DBDBDB",
            }
          : null
      }
    >
      <FormControlLabel
        label={
          <Grid container>
            <Typography variant="caption" fontSize={14}>
              {label}
            </Typography>
            <Typography color={"red"}>{important && "*"}</Typography>
          </Grid>
        }
        labelPlacement="start"
        control={
          <Checkbox
            className={classes.checkbox}
            checked={checked}
            onChange={handleCheckboxChange}
            disabled={
              !hasPermissions(
                permissions.UPDATE_ATHLETE_RP.action,
                permissions.UPDATE_ATHLETE_RP.subject,
              )
            }
          />
        }
        className={classes.checkboxLabel}
      />
    </Grid>
  );
};
