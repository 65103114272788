import { CardioUnits } from "@readyfit-common/models";
import { apiSlice } from "src/store/apiSlice";

export interface TestDefinitionAsset {
  name?: string;
  uri?: string;
}

export interface TestDefinitionPropertyOption {
  value: string;
  displayName: string;
}

export interface TestDefinitionProperty {
  key: string;
  displayName: string;
  options: TestDefinitionPropertyOption[];
}

export interface ExerciseResultRange {
  min?: number | string;
  max?: number | string;
}

export interface ExerciseInfo {
  hidden?: boolean;
  subtitle?: string;
  descriptionFormat?: string;
  demoVideo?: TestDefinitionAsset;
  instructionVideo?: TestDefinitionAsset;
  instructions?: string[];
  gradingCriteria?: string[];
  resultRange?: ExerciseResultRange;
}

export interface RestTimeFormat {
  minSeconds?: number;
  maxSeconds?: number;
}

export interface ExerciseUserEnteredResult {
  type?: string;
  label?: string;
  unit?: string;
  minimumValue?: number | string;
  maximumValue?: number | string;
  step?: number | string;
}

export interface ExerciseLaps {
  count?: number;
  text?: string[];
}

export interface ExerciseDistance {
  value: number;
  units: CardioUnits;
}

export interface ExerciseTesting {
  restTime?: RestTimeFormat;
  countdownSeconds?: number;
  maxDurationSeconds?: number;
  timerFormat?: string;
  audioCues?: string[];
  userEnteredResult?: ExerciseUserEnteredResult;
  cameraMode?: string;
  laps?: ExerciseLaps;
  distance?: ExerciseDistance;
}

export interface ExerciseResultsConfigFormat {
  exerciseCategory?: string;
  resultDescriptionFormat: string;
  scorecardStyle?: string;
}

export interface ExerciseFormat {
  exerciseId?: string;
  displayName?: string;
  exerciseType?: string;
  info?: ExerciseInfo;
  testing?: ExerciseTesting;
  results?: ExerciseResultsConfigFormat;
}

export interface TestStepDefinitionFormat {
  stepDescription?: string;
  exerciseId?: string;
  displayName?: string;
  exerciseType?: ExerciseType;
  info?: ExerciseInfo;
  testing?: ExerciseTesting;
  results?: ExerciseResultsConfigFormat;
  choices?: ExerciseFormat[];
}

export type ExerciseType =
  | string
  | "cardio"
  | "reps"
  | "bodyComp"
  | "maxTime"
  | "minTime";

export interface TestDefinitionInfoType {
  displayName: string;
  branch: string;
  buttonImage: TestDefinitionAsset;
  bannerImage: TestDefinitionAsset;
  overviewVideo?: TestDefinitionAsset;
  completionVideo?: TestDefinitionAsset;
  estimatedTime?: string;
  equipment?: string;
  description: string;
}

export interface TestDefinitionFormat {
  testId: string;
  version: number;
  isDemo?: boolean;
  infos: TestDefinitionInfoType[];
  properties?: TestDefinitionProperty[];
  steps: TestStepDefinitionFormat[];
}

export function isChoice(step: TestStepDefinitionFormat): boolean {
  return step.choices !== undefined;
}

export function isExerciseStep(step: TestStepDefinitionFormat): boolean {
  return step.exerciseType !== undefined;
}

export const testsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTestDefinitionById: builder.query({
      query: (params) => {
        const { id, version = 1 } = params;
        return {
          url: `/api/test/test-definitions/${id}/${version}?format=grading`,
          method: "GET",
        };
      },
      transformResponse: (response: {
        data: { testDefinition: TestDefinitionFormat };
      }) => response.data,
    }),
    getAllTestDefinitions: builder.query<
      { data: { testDefinitions: TestDefinitionFormat[] } },
      void
    >({
      query: (params) => {
        return {
          url: `/api/test/test-definitions?format=grading`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetTestDefinitionByIdQuery,
  useLazyGetTestDefinitionByIdQuery,
  useGetAllTestDefinitionsQuery,
  useLazyGetAllTestDefinitionsQuery,
} = testsApiSlice;
