import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type StyleProps = {
  mini: boolean;
};

const useStyles = ({ mini }: StyleProps) =>
  makeStyles((theme: Theme) => ({
    stepperContainer: {
      padding: `${theme.spacing(3)} ${theme.spacing(5.25)}`,
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: "10px",
      boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.15)",
    },
    button: {
      width: mini ? theme.spacing(12) : theme.spacing(21),
      height: theme.spacing(5),
      borderRadius: theme.spacing(1),
    },
    backButton: {
      backgroundColor: "#fff",
      color: "black",
      "&:hover": {
        backgroundColor: "#fff",
        color: "black",
        opacity: 0.6,
      },
    },
    nextButton: {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#000",
    },
  }));

export default useStyles;
