import { Menu } from "@mui/material";
import React from "react";

interface RFMenuProps {
  children: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  anchorEl: null | HTMLElement;
}

const RFMenu = ({ children, open, handleClose, anchorEl }: RFMenuProps) => {
  return (
    <div>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              width: "fit-content",
            },
          },
        }}
      >
        {children}
      </Menu>
    </div>
  );
};

export default RFMenu;
