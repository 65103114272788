import {
  PauseOutlined,
  PlayArrowOutlined,
  StopOutlined,
} from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const Stopwatch = () => {
  const [time, setTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isRunning) {
      intervalId = setInterval(() => setTime(time + 100), 1000);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, time]);

  // Hours calculation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const hours = Math.floor(time / 360000);

  // Minutes calculation
  const minutes = Math.floor((time % 360000) / 6000);

  // Seconds calculation
  const seconds = Math.floor((time % 6000) / 100);

  // Milliseconds calculation
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const milliseconds = time % 100;

  // Method to start and stop timer
  const startAndStop = () => {
    setIsRunning(!isRunning);
  };

  // Method to reset timer back to 0
  const reset = () => {
    setTime(0);
    setIsRunning(!isRunning);
  };
  return (
    <Grid
      container
      xs={12}
      flex={1}
      justifyContent={"center"}
      alignItems={"center"}
    >
      <Grid
        item
        container
        xs={2.5}
        bgcolor={"#3F4E57"}
        justifyContent={"center"}
        height={"100%"}
        borderRadius={1}
      >
        <IconButton onClick={startAndStop}>
          {isRunning ? (
            <PauseOutlined
              style={{
                color: "white",
                fontSize: 35,
              }}
            />
          ) : (
            <PlayArrowOutlined
              style={{
                color: "white",
                fontSize: 35,
              }}
            />
          )}
        </IconButton>
      </Grid>
      <Grid
        item
        container
        flex={1}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        <Typography
          variant={"subtitle1"}
          textTransform={"uppercase"}
          color={isRunning ? "white" : "#8f8f8f"}
        >
          Rest Timer
        </Typography>
        <Typography variant={"h2"} color={isRunning ? "white" : "#8F8F8F"}>
          {minutes.toString().padStart(2, "0")}:
          {seconds.toString().padStart(2, "0")}
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={2.5}
        bgcolor={"#3F4E57"}
        justifyContent={"center"}
        height={"100%"}
      >
        <IconButton onClick={reset}>
          <StopOutlined
            style={{
              color: "white",
              fontSize: 35,
            }}
          />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default Stopwatch;
