import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { genderOptions } from "@readyfit-common/models";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { LoadingButton } from "src/grading/components";
import { inputError } from "src/grading/components/InputError/InputError";
import RFModal from "src/grading/components/RFModal/RFModal";
import { useAddNewAthleteMutation } from "src/store/reducers/liveTest/liveTestSlice";
import theme from "src/theme";
import validationSchema from "./AddNewAthlete.validation";
import useStyles from "./AddNewAthleteModal.style";

interface AddNewathleteModalProps {
  open: boolean;
  onClose: () => void;
}

export interface AddNewAthleteFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  branchOfService?: string;
  birthday: string;
  gender: string;
}

const AddNewAthleteModal = (props: AddNewathleteModalProps) => {
  const classes = useStyles();
  const [addNewAthlete, { isLoading }] = useAddNewAthleteMutation();

  const saveAthlete = async (values: AddNewAthleteFormValues) => {
    const result = await addNewAthlete(values).unwrap();

    if (result.success) {
      props.onClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      branchOfService: "",
      birthday: "",
      gender: "",
    },
    validationSchema,
    onSubmit: async (values: AddNewAthleteFormValues) => {
      // const { branchOfService, ...otherValues } = values;
      await saveAthlete(values);
      formik.resetForm();
    },
  });

  const { values, handleSubmit, setFieldValue, handleChange, errors } = formik;

  return (
    <RFModal
      {...props}
      boxProps={{
        className: classes.container,
      }}
    >
      <Grid
        container
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <Typography variant="h5">Add New Athlete</Typography>

        <Grid container item marginTop={theme.spacing(4)} spacing={2}>
          <Grid item xs={12} md={6}>
            <InputLabel className={classes.inputLabel}>First Name</InputLabel>
            <TextField
              InputProps={{
                className: classes.input,
              }}
              variant="outlined"
              fullWidth
              value={values.firstName}
              onChange={handleChange("firstName")}
              error={
                inputError("firstName", formik.touched, formik.errors).error
              }
              helperText={
                inputError("firstName", formik.touched, formik.errors).display
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputLabel className={classes.inputLabel}>Last Name</InputLabel>
            <TextField
              InputProps={{
                className: classes.input,
              }}
              variant="outlined"
              fullWidth
              value={values.lastName}
              onChange={handleChange("lastName")}
              error={
                inputError("lastName", formik.touched, formik.errors).error
              }
              helperText={
                inputError("lastName", formik.touched, formik.errors).display
              }
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel className={classes.inputLabel}>Gender</InputLabel>
            <Select
              className={classes.input}
              variant="outlined"
              fullWidth
              value={values.gender}
              onChange={(e) => setFieldValue("gender", e.target.value)}
              error={inputError("gender", formik.touched, formik.errors).error}
            >
              {genderOptions.map((genderOption, i) => (
                <MenuItem key={i} value={genderOption}>
                  {genderOption}
                </MenuItem>
              ))}
            </Select>
            {errors?.gender && (
              <FormHelperText
                style={{ marginLeft: 14, color: "red", fontSize: "14px" }}
                error
              >
                {errors?.gender}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={12} md={6}>
            <InputLabel className={classes.inputLabel}>Birthday</InputLabel>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                value={values.birthday}
                onChange={(date) => {
                  if (date) {
                    setFieldValue(
                      "birthday",
                      moment(date).format("YYYY-MM-DD"),
                    );
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    fullWidth
                    error={
                      inputError("birthday", formik.touched, formik.errors)
                        .error
                    }
                    helperText={
                      inputError("birthday", formik.touched, formik.errors)
                        .display
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Email</InputLabel>
            <TextField
              InputProps={{
                className: classes.input,
              }}
              variant="outlined"
              fullWidth
              value={values.email}
              onChange={handleChange("email")}
              error={inputError("email", formik.touched, formik.errors).error}
              helperText={
                inputError("email", formik.touched, formik.errors).display
              }
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Phone</InputLabel>
            <TextField
              InputProps={{
                className: classes.input,
              }}
              variant="outlined"
              fullWidth
              value={values.phone}
              onChange={handleChange("phone")}
              error={inputError("phone", formik.touched, formik.errors).error}
              helperText={
                inputError("phone", formik.touched, formik.errors).display
              }
            />
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Unit</InputLabel>
            <Select
              className={classes.input}
              variant="outlined"
              fullWidth
              value={values.branchOfService}
              onChange={(e) => setFieldValue("branchOfService", e.target.value)}
            >
              <MenuItem value="army">Army</MenuItem>
              <MenuItem value="navy">Navy</MenuItem>
              <MenuItem value="airforce">Air Force</MenuItem>
            </Select>
          </Grid>

          <Grid
            container
            item
            xs={12}
            justifyContent={"space-between"}
            marginTop={theme.spacing(2)}
          >
            <Grid item className={classes.buttonContainer}>
              <Button
                color="secondary"
                className={` ${classes.button} ${classes.backButton}`}
                onClick={props.onClose}
                fullWidth
              >
                Cancel
              </Button>
            </Grid>

            <Grid item className={classes.buttonContainer}>
              <LoadingButton
                variant="contained"
                color="primary"
                className={classes.button}
                loading={isLoading}
                label="Save"
                type="submit"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </RFModal>
  );
};

export default AddNewAthleteModal;
