import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type StyleProps = {
  hasChoices: boolean;
};

const useStyles = ({ hasChoices }: StyleProps) =>
  makeStyles((theme: Theme) => ({
    titleContainer: {
      marginTop: theme.spacing(2),
    },
    stepCount: {
      textTransform: "uppercase",
      backgroundColor: "#D6D6D6",
      color: "#333333",
      borderRadius: "5px",
      marginLeft: theme.spacing(2),
      display: "flex",
      padding: `0 ${theme.spacing(1)}`,
      alignItems: "center",
      justifyContent: "center",
    },
    bannerContainer: {
      marginTop: theme.spacing(5),
      backgroundColor: "#262F34",
      overflow: "hidden",
      borderRadius: theme.spacing(1),
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
    divider: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      border: "1px solid #3F4E57",
    },
    rulesButton: {
      textTransform: "uppercase",
      borderRadius: theme.spacing(1),
      padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
    },
    athletesContainer: {
      marginTop: theme.spacing(4),
    },
    athleteRow: {
      alignItems: "center",
      marginBottom: theme.spacing(1),
      border: `1px solid ${theme.palette.secondary.light}`,
      borderRadius: theme.spacing(1),
      ...(hasChoices && {
        borderTopRightRadius: 0,
      }),
    },
    athleteFirstRow: {
      padding: theme.spacing(2),
    },
    athleteInfoContainer: {
      alignItems: "center",
    },
    standarsContainer: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      marginRight: theme.spacing(2),
    },
    alternateExerciseOptionContainer: {
      borderRadius: theme.spacing(1),
    },
    actionButtonContainer: {
      justifyContent: "space-evenly",
    },
    actionButton: {
      width: theme.spacing(15),
      maxWidth: theme.spacing(15),
      maxHeight: theme.spacing(5.25),
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.primary.main}`,
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
    },
    passButton: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    failButton: {
      borderColor: theme.palette.error.main,
      color: theme.palette.success.contrastText,
    },
    gradeInputsContainer: {
      backgroundColor: "#F2F2F2",
    },
    gradeInputsContainerContent: {
      padding: theme.spacing(2),
    },
    inputLabel: {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "12px",
    },
    alternateExerciseRowContainer: {
      justifyContent: "flex-end",
    },
    alternateExerciseRow: {
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.secondary.light}`,
      backgroundColor: "#FFF",
      justifySelf: "flex-end",
      margin: 0,
      borderBottom: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  }));

export default useStyles;
