import { Profile } from "@readyfit-common/models";
import { Permission } from "src/grading/utils/permissionsHelpers";
import { apiSlice } from "../../apiSlice";

interface LoginResponse {
  accessToken: string;
  refreshToken: string;
}

export interface ForgotPasswordPayload {
  email: string;
  code?: string;
  password?: string;
}

export interface ForgotPasswordResponse {
  success: boolean;
  message: string;
}

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "/api/account/login",
        method: "POST",
        body: { ...credentials },
      }),
      transformResponse: (response: { data: LoginResponse }) => response.data,
    }),
    resetPassword: builder.mutation<
      ForgotPasswordResponse,
      ForgotPasswordPayload
    >({
      query: (credentials) => ({
        url: "/api/account/forgot-password",
        method: "PUT",
        body: { ...credentials },
      }),
    }),
    getUser: builder.query<any, void>({
      query: () => ({
        url: "/api/account/me",
        method: "GET",
      }),
      transformResponse: (response: { data: { profile: Profile } }) =>
        response.data,
    }),
    getPermissions: builder.query<any, void>({
      query: () => ({
        url: "/api/account/me/permissions",
        method: "GET",
      }),
      transformResponse: (response: { data: { permissions: Permission[] } }) =>
        response.data,
    }),
  }),
});

export const {
  useLoginMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useResetPasswordMutation,
  useLazyGetPermissionsQuery,
} = authApiSlice;
