import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  clickable: {
    cursor: "pointer",
  },
  button: {
    margin: "10px",
    width: "100%",
  },
  doubleButton: {
    width: "40%",
  },
  stepCount: {
    textTransform: "uppercase",
    backgroundColor: "#D6D6D6",
    color: "#333333",
    padding: "5px 10px",
    borderRadius: "5px",
  },
  nextButton: {
    backgroundColor: "#fff",
    color: "black",
    "&:hover": {
      backgroundColor: "#fff",
      color: "black",
      opacity: 0.6,
    },
  },
}));

export default useStyles;
