import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(10),
  },
  usersLength: {
    padding: theme.spacing(0.5),
    backgroundColor: "#F2F2F2",
    fontWeight: "bold",
    fontSize: "18px",
    marginLeft: theme.spacing(2),
    borderRadius: theme.spacing(1),
  },
  newAthleteButtonContainer: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
    },
  },
  mobileAthleteCardContainer: {
    marginTop: theme.spacing(2),
  },
  select: {
    borderRadius: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
}));

export default useStyles;
