import {
  Button,
  Card,
  CardHeader,
  CardMedia,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { isTimeExerciseInRep } from "@readyfit-common/models";
import { useState } from "react";
import useStepMetadata from "src/grading/hooks/useStepMetadata";
import { timeFromString } from "src/grading/utils";
import { didPassExercise } from "src/grading/utils/testHelpers";
import { useAppSelector } from "src/store/hooks";
import { useLazyGetFileUrlQuery } from "src/store/reducers/documents/documentsApiSlice";
import { selectGradingStep } from "src/store/reducers/grading/gradingSlice";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import { RepTimeGradeResult } from "../../models";
import useStyles from "./ReviewTimeCard.styles";

type ReviewTimeCardProps = RepTimeGradeResult & {
  regradeClick: () => void;
  fileKey: string | undefined;
  step: number;
  testScore: TestScore;
};

export function ReviewTimeCard({
  discrepancies,
  fileKey,
  graderTime,
  notes,
  regradeClick,
  userResult,
  accuracyScore,
  step,
  testScore,
}: ReviewTimeCardProps) {
  const [videoUrl, setVideoUrl] = useState<URL | "pending" | "not_started">(
    "not_started",
  );
  const userScore = userResult.reps;
  const officialScore = graderTime
    ? `${graderTime.minutes}:${graderTime.seconds.toString().padStart(2, "0")}`
    : "00:00";

  const userScoreToTime = timeFromString(userScore);
  const userScoreTotalSeconds = userScoreToTime
    ? userScoreToTime.minutes * 60 + userScoreToTime.seconds
    : 0;

  const officialScoreToTime = timeFromString(officialScore);
  const officialScoreTotalSeconds = officialScoreToTime
    ? officialScoreToTime.minutes * 60 + officialScoreToTime.seconds
    : 0;

  const { stepMetadata } = useStepMetadata(step);
  const maximunValue =
    stepMetadata?.testing?.userEnteredResult?.maximumValue ?? "0:00";
  const maximunValueToTime = timeFromString(maximunValue.toString());
  const maximunValueTotalSeconds = maximunValueToTime
    ? maximunValueToTime.minutes * 60 + maximunValueToTime.seconds
    : 0;

  const classes = useStyles({
    userScore: userScoreTotalSeconds,
    officialScore: officialScoreTotalSeconds,
    maximunValue: maximunValueTotalSeconds,
  })();

  const [getFileUrl] = useLazyGetFileUrlQuery();

  const gradingStep = useAppSelector(selectGradingStep);

  const isResultStepper = gradingStep > (testScore?.exercises?.length ?? 0);

  if (videoUrl === "not_started") {
    setVideoUrl("pending");
    getFileUrl(`${fileKey}?ext=mp4`)
      .unwrap()
      .then((data) => setVideoUrl(new URL(data.url)));
  }

  const grade = testScore?.exercises[step].grade;

  return (
    <Grid container spacing={2} xs={12}>
      <Card className={classes.gradingReviewCard}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Grid item container xs={12} alignItems={"center"}>
              <Typography
                variant="subtitle1"
                className={classes.stepNumberContainer}
              >
                {step}
              </Typography>
              <Typography variant="h4">{userResult.name}</Typography>

              <Typography
                variant="button"
                className={
                  didPassExercise(grade || "")
                    ? classes.approveLabel
                    : classes.failedLabel
                }
              >
                {grade}
              </Typography>

              {!isResultStepper && (
                <Grid item>
                  <Button
                    onClick={regradeClick}
                    className={classes.regradeButton}
                  >
                    Re-grade
                  </Button>
                </Grid>
              )}
            </Grid>
          }
        />
        <Grid container xs={12} px={4} py={3} gap={1}>
          <Grid item xs={4} className={classes.gradingReviewCardVideoContainer}>
            <CardMedia
              component="video"
              image={
                videoUrl !== "pending" && videoUrl !== "not_started"
                  ? videoUrl.toString()
                  : undefined
              }
              className={classes.gradingReviewCardVideo}
              controls
            />
          </Grid>

          <Grid item xs={4}>
            <Grid container>
              <Grid item container flexDirection={"column"}>
                <Typography variant="subtitle1" textTransform={"uppercase"}>
                  {(stepMetadata?.testing &&
                    isTimeExerciseInRep(stepMetadata?.testing) &&
                    "User Time Count") ||
                    "User Distance Count"}
                </Typography>

                <Typography variant="h4" className={classes.userGradingBar}>
                  {userScore}
                </Typography>
              </Grid>

              <Grid item container flexDirection={"column"}>
                <Typography variant="subtitle1" textTransform={"uppercase"}>
                  {(stepMetadata?.testing &&
                    isTimeExerciseInRep(stepMetadata?.testing) &&
                    "Grader Time Count") ||
                    "Grader Distance Count"}
                </Typography>

                <Typography variant="h4" className={classes.graderGradingBar}>
                  {officialScore}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item flexDirection={"column"} marginLeft={"auto"}>
            <Typography variant="subtitle1">Total Score</Typography>

            <Typography variant="h1">
              {testScore?.exercises[step].score}
              <span style={{ color: "#8F8F8F" }}>
                /{testScore?.exercises[step].maxScore}
              </span>
            </Typography>
          </Grid>

          <Grid xs={11}>
            <InputLabel>
              <Typography variant="subtitle1">Grader Notes</Typography>
            </InputLabel>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={userResult?.graderNotes || "N/A"}
              disabled={true}
              placeholder="Grader notes about exercise"
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
