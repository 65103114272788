import { CheckCircleOutline, DoNotDisturbRounded } from "@mui/icons-material";
import { Button, Grid } from "@mui/material";
import { Styles, createStyles, makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";

const rootButtonStyles = {
  margin: "10px",
  // width: "120px",
  minWidth: "120px",
  color: "black",
  padding: "8px",
};

const filledButtonStyles = (
  color: string,
  hoverColor: string,
  customStyles?: React.CSSProperties,
) =>
  makeStyles((theme) =>
    createStyles({
      root: {
        ...rootButtonStyles,
        backgroundColor: color,
        border: `1px solid ${color}`,
        color: "black",
        "&:hover": {
          background: hoverColor,
        },
        ...customStyles,
      },
    }),
  );

export function ApprovalButton(props: ApprovalButtonProps) {
  const [status, setStatus] = useState<boolean | undefined>(props.default);
  const onClick = (newStatus: boolean) => {
    if (newStatus !== status) {
      setStatus(newStatus);
      props.onClick(newStatus);
    }
  };

  useEffect(() => {
    setStatus(props.default);
  }, [props?.default]);

  return (
    <Grid container xs={12} className="approvalButtons">
      <Button
        classes={
          status === false
            ? filledButtonStyles("#c0392b", "#e74c3c", props.customStyles)()
            : outlineButtonStyles("#c0392b", "#e74c3c")()
        }
        variant={"outlined"}
        onClick={() => onClick(false)}
      >
        <DoNotDisturbRounded sx={{ marginRight: "4px" }} />
        {props.denyLabel || "Deny"}
      </Button>
      <Button
        classes={
          status
            ? filledButtonStyles("#27ae60", "#2ecc71", props.customStyles)()
            : outlineButtonStyles("#27ae60", "#2ecc71")()
        }
        variant={status ? "contained" : "outlined"}
        onClick={() => onClick(true)}
      >
        <CheckCircleOutline sx={{ marginRight: "4px" }} />
        {props.approvedLabel || "Approved"}
      </Button>
    </Grid>
  );
}

type ApprovalButtonProps = {
  default?: boolean;
  onClick: (status: boolean) => void;
  customStyles?: React.CSSProperties;
  approvedLabel?: string;
  denyLabel?: string;
};

const outlineButtonStyles = (color: string, hoverColor: string) =>
  makeStyles((theme) =>
    createStyles({
      root: {
        ...rootButtonStyles,
        border: `1px solid ${color}`,
        color: "black",
        "&:hover": {
          background: hoverColor,
        },
      },
    }),
  );
