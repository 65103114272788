import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    border: `1px solid #DBDBDB`,
  },
  customHeaderContainer: {
    padding: theme.spacing(3),
    alignItems: "center",
  },
  customHeaderFiltersContainer: {
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
    },
  },
  tableColumn: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "80%",
    borderRight: `1px solid ${theme.palette.secondary.light}`,
  },
  testBadge: {
    padding: `0 ${theme.spacing(1)}`,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "fit-content",
    maxWidth: "fit-content",
    borderRadius: theme.spacing(1),
  },
  divider: {
    marginRight: theme.spacing(1),
  },
  dividerContainer: {
    maxWidth: theme.spacing(3),
    width: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  selectInputContainer: {
    [theme.breakpoints.up("sm")]: {
      minWidth: "176px",
      width: "176px",
    },
  },
  selectInput: {
    fontSize: "16px",
    fontWeight: 400,
    width: "100%",
    "& .MuiSelect-select": {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  exportButton: {
    backgroundColor: theme.palette.success.contrastText,
    color: "white",
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
}));

export default useStyles;
