import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  bannerContainer: {
    marginTop: theme.spacing(5),
    backgroundColor: "#262F34",
    overflow: "hidden",
    borderRadius: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  bannerButtonsContainer: {
    padding: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flex: 1,
    },
  },
  bannerButtons: {
    marginBottom: theme.spacing(1),
  },
  divider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    border: "1px solid #3F4E57",
  },
  navigationButton: {
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main,
      opacity: 0.6,
    },
  },
  input: {
    fontSize: "1em",
    borderRadius: theme.spacing(1),
    color: "white",
  },
}));

export default useStyles;
