import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store/rootStore";
import {
  Member,
  Organization,
  OrganizationRecruitmentProcess,
  Role,
  Unit,
} from "./organizationsApiSlice";

interface OrganizationSliceState {
  organization: Organization | null;
  units: Unit[];
  members: Member[];
  roles: Role[];
  recruitmentProcess: OrganizationRecruitmentProcess | null;
}

const organizationSlice = createSlice({
  name: "organization",
  initialState: {
    organization: null,
    units: [],
    members: [],
    roles: [],
    recruitmentProcess: null,
  } as OrganizationSliceState,
  reducers: {
    resetOrganizationSlice: (state, action) => {
      state.organization = null;
      state.units = [];
      state.members = [];
      state.roles = [];
      state.recruitmentProcess = null;
    },
    setOrganization: (state, action) => {
      const { organization } = action.payload;
      state.organization = organization;
    },
    setUnits: (state, action) => {
      const { units } = action.payload;
      state.units = units;
    },
    appendUnit: (state, action) => {
      const { unit } = action.payload;
      state.units = [...state.units, unit];
    },
    setMembers: (state, action) => {
      const { members } = action.payload;
      state.members = members;
    },
    setRoles: (state, action) => {
      const { roles } = action.payload;
      state.roles = roles;
    },
  },
});

export const { setOrganization, setUnits, appendUnit, setMembers, setRoles } =
  organizationSlice.actions;

export default organizationSlice.reducer;

export const selectCurrentOrganization = (state: RootState) =>
  state.organization.organization;

export const selectCurrentUnits = (state: RootState) =>
  state.organization.units;

export const selectCurrentMembers = (state: RootState) =>
  state.organization.members;

export const selectCurrentRoles = (state: RootState) =>
  state.organization.roles;
