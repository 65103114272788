import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  gradingStepMain: {
    width: "100%",
    display: "inline-block",
    minHeight: "415px",
    maxHeight: "415px",
    "& video": {
      maxHeight: "inherit",
    },
  },
  gradingInputs: {
    width: "100%",
  },
  userNotesContainer: {
    display: "flex",
    flexDirection: "column",
  },
  userNotesHeader: {
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    backgroundColor: "#F0F0F0",
    borderRadius: "4px",
  },
  gradingMap: {
    minWidth: "300px",
    minHeight: "500px",
    width: "100%",
    display: "inline-block",
  },
}));

export default useStyles;
