import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  titleContainer: {
    marginTop: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(9),
    height: theme.spacing(9),
  },
  divider: {
    borderColor: "#DBDBDB",
    marginTop: theme.spacing(3.5),
    marginBottom: theme.spacing(3.5),
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  profileText: {
    marginLeft: theme.spacing(2.5),
  },
  stepCircle: {
    backgroundColor: "#DBDBDB",
    borderRadius: "50%",
    width: theme.spacing(3),
    height: theme.spacing(3),
    position: "relative",
  },
  circleText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#333333",
  },
  secondRowContainer: {
    borderBottomLeftRadius: theme.spacing(0),
    borderBottomRightRadius: theme.spacing(0),
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
  },
  signButton: {
    backgroundColor: "#242424",
    color: "#FFFFFF",
    textTransform: "uppercase",
    borderRadius: theme.spacing(1),
  },
}));

export default useStyles;
