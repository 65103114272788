import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "src/store/rootStore";
import { TestScore } from "../scores/scoresApiSlice";
import {
  TestDefinitionFormat,
  TestStepDefinitionFormat,
} from "../tests/testsApiSlice";

interface GradingSliceState {
  test?: TestScore;
  testMetadata?: TestDefinitionFormat;
  stepMetadata?: TestStepDefinitionFormat;
  step: number;
  stepperType: string;
  prevStep: number;
}

const initialState: GradingSliceState = {
  step: -1,
  testMetadata: undefined,
  stepMetadata: {},
  stepperType: "",
  test: undefined,
  prevStep: -1,
};

const gradingSlice = createSlice({
  name: "grading",
  initialState,
  reducers: {
    resetGradingSlice: (state) => Object.assign(state, initialState),
    setTest: (state, action: PayloadAction<TestScore>) => {
      state.test = action.payload;
    },
    setGradingStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setGradingTestMetadata: (
      state,
      action: PayloadAction<TestDefinitionFormat | undefined>,
    ) => {
      state.testMetadata = action.payload;
    },
    setGradingStepMetadata: (
      state,
      action: PayloadAction<TestStepDefinitionFormat | undefined>,
    ) => {
      state.stepMetadata = action.payload;
    },
    setStepperType: (state, action: PayloadAction<string>) => {
      state.stepperType = action.payload;
    },
    setPrevStep: (state, action: PayloadAction<number>) => {
      state.prevStep = action.payload;
    },
  },
});

export const selectGradingTest = (state: RootState) => state.grading.test;
export const selectGradingTestMetadata = (state: RootState) =>
  state.grading.testMetadata;
export const selectGradingStepMetadata = (state: RootState) =>
  state.grading.stepMetadata;
export const selectGradingStep = (state: RootState) => state.grading.step;
export const selectGradingPrevStep = (state: RootState) =>
  state.grading.prevStep;

export const {
  resetGradingSlice,
  setGradingStep,
  setGradingTestMetadata,
  setGradingStepMetadata,
  setStepperType,
  setTest,
  setPrevStep,
} = gradingSlice.actions;

export default gradingSlice.reducer;
