import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  successRoot: {
    backgroundColor: "#27AE60",
    color: "#1B7A43",
  },
  errorRoot: {},
}));

export default useStyles;
