import {
  Button,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { GridColDef, GridSortModel } from "@mui/x-data-grid";
import RFDataGrid from "src/grading/components/RFDataGrid/RFDataGrid";
import { getRelativeTime } from "src/grading/utils";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import theme from "src/theme";
import useStyles from "./PastTestsDataGrid.styles";

export type TestScoreRow = { row: TestScore };

interface PastTestsDataGridProps {
  testScores: TestScore[];
  isLoading: boolean;
  sortModel: GridSortModel;
  setSortModel: (sortModel: GridSortModel) => void;
  getAthleteScores: () => void;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  exportPastTests: () => void;
}

const GridCell = ({ label, value }: { label: string; value: string }) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.tableColumn}
      style={{ borderRight: "none" }}
      flexDirection={"column"}
    >
      <Typography variant="subtitle1" textTransform={"uppercase"}>
        {label}
      </Typography>
      <Typography variant="h4" fontWeight={"bold"}>
        {value}
      </Typography>
    </Grid>
  );
};

const TestBadge = ({ row }: TestScoreRow) => {
  const classes = useStyles();
  return (
    <Grid container justifyContent={"center"}>
      <Typography
        variant="caption"
        className={classes.testBadge}
        {...(!row.liveTestId
          ? { style: { backgroundColor: theme.palette.primary.main } }
          : {
              style: {
                backgroundColor: theme.palette.success.contrastText,
              },
            })}
      >
        {row.liveTestId ? "In Person" : "Virtual"}
      </Typography>
    </Grid>
  );
};

const MobilePastTestCard = ({ row }: TestScoreRow) => {
  const classes = useStyles();
  return (
    <Grid container flexDirection={"row"}>
      <Grid
        item
        className={classes.dividerContainer}
        bgcolor={row?.state.toLowerCase() === "pass" ? "#00A86B" : "#FF0000"}
      >
        <Divider orientation="vertical" />
      </Grid>
      <Grid item>
        <Grid item xs={12}>
          <Typography variant="h4">{row.testTitle}</Typography>
        </Grid>
        <Grid item xs={12} marginTop={1}>
          <Typography
            variant="h4"
            fontSize={"14px"}
            className={classes.testBadge}
            {...(!row.liveTestId
              ? { style: { backgroundColor: theme.palette.primary.main } }
              : {
                  style: {
                    backgroundColor: theme.palette.success.contrastText,
                  },
                })}
          >
            {row.liveTestId ? "In Person" : "Virtual"}
          </Typography>
        </Grid>

        <Grid item xs={12} marginTop={1}>
          <Typography variant="caption">
            {getRelativeTime(row.timestamp)}
          </Typography>
        </Grid>

        <Grid item xs={12} marginTop={1}>
          <Typography variant="caption">
            {`${row.score} / ${row.maxScore}`}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const SelectPastTestsDataGridColumns = (): GridColDef[] => [
  {
    field: "testTitle",
    headerName: "Test",
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    minWidth: 250,
    width: 250,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }: TestScoreRow) => (
      <Grid container flexDirection={"row"}>
        <Grid
          item
          style={{
            maxWidth: theme.spacing(3),
            width: theme.spacing(0.5),
            borderRadius: theme.spacing(1),
            marginRight: theme.spacing(1),
          }}
          bgcolor={row?.state.toLowerCase() === "pass" ? "#00A86B" : "#FF0000"}
        >
          <Divider orientation="vertical" />
        </Grid>
        <Grid item flex={1}>
          <Grid container justifyContent={"center"}>
            <GridCell label={"Test"} value={row.testTitle} />
          </Grid>
        </Grid>
      </Grid>
    ),
  },

  {
    field: "presence",
    headerName: "",
    flex: 1,
    editable: false,
    sortable: false,
    filterable: false,
    minWidth: 250,
    width: 250,
    align: "center",
    headerAlign: "center",
    renderCell: ({ row }: TestScoreRow) => (
      <Grid container>
        <TestBadge row={row} />
      </Grid>
    ),
  },
  {
    field: "timestamp",
    headerName: "Test Taken",
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    minWidth: 250,
    width: 250,
    headerAlign: "center",
    valueGetter: ({ row }: TestScoreRow) => row.timestamp,
    renderCell: ({ row }: TestScoreRow) => (
      <Grid container>
        <GridCell
          label={"Test Taken"}
          value={getRelativeTime(row.timestamp) || "-"}
        />
      </Grid>
    ),
  },
  {
    field: "score",
    headerName: "Score",
    flex: 1,
    editable: false,
    sortable: true,
    filterable: false,
    minWidth: 250,
    width: 250,
    headerAlign: "center",
    renderCell: ({ row }: TestScoreRow) => (
      <Grid container justifyContent={"center"}>
        <Typography
          variant="caption"
          fontSize={"20px"}
          color={"black"}
          fontWeight={"bold"}
        >
          {`${row.score} / `}{" "}
          <Typography
            variant="caption"
            fontSize={"20px"}
            color={"#666666"}
            fontWeight={"bold"}
          >
            {row.maxScore}
          </Typography>
        </Typography>
      </Grid>
    ),
  },
];

const SelectPastTestsDataGridColumnsMobile = (): GridColDef[] => [
  {
    field: "athlete",
    headerName: "Past Tests",
    flex: 1,
    editable: false,
    sortable: false,
    filterable: false,
    minWidth: 250,
    width: 250,
    renderCell: ({ row }: TestScoreRow) => <MobilePastTestCard row={row} />,
  },
];

const CustomHeader = ({
  sortBy,
  setSortBy,
  exportPastTests,
}: {
  sortBy: string;
  setSortBy: (value: string) => void;
  exportPastTests: () => void;
}) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.customHeaderContainer}>
      <Typography variant="h5">Past Tests</Typography>

      <Grid item className={classes.customHeaderFiltersContainer}>
        <Grid container gap={1}>
          {/* <Grid
            item
            container
            className={classes.selectInputContainer}
            xs={12}
            sm={2}
          >
            <Select
              name="organization"
              value={"-1"}
              // onChange={(e) => setFieldValue("organization", e.target.value)}
              className={classes.selectInput}
            >
              <MenuItem value={"-1"} disabled>
                Filter by test
              </MenuItem>
            </Select>
          </Grid> */}

          <Grid
            item
            container
            className={classes.selectInputContainer}
            xs={12}
            sm={2}
          >
            <Select
              name="organization"
              value={sortBy}
              onChange={(e) => setSortBy(e.target.value)}
              className={classes.selectInput}
            >
              <MenuItem value={"-1"} disabled>
                Sort by recent activity
              </MenuItem>
              <MenuItem value="timestamp.desc">Sort by most recent</MenuItem>
              <MenuItem value="timestamp.asc">Sort by less recent</MenuItem>
            </Select>
          </Grid>

          <Grid
            item
            container
            className={classes.selectInputContainer}
            xs={12}
            sm={2}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.exportButton}
              fullWidth
              onClick={exportPastTests}
            >
              Export
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const PastTestsDataGrid = ({
  testScores,
  isLoading,
  sortModel,
  setSortModel,
  getAthleteScores,
  sortBy,
  setSortBy,
  exportPastTests,
}: PastTestsDataGridProps) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();
  return (
    <RFDataGrid
      rows={testScores}
      columns={
        isSmallScreen
          ? SelectPastTestsDataGridColumnsMobile()
          : SelectPastTestsDataGridColumns()
      }
      loading={isLoading}
      disableSelectionOnClick
      getRowId={(row) => `${row.testId}-${row.timestamp}-${Math.random()}`}
      {...(isSmallScreen && {
        rowHeight: 140,
      })}
      className={classes.table}
      components={{
        Header: () => (
          <CustomHeader
            sortBy={sortBy}
            setSortBy={setSortBy}
            exportPastTests={exportPastTests}
          />
        ),
      }}
      headerHeight={0}
    />
  );
};

export default PastTestsDataGrid;
