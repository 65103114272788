import { Close } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import RFModal from "src/grading/components/RFModal/RFModal";
import { TestStepDefinitionFormat } from "src/store/reducers/tests/testsApiSlice";
import theme from "src/theme";
import useStyles from "./TestRulesModal.styles";

interface TestRulesModalProps {
  open: boolean;
  onClose: () => void;
  exercise: TestStepDefinitionFormat;
  noText?: boolean;
  contaienrStyles?: React.CSSProperties;
}

const TestRulesModal = ({
  exercise,
  noText,
  contaienrStyles,
  ...props
}: TestRulesModalProps) => {
  const classes = useStyles();

  return (
    <RFModal
      {...props}
      boxProps={{
        className: classes.container,
        style: contaienrStyles,
      }}
    >
      <Grid container position={"relative"} width={"100%"}>
        <Grid item container alignItems={"center"}>
          <Typography variant="h5">{exercise.displayName}</Typography>
          <IconButton
            className={classes.closeIconButton}
            onClick={props.onClose}
          >
            <Close className={classes.closeIcon} />
          </IconButton>
        </Grid>

        {/* <Grid
          container
          item
          marginTop={theme.spacing(2)}
          spacing={2}
          justifyContent={"center"}
          width={"100%"}
        > */}
        <div className={classes.videoContainer}>
          <video controls className={classes.video}>
            <source
              src={exercise?.info?.instructionVideo?.uri}
              type="video/mp4"
            />
          </video>
        </div>

        {!noText && (
          <Grid item container marginTop={theme.spacing(3)}>
            <Typography variant="h6" textTransform={"uppercase"}>
              Information
            </Typography>

            {exercise?.info?.instructions?.map((instruction, i) => (
              <Typography
                key={i}
                variant="caption"
                marginTop={theme.spacing(1)}
              >
                <span style={{ fontWeight: "bold", color: "#666" }}>
                  Step {i + 1}:
                </span>{" "}
                {instruction}
              </Typography>
            ))}
          </Grid>
        )}
        {/* </Grid> */}
      </Grid>
    </RFModal>
  );
};

export default TestRulesModal;
