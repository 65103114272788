import { ArrowForwardRounded, ScheduleOutlined } from "@mui/icons-material";
import { Grid, IconButton, Paper, Typography } from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import RFDataGrid from "src/grading/components/RFDataGrid/RFDataGrid";
import AssetService from "src/grading/services/AssetService";
import { parseLiveTestName } from "src/grading/utils/testHelpers";
import {
  LiveTest,
  useLazyGetAllLiveTestScoresQuery,
} from "src/store/reducers/liveTest/liveTestSlice";
import { addBreadcrumb } from "src/store/reducers/navigation/navigationSlice";
import theme from "src/theme";
import useStyles from "./InPersonTestsGrid.styles";

export type InPersonTestsGridProps = {
  certified?: "true" | "false" | "pending";
  athleteId?: string;
};

const EmptyTable = () => {
  return (
    <Grid container>
      <Grid
        marginTop={theme.spacing(5)}
        flex={1}
        container
        justifyContent="center"
        alignItems="center"
      >
        <ScheduleOutlined fontSize="large" />
        <Typography variant="h4">There are no tests scheduled yet </Typography>
      </Grid>
    </Grid>
  );
};

const InPersonTestsGrid = (props: InPersonTestsGridProps) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [sortModel, setSortModel] = useState<GridSortModel>([
    { field: "date", sort: "desc" },
  ]);
  const [getAllLiveTestScores, { isFetching }] =
    useLazyGetAllLiveTestScoresQuery();
  const [liveTestScores, setLiveTestScores] = useState<LiveTest[]>([]);

  const loadData = async () => {
    const response = await getAllLiveTestScores({
      certified: props.certified,
      athleteId: props.athleteId,
    }).unwrap();
    setLiveTestScores(response.data.liveTests);
  };

  const handleOnRowClick = (row: LiveTest) => {
    history.push(`/grading/certified/live-test/${row.id}`, row);
    dispatch(
      addBreadcrumb({
        label: "Certified Tests",
        path: `/grading/certified`,
        index: 0,
      }),
    );
  };

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Grid item container>
      <Paper className={classes.paperContainer}>
        <RFDataGrid
          rows={liveTestScores}
          hideFooterSelectedRowCount={true}
          headerHeight={50}
          pageSize={15}
          sx={{
            border: "none",
          }}
          rowHeight={108}
          className={classes.root}
          autoHeight
          components={{
            NoRowsOverlay: () => <EmptyTable />,
          }}
          onCellClick={({ row }: { row: LiveTest }) => handleOnRowClick(row)}
          columns={[
            {
              field: "logo",
              headerName: "",
              minWidth: 100,
              editable: false,
              sortable: false,
              filterable: false,
              renderCell: ({ row }: { row: LiveTest }) => {
                const testDefinitionInfos = row.testDefinition.infos;

                let imageSrc = "";

                if (testDefinitionInfos.length > 1) {
                  const branchTestDefinitionInfo = testDefinitionInfos.find(
                    (info) => {
                      return row.name.includes(info.displayName);
                    },
                  );
                  if (branchTestDefinitionInfo?.buttonImage.name) {
                    imageSrc = AssetService.Instance.get(
                      branchTestDefinitionInfo?.buttonImage.name,
                    );
                  } else {
                    if (testDefinitionInfos[0].buttonImage.name) {
                      imageSrc = AssetService.Instance.get(
                        testDefinitionInfos[0].buttonImage.name,
                      );
                    }
                  }
                } else {
                  if (testDefinitionInfos[0].buttonImage.name) {
                    imageSrc = AssetService.Instance.get(
                      testDefinitionInfos[0].buttonImage.name,
                    );
                  }
                }
                return (
                  <Grid item container flex={1} justifyContent={"center"}>
                    <img
                      src={imageSrc}
                      alt={"test-logo"}
                      className={classes.testLogo}
                    />
                  </Grid>
                );
              },
            },
            {
              field: "name",
              headerName: "",
              headerAlign: "left",
              minWidth: 200,
              flex: 1,
              editable: false,
              sortable: false,
              filterable: false,
              renderCell: ({ row }: { row: LiveTest }) => {
                return (
                  <Grid container className={classes.logoTitleContainer}>
                    <Grid
                      container
                      item
                      xs={9}
                      flex={1}
                      className={classes.testTitle}
                    >
                      <Typography textAlign={"left"} variant="h4">
                        {parseLiveTestName(row.name)}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              },
            },
            {
              field: "date",
              headerName: "Date",
              headerAlign: "center",
              width: 200,
              minWidth: 200,
              editable: false,
              sortable: true,
              valueGetter: (params) => {
                return params.row.date;
              },
              renderCell: ({ row }: { row: LiveTest }) => {
                const date = new Date(row.date).toLocaleString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                });
                return (
                  <Grid container className={classes.tableColumn}>
                    <Typography variant="subtitle1" textTransform={"uppercase"}>
                      Date
                    </Typography>
                    <Typography variant="h4" fontWeight={"bold"}>
                      {date}
                    </Typography>
                  </Grid>
                );
              },
            },
            {
              field: "athletesCount",
              headerName: "Athletes",
              headerAlign: "center",
              minWidth: 100,
              width: 100,
              editable: false,
              sortable: false,
              renderCell: ({ row }: { row: LiveTest }) => (
                <Grid
                  container
                  className={classes.tableColumn}
                  style={{ borderRight: "none" }}
                >
                  <Typography variant="subtitle1" textTransform={"uppercase"}>
                    Athletes
                  </Typography>
                  <Typography variant="h4" fontWeight={"bold"}>
                    {row.athletes.length}
                  </Typography>
                </Grid>
              ),
            },
            {
              field: "action",
              headerName: "",
              width: 100,
              minWidth: 100,
              headerAlign: "center",
              editable: false,
              sortable: false,
              filterable: false,
              renderCell: ({ row }: { row: LiveTest }) => (
                <div className={classes.profileText}>
                  <IconButton>
                    <ArrowForwardRounded
                      style={{
                        color: "white",
                        borderRadius: "50%",
                      }}
                      fontSize="large"
                      className={classes.forwardIcon}
                    />
                  </IconButton>
                </div>
              ),
            },
          ]}
          sortModel={sortModel}
          onSortModelChange={(newSortModel) => setSortModel(newSortModel)}
          loading={isFetching}
        />
      </Paper>
    </Grid>
  );
};

export default InPersonTestsGrid;
