import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import {
  selectCurrentAccessToken,
  selectCurrentUser,
} from "../store/reducers/auth/authSlice";
import { allowedRoles, checkRoles } from "./utils/permissionsHelpers";

function PrivateRoute({ children, ...rest }) {
  const currentAccessToken = useSelector(selectCurrentAccessToken);
  const user = useSelector(selectCurrentUser);

  return (
    <Route
      {...rest}
      render={() => {
        return currentAccessToken && checkRoles(allowedRoles, user?.roles) ? (
          children
        ) : (
          <Redirect to="/grading" />
        );
      }}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateRoute;
