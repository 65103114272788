import { Button, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import theme from "src/theme";
import RFModal from "../RFModal/RFModal";
import useStyles from "./GoAwayModal.styles";

interface GoAwayModalProps {
  title?: string;
  message?: string;
  open: boolean;
  onClose: () => void;
  onNavigateAway: () => void;
  newLocation?: string;
  canNavigateAway: boolean;
}

const GoAwayModal = ({
  message,
  title,
  onClose,
  open,
  newLocation,
  canNavigateAway,
  onNavigateAway,
  ...props
}: GoAwayModalProps) => {
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    if (canNavigateAway && newLocation) {
      history.push(newLocation);
    }
  }, [canNavigateAway, history, newLocation]);
  return (
    <RFModal
      boxProps={{
        className: classes.container,
      }}
      open={open}
      {...props}
    >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">{title || "Navigating away"}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" marginTop={2}>
            {message || "Are you sure you want to navigate away?"}
          </Typography>
        </Grid>

        <Grid
          container
          xs={12}
          mt={3}
          justifyContent={"space-evenly"}
          className={classes.buttonsContainer}
        >
          <Grid container item xs={6} justifyContent={"center"}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onClose}
              className={classes.cancelButton}
            >
              Cancel
            </Button>
          </Grid>
          <Grid container item xs={6} justifyContent={"center"}>
            <Button
              variant="contained"
              color="primary"
              onClick={onNavigateAway}
              className={classes.yesButton}
            >
              Yes
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </RFModal>
  );
};

export default GoAwayModal;
