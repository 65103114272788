import { Card, Grid, Typography } from "@mui/material";
import { isDistanceInRepExercise } from "@readyfit-common/models";
import useStepMetadata from "src/grading/hooks/useStepMetadata";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import { RepCountGradeResult } from "../../models";
import useStyles from "./ResultsRepsCard.styles";

type ResultsRepsCardProps = RepCountGradeResult & {
  fileKey: string | undefined;
  step: number;
  metadata?: any;
  score?: number;
  maxScore?: number;
  testScore?: TestScore;
};

export function ResultsRepsCard({
  userResult,
  discrepancies,
  fileKey,
  notes,
  step,
  accuracyScore,
  graderCount,
  metadata,
  score,
  maxScore,
  testScore,
}: ResultsRepsCardProps) {
  const userScore = userResult?.reps || metadata?.reps;
  const officialScore = (graderCount ?? 0).toString();
  const { stepMetadata } = useStepMetadata(step);
  const maximunValue = Number(
    stepMetadata?.testing?.userEnteredResult?.maximumValue ?? 100,
  );
  const classes = useStyles({
    userScore: Number(userScore),
    officialScore: Number(officialScore),
    maximunValue,
  })();

  return (
    <Grid container spacing={2} xs={12}>
      <Card className={classes.gradingReviewCard}>
        <Grid container xs={12} mx={4} my={3} gap={1} flexDirection={"row"}>
          <Grid
            container
            item
            flexDirection={"column"}
            xs={7}
            justifyContent={"center"}
          >
            <Typography variant="h4">
              {userResult?.name || metadata?.name}
            </Typography>
          </Grid>
          <Grid item flexDirection={"column"} mr={1.5}>
            <Typography variant="subtitle1">
              {(stepMetadata?.results &&
                isDistanceInRepExercise(stepMetadata?.results)) ||
              metadata?.name.toLowerCase().includes("throw")
                ? "Distance"
                : "Repetitions"}
            </Typography>
            <Typography variant="caption" fontSize={"18px"}>
              {graderCount || metadata?.reps}
            </Typography>
          </Grid>
          <Grid item flexDirection={"column"} mr={1.5}>
            <Typography variant="subtitle1">Score</Typography>

            <Typography variant="caption" fontSize={"18px"}>
              {testScore?.exercises[step].score?.toString()}
              <span style={{ color: "#8F8F8F" }}>
                /{testScore?.exercises[step].maxScore}
              </span>
            </Typography>
          </Grid>
          <Grid item flexDirection={"column"}>
            <Typography variant="subtitle1">Ranking</Typography>

            <Typography variant="caption" fontSize={"18px"}>
              {testScore?.exercises[step]?.grade ||
                testScore?.exercises[step].grade ||
                "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}
