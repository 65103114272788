import {
  Grid,
  GridProps,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Profile } from "@readyfit-common/models";
import { useState } from "react";
import AthleteIcon from "src/grading/components/AthleteIcon/AthleteIcon";
import GradingBox from "src/grading/components/GradingBox/GradingBox";
import GradingHeader from "src/grading/components/GradingHeader/GradingHeader";
import LiveTestStepper from "src/grading/components/LiveTestStepper/LiveTestStepper";
import { GradeResult } from "src/grading/models";
import {
  getLabelBasedOnGradeResult,
  getValuesBasedOnGradeResult,
} from "src/grading/utils/testHelpers";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import {
  selectLiveTestGradeResults,
  selectLiveTestScores,
  selectSelectedAthleteToSign,
  setAthletesLiveTestGradeResults,
} from "src/store/reducers/liveTest/liveTestSlice";
import theme from "src/theme";
import useStyles from "./LiveTestSign.styles";
import SignModal from "./SignModal/SignModal";
import SignStepper from "./SignStepper/SignStepper";

interface RowItemProps extends GridProps {
  label?: string;
  value: string | number;
  spacing?: number;
  maxValue?: string | number;
  scoreValue?: boolean;
}

const RowItem = ({
  label,
  value,
  spacing = 2,
  maxValue,
  scoreValue,
  ...props
}: RowItemProps) => {
  return (
    <Grid
      item
      container
      xs={spacing}
      alignItems={"center"}
      flexDirection={"column"}
      {...props}
    >
      {label && (
        <Typography
          variant="subtitle1"
          color={"#666666"}
          textTransform={"uppercase"}
        >
          {label}
        </Typography>
      )}

      {scoreValue ? (
        <Typography variant="h5" fontWeight={"bold"}>
          {value}/<span style={{ color: "#666666" }}>{maxValue}</span>
        </Typography>
      ) : (
        <Typography variant="h5" fontWeight={"bold"}>
          {value}
        </Typography>
      )}
    </Grid>
  );
};

const LiveTestSign = ({ yOffset }: { yOffset: number }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const selectedAthlete = useAppSelector(selectSelectedAthleteToSign);
  const allGradeResults = useAppSelector(selectLiveTestGradeResults);
  const liveTestScores = useAppSelector(selectLiveTestScores);
  const [openSignModal, setOpenSignModal] = useState<{
    open: boolean;
    profile: Profile;
    signingAs?: "grader" | "athlete";
  } | null>(null);

  const [signingAs, setSigningAs] = useState<"athlete" | "grader" | undefined>(
    undefined,
  );
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const athleteLiveGradeResult = allGradeResults.find(
    (gradeResult) => gradeResult.profile.id === selectedAthlete?.id,
  );

  const gradeResultIndexToEdit = allGradeResults.findIndex(
    (gradeResult) => gradeResult.profile.id === selectedAthlete?.id,
  );

  const signatureDetails =
    signingAs === "athlete"
      ? athleteLiveGradeResult?.athleteSignatureDetails
      : athleteLiveGradeResult?.graderSignatureDetails;

  const handleOpenSignModal = (
    profile: Profile,
    signingAs: "athlete" | "grader",
  ) => {
    if (profile) {
      setOpenSignModal({ open: true, profile, signingAs });
    }
  };

  const user = useAppSelector(selectCurrentUser);

  const athleteScores = liveTestScores?.scores.find(
    (score) => score.profile.id === athleteLiveGradeResult?.profile.id,
  );

  return (
    selectedAthlete && (
      <Grid container gap={2}>
        <Grid
          container
          className={classes.headerContainer}
          component={Paper}
          elevation={1}
        >
          <Grid item container xs={12} className={classes.titleContainer}>
            <GradingHeader
              withBreadcrumbs
              containerStyles={{
                marginTop: 0,
                marginBottom: theme.spacing(2),
              }}
            />
            <Typography variant="h3">Sign Test</Typography>

            <Grid item marginLeft={"auto"} marginRight={1}>
              <LiveTestStepper mini={true} yOffset={yOffset} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container xs={12} gap={2}>
          <Grid item container flex={2.5}>
            <Grid container item flex={1}>
              <Grid
                mb={2}
                container
                xs={12}
                padding={5}
                bgcolor={theme.palette.primary.light}
                borderRadius={2}
                component={Paper}
              >
                <Grid container item xs={6}>
                  <AthleteIcon
                    profile={selectedAthlete}
                    customStyles={classes.avatar}
                  />
                  <div className={classes.textContainer}>
                    <Grid alignItems={"center"} className={classes.profileText}>
                      <Typography variant="subtitle1">Name</Typography>
                      <Typography variant="h4" fontWeight={"bold"}>
                        {selectedAthlete?.firstName ?? "N/A"}{" "}
                        {selectedAthlete?.lastName ?? "N/A"}
                      </Typography>
                    </Grid>
                  </div>
                </Grid>

                <Grid container item xs={5}>
                  <div className={classes.textContainer}>
                    <Grid className={classes.profileText} padding={2}>
                      <Typography variant="subtitle1">Unit</Typography>
                      <Typography variant="h4" fontWeight={"bold"}>
                        {selectedAthlete?.branchOfService ?? "N/A"}
                      </Typography>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            {athleteLiveGradeResult?.gradeResults?.map(
              (gradeResult, gradeResultIndex) => {
                const exercise = athleteScores?.score?.exercises.find(
                  (exercise) =>
                    exercise.exerciseId === gradeResult?.userResult?.exerciseId,
                );

                return (
                  <GradingBox.Container key={gradeResultIndex}>
                    <GradingBox.FirstRowContent>
                      <Grid container xs={12}>
                        <Grid item container flex={1}>
                          <Grid item className={classes.stepCircle}>
                            <Typography
                              variant="subtitle1"
                              className={classes.circleText}
                            >
                              {gradeResultIndex + 1}
                            </Typography>
                          </Grid>

                          <Grid
                            item
                            marginLeft={isSmallScreen ? 0 : theme.spacing(1)}
                          >
                            <Typography variant="h4">
                              {gradeResult?.userResult?.name}
                            </Typography>
                          </Grid>
                        </Grid>

                        <Grid
                          item
                          container
                          flex={1}
                          justifyContent={"flex-end"}
                        >
                          <Typography
                            variant="subtitle1"
                            fontWeight={"bold"}
                            color="#fff"
                            textTransform={"uppercase"}
                            padding={`${theme.spacing(0.25)} ${theme.spacing(
                              2,
                            )}`}
                            borderRadius={theme.spacing(7)}
                            bgcolor={
                              exercise?.grade?.toLowerCase() === "excellent" ||
                              exercise?.grade
                                ?.toLowerCase()
                                .includes("satisfactory") ||
                              exercise?.grade?.toLowerCase().includes("high") ||
                              exercise?.grade?.toLowerCase().includes("good") ||
                              exercise?.grade?.toLowerCase().includes("perfect")
                                ? "#00A86B"
                                : "#FF0000"
                            }
                            width={"fit-content"}
                            textAlign={"center"}
                            maxHeight={theme.spacing(3)}
                          >
                            {exercise?.grade}
                          </Typography>
                        </Grid>
                      </Grid>
                    </GradingBox.FirstRowContent>

                    <GradingBox.SecondRowContent
                      justifyContent={"space-between"}
                      gap={2}
                      className={classes.secondRowContainer}
                    >
                      <RowItem
                        label={getLabelBasedOnGradeResult(gradeResult)}
                        value={getValuesBasedOnGradeResult(gradeResult)}
                      />

                      <RowItem
                        label={"Score"}
                        scoreValue
                        value={exercise?.score || 0}
                        maxValue={exercise?.maxScore || 100}
                        spacing={5}
                      />

                      <RowItem
                        label={"Ranking"}
                        value={exercise?.grade as string}
                        flex={1}
                        spacing={4}
                        alignItems={"flex-start"}
                        marginLeft={theme.spacing(1)}
                      />
                    </GradingBox.SecondRowContent>
                    {/* NOTES ROW */}
                    <GradingBox.SecondRowContent
                      justifyContent={"space-between"}
                      // gap={2}
                      // className={classes.secondRowContainer}
                    >
                      <Typography
                        variant="subtitle1"
                        color={"#666666"}
                        textTransform={"uppercase"}
                      >
                        Notes
                      </Typography>
                      <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        value={gradeResult?.userResult?.graderNotes}
                        placeholder="Write Notes Here."
                        onChange={(event) => {
                          const currentGrade = {
                            ...gradeResult,
                            userResult: {
                              ...gradeResult?.userResult,
                              graderNotes: event.target.value,
                            },
                          } as GradeResult;

                          const stepGradeIndex = allGradeResults.findIndex(
                            (currentGrade) =>
                              currentGrade.profile.id === selectedAthlete?.id,
                          );

                          const updatedResults = allGradeResults.map(
                            (result, index) => {
                              const newValue = {
                                ...result,
                                gradeResults: [
                                  ...allGradeResults[
                                    stepGradeIndex
                                  ].gradeResults.slice(0, gradeResultIndex),
                                  currentGrade,
                                  ...allGradeResults[
                                    stepGradeIndex
                                  ].gradeResults.slice(gradeResultIndex + 1),
                                ] as GradeResult[],
                              };
                              if (index === stepGradeIndex) {
                                return newValue;
                              }

                              return result;
                            },
                          );

                          dispatch(
                            setAthletesLiveTestGradeResults({
                              athleteLiveTestGradeResults: updatedResults,
                            }),
                          );
                        }}
                      />
                    </GradingBox.SecondRowContent>
                  </GradingBox.Container>
                );
              },
            )}
          </Grid>

          {/* SIGN MENU  */}
          <Grid item container flex={1}>
            <Grid
              // container
              xs={12}
              border={`1px solid #B9B9B9`}
              borderRadius={2}
              sx={{ height: "fit-content", width: "fit-content" }}
            >
              <Grid
                item
                container
                flexDirection={"column"}
                sx={{ position: "relative" }}
              >
                <Grid
                  container
                  p={4}
                  bgcolor={"#F0F0F0"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Typography variant="h5">Sign test</Typography>
                </Grid>

                <SignStepper
                  handleOpenSignModal={handleOpenSignModal}
                  athleteProfile={selectedAthlete}
                  graderProfile={user as Profile}
                  setSigningAs={setSigningAs}
                  liveGradeResult={athleteLiveGradeResult}
                  athleteScores={athleteScores}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <SignModal
          open={Boolean(openSignModal?.open)}
          onClose={() => setOpenSignModal(null)}
          profile={openSignModal?.profile as Profile}
          signingAs={openSignModal?.signingAs}
          signatureDetails={signatureDetails}
          gradeResultIndexToEdit={gradeResultIndexToEdit}
          athleteLiveGradeResult={athleteLiveGradeResult}
          athleteId={selectedAthlete.id}
        />
      </Grid>
    )
  );
};

export default LiveTestSign;
