import { Grid, Typography } from "@mui/material";
import { RecruitmentProcess } from "@readyfit-common/models";
import { OrganizationRecruitmentProcess } from "src/store/reducers/organizations/organizationsApiSlice";
import useStyles from "./RecruitmentProcessWidget.styles";
import {
  CardHeader,
  FitnessTestCard,
  RecruitmentProcessBadge,
  RecruitmentProcessCheckBox,
  RecruitmentProcessCheckboxGroup,
  RecruitmentProcessLabel,
} from "./components";

const RecruitmentProcessWidget = ({
  recruitmentProcess,
  organizationRecruitmentProcess,
}: {
  recruitmentProcess: RecruitmentProcess;
  organizationRecruitmentProcess: OrganizationRecruitmentProcess;
}) => {
  const classes = useStyles();

  const {
    userId,
    status,
    preQualify,
    fitnessTest,
    training,
    qualifiedForService,
  } = recruitmentProcess;
  return (
    <Grid item xs={12}>
      <Grid container alignItems={"center"}>
        <Typography variant="h5">Recruitment process</Typography>
        <RecruitmentProcessBadge label={status} right />
      </Grid>

      <Grid container marginTop={3} className={classes.container}>
        {/* pre-qualify */}
        <RecruitmentProcessCheckboxGroup
          userId={userId}
          organizationId={organizationRecruitmentProcess.organizationId}
          groupKey="preQualify"
          groupTitle="pre-qualify"
          data={preQualify}
          dict={organizationRecruitmentProcess.preQualify}
          order={organizationRecruitmentProcess.preQualifyOrder}
        />

        {/* fitness test */}
        <Grid
          container
          md={3}
          sx={{
            borderRight: 1,
            borderColor: "#DBDBDB",
            flexDirection: "column",
          }}
        >
          <CardHeader title="fitness test" progress={0} />
          <RecruitmentProcessLabel label={fitnessTest.name} border />
          <FitnessTestCard
            score={fitnessTest.score}
            maxScore={fitnessTest.maxScore}
            status={fitnessTest.status}
            date={fitnessTest.date}
          />
        </Grid>

        {/* training */}
        <Grid
          container
          md={3}
          sx={{
            borderRight: 1,
            borderColor: "#DBDBDB",
            flexDirection: "column",
          }}
        >
          <CardHeader title="training" progress={0} />
          <RecruitmentProcessLabel label="Not assigned" border />
          <RecruitmentProcessCheckBox
            id="Assign Training"
            label="Assign Training"
            border
            checked={training.assigned}
          />
          <RecruitmentProcessCheckBox
            id="Complete Training"
            label="Complete Training"
            checked={training.completed}
          />
        </Grid>

        {/* qualified for service */}
        <RecruitmentProcessCheckboxGroup
          userId={userId}
          organizationId={organizationRecruitmentProcess.organizationId}
          groupKey="qualifiedForService"
          groupTitle="qualified for service"
          data={qualifiedForService}
          dict={organizationRecruitmentProcess.qualifiedForService}
          order={organizationRecruitmentProcess.qualifiedForServiceOrder}
        />
      </Grid>
    </Grid>
  );
};

export default RecruitmentProcessWidget;
