import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  clickable: {
    cursor: "pointer",
  },
  button: {
    margin: "10px",
    width: "100%",
  },
  doubleButton: {
    width: "40%",
  },
  divider: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
