import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: "#DBDBDB",
    "& .MuiFilledInput-root": {
      backgroundColor: "#FFFFFF",
    },
  },
  navbarContainer: {
    padding: theme.spacing(2.5),
    backgroundColor: "black",
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 100,
  },
  logo: {
    objectFit: "contain",
    height: theme.spacing(6.25),
  },
  headerContainer: {
    width: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      background: "black",
    },
  },
  background: {
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  backgroundImage: {
    marginLeft: "auto",
    width: "100%",
    height: "100%",
    mixBlendMode: "multiply",
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      objectPosition: "center",
      mixBlendMode: "normal",
    },
  },
  titleContainer: {
    position: "absolute",
    zIndex: 1,
    left: "10%",
    top: "10%",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      left: 0,
      top: 0,
      position: "relative",
      backgroundColor: "black",
      width: "100%",
      padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
    },
  },
  heroCaption: {
    marginTop: theme.spacing(3),
  },
  registerButton: {
    marginTop: theme.spacing(6),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
    },
    marginLeft: "auto",
    marginRight: "auto",
  },
  navigationLinks: {
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(1),
    },
  },
  coronadoPrepContainer: {
    padding: `0 ${theme.spacing(2)}`,
    flexDirection: "column",
    [theme.breakpoints.up("xs")]: {
      flexDirection: "row",
      marginTop: theme.spacing(10),
      justifyContent: "space-evenly",
    },
  },
  coronadoPrepImageContainer: {
    justifyContent: "center",
    overflow: "hidden",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      width: "50%",
    },
  },
  coronadoPrepImage: {
    width: "100%",
    // width: "342px",
    [theme.breakpoints.up("sm")]: {
      width: "410px",
      height: "248px",
    },
  },
  divider: {
    width: "95%",
    margin: "auto",
    borderColor: "#666666",
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
    borderWidth: "1.5px",
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(7.5),
      marginBottom: theme.spacing(7.5),
    },
  },
  registrationContainer: {
    width: "100%",
    padding: `0 ${theme.spacing(2)}`,
    [theme.breakpoints.up("xs")]: {
      paddingBottom: theme.spacing(10),
    },
  },
  registrationImage: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "415px",
      height: "691px",
    },
  },
  inputContainer: {
    flexDirection: "column",
    marginBottom: theme.spacing(2),
  },
  inputLabel: {
    marginLeft: theme.spacing(1),
    color: "#666666",
    fontSize: "12px",
    marginBottom: 0,
  },
  input: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(1),
    borderWidth: 0,
  },
  selectInput: {
    padding: 0,
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(1),
    borderWidth: 0,
  },
  multilineInput: {
    backgroundColor: "#FFFFFF",
    borderRadius: theme.spacing(1),
  },
  readyfitContainer: {
    backgroundColor: "#000000",
    position: "relative",
  },
  readyfitImage: {
    width: "100%",
  },
  readyfitContentContainer: {
    padding: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      width: "50%",
      left: "10%",
      top: "5%",
    },
    [theme.breakpoints.up("md")]: {
      top: "20%",
    },
  },
  readyfitLogotype: {
    objectFit: "contain",
    width: "fit-content",
  },
  storesIconContainer: {
    display: "flex",
    alignItems: "center",
    width: "60%",
    justifyContent: "space-evenly",
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "30%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
    },
  },
  footerContainer: {
    flexDirection: "column",
    paddingTop: theme.spacing(10),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(2),
    backgroundColor: "#000000",
    paddingBottom: theme.spacing(7.5),
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      // justifyContent: "space-between",
    },
  },
}));

export default useStyles;
