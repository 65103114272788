import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
    boxshadow: "-2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
    minHeight: "200px",
  },
  buttonsContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "50%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      width: "100%",
    },
  },
  cancelButton: {
    minWidth: "100px",
    color: "black",
    border: 0,
  },
  yesButton: {
    minWidth: "100px",
  },
}));

export default useStyles;
