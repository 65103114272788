import { Switch, SwitchProps } from "@mui/material";
import useStyles from "./RFSwitch.styles";

interface RFSwitchProps extends SwitchProps {
  activeColor?: string;
  inactiveColor?: string;
  inactiveTrackColor?: string;
}

export const RFSwitch = ({
  activeColor,
  inactiveColor,
  inactiveTrackColor,
  ...props
}: RFSwitchProps) => {
  const classes = useStyles({
    activeColor,
    inactiveColor,
    inactiveTrackColor,
  })();
  return (
    <Switch
      classes={{
        switchBase: classes.switchBase,
        checked: classes.checked,
        track: classes.track,
      }}
      {...props}
    />
  );
};
