import { Apps, Workspaces } from "@mui/icons-material";
import { ReactNode } from "react";
import { Permission, permissions } from "src/grading/utils/permissionsHelpers";
import {
  AthletesMenuIcon,
  CertifiedIcon,
  LiveIcon,
  LogoutIcon,
  PendingIcon,
} from "../../assets";

export interface NavLink {
  path: string;
  name: string;
  icon?: ReactNode;
  childLinks?: NavLink[];
  isOpen?: boolean;
  openInNewTab?: boolean;
  onClick?: () => void;
  permission?: Permission;
}

export const standardLinks: NavLink[] = [
  {
    name: "Organization",
    path: "/grading/organization",
    icon: <Workspaces style={{ color: "white" }} />,
    permission: permissions.READ_ORGANIZATION,
  },
  {
    name: "Live Testing",
    path: "/grading/live",
    icon: <LiveIcon />,
    permission: permissions.READ_LIVE_TESTS,
  },
  {
    name: "Virtual Testing",
    path: "/grading/pending",
    icon: <PendingIcon />,
    permission: permissions.READ_PENDING_TESTS,
  },

  {
    name: "Certified Tests",
    path: "/grading/certified",
    icon: <CertifiedIcon />,
    permission: permissions.READ_CERTIFIED_TESTS,
  },

  {
    name: "Athletes",
    path: "/grading/athletes",
    icon: <AthletesMenuIcon />,
    permission: permissions.READ_ATHLETES,
  },

  // Admin
  {
    name: "Demo Tests",
    path: "/grading/admin/demo-tests",
    icon: <Apps style={{ color: "white" }} />,
    permission: permissions.READ_DEMO_TESTS,
  },

  // Hiding until resources are available
  // { name: "Resources", path: "/resources", icon: <ResourcesIcon /> }
];

export const bottomLinks: NavLink[] = [
  /* { name: "Profile", path: "/profile", icon: <ProfileIcon /> }, */
  {
    name: "Logout",
    path: "/logout",
    onClick: () => {
      window.localStorage.removeItem("readyFitAuth");
      window.localStorage.removeItem("userInfo");
      window.location.replace("/grading");
    },
    icon: <LogoutIcon />,
  },
];
