import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = ({
  color,
  hoverColor,
}: {
  color: string;
  hoverColor: string;
}) =>
  makeStyles((theme: Theme) => ({
    buttonsContainer: {
      alignItems: "center",
      marginLeft: "auto",
      justifyContent: "space-evenly",
    },
    actionButton: {
      width: theme.spacing(15),
      maxWidth: theme.spacing(15),
      maxHeight: theme.spacing(5.25),
      borderRadius: theme.spacing(1),
      border: `1px solid ${theme.palette.primary.main}`,
      display: "flex",
      justifyContent: "space-evenly",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        width: theme.spacing(11),
        maxWidth: theme.spacing(11),
        maxHeight: theme.spacing(4.25),
      },
    },
    passButton: {
      borderColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(0.5),
      },
    },
    failButton: {
      borderColor: theme.palette.error.main,
      color: theme.palette.success.contrastText,
    },
    fillSelected: {
      backgroundColor: color,
      border: `1px solid ${color}`,
      color: "black",
      "&:hover": {
        background: hoverColor,
      },
    },
  }));

export default useStyles;
