import moment from "moment";

export function GetRestTime(
  prevTime: number | null | undefined,
  currTime: number,
) {
  if (prevTime === null || prevTime === undefined) {
    return "-";
  }

  const startTime = new Date(+prevTime);
  const endTime = new Date(+currTime);
  const diffTimeSeconds = Math.round(
    Math.abs(endTime.valueOf() - startTime.valueOf()) * 0.001,
  );

  const minPart = Math.floor(diffTimeSeconds / 60);
  const secPart = diffTimeSeconds % 60;
  return `${minPart > 9 ? minPart : "0" + minPart}:${
    secPart > 9 ? secPart : "0" + secPart
  }`;
}

export function CalculateAge(birthday: number) {
  const ageDifMs = Date.now() - birthday;
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
}

export function isTime(time: string) {
  return timeFromString(time) !== null;
}

export function timeFromString(time: string): GradedExerciseTime | null {
  const regexp = /([0-9]{1,2}):([0-9]{1,2})/g;
  const matches = Array.from(`${time}`.matchAll(regexp));

  if (matches.length > 0) {
    const groups = matches[0];
    const minutes = parseInt(groups[1].toString());
    const seconds = parseInt(groups[2].toString());

    if (groups.length === 3 && !isNaN(minutes) && !isNaN(seconds)) {
      return {
        minutes,
        seconds,
      };
    }
  }

  return null;
}

export function stringFromTime(time: GradedExerciseTime): string {
  if (time.seconds > 60) {
    time.minutes += Math.floor(time.seconds / 60);
    time.seconds = Math.round(time.seconds % 60);
  }
  return `${("00" + time.minutes).slice(-2)}:${("00" + time.seconds).slice(
    -2,
  )}`;
}

export type GradedExerciseTime = {
  minutes: number;
  seconds: number;
};

const units = {
  year: 24 * 60 * 60 * 1000 * 365,
  month: (24 * 60 * 60 * 1000 * 365) / 12,
  day: 24 * 60 * 60 * 1000,
  hour: 60 * 60 * 1000,
  minute: 60 * 1000,
  second: 1000,
};

const rtf = new Intl.RelativeTimeFormat("en", { numeric: "auto" });

export const getRelativeTime = (d1: number, d2 = new Date()) => {
  const elapsed = d1 - +d2;

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (const u in units)
    if (Math.abs(elapsed) > (units as any)[u] || u === "second")
      return rtf.format(Math.round(elapsed / (units as any)[u]), u as any);
};

export const DisplayTimeFromSeconds = (
  totalSeconds: number,
  format?: string,
): string => {
  return moment.utc(totalSeconds * 1000).format(format ?? "mm:ss");
};

export const getSecondsFromTime = (
  time: GradedExerciseTime,
  addTwoMinutesExtra?: boolean,
): number => {
  if (addTwoMinutesExtra) {
    return (time.minutes + 2) * 60 + time.seconds;
  }
  return time.minutes * 60 + time.seconds;
};

export const getRuntime = (endTime: number, startTime: number) => {
  const elapsed = endTime - startTime;
  return moment.utc(elapsed).format("mm:ss");
};
