import {
  Button,
  Grid,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { GridSortModel } from "@mui/x-data-grid";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { LoadingButton } from "src/grading/components";
import AthletesLayout from "src/grading/layout/AthletesLayout/AthletesLayout";
import { DEFAULT_ORGANIZATION } from "src/grading/models";
import { useAppSelector } from "src/store/hooks";
import { selectCurrentUser } from "src/store/reducers/auth/authSlice";
import { useLazyExportUsersQuery } from "src/store/reducers/documents/documentsApiSlice";
import {
  Member,
  Unit,
  useGetOrganizationsQuery,
  useLazyGetMembersQuery,
  useLazyGetUnitsQuery,
} from "src/store/reducers/organizations/organizationsApiSlice";
import theme from "src/theme";
import AddNewAthleteModal from "../LiveTest/SelectAthlete/AddNewAthleteModal/AddNewAthleteModal";
import AthleteMobileTable from "./AthleteMobileTable/AthleteMobileTable";
import useStyles from "./Athletes.styles";
import AthleteFilters from "./AthletesFilters/AthleteFilters";
import AthleteTable from "./AthletesTable/AthletesTable";

export const initialFilterValues = {
  search: "",
  status: "-1",
  unit: "-1",
  sortBy: "-1",
};

const Athletes = () => {
  const classes = useStyles();
  const [getMembers, { isLoading }] = useLazyGetMembersQuery();
  const [members, setMembers] = useState<Member[]>([]);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useAppSelector(selectCurrentUser);
  const [newAthleteModalOpen, setNewAthleteModalOpen] = useState(false);
  const [filters, setFilters] = useState<{
    search?: string;
    status?: string;
    unit?: string;
    sortBy?: string;
  }>({});

  const [exportAthletes, { isLoading: isExporting }] =
    useLazyExportUsersQuery();

  const { data: organizationsData } = useGetOrganizationsQuery();
  const [organizationId, setOrganizationId] = useState(
    user.organizationId ? user.organizationId : DEFAULT_ORGANIZATION.id,
  );
  const [units, setUnits] = useState<Unit[]>([]);
  const [getUnits] = useLazyGetUnitsQuery();

  useEffect(() => {
    const fetchUnits = async () => {
      const { units } = await getUnits({ organizationId }).unwrap();
      setUnits(units);
    };

    fetchUnits();
  }, [getUnits, organizationId]);

  const handleExportAthletes = async () => {
    let url = "";
    try {
      url = await exportAthletes({
        organizationId,
        search: filters.search,
        status: filters.status,
        unit: filters.unit,
        sortBy: filters.sortBy,
      }).unwrap();
    } catch (error) {
      console.log(error);
    }

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getMembers({
        organizationId,
      }).unwrap();

      if (response?.members.length > 0) {
        setMembers(response.members);
      }
    };

    fetchUsers();
  }, [getMembers, organizationId]);

  const formik = useFormik({
    initialValues: initialFilterValues,
    onSubmit: async (values) => {},
  });

  const { values, setFieldValue, errors, touched } = formik;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((searchValue) => {
      setFilters((f) => ({ ...f, search: searchValue }));
      const fetchUsers = async () => {
        const response = await getMembers({
          organizationId,
          search: searchValue,
        }).unwrap();
        if (response?.members.length > 0) {
          setMembers(response?.members);
        }
      };

      fetchUsers();
    }, 500),
    [],
  );

  const filterSearch = (filter: {
    search?: string;
    unit?: string;
    status?: string;
    sortBy?: string;
  }) => {
    const { search, unit, status, sortBy } = filter;
    setFilters((f) => ({ ...f, search, unit, status, sortBy }));
    const fetchUsers = async () => {
      const response = await getMembers({
        organizationId,
        search,
        unit,
        status,
        sortBy,
      }).unwrap();

      if (response?.members) setMembers(response.members);
    };

    fetchUsers();
  };

  return (
    <AthletesLayout>
      <Grid container className={classes.container}>
        {/* ATHLETES HEADER CONTAINER */}
        <Grid container item alignItems={"center"}>
          <Grid item>
            <Grid container alignItems={"center"}>
              <Typography variant="h2">Athletes</Typography>
              <Typography variant="caption" className={classes.usersLength}>
                {members.length}
              </Typography>
              {organizationsData?.organizations &&
              organizationsData.organizations.length > 1 ? (
                <Select
                  variant="outlined"
                  className={classes.select}
                  value={organizationId}
                  onChange={(e) => setOrganizationId(e.target.value)}
                >
                  {organizationsData?.organizations?.map((o) => (
                    <MenuItem key={o.id} value={o.id}>
                      {o.name}
                    </MenuItem>
                  ))}
                </Select>
              ) : (
                <Typography variant="caption" className={classes.usersLength}>
                  {user.organizationId
                    ? user?.organization?.name
                    : DEFAULT_ORGANIZATION.name}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid item className={classes.newAthleteButtonContainer}>
            <LoadingButton
              variant="contained"
              color="primary"
              label="Export Athletes"
              loading={isExporting}
              style={{
                marginRight: theme.spacing(2),
                width: theme.spacing(21),
              }}
              onClick={handleExportAthletes}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => setNewAthleteModalOpen(true)}
            >
              New Athlete
            </Button>
          </Grid>
        </Grid>

        {/* FILTERS */}
        <AthleteFilters
          values={values}
          setFieldValue={setFieldValue}
          debounceSearch={debounceSearch}
          filterSearch={filterSearch}
          errors={errors}
          touched={touched}
          units={units}
        />

        {/* Table */}
        {!isSmallScreen ? (
          <AthleteTable
            members={members}
            isLoading={isLoading}
            sortModel={sortModel}
            setSortModel={setSortModel}
          />
        ) : (
          <AthleteMobileTable
            members={members}
            isLoading={isLoading}
            sortModel={sortModel}
            setSortModel={setSortModel}
          />
        )}

        <AddNewAthleteModal
          onClose={() => setNewAthleteModalOpen(false)}
          open={newAthleteModalOpen}
        />
      </Grid>
    </AthletesLayout>
  );
};

export default Athletes;
