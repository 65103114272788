import { CheckCircleOutline } from "@mui/icons-material";
import {
  Button,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { LiveTestScore, Profile } from "@readyfit-common/models";
import LineItem from "src/grading/components/LineItem/LineItem";
import { useAppSelector } from "src/store/hooks";
import {
  LiveTestGradeResult,
  selectCurrentLiveTestInfo,
} from "src/store/reducers/liveTest/liveTestSlice";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import theme from "src/theme";
import useStyles from "./SignStepper.styles";

interface SignStepperProps {
  graderProfile: Profile;
  athleteProfile: Profile;
  testScore?: TestScore;
  handleOpenSignModal: (
    profile: Profile,
    signingAs: "athlete" | "grader",
  ) => void;
  setSigningAs: React.Dispatch<
    React.SetStateAction<"athlete" | "grader" | undefined>
  >;
  liveGradeResult?: LiveTestGradeResult;
  athleteScores?: LiveTestScore;
}

const commonStyles = {
  flexDirection: "column",
  alignItems: "flex-start",
};

const SignedStatus = ({ signedBy }: { signedBy: "grader" | "athlete" }) => {
  return (
    <Grid item container alignItems={"center"}>
      <CheckCircleOutline sx={{ color: "#27AE60" }} />
      <Typography
        variant="subtitle1"
        textTransform={"uppercase"}
        fontSize={"14"}
        fontWeight={"bold"}
        marginLeft={1}
      >
        {signedBy === "grader" ? "Grader" : "Athlete"}
      </Typography>
    </Grid>
  );
};

const SignStepper = ({
  testScore,
  handleOpenSignModal,
  graderProfile,
  athleteProfile,
  setSigningAs,
  liveGradeResult,
  athleteScores,
}: SignStepperProps) => {
  const classes = useStyles();
  const liveTestInfo = useAppSelector(selectCurrentLiveTestInfo);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Grid>
      <Grid p={isSmallScreen ? 2 : 4}>
        <Grid mt={1.5} container item flexDirection={"column"}>
          <LineItem
            label={"Type of Test"}
            value={liveTestInfo?.displayName}
            sx={commonStyles}
          />
          <Divider className={classes.divider} />

          <LineItem
            label={"Overall Ranking"}
            // value={testScore?.grades.join("-")}
            value={"Outstanding"}
            sx={commonStyles}
          />
          <Divider className={classes.divider} />
        </Grid>

        <Grid container item flexDirection={"column"}>
          <Typography variant="subtitle1" textTransform={"uppercase"}>
            Total Score
          </Typography>

          <Typography variant="h1">
            {/* {testScore?.score} */}
            {athleteScores?.score?.score}
            {/* <span style={{ color: "#8F8F8F" }}>/{testScore?.maxScore}</span> */}
            <span
              style={{ color: "#8F8F8F" }}
            >{`/${athleteScores?.score?.maxScore}`}</span>
          </Typography>
          <Divider className={classes.divider} />
        </Grid>

        <Grid container item flexDirection={"column"} gap={2}>
          <Typography variant="subtitle1" textTransform={"uppercase"}>
            Sign test
          </Typography>
          {liveGradeResult?.athleteSignatureDetails?.signature ? (
            <Typography variant="subtitle1" textTransform={"uppercase"}>
              <SignedStatus signedBy={"athlete"} />
            </Typography>
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.signButton}
              onClick={() => {
                setSigningAs("athlete");
                handleOpenSignModal(athleteProfile, "athlete");
              }}
            >
              Athlete
            </Button>
          )}

          {liveGradeResult?.graderSignatureDetails?.signature ? (
            <SignedStatus signedBy={"grader"} />
          ) : (
            <Button
              variant="contained"
              color="primary"
              className={classes.signButton}
              onClick={() => {
                setSigningAs("grader");
                handleOpenSignModal(graderProfile, "grader");
              }}
            >
              Grader
            </Button>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SignStepper;
