import { Box, BoxProps, Modal, ModalProps } from "@mui/material";
import useStyles from "./RFModal.styles";

interface RFModalProps extends ModalProps {
  boxProps?: BoxProps;
}

const RFModal = (props: RFModalProps) => {
  return (
    <div style={{ backgroundColor: "yellow" }}>
      <Modal
        {...props}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box {...props.boxProps}>{props.children}</Box>
      </Modal>
    </div>
  );
};

export default RFModal;
