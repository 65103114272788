import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  toolbarContainer: {
    marginTop: theme.spacing(4),
  },
  toolbarButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.error.contrastText,
    heightt: "100%",
    border: "1px solid #DBDBDB",
    borderRadius: theme.spacing(1.25),
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  paperContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    width: "100%",
  },
}));

export default useStyles;
