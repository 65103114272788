import {
  ExerciseResult,
  isCardio,
  isPassFail,
  isRepForTime,
  isReps,
} from "@readyfit-common/models";
import { ExerciseScore } from "src/store/reducers/scores/scoresApiSlice";
import {
  ExerciseFormat,
  TestStepDefinitionFormat,
  isChoice,
  isExerciseStep,
} from "src/store/reducers/tests/testsApiSlice";
import { GradeTestExerciseCardio } from "./components/GradeTestExerciseCardio/GradeTestExerciseCardio";
import { GradeTestExercisePassFail } from "./components/GradeTestExercisePassFail/GradeTestExercisePassFail";
import { GradeTestExerciseReps } from "./components/GradeTestExerciseReps/GradeTestExerciseReps";
import {
  CardioApprovalGradeResult,
  GradeResult,
  PassFailGradeResult,
  RepCountGradeResult,
  RepTimeGradeResult,
} from "./models/grading";
import { timeFromString } from "./utils";

export function GradeTestExcercise(props: GradeTestExcerciseProps) {
  let stepInfo: ExerciseFormat | undefined = undefined;
  if (props.stepMetadata !== undefined) {
    if (isChoice(props.stepMetadata)) {
      for (let i = 0; i < props.stepMetadata.choices!.length; i++) {
        const exercise = props.stepMetadata.choices![i];
        if (exercise.displayName === props.result.metadata.name) {
          stepInfo = exercise;
          break;
        }
      }
    } else if (isExerciseStep(props.stepMetadata)) {
      stepInfo = props.stepMetadata;
    }
  }

  if (isReps(props.result.metadata)) {
    let currentGrade: RepTimeGradeResult | RepCountGradeResult | undefined =
      undefined;
    if (isRepForTime(props.result.metadata)) {
      currentGrade = (props.currentGrade as RepTimeGradeResult) ?? {
        userResult: props.result.metadata,
        notes: "",
        graderTime: timeFromString(props.result.metadata.reps),
      };
    } else {
      currentGrade = (props.currentGrade as RepCountGradeResult) ?? {
        userResult: props.result.metadata,
        notes: "",
        graderCount: undefined,
      };
    }

    return (
      <GradeTestExerciseReps
        prevEnd={props.prevEnd}
        exercise={props.result.metadata}
        onGrade={props.onGrade}
        currentGrade={currentGrade}
        userScore={props.userScore}
        stepMetadata={stepInfo}
        fileKey={props.userScore?.fileKey}
      />
    );
  } else if (isCardio(props.result.metadata)) {
    const currentGrade = (props.currentGrade as CardioApprovalGradeResult) ?? {
      userResult: props.result.metadata,
      notes: "",
      approved: undefined,
    };
    return (
      <GradeTestExerciseCardio
        prevEnd={props.prevEnd}
        exercise={props.result.metadata}
        onGrade={props.onGrade}
        currentGrade={currentGrade}
        stepMetadata={stepInfo}
        fileKey={props.userScore?.fileKey}
      />
    );
  } else {
    if (isPassFail(props.result.metadata)) {
      const currentGrade = (props.currentGrade as PassFailGradeResult) ?? {
        userResult: props.result.metadata,
        notes: "",
        approved: undefined,
      };
      return (
        <GradeTestExercisePassFail
          prevEnd={props.prevEnd}
          exercise={props.result.metadata}
          onGrade={props.onGrade}
          currentGrade={currentGrade}
          stepMetadata={stepInfo}
          fileKey={props.userScore?.fileKey}
        />
      );
    }
  }
}

// return <div />;

type GradeTestExcerciseProps = {
  result: ExerciseResult;
  userScore?: ExerciseScore;
  prevEnd: number | null; // The end time of the previous step, for rest time displays
  onGrade: (stepGrade: GradeResult) => void;
  currentGrade: GradeResult | undefined;
  stepMetadata: TestStepDefinitionFormat | undefined;
};
