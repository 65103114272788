import {
  ExerciseResultsConfigFormat,
  ExerciseTesting,
} from "src/store/reducers/tests/testsApiSlice";
import { isTime } from "../../src/grading/utils";
import { Profile } from "./Profile";
import { ExerciseScore } from "./TestScore";

const timeRegex = /^(\d{1,2}):([0-5]\d)$/;

export type TestResultId = string;

export interface TestContext {
  testId: string;
  testVersion: number;
  properties?: { [key: string]: string };
}

export interface TestSubmission extends TestContext {
  id: TestResultId;
  timestamp: number;
  datetime: string;
  appVersion: string;
  profileSnapshot: ProfileSnapshot;
  exercises: ExerciseResult[];
  branch: string | null;
}

export interface TestResult extends TestSubmission {
  // Note that the result title might not match the title of the test definition.
  // E.g. for the SEAL test, the title is set by the submitTest function on the backend.
  testTitle: string;
}

export interface GradedTestResult extends TestResult {
  uid?: string;
  isCertified: boolean;
}

export type ProfileSnapshot = Pick<
  Profile,
  "email" | "birthday" | "gender" | "id"
>;

export interface ExerciseResult {
  metadata: ExerciseResultMetadata;
}

export interface ExerciseResultMetadata {
  exerciseId: string;
  startTime: number;
  endTime: number;
  userNotes?: string;
  graderNotes?: string;
  [key: string]: string | number | boolean | number[] | null | undefined;
  passFail?: boolean;

  // This only exists in the grading platform and in the backend, not in the app,
  // to allow the grading platform to override the final score in addition to verifying the results.
  // E.g. for body composition steps, the grader can override a Pass or Fail rather than letting
  // the results go through the scoring tables.
  score?: ExerciseScore["score"];

  // TODO: once we have a shared service/database/codebase across the frontend and backend
  //       for our test definitions, the completed test results shouldn't need to include
  //       display names for these items, which can be looked up by the ID. Scorers are
  //       being updated to use IDs since they don't involve display names, but the grading
  //       platform still relies on these display names being submitted by the app since
  //       this reference data doesn't exist anywhere on the backend.
  name: string;
  exerciseType?:
    | string
    | "cardio"
    | "reps"
    | "bodyComp"
    | "maxTime"
    | "minTime";
}

export interface CardioExerciseResultMetadata extends ExerciseResultMetadata {
  distance: string;
  units: string;
  altitudeMeters?: number;
  filename: string;
  duration?: string;
}

export interface RepExerciseResultMetadata extends ExerciseResultMetadata {
  reps: string;
  filename: string;
  fileKey: string;
}

export interface PassFailExerciseResultMetadata extends ExerciseResultMetadata {
  passFail?: boolean;
}

export interface BodyCompositionResultMetadata extends ExerciseResultMetadata {
  height?: number;
  weight?: number;
  neckCircumference?: number;
  abdomenCircumference?: number;
  hipCircumference?: number;
  waistCircumference?: number;
  bmi?: number;
  bodyFat?: number;
}

export function isCardio(
  metadata: ExerciseResultMetadata,
): metadata is CardioExerciseResultMetadata {
  return "distance" in metadata;
}

export function isPassFail(
  metadata: ExerciseResultMetadata,
): metadata is PassFailExerciseResultMetadata {
  return "passFail" in metadata;
}

export function isReps(
  metadata: ExerciseResultMetadata,
): metadata is RepExerciseResultMetadata {
  return "reps" in metadata;
}

export function isRepsPassFail(
  metadata: ExerciseResultMetadata,
): metadata is RepExerciseResultMetadata {
  return (
    isReps(metadata) &&
    (parseInt(metadata.reps) === 1 || parseInt(metadata.reps) === 0)
  );
}

export function isRepForTime(
  metadata: ExerciseResultMetadata,
): metadata is RepExerciseResultMetadata {
  return isReps(metadata) && isTime(metadata.reps);
}

export function isBodyComp(
  metadata: ExerciseResultMetadata,
): metadata is BodyCompositionResultMetadata {
  return metadata.name === "Body Composition";
}

export function isDistanceInRepExercise(results: ExerciseResultsConfigFormat) {
  return results.resultDescriptionFormat.includes("Feet");
}

export function isTimeExerciseInRep(results: ExerciseTesting) {
  return timeRegex.test(results.userEnteredResult?.maximumValue as string);
}

export interface ReadyFitIndex {
  cardioScore: number;
  strengthScore: number;
}
