import {
  Card,
  CardContent,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { RepExerciseResultMetadata } from "@readyfit-common/models";
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Spinner from "react-bootstrap/Spinner";
import { useLazyGetFileUrlQuery } from "src/store/reducers/documents/documentsApiSlice";
import { ExerciseScore } from "src/store/reducers/scores/scoresApiSlice";
import { ExerciseFormat } from "src/store/reducers/tests/testsApiSlice";
import { ExerciseChecklist } from "../../exerciseChecklist";
import {
  GradeResult,
  RepCountGradeResult,
  RepTimeGradeResult,
  isTimeGrade,
} from "../../models";
import { GetRestTime } from "../../utils";
import useStyles from "./GradeTestExerciseReps.styles";

export function GradeTestExerciseReps(props: GradeTestExerciseRepsProps) {
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoTimerRef = useRef<NodeJS.Timeout>();
  const [videoUrl, setVideoUrl] = useState<URL | "pending" | "not_started">(
    "not_started",
  );

  useEffect(() => () => clearInterval(videoTimerRef.current), []);
  const setVideoRef = useCallback((ref: HTMLVideoElement) => {
    clearInterval(videoTimerRef.current);
    videoTimerRef.current = setInterval(() => {
      const milliseconds = Math.floor((ref.currentTime * 1000) % 1000)
        .toString()
        .padStart(3, "0");
      const seconds = Math.floor(ref.currentTime % 60)
        .toString()
        .padStart(2, "0");
      const minutes = Math.floor(ref.currentTime / 60)
        .toString()
        .padStart(2, "0");
      const timestamp = `${minutes}:${seconds}.${milliseconds}`;
      videoContainerRef.current?.style.setProperty(
        "--video-timestamp",
        `'${timestamp}'`,
      );
    }, 10);
  }, []);

  const [getFileUrl] = useLazyGetFileUrlQuery();

  const classes = useStyles();

  if (videoUrl === "not_started") {
    setVideoUrl("pending");

    getFileUrl(`${props.fileKey}?ext=mp4`)
      .unwrap()
      .then((data) => setVideoUrl(new URL(data.url)));
  }

  const fieldLabel =
    props.stepMetadata?.testing?.userEnteredResult?.label !== undefined
      ? props.stepMetadata?.testing?.userEnteredResult?.label
      : "Reps";
  const fieldStep =
    props.stepMetadata?.testing?.userEnteredResult?.step !== undefined
      ? parseFloat(
          props.stepMetadata?.testing?.userEnteredResult?.step.toString(),
        )
      : 1;
  const fieldUnits = props.stepMetadata?.testing?.userEnteredResult?.unit;

  return (
    <Grid container>
      <Grid item container xs={12}>
        <h1>{props.exercise.name}</h1>
        <div ref={videoContainerRef} className={classes.gradingStepMain}>
          {/* <div className={"grading-step-main"}> */}
          {videoUrl === "pending" || videoUrl === "not_started" ? (
            <Spinner
              animation="border"
              style={{ left: "50%", position: "relative" }}
            />
          ) : (
            <video ref={setVideoRef} controls width="100%">
              <source src={videoUrl.toString()} type="video/mp4" />
            </video>
          )}
        </div>
      </Grid>
      <div className={classes.gradingInputs}>
        <Grid item container xs={12} mt={4}>
          <Grid item xs={12} className={classes.userNotesContainer}>
            <Typography variant="h4" className={classes.userNotesHeader}>
              User Notes
            </Typography>

            <TextField
              label="Notes"
              multiline
              fullWidth
              variant="outlined"
              // helperText="Grading notes will be visible to the test-taker"
              disabled={true}
              value={props.currentGrade.userResult.userNotes}
              placeholder="No notes"
              onChange={(event) => {
                const currentGrade = {
                  ...props.currentGrade,
                  notes: event.target.value,
                };
                props.onGrade(currentGrade);
              }}
            />
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
}

type GradeTestExerciseRepsProps = {
  exercise: RepExerciseResultMetadata;
  userScore?: ExerciseScore;
  prevEnd: number | null;
  currentGrade: RepCountGradeResult | RepTimeGradeResult;
  onGrade: (stepGrade: GradeResult) => void;
  stepMetadata: ExerciseFormat | undefined;
  fileKey: string | undefined;
};
