import {
  LiveTestGradeResult,
  LiveTestSliceState,
  LiveTestStep,
} from "src/store/reducers/liveTest/liveTestSlice";
import { TestStepDefinitionFormat } from "src/store/reducers/tests/testsApiSlice";
import {
  isCardioType,
  isRepsType,
  isTimeExercise,
} from "../components/Stepper/RepsStepper/StepperInput";
import {
  ApprovalGradeResult,
  CardioApprovalGradeResult,
  GradeResult,
  RepCountGradeResult,
  RepTimeGradeResult,
  isApprovalGrade,
} from "../models";
import { DisplayTimeFromSeconds } from "./timeHelpers";

export const getExerciseStepNumber = (
  testSteps: TestStepDefinitionFormat[],
  isLiveTesting?: boolean,
) => {
  const LIVE_TEST_STEPS_BEFORE_FIRST_EXERCISE = 2;
  let steps =
    testSteps[0]?.exerciseType === "bodyComp"
      ? testSteps.length - 1
      : testSteps.length;

  if (isLiveTesting) {
    steps = steps + LIVE_TEST_STEPS_BEFORE_FIRST_EXERCISE;
  }

  return steps;
};

export const getLabelBasedOnGradeResult = (gradeResult: GradeResult) => {
  return (
    ("graderCount" in (gradeResult as RepCountGradeResult) && "Repetitions") ||
    ("graderTime" in (gradeResult as RepCountGradeResult) && "Time") ||
    ("graderTime" in (gradeResult as CardioApprovalGradeResult) &&
      "approved" in (gradeResult as CardioApprovalGradeResult) &&
      "Distance") ||
    ("Grade" in (gradeResult as CardioApprovalGradeResult) && "Approved") ||
    ("passFail" in (gradeResult as ApprovalGradeResult).userResult &&
      "Passed | Failed") ||
    ((gradeResult as ApprovalGradeResult).userResult.exerciseType ===
      "passFail" &&
      "Passed / Failed") ||
    "Repetitions"
  );
};

export const displayTimeFromGraderTime = ({
  minutes,
  seconds,
}: {
  minutes: number;
  seconds: number;
}): string => {
  const totalSeconds = minutes * 60 + seconds;
  return DisplayTimeFromSeconds(totalSeconds);
};

export const getValuesBasedOnGradeResult = (gradeResult: GradeResult) => {
  if ((gradeResult as RepCountGradeResult).graderCount) {
    return (gradeResult as RepCountGradeResult).graderCount || 0;
  }

  if ((gradeResult as RepTimeGradeResult).graderTime) {
    return (
      displayTimeFromGraderTime(
        (gradeResult as RepTimeGradeResult).graderTime,
      ) || 0
    );
  }

  if ((gradeResult as CardioApprovalGradeResult).approved) {
    return (gradeResult as CardioApprovalGradeResult).approved ? "Passed" : 0;
  }

  if ("passFail" in (gradeResult as ApprovalGradeResult).userResult) {
    return (gradeResult as ApprovalGradeResult).userResult.passFail
      ? "Passed"
      : "Failed";
  }

  return 0;
};

export const getExerciseIndex = (
  liveTestStep: number,
  testSteps?: TestStepDefinitionFormat[],
) => {
  return testSteps?.[0].exerciseType === "bodyComp"
    ? liveTestStep - LiveTestStep.GRADING_STEPS + 1
    : liveTestStep - LiveTestStep.GRADING_STEPS;
};

/**
 * If for some reason the grader didn't put in a grade for all athletes
 * this function should take care of adding grades with the minimun values
 * to the liveTest.gradeResults array
 */
export const handleEmptyGrades = (
  liveTestState: LiveTestSliceState,
): Promise<LiveTestGradeResult[]> =>
  new Promise((resolve, reject) => {
    const steps =
      liveTestState.currentLiveTest?.steps[0].exerciseType === "bodyComp"
        ? liveTestState.currentLiveTest.steps?.slice(1)
        : liveTestState.currentLiveTest?.steps;

    const athleteLiveTestGradeResults =
      liveTestState.athletesLiveTestGradeResults;

    // eslint-disable-next-line prefer-const
    let tempAthleteGradeResults: LiveTestGradeResult[] =
      liveTestState.athletesLiveTestGradeResults.slice();

    steps?.forEach((step, stepIndex) => {
      // exercises
      const exercise = step?.choices ? step.choices[0] : step;
      athleteLiveTestGradeResults.forEach(
        (athleteLiveTestGradeResult, athleteLiveTestGradeResultIndex) => {
          if (!athleteLiveTestGradeResult.gradeResults[stepIndex]?.userResult) {
            const initialGradeResultValues: GradeResult = {
              discrepancies: [],
              notes: "",
              exceededRestTime: false,
              userResult: {
                startTime: Date.now(),
                endTime: Date.now(),
                exerciseId: exercise.exerciseId!,
                name: exercise.displayName!,
              },
            };

            let updatedValues: any = {};

            if (isRepsType(exercise?.exerciseType)) {
              updatedValues = {
                ...initialGradeResultValues,
                approved: false,
                graderCount: 0,
                userResult: {
                  ...initialGradeResultValues.userResult,
                  reps: "0",
                  fileKey: "",
                  filename: "",
                },
              } as RepCountGradeResult;
            }

            if (isTimeExercise(exercise?.exerciseType)) {
              updatedValues = {
                ...initialGradeResultValues,
                approved: false,
                graderTime: {
                  minutes: 0,
                  seconds: 0,
                },
                userResult: {
                  ...initialGradeResultValues.userResult,
                  reps: "0",
                  fileKey: "",
                  filename: "",
                },
              } as RepTimeGradeResult;
            }

            if (isCardioType(exercise.exerciseType)) {
              updatedValues = {
                ...initialGradeResultValues,
                approved: false,
                graderTime: {
                  minutes: 0,
                  seconds: 0,
                },
                userResult: {
                  ...initialGradeResultValues.userResult,
                  distance: String(exercise?.testing?.distance?.value) || "0",
                  units: "miles",
                  duration: "0",
                },
              } as CardioApprovalGradeResult;
            }

            if (exercise.exerciseType === "passFail") {
              updatedValues = {
                ...initialGradeResultValues,
                approved: false,
                userResult: {
                  ...initialGradeResultValues.userResult,
                  passFail: false,
                },
              } as ApprovalGradeResult;
            }

            tempAthleteGradeResults[athleteLiveTestGradeResultIndex] = {
              ...tempAthleteGradeResults[athleteLiveTestGradeResultIndex],
              gradeResults: [
                ...tempAthleteGradeResults[
                  athleteLiveTestGradeResultIndex
                ].gradeResults.slice(0, stepIndex),
                updatedValues,
                ...tempAthleteGradeResults[
                  athleteLiveTestGradeResultIndex
                ].gradeResults.slice(stepIndex + 1),
              ],
            };
          }
        },
      );
    });

    resolve(tempAthleteGradeResults);
  });

export const parseTestDuration = (timeStr: string) => {
  if (!timeStr) return "N/A";

  let comparison = null;
  if (timeStr?.includes("under")) {
    comparison = "Under";
  } else if (timeStr?.includes("more")) {
    comparison = "More";
  }

  // @TODO: this is hardcoded for now because of the complexity of the string
  // This should be refactored in the future to be more dynamic
  if (
    timeStr === "35 Minutes (diagnostic test) / 1 hour 20 minutes (full test)"
  )
    return "35 - 80 Minutes";

  // Remove all non-digit characters except for spaces and hyphens
  const cleanedStr = timeStr?.replace(/[^\d\s-]/g, "");

  // Split the string into parts by spaces and hyphens, and filter out empty strings
  const parts = cleanedStr
    .split(/[\s-]+/)
    .filter(Boolean)
    .map(Number);

  // Calculate the min and max time
  const minTime = Math.min(...parts);
  const maxTime = Math.max(...parts);

  if (comparison) {
    if (minTime === maxTime) return `${comparison} ${maxTime} Minutes`;
    return ` ${comparison} ${minTime} - ${maxTime} Minutes`;
  } else {
    if (minTime === maxTime) return `${maxTime} Minutes`;
    return ` ${minTime} - ${maxTime} Minutes`;
  }
};

export const parseLiveTestName = (name: string) => {
  return name.split("(")[0].trim();
};

export const didPassExercise = (grade: string): boolean => {
  return (
    grade?.toLowerCase() === "excellent" ||
    grade?.toLowerCase().includes("satisfactory") ||
    grade?.toLowerCase().includes("high") ||
    grade?.toLowerCase().includes("good") ||
    grade?.toLowerCase().includes("perfect") ||
    grade?.toLowerCase().includes("pass")
  );
};
