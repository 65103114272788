import { Search } from "@mui/icons-material";
import {
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { FormikErrors, FormikTouched } from "formik";
import { inputError } from "src/grading/components/InputError/InputError";
import { Unit } from "src/store/reducers/organizations/organizationsApiSlice";
import { initialFilterValues } from "../Athletes";
import useStyles from "./AthleteFilters.styles";

interface AthleteFilterProps {
  values: typeof initialFilterValues;
  setFieldValue: (field: string, value: string) => void;
  debounceSearch: (searchValue: string) => void;
  filterSearch: (filter: {
    search?: string;
    unit?: string;
    status?: string;
    sortBy?: string;
  }) => void;
  errors: FormikErrors<typeof initialFilterValues>;
  touched: FormikTouched<typeof initialFilterValues>;
  units: Unit[];
}

const AthleteFilters = ({
  values,
  setFieldValue,
  debounceSearch,
  filterSearch,
  errors,
  touched,
  units,
}: AthleteFilterProps) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.filtersContainer}>
      <Grid container item sm={3} xs={12}>
        <TextField
          name="search"
          type="text"
          className={classes.searchField}
          inputProps={{
            className: classes.searchInputField,
          }}
          value={values.search}
          onChange={(e) => {
            setFieldValue("search", e.target.value);
            debounceSearch(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            ),
          }}
          placeholder="Search by user"
          error={inputError("search", touched, errors).error}
          helperText={inputError("search", touched, errors).helperText}
        />
      </Grid>

      <Grid className={classes.secondaryFiltersContainer} container flex={1}>
        <Grid
          item
          container
          className={classes.selectInputContainer}
          xs={5.9}
          sm={2}
        >
          <Select
            name="status"
            value={values.status}
            onChange={(e) => {
              setFieldValue("status", e.target.value);
              filterSearch({
                search: values.search ? values.search : undefined,
                unit: values.unit !== "-1" ? values.unit : undefined,
                status: e.target.value !== "-1" ? e.target.value : undefined,
                sortBy: values.sortBy !== "-1" ? values.sortBy : undefined,
              });
            }}
            className={classes.selectInput}
          >
            <MenuItem value={"-1"} disabled>
              Filter by status
            </MenuItem>
            <MenuItem value={"qualified"}>Qualified</MenuItem>
            <MenuItem value={"not qualified"}>Not Qualified</MenuItem>
            <MenuItem value={"pending"}>Pending</MenuItem>
          </Select>
        </Grid>

        <Grid item className={classes.selectInputContainer} xs={5.9} sm={2}>
          <Select
            name="unit"
            value={values.unit}
            onChange={(e) => {
              setFieldValue("unit", e.target.value);
              filterSearch({
                search: values.search ? values.search : undefined,
                unit: e.target.value !== "-1" ? e.target.value : undefined,
                status: values.status !== "-1" ? values.status : undefined,
                sortBy: values.sortBy !== "-1" ? values.sortBy : undefined,
              });
            }}
            className={classes.selectInput}
          >
            <MenuItem value={"-1"} disabled>
              Filter by unit
            </MenuItem>

            {units.map((u) => (
              <MenuItem key={u.id} value={u.id}>
                {u.name}
              </MenuItem>
            ))}
            {/* <MenuItem value="test-unit">Test unit</MenuItem> */}
          </Select>
        </Grid>

        <Grid
          item
          className={`${classes.selectInputContainer} ${classes.sortByInput}`}
          xs={12}
          sm={2}
        >
          <Select
            name="sortBy"
            value={values.sortBy}
            onChange={(e) => {
              setFieldValue("sortBy", e.target.value);
              filterSearch({
                search: values.search ? values.search : undefined,
                unit: values.unit !== "-1" ? values.unit : undefined,
                status: values.status !== "-1" ? values.status : undefined,
                sortBy: e.target.value !== "-1" ? e.target.value : undefined,
              });
            }}
            className={classes.selectInput}
          >
            <MenuItem value={"-1"} disabled>
              Sort By
            </MenuItem>
            <MenuItem value="recentActivity.desc">
              Most recent activity
            </MenuItem>
            <MenuItem value="recentActivity.asc">Less recent activity</MenuItem>
          </Select>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AthleteFilters;
