import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: "10px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxShadow: "0px 0px 8px 0px rgba(0, 0, 0, 0.15)",
    marginTop: "-10px",
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
  },
  titleContainer: {
    alignItems: "center",
    padding: `${theme.spacing(1)} ${theme.spacing(1)}`,
  },
  stepCount: {
    textTransform: "uppercase",
    backgroundColor: "#D6D6D6",
    color: "#333333",
    borderRadius: "5px",
    marginLeft: theme.spacing(2),
    display: "flex",
    padding: `0 ${theme.spacing(1)}`,
    alignItems: "center",
    justifyContent: "center",
  },
  testInfoContainer: {
    flexDirection: "column",
    backgroundColor: "#232A2D",
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
    },
  },
  testInfoContent: {
    padding: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderColor: "#3F4E57",
  },
  stepCircle: {
    backgroundColor: "#FFFFFF",
    borderRadius: "50%",
    width: theme.spacing(3),
    height: theme.spacing(3),
    position: "relative",
  },
  circleText: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#000000",
  },
  athletesContainer: {
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(3),
    height: "fit-content",
  },
  athleteRow: {
    flexDirection: "row",
    margin: theme.spacing(1),
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.secondary.light}`,
    paddingBottom: theme.spacing(3),
  },
  profileIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(1),
    backgroundColor: "#6FA088",
    border: "1px solid #436052",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  videoIcon: {
    marginLeft: "auto",
  },
}));

export default useStyles;
