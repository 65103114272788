import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    height: "100vh",
    width: "80%",
    padding: "10px",
  },
  toolsContainer: {
    marginTop: theme.spacing(4),
  },
  addUnitButton: {
    backgroundColor: theme.palette.primary.contrastText,
    color: theme.palette.error.contrastText,
    heightt: "100%",
    border: "1px solid #DBDBDB",
    borderRadius: theme.spacing(1.25),
    alignItems: "center",
    justifyContent: "center",
    gap: theme.spacing(2),
  },
  paperContainer: {
    marginTop: theme.spacing(3),
    display: "flex",
    width: "100%",
  },
  root: {
    border: 0,
    "& .MuiDataGrid-columnSeparator": {
      display: "none",
    },
    "& .MuiDataGrid-colCellTitle": {
      overflow: "unset",
    },
  },
  unitText: {
    marginLeft: theme.spacing(2.5),
  },
  filtersContainer: {
    marginTop: theme.spacing(4),
  },
}));

export default useStyles;
