import { Grid, Typography } from "@mui/material";
import useStyles from "../RecruitmentProcessWidget.styles";

export const RecruitmentProcessLabel = ({
  label,
  border,
}: {
  label: string;
  border?: boolean;
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.checkboxContainer}
      sx={
        border
          ? {
              borderBottom: 1,
              borderColor: "#DBDBDB",
            }
          : null
      }
    >
      <Grid container className={classes.label}>
        <Typography variant="caption" fontSize={14}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};
