import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  profileIcon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(1),
    backgroundColor: "#6FA088",
    border: "1px solid #436052",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  nameContainer: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  profileDataColumn: {
    display: "flex",
    flexDirection: "column",
    width: "60%",
    marginLeft: theme.spacing(1),
  },
  tableActionButton: {
    color: "white",
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    backgroundColor: theme.palette.success.contrastText,
    borderRadius: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
}));

export default useStyles;
