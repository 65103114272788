import { Close } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import RFModal from "src/grading/components/RFModal/RFModal";
import {
  TestScore,
  useLazyGetScoreByIdQuery,
} from "src/store/reducers/scores/scoresApiSlice";
import useStyles from "./LastTestScoreModal.styles";

interface lastTestScoreModalProps {
  open: boolean;
  onClose: () => void;
  lastTestScore: TestScore;
}

export interface AddNewAthleteFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  branchOfService?: string;
  birthday: string;
  gender: string;
}

const ExerciseRow = ({
  label,
  value,
  lastExercise,
}: {
  label: string;
  value: string;
  lastExercise: boolean;
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      className={classes.exerciseRowContainer}
      borderBottom={lastExercise ? "0" : `1px solid #DBDBDB`}
    >
      <Grid item xs={6}>
        <Typography
          variant="h4"
          textAlign={"start"}
          color={"#666666"}
          fontWeight={"bold"}
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="h4" textAlign={"end"} fontWeight={"bold"}>
          {value}
        </Typography>
      </Grid>
    </Grid>
  );
};

const LastTestScoreModal = ({
  lastTestScore,
  onClose,
  ...props
}: lastTestScoreModalProps) => {
  const classes = useStyles();
  const [testScore, setTestScore] = useState<TestScore | null>(null);

  const [getScoreById, { isLoading }] = useLazyGetScoreByIdQuery();

  useEffect(() => {
    const loadData = async () => {
      if (lastTestScore?.testResultId) {
        const result = await getScoreById(lastTestScore?.testResultId).unwrap();
        if (result.testScore.testResultId) {
          setTestScore(result.testScore);
        }
      }
    };

    loadData();
  }, [getScoreById, lastTestScore?.testResultId]);

  return (
    <RFModal
      {...props}
      onClose={onClose}
      boxProps={{
        className: classes.container,
      }}
    >
      {isLoading ? (
        <Spinner
          animation="border"
          style={{ left: "50%", position: "relative" }}
        />
      ) : (
        <Grid container>
          <Grid container item alignItems={"center"}>
            <Grid item xs={9}>
              <Typography variant="h5" fontWeight={"bold"}>
                Score Details
              </Typography>
            </Grid>

            <IconButton className={classes.closeIconButton} onClick={onClose}>
              <Close
                style={{
                  color: "#000000",
                }}
              />
            </IconButton>
          </Grid>

          <Grid container className={classes.scoreBannerContainer}>
            <Typography variant="h5" color={"white"} textAlign={"start"}>
              Score
            </Typography>
            <Typography variant="h5" color={"white"} textAlign={"end"}>
              {`${lastTestScore?.score} / ${lastTestScore?.maxScore}`}
            </Typography>
          </Grid>

          <Grid container className={classes.exerciseListContainer}>
            {testScore?.exercises?.map((exercise) => (
              <ExerciseRow
                key={exercise.exerciseId}
                label={exercise.metadata?.name || "Unknown Exercise"}
                value={`${exercise.score} / ${exercise.maxScore}`}
                lastExercise={
                  testScore.exercises[testScore.exercises.length - 1]
                    .exerciseId === exercise.exerciseId
                }
              />
            ))}
          </Grid>
        </Grid>
      )}
    </RFModal>
  );
};

export default LastTestScoreModal;
