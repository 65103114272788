import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import theme from "src/theme";
import useStyles from "./LiveTestAgreementButtons.styles";

interface LiveTestAgreementButtonsProps {
  status: boolean | undefined;
  setStatus: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  onClick: (status: boolean) => void;
}

const LiveTestAgreementButtons = ({
  onClick,
  setStatus,
  status,
}: LiveTestAgreementButtonsProps) => {
  const classes = useStyles({
    color: status ? "#27ae60" : "#c0392b",
    hoverColor: status ? "#2ecc71" : "#e74c3c",
  })();
  const handleClick = (newStatus: boolean) => {
    if (newStatus !== status) {
      setStatus(newStatus);
      onClick(newStatus);
    }
  };
  return (
    <Grid
      item
      container
      xs={12}
      spacing={1}
      className={classes.buttonContainer}
    >
      <Grid item xs={6}>
        <IconButton
          className={`${classes.button} ${status && classes.passButton}`}
          onClick={() => handleClick(true)}
          sx={status ? { backgroundColor: "#27AE60" } : {}}
        >
          {status ? (
            <CheckCircleOutline
              sx={{ color: theme.palette.primary.contrastText }}
            />
          ) : (
            <RadioButtonUnchecked />
          )}
          <Typography
            variant="caption"
            color={status ? theme.palette.primary.contrastText : "inherit"}
          >
            Agree
          </Typography>
        </IconButton>
      </Grid>

      <Grid item xs={6}>
        <IconButton
          className={`${classes.button} ${
            status === false && classes.failButton
          }`}
          onClick={() => handleClick(false)}
          sx={status === false ? { backgroundColor: "#EB5757" } : {}}
        >
          {status === false ? (
            <CheckCircleOutline
              sx={{ color: theme.palette.primary.contrastText }}
            />
          ) : (
            <RadioButtonUnchecked />
          )}
          <Typography
            variant="caption"
            color={
              status === false ? theme.palette.primary.contrastText : "inherit"
            }
          >
            Disagree
          </Typography>
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default LiveTestAgreementButtons;
