import { ArrowForward } from "@mui/icons-material";
import { Divider, Grid, IconButton, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import AssetService from "src/grading/services/AssetService";
import { parseTestDuration } from "src/grading/utils/testHelpers";
import { useAppDispatch } from "src/store/hooks";
import {
  LiveTestStep,
  setCurrentLiveTest,
  setCurrentLiveTestInfo,
  setLiveTestStep,
} from "src/store/reducers/liveTest/liveTestSlice";
import { addBreadcrumb } from "src/store/reducers/navigation/navigationSlice";
import {
  TestDefinitionFormat,
  TestDefinitionInfoType,
} from "src/store/reducers/tests/testsApiSlice";
import theme from "src/theme";
import useStyles from "./LiveTestCard.styles";

interface LiveTestCardProps {
  testDefinitionInfo: TestDefinitionInfoType;
  testDefinition: TestDefinitionFormat;
}

const LiveTestCard = ({
  testDefinition,
  testDefinitionInfo,
}: LiveTestCardProps) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();

  let imageSrc = "";
  if (testDefinitionInfo.buttonImage.name) {
    imageSrc = AssetService.Instance.get(testDefinitionInfo.buttonImage.name);
  }

  const handleOnClick = () => {
    dispatch(setCurrentLiveTest(testDefinition));
    dispatch(setCurrentLiveTestInfo(testDefinitionInfo));
    dispatch(setLiveTestStep(LiveTestStep.SELECT_ATHLETE));
    dispatch(
      addBreadcrumb({
        label: "Live Test",
        index: LiveTestStep.SELECT_TEST,
        path: "/grading/live",
      }),
    );
    history.push(`live/${testDefinition.testId}/${testDefinitionInfo.branch}`);
  };

  return (
    <Grid container xs={12} className={classes.container}>
      <Grid
        container
        item
        md={1}
        sm={2}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {!testDefinition.isDemo && (
          <img
            src={imageSrc}
            alt={`${testDefinitionInfo.displayName}-logo`}
            className={classes.testLogo}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              maxHeight: "100px",
              maxWidth: "100px",
            }}
          />
        )}
      </Grid>
      <Grid container item flex={1} className={classes.titleContainer}>
        <Typography variant="h5">{testDefinitionInfo.displayName}</Typography>
      </Grid>

      <Divider orientation="vertical" className={classes.divider} />

      <Grid
        container
        item
        xs={2}
        flexDirection={"column"}
        justifyContent={"center"}
        position={"relative"}
        marginTop={1.5}
      >
        <Grid item className={classes.cellTitle}>
          <Typography
            variant="subtitle1"
            color={theme.palette.secondary.light}
            textTransform={"uppercase"}
            textAlign={"center"}
          >
            Duration
          </Typography>
        </Grid>
        <Grid item className={classes.cellCaption}>
          <Typography
            variant="caption"
            textAlign={"center"}
            fontWeight={"bold"}
          >
            {parseTestDuration(testDefinitionInfo?.estimatedTime as string)}
          </Typography>
        </Grid>
      </Grid>

      <Divider orientation="vertical" className={classes.divider} />

      <Grid
        container
        item
        xs={2}
        flexDirection={"column"}
        justifyContent={"center"}
        position={"relative"}
        marginTop={1.5}
      >
        <Grid item className={classes.cellTitle}>
          <Typography
            variant="subtitle1"
            color={theme.palette.secondary.light}
            textTransform={"uppercase"}
            textAlign={"center"}
          >
            Exercises
          </Typography>
        </Grid>
        <Grid item className={classes.cellCaption}>
          <Typography variant="h5" textAlign={"center"}>
            {testDefinition.steps.length}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={1}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <IconButton className={classes.buttonContainer} onClick={handleOnClick}>
          <ArrowForward className={classes.buttonIcon} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default LiveTestCard;
