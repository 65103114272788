import { Grid, SxProps, Theme, Typography } from "@mui/material";
import React from "react";

const LineItem = ({
  label,
  value,
  sx,
}: {
  label: string;
  value?: number | string;
  sx?: SxProps<Theme>;
}) => {
  return (
    <Grid
      container
      xs={12}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={sx}
    >
      <Typography
        color={"#666"}
        variant="button"
        maxWidth={"80%"}
        flexWrap={"wrap"}
        fontWeight={"400"}
        textTransform={"none"}
      >
        {label}
      </Typography>

      {value && <Typography variant="caption">{value}</Typography>}
    </Grid>
  );
};

export default LineItem;
