import * as React from "react";

export interface SvgComponentProps {
  fill?: string;
  stroke?: string;
}

const SvgComponent = ({ fill, stroke, ...props }: SvgComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    fill={fill || "none"}
    {...props}
  >
    <path
      stroke={stroke || "#fff"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M3.75 6h16.5m-16.5 6h6.75m-6.75 6H12m7.372-2.378L21.75 18m-1.5-4.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    />
  </svg>
);
export default SvgComponent;
