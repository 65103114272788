import {
  Button,
  Chip,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { LoadingButton } from "src/grading/components";
import RFModal from "src/grading/components/RFModal/RFModal";
import { useRegisterDemoTestsMutation } from "src/store/reducers/demoTests/demoTestsSlice";
import {
  Organization,
  useLazyGetOrganizationsQuery,
} from "src/store/reducers/organizations/organizationsApiSlice";
import theme from "src/theme";
import * as Yup from "yup";
import useStyles from "./RegisterDemoTestsModal.styles";

interface RegisterDemoTestsModalProps {
  open: boolean;
  onClose: () => void;
  fetchDemoTests: () => void;
}

export interface RegisterDemoTestsFormValues {
  ids: string[];
  organizationId: string;
}

const validationSchema = Yup.object().shape({
  ids: Yup.array().of(Yup.string().uuid()).min(1).required("Ids are required"),
  organizationId: Yup.string().required("Organization Id is required"),
});

interface RegisterDemoTestsState {
  testId: string;
  organizations: Organization[];
  idsError?: string;
}

const RegisterDemoTestsModal = (props: RegisterDemoTestsModalProps) => {
  const classes = useStyles();

  const [state, setState] = useState<RegisterDemoTestsState>({
    testId: "",
    organizations: [],
  });

  const [getOrganizations] = useLazyGetOrganizationsQuery();

  const [registerDemoTests, { isLoading }] = useRegisterDemoTestsMutation();

  useEffect(() => {
    const fetchData = async () => {
      const { organizations } = await getOrganizations().unwrap();

      setState((prev) => ({
        ...prev,
        organizations: organizations ?? [],
      }));
    };

    fetchData();
  }, [getOrganizations]);

  const handleRegisterDemoTests = async (
    values: RegisterDemoTestsFormValues,
  ) => {
    const result = await registerDemoTests(values).unwrap();

    if (result.success) {
      handleClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      ids: [],
      organizationId: "",
    },
    validationSchema,
    onSubmit: async (values: RegisterDemoTestsFormValues) => {
      await handleRegisterDemoTests(values);
      props.fetchDemoTests();
      formik.resetForm();
    },
  });

  const { values, setFieldValue, setValues, handleSubmit, errors } = formik;

  const handleClose = () => {
    setState((prev) => ({ ...prev, testId: "", idsError: "" }));
    setValues({ ids: [], organizationId: "" });
    props.onClose();
  };

  return (
    <RFModal
      open={props.open}
      boxProps={{ className: classes.container }}
      onClose={handleClose}
    >
      <Grid
        container
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <Typography variant="h5">Register Demo Tests</Typography>

        <Grid container item marginTop={theme.spacing(2)} spacing={2}>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Tests Ids</InputLabel>
            <TextField
              InputProps={{ className: classes.input }}
              variant="outlined"
              fullWidth
              value={state.testId}
              onChange={(e) => {
                setState((prev) => ({
                  ...prev,
                  testId: e.target.value,
                  idsError: undefined,
                }));
              }}
              onKeyUp={async (e) => {
                if (e.key === "Enter") {
                  try {
                    if (state.testId !== "") {
                      await validationSchema.validateAt("ids", {
                        ids: [...values.ids, state.testId],
                      });

                      setFieldValue("ids", [...values.ids, state.testId]);

                      setState((prev) => ({
                        ...prev,
                        testId: "",
                      }));
                    }

                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  } catch (error) {
                    setState((prev) => ({
                      ...prev,
                      testId: "",
                      idsError: "ids must be valid UUIDs",
                    }));
                  }
                }
              }}
              error={!!state.idsError}
            />
          </Grid>

          <Grid item xs={12}>
            {values.ids.map((id, idx) => (
              <Chip
                key={idx}
                className={classes.chip}
                label={id}
                onDelete={() => {
                  setFieldValue(
                    "ids",
                    values.ids.filter((vid) => vid !== id),
                  );
                }}
              />
            ))}

            {(state.idsError || errors.ids) && (
              <FormHelperText
                style={{ marginLeft: 14, color: "red", fontSize: "14px" }}
                error={!!state.idsError}
              >
                {state.idsError}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Organization</InputLabel>

            <Select
              className={classes.input}
              variant="outlined"
              fullWidth
              value={values.organizationId}
              onChange={(e) => setFieldValue("organizationId", e.target.value)}
            >
              {state.organizations.map((o) => (
                <MenuItem key={o.id} value={o.id}>
                  {o.id}
                </MenuItem>
              ))}
            </Select>

            {errors?.organizationId && (
              <FormHelperText
                style={{ marginLeft: 14, color: "red", fontSize: "14px" }}
                error
              >
                {errors?.organizationId}
              </FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent={"space-between"}
          marginTop={theme.spacing(4)}
        >
          <Grid item>
            <Button
              color="secondary"
              className={` ${classes.button} ${classes.backButton}`}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              className={classes.button}
              loading={isLoading}
              label="Save"
              type="submit"
            />
          </Grid>
        </Grid>
      </Grid>
    </RFModal>
  );
};

export default RegisterDemoTestsModal;
