import { Apple, ArrowForward, Google } from "@mui/icons-material";
import { DatePicker, LoadingButton, LocalizationProvider } from "@mui/lab";
import AdapterMoment from "@mui/lab/AdapterMoment";
import {
  Button,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useFormik } from "formik";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { inputError } from "src/grading/components/InputError/InputError";
import RFSnackbar from "src/grading/components/RFSnackbar/RFSnackbar";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectSnackbar,
  setSnackbar,
} from "src/store/reducers/navigation/navigationSlice";
import {
  PageContent,
  useJoinOrganizationMutation,
  useLazyGetOrganizationContentQuery,
} from "src/store/reducers/users/usersApiSlice";
import theme from "src/theme";
import useStyles from "./LandingPage.styles";
import validationSchema from "./LandingPageFormValidation";

const NAVBAR_LINKS = [
  { name: "Organization", id: "organization" },
  { name: "Registration", id: "registration" },
  { name: "About Us", id: "about-us" },
];

const LandingPage = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [pageContent, setPageContent] = useState<PageContent | null>(null);
  const { organizationId } = useParams<{ organizationId: string }>();
  const history = useHistory();
  const location = useLocation();
  const [getOrganizationContent] = useLazyGetOrganizationContentQuery();
  const [joinOrganization, { isLoading: isLoadingJoinOrganization }] =
    useJoinOrganizationMutation();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const dispatch = useAppDispatch();
  const liveTestSnackbar = useAppSelector(selectSnackbar);
  const dateRef = useRef(null);

  useEffect(() => {
    const fetchPageContent = async () => {
      try {
        const result = await getOrganizationContent(organizationId).unwrap();

        if (result.success) {
          setPageContent(result.data);
        }
      } catch (error) {
        console.log("error", error);
        history.push("/organization/team-nswac");
      }
    };
    fetchPageContent();
    // eslint-disable-next-line
  }, [location.pathname]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      gender: "-1",
      birthday: null,
      joinRequestReason: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      joinOrganization({
        ...values,
        organizationId,
        birthday: moment(values.birthday).format("YYYY-MM-DD"),
      })
        .unwrap()
        .then((result) => {
          if (result.success) {
            dispatch(
              setSnackbar({
                open: true,
                message: "Registration completed!",
                type: "success",
              }),
            );
            formik.resetForm();
            formik.setFieldValue("birthday", "");
          }
        })
        .catch((error) => {
          console.log("error", error);
          dispatch(
            setSnackbar({
              open: true,
              message:
                "Something went wrong on our end, please try again later.",
              type: "error",
            }),
          );
        });
      formik.resetForm();
      formik.setFieldValue("birthday", null);
    },
  });

  const { values, setFieldValue, handleChange, errors } = formik;

  return (
    <div className={classes.container}>
      {/* HEADER */}
      <Grid container className={classes.navbarContainer}>
        <Grid container item xs={12} sm={4} justifyContent={"center"}>
          <img
            alt="logo"
            src={require("../../assets/images/logo.png")}
            className={classes.logo}
          />
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={8}
          justifyContent={"flex-end"}
          className={classes.navigationLinks}
        >
          {NAVBAR_LINKS.map((link, i) => (
            <Button
              key={i}
              onClick={() => {
                if (link.id === "organization") {
                  const element = document.getElementById("organization");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }
                } else if (link.id === "registration") {
                  const element = document.getElementById("registration");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }
                } else if (link.id === "about-us") {
                  const element = document.getElementById("about-us");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }
                }
              }}
            >
              <Typography variant="button" color="white">
                {i === 0 ? pageContent?.organizationName : link.name}
              </Typography>
            </Button>
          ))}
        </Grid>
      </Grid>

      {/* HERO BANNER */}
      <div className={classes.headerContainer}>
        {isMobile ? (
          <Grid container>
            <Grid container>
              <img
                alt="landing-page-background"
                src={require("../../assets/images/landing-page-background-mobile.png")}
                className={classes.backgroundImage}
              />
            </Grid>

            <Grid container className={classes.titleContainer}>
              <Typography variant={"h2"} color="white">
                {pageContent?.testTitle}
              </Typography>

              <Typography variant={"caption"} color={"white"} marginTop={2}>
                {pageContent?.testBody}
              </Typography>

              <LoadingButton
                variant="contained"
                className={classes.registerButton}
                loading={isLoadingJoinOrganization}
                onClick={() => {
                  const element = document.getElementById("registration");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }
                }}
              >
                <Typography variant="button">Register now</Typography>
              </LoadingButton>
            </Grid>
          </Grid>
        ) : (
          <Grid container className={classes.background}>
            <img
              alt="landing-page-background"
              src={require("../../assets/images/landing-page-background.png")}
              className={classes.backgroundImage}
            />
            <Grid item className={classes.titleContainer}>
              <Typography variant="h1" color="white">
                {pageContent?.testTitle}
              </Typography>
              <Typography variant="h4" color={"white"} marginTop={2}>
                {pageContent?.testBody}
              </Typography>
              <Button
                variant="contained"
                className={classes.registerButton}
                onClick={() => {
                  const element = document.getElementById("registration");
                  if (element) {
                    element.scrollIntoView({
                      behavior: "smooth",
                      block: "center",
                    });
                  }
                }}
              >
                <Typography variant="button">Register now</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </div>

      {/* PREP CONTNET */}

      <Grid
        container
        className={classes.coronadoPrepContainer}
        id="organization"
      >
        <Grid
          container
          item
          className={classes.coronadoPrepImageContainer}
          sm={6}
        >
          <img
            src={pageContent?.organizationImageUrl}
            alt="organization"
            className={classes.coronadoPrepImage}
          />
        </Grid>

        <Grid container item sm={5}>
          <Typography
            variant={isMobile ? "h5" : "h3"}
            marginTop={isMobile ? 4 : 0}
          >
            {pageContent?.organizationName}
          </Typography>

          <Typography
            variant="caption"
            marginTop={isMobile ? theme.spacing(3) : theme.spacing(2)}
          >
            {pageContent?.organizationBody}
          </Typography>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      {/* REGISTRATION FORM */}
      <Grid
        container
        className={classes.registrationContainer}
        id={"registration"}
      >
        <Grid container item sm={6} justifyContent={"center"}>
          <Grid
            container
            item
            style={{
              width: isMobile ? "100%" : "70%",
            }}
            flexDirection={"column"}
          >
            {isMobile && (
              <img
                src={
                  isMobile
                    ? require("../../assets/images/registration-image-mobile.png")
                    : require("../../assets/images/registration-image.png")
                }
                alt="registration"
                className={classes.registrationImage}
              />
            )}
            <Typography
              variant={isMobile ? "h5" : "h3"}
              marginTop={theme.spacing(4)}
            >
              Registration
            </Typography>

            <Typography
              variant="caption"
              marginTop={isMobile ? theme.spacing(3) : 1}
            >
              {`Complete the form below to confirm your invitation to testing via the ReadyFit app. Once a group lead approves your request, you will receive additional instructions to download the app and you can begin taking tests.`}
            </Typography>

            <Grid
              container
              item
              marginTop={isMobile ? theme.spacing(4) : theme.spacing(2)}
              spacing={2}
              component={"form"}
            >
              <Grid container item md={6} className={classes.inputContainer}>
                <InputLabel htmlFor="firstName" className={classes.inputLabel}>
                  First Name
                </InputLabel>
                <TextField
                  name="firstName"
                  type="text"
                  value={values.firstName}
                  onChange={handleChange("firstName")}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  error={
                    inputError("firstName", formik.touched, formik.errors).error
                  }
                  helperText={
                    inputError("firstName", formik.touched, formik.errors)
                      .display
                  }
                />
              </Grid>

              <Grid container item md={6} className={classes.inputContainer}>
                <InputLabel htmlFor="lastName" className={classes.inputLabel}>
                  Last Name
                </InputLabel>
                <TextField
                  name="lastName"
                  type="text"
                  value={values.lastName}
                  onChange={handleChange("lastName")}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  error={
                    inputError("lastName", formik.touched, formik.errors).error
                  }
                  helperText={
                    inputError("lastName", formik.touched, formik.errors)
                      .display
                  }
                />
              </Grid>

              <Grid container item className={classes.inputContainer}>
                <InputLabel htmlFor="email" className={classes.inputLabel}>
                  Email
                </InputLabel>
                <TextField
                  name="email"
                  type="text"
                  value={values.email}
                  onChange={handleChange("email")}
                  InputProps={{
                    classes: {
                      input: classes.input,
                    },
                  }}
                  error={
                    inputError("email", formik.touched, formik.errors).error
                  }
                  helperText={
                    inputError("email", formik.touched, formik.errors).display
                  }
                  placeholder="Enter your email here"
                />
              </Grid>

              <Grid container item md={6} className={classes.inputContainer}>
                <InputLabel htmlFor="gender" className={classes.inputLabel}>
                  Gender
                </InputLabel>
                <Select
                  name="gender"
                  type="text"
                  value={values.gender}
                  onChange={(e) => setFieldValue("gender", e.target.value)}
                  className={classes.input}
                  inputProps={{
                    className: classes.selectInput,
                  }}
                >
                  <MenuItem value={"-1"} disabled>
                    Select one
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                </Select>
                {errors?.gender && (
                  <FormHelperText
                    style={{ marginLeft: 14, color: "red", fontSize: "14px" }}
                    error
                  >
                    {errors?.gender}
                  </FormHelperText>
                )}
              </Grid>

              <Grid container item md={6} className={classes.inputContainer}>
                <InputLabel className={classes.inputLabel}>Birthday</InputLabel>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    value={values.birthday}
                    open={openDatePicker}
                    onOpen={() => setOpenDatePicker(true)}
                    onClose={() => setOpenDatePicker(false)}
                    onChange={(date) => {
                      if (date) {
                        console.log("date", date);
                        setFieldValue(
                          "birthday",
                          moment(date).format("YYYY-MM-DD"),
                        );
                        setOpenDatePicker(false);
                      }
                    }}
                    ref={dateRef}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="birthday"
                        id="birthday"
                        variant="outlined"
                        fullWidth
                        error={
                          inputError("birthday", formik.touched, formik.errors)
                            .error
                        }
                        helperText={
                          inputError("birthday", formik.touched, formik.errors)
                            .display
                        }
                        InputProps={{
                          classes: {
                            input: classes.input,
                          },
                        }}
                        onBlur={() => {
                          console.log("executing");
                          setFieldValue(
                            "birthday",
                            moment(values.birthday).format("YYYY-MM-DD"),
                          );
                        }}
                        value={values.birthday}
                        onClick={() => setOpenDatePicker(true)}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid container item className={classes.inputContainer}>
                <InputLabel
                  htmlFor="joinRequestReason"
                  className={classes.inputLabel}
                >
                  {`Why are you requesting an invite and how did you hear about this?`}
                </InputLabel>
                <TextField
                  name="joinRequestReason"
                  type="text"
                  value={values.joinRequestReason}
                  onChange={handleChange("joinRequestReason")}
                  InputProps={{
                    classes: {
                      input: classes.multilineInput,
                      formControl: classes.multilineInput,
                    },
                    disableUnderline: true,
                  }}
                  multiline
                  rows={3}
                  fullWidth
                  error={
                    inputError(
                      "joinRequestReason",
                      formik.touched,
                      formik.errors,
                    ).error
                  }
                  helperText={
                    inputError(
                      "joinRequestReason",
                      formik.touched,
                      formik.errors,
                    ).display
                  }
                  placeholder="Add your answer"
                  variant="filled"
                />
              </Grid>
              <Grid container>
                <Button
                  variant="contained"
                  className={classes.registerButton}
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                  }}
                  disabled={isLoadingJoinOrganization || !formik.isValid}
                >
                  <Typography variant="button">Register now</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {!isMobile && (
          <Grid container item sm={6} justifyContent={"center"}>
            <img
              src={require("../../assets/images/registration-image.png")}
              alt="registration"
              className={classes.registrationImage}
            />
          </Grid>
        )}
      </Grid>

      {/* READYFIT INFO */}

      <Grid container className={classes.readyfitContainer}>
        <img
          src={
            isMobile
              ? require("../../assets/images/readyfit-footer-image-mobile.png")
              : require("../../assets/images/readyfit-footer-image.png")
          }
          alt="readyfit"
          className={classes.readyfitImage}
        />

        <Grid container item className={classes.readyfitContentContainer}>
          <Typography
            variant={isMobile ? "h5" : "h1"}
            marginTop={theme.spacing(4)}
            color={"#ffffff"}
          >
            ReadyFit
          </Typography>

          <Typography
            variant="caption"
            marginTop={theme.spacing(3)}
            color={"#ffffff"}
            fontSize={isMobile ? "16px" : "18px"}
          >
            {`We are on a mission, administering the boldest tests for military readiness ever created. We are supporting the next generation of America's fighting forces while encouraging active personnel and veterans to stay physically fit. See how you measure up against today's military fitness standards.`}
          </Typography>
        </Grid>
      </Grid>

      <Grid item container className={classes.footerContainer} id={"about-us"}>
        <Grid container item flexDirection={"column"} md={3}>
          <img
            src={require("../../assets/images/readyfit-logotype.png")}
            alt="readyfit-logotype"
            className={classes.readyfitLogotype}
          />

          <Typography
            variant="caption"
            fontSize={"14px"}
            color={"#ffffff"}
            marginTop={1}
          >
            An Easy Day Sports Company
          </Typography>
        </Grid>

        <Grid item container marginTop={2} flexDirection={"column"} md={3}>
          <Typography variant="h4" color={"#ffffff"}>
            Contact
          </Typography>
          <Typography variant="subtitle1" color={"#ffffff"} fontSize={"14px"}>
            info@readyfit.com
          </Typography>
        </Grid>

        <Grid item container marginTop={2} flexDirection={"column"} md={3}>
          <Typography variant="h4" color={"#ffffff"}>
            Location
          </Typography>
          <Typography variant="subtitle1" color={"#ffffff"} fontSize={"14px"}>
            Coronado, CA
          </Typography>
        </Grid>

        <Grid item container marginTop={2} flexDirection={"column"} md={3}>
          <Typography variant="h4" color={"#ffffff"}>
            Download the App
          </Typography>
          <Grid
            container
            className={classes.storesIconContainer}
            component={"a"}
            href="https://apps.apple.com/es/app/readyfit-fitness-tests/id1555380908"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Apple style={{ color: "white" }} />
            <Typography variant="button" color={"#ffffff"} fontSize={"14px"}>
              App Store
            </Typography>
            <ArrowForward style={{ color: "white" }} />
          </Grid>

          <Grid
            container
            className={classes.storesIconContainer}
            component={"a"}
            href="https://play.google.com/store/apps/details?id=com.readyfit.ReadyFit"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Google style={{ color: "white" }} />
            <Typography variant="button" color={"#ffffff"} fontSize={"14px"}>
              Play Store
            </Typography>
            <ArrowForward style={{ color: "white" }} />
          </Grid>
        </Grid>
      </Grid>
      <RFSnackbar
        open={liveTestSnackbar.open}
        onClose={() => dispatch(setSnackbar({ open: false, message: "" }))}
        message={liveTestSnackbar.message}
        type={liveTestSnackbar.type as "success" | "error"}
      />
    </div>
  );
};

export default LandingPage;
