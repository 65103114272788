import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

type SwitchColors = {
  activeColor?: string;
  inactiveColor?: string;
  inactiveTrackColor?: string;
};

const useStyles = ({
  inactiveColor,
  activeColor,
  inactiveTrackColor,
}: SwitchColors) =>
  makeStyles((theme: Theme) => ({
    switchBase: {
      color: inactiveColor || "green", // color when unchecked
      "&$checked": {
        color: activeColor || "red", // color when checked
      },
      "&$checked + $track": {
        backgroundColor: activeColor || "red", // background color when checked
      },
    },
    checked: {},
    track: {
      backgroundColor: inactiveTrackColor || "green",
    },
  }));

export default useStyles;
