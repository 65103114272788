import {
  Button,
  Card,
  CardHeader,
  CardMedia,
  Grid,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { isCardio } from "@readyfit-common/models";
import GpxParser from "gpxparser";
import { useState } from "react";
import { MapContainer, Polyline, TileLayer } from "react-leaflet";
import useStepMetadata from "src/grading/hooks/useStepMetadata";
import { CardioApprovalGradeResult } from "src/grading/models";
import { GetRestTime } from "src/grading/utils";
import { didPassExercise } from "src/grading/utils/testHelpers";
import { useAppSelector } from "src/store/hooks";
import { useLazyGetFileUrlQuery } from "src/store/reducers/documents/documentsApiSlice";
import {
  selectGradingStep,
  selectGradingTest,
} from "src/store/reducers/grading/gradingSlice";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import theme from "src/theme";
import useStyles from "./ReviewApprovalCardioCard.styles";

type ReviewApprovalCardProps = CardioApprovalGradeResult & {
  regradeClick: () => void;
  fileKey: string | undefined;
  step: number;
  testScore: TestScore;
};

export function ReviewApprovalCardio(props: ReviewApprovalCardProps) {
  const classes = useStyles();

  const [gpxData, setGpxData] = useState<GpxParser | "pending" | "not_started">(
    "not_started",
  );
  // const [isResultStepper, setIsResultStepper] = useState(false);
  const distanceInMiles = props?.userResult?.distance;
  useStepMetadata(props?.step);
  const runTime = GetRestTime(
    props.userResult.startTime,
    props.userResult.endTime,
  );

  const gradingStep = useAppSelector(selectGradingStep);
  const [getFileUrl] = useLazyGetFileUrlQuery();

  if (gpxData === "not_started" && isCardio(props.userResult)) {
    setGpxData("pending");
    getFileUrl(props.fileKey)
      .unwrap()
      .then((response) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = (event) => {
          const parser = new GpxParser();
          parser.parse(xhr.response);
          setGpxData(parser);
        };
        xhr.open("GET", response.url);
        xhr.send();
      });
  }

  let positions: [number, number][] = [];
  if (gpxData !== "pending" && gpxData !== "not_started") {
    if (gpxData.tracks[0])
      positions = gpxData.tracks[0].points.map((p) => [p.lat, p.lon]);
  }

  const isResultStepper =
    gradingStep > (props.testScore?.exercises?.length ?? 0);

  const grade = props.testScore?.exercises[props.step].grade;

  return (
    <Grid container spacing={2} xs={12}>
      <Card className={classes.gradingReviewCard}>
        <CardHeader
          className={classes.cardHeader}
          title={
            <Grid item container xs={12} alignItems={"center"}>
              <Typography
                variant="subtitle1"
                className={classes.stepNumberContainer}
              >
                {props.step}
              </Typography>
              <Typography variant="h4">{props.userResult.name}</Typography>

              <Typography
                variant="button"
                className={
                  didPassExercise(grade || "")
                    ? classes.approveLabel
                    : classes.disapprovedLabel
                }
              >
                {grade}
              </Typography>

              {!isResultStepper && (
                <Grid item>
                  <Button
                    onClick={props.regradeClick}
                    className={classes.regradeButton}
                  >
                    Re-grade
                  </Button>
                </Grid>
              )}
            </Grid>
          }
        />
        <Grid container xs={12} px={4} py={3} gap={1}>
          <Grid item xs={4}>
            <CardMedia>
              {positions.length > 0 && (
                <MapContainer
                  center={positions[Math.round(positions.length / 2)]}
                  zoom={14}
                  scrollWheelZoom={false}
                  className="grading-map-review"
                >
                  <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                  <Polyline
                    pathOptions={{ fillColor: "red", color: "green" }}
                    positions={positions}
                  />
                </MapContainer>
              )}
            </CardMedia>
          </Grid>

          <Grid item xs={4} justifyContent={"center"}>
            <Grid item flexDirection={"column"} ml={theme.spacing(3)}>
              <Typography variant="subtitle1">Calculated Run Time</Typography>

              <Typography variant="h1">{runTime}</Typography>
            </Grid>

            <Grid item flexDirection={"column"} ml={theme.spacing(3)}>
              <Typography variant="subtitle1">Distance</Typography>

              <Typography variant="h1">{distanceInMiles}</Typography>
            </Grid>
          </Grid>

          <Grid item flexDirection={"column"} marginLeft={"auto"}>
            <Typography variant="subtitle1">Total Score</Typography>

            <Typography variant="h1">
              {props.testScore?.exercises[props?.step].score}
              <span style={{ color: "#8F8F8F" }}>
                /{props.testScore?.exercises[props?.step].maxScore}
              </span>
            </Typography>
          </Grid>

          <Grid xs={11}>
            <InputLabel>
              <Typography variant="subtitle1">Grader Notes</Typography>
            </InputLabel>
            <TextField
              fullWidth
              multiline
              variant="outlined"
              value={props.userResult?.graderNotes || "N/A"}
              disabled={true}
              placeholder="Grader notes about exercise"
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

const avatarStyles = (backgroundColor: string) =>
  makeStyles((theme) =>
    createStyles({
      root: {
        backgroundColor,
      },
    }),
  );
