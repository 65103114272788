import {
  Divider,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { ExerciseResultMetadata, isBodyComp } from "@readyfit-common/models";
import { DotIcon, TestOverviewIcon } from "src/grading/assets";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectGradingStep,
  setGradingStep,
} from "src/store/reducers/grading/gradingSlice";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import theme from "src/theme";
import useStyles from "./OverviewStepper.styles";

interface OverviewStepperProps {
  test: TestScore;
}

const OverviewStepper = ({ test }: OverviewStepperProps) => {
  const step = useAppSelector(selectGradingStep);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  return (
    <Grid>
      <Grid p={4}>
        <Typography variant="h4" fontSize={"18px"}>
          {test.testTitle}
        </Typography>

        <Grid
          mt={2.5}
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Typography variant="caption" color={"#666"}>
            Taken on
          </Typography>
          {test.timestamp && (
            <Typography variant="caption" color={"#000"}>
              {new Date(Number(test.timestamp)).toLocaleDateString()}
            </Typography>
          )}
        </Grid>
        <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
        <Grid
          mt={1.5}
          container
          flexDirection={"row"}
          justifyContent={"space-between"}
        >
          <Typography variant="caption" color={"#666"}>
            Hour
          </Typography>
          {test.timestamp && (
            <Typography variant="caption" color={"#000"}>
              {new Date(Number(test.timestamp)).toLocaleTimeString()}
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid alignItems={"center"} p={4}>
        <Typography variant="h4" fontSize={"18px"}>
          Steps
        </Typography>
        <Stepper
          sx={{ marginTop: theme.spacing(2) }}
          activeStep={
            step +
            (test.exercises.some((ex) =>
              isBodyComp(ex.metadata as ExerciseResultMetadata),
            )
              ? 1
              : 0)
          }
          orientation="vertical"
        >
          <Step key={0}>
            <StepLabel StepIconComponent={() => <DotIcon stroke="black" />}>
              Overview
            </StepLabel>
          </Step>
          {test.exercises.map((stepData, idx) => {
            const hasBodyCompExercise = isBodyComp(test.exercises[0].metadata);
            if (!isBodyComp(stepData.metadata as ExerciseResultMetadata)) {
              return (
                <Step key={idx + 1}>
                  <StepLabel
                    sx={{ marginLeft: theme.spacing(1) }}
                    onClick={() =>
                      step > idx && dispatch(setGradingStep(idx + 1))
                    }
                    className={step > idx ? `${classes.clickable}` : ""}
                    StepIconComponent={() =>
                      hasBodyCompExercise ? idx : idx + 1
                    }
                  >
                    {stepData.metadata?.name}
                  </StepLabel>
                </Step>
              );
            } else {
              return <></>;
            }
          })}
          <Step key={test.exercises.length}>
            <StepLabel
              StepIconComponent={() => <TestOverviewIcon stroke="black" />}
            >
              Test Review
            </StepLabel>
          </Step>
          <Step key={test.exercises.length}>
            <StepLabel StepIconComponent={() => <DotIcon stroke="black" />}>
              Finish
            </StepLabel>
          </Step>
        </Stepper>
      </Grid>
    </Grid>
  );
};

export default OverviewStepper;
