import { ArrowBack } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { GRADING_ROUTES } from "src/grading/routes/GradingRoutes";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { setLiveTestStep } from "src/store/reducers/liveTest/liveTestSlice";
import {
  BreadcrumbItem,
  removeBreadcrumb,
  selectBreadcrumbs,
} from "src/store/reducers/navigation/navigationSlice";
// import theme from "src/theme";
import useStyles from "./GradingHeader.styles";

interface GradingHeaderProps {
  title?: string;
  label?: string;
  onClick?: () => void;
  withBreadcrumbs?: boolean;
  containerStyles?: React.CSSProperties;
}

const GradingHeader = ({
  title,
  label,
  onClick,
  withBreadcrumbs,
  containerStyles,
}: GradingHeaderProps) => {
  const history = useHistory();
  const breadcrumbs = useAppSelector(selectBreadcrumbs);
  const dispatch = useAppDispatch();
  const location = useLocation();

  const handleBreadcrumbClick = (index: number, breadcrumb: BreadcrumbItem) => {
    if (breadcrumb?.path === location.pathname) return;
    if (breadcrumb?.path) {
      history.push(breadcrumb.path);
      dispatch(removeBreadcrumb(index));
      return;
    }

    console.log("breadcrumb.index", breadcrumb.index);
    dispatch(setLiveTestStep(Number(breadcrumb.index)));
    dispatch(removeBreadcrumb(index));
  };

  const classes = useStyles();
  return (
    <Grid item container mt={3} height={"fit-content"} style={containerStyles}>
      <Grid item ml={-2}>
        {withBreadcrumbs && breadcrumbs.length > 0 ? (
          <Grid container item ml={2}>
            <ArrowBack />

            {breadcrumbs.map((breadcrumb, index) => {
              return (
                <Typography
                  key={index}
                  textTransform={"uppercase"}
                  color={"#000"}
                  onClick={() => handleBreadcrumbClick(index, breadcrumb)}
                  className={classes.navigationButton}
                  variant="button"
                >
                  {index === 0 ? breadcrumb.label : ` / ${breadcrumb.label}`}
                </Typography>
              );
            })}
          </Grid>
        ) : !withBreadcrumbs ? (
          <IconButton
            onClick={() =>
              onClick || history.push(GRADING_ROUTES.PENDING_TESTS)
            }
            style={{ backgroundColor: "transparent" }}
          >
            <ArrowBack />

            <Typography
              textTransform={"uppercase"}
              variant="button"
              color={"#000"}
            >
              {label || "Pending Tests / Grading"}
            </Typography>
          </IconButton>
        ) : null}
      </Grid>

      {title && <Typography variant="h2">{title}</Typography>}
    </Grid>
  );
};

export default GradingHeader;
