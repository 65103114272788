import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = ({
  userScore,
  officialScore,
  maximunValue,
}: {
  userScore: number;
  officialScore: number;
  maximunValue: number;
}) =>
  makeStyles((theme: Theme) => ({
    gradingReviewCard: {
      minWidth: "300px",
      width: "100%",
      display: "inline-block",
      margin: "15px",
      // minHeight: "400px",
      verticalAlign: "top",
    },
    cardHeader: {
      backgroundColor: "#F2F2F2",
    },
    stepNumberContainer: {
      // padding: theme.spacing(1),
      backgroundColor: "#DBDBDB",
      width: theme.spacing(3),
      height: theme.spacing(3),
      borderRadius: "50%",
      textAlign: "center",
      lineHeight: theme.spacing(3),
      color: theme.palette.error.contrastText,
      marginRight: theme.spacing(1),
    },
    approveLabel: {
      backgroundColor: theme.palette.success.main,
      borderRadius: "10px",
      marginLeft: "auto",
      marginRight: theme.spacing(1),
      color: theme.palette.primary.contrastText,
      padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    },
    regradeButton: {
      backgroundColor: theme.palette.warning.contrastText,
      borderRadius: "5px",
      marginRight: theme.spacing(3),
      color: theme.palette.primary.contrastText,
    },
    gradingReviewCardVideoContainer: {
      width: "250px",
      maxHeight: "175px",
      borderRadius: "8px",
    },
    gradingReviewCardVideo: {
      maxHeight: "175px",
      borderRadius: "8px",
      backgroundColor: theme.palette.error.contrastText,
    },
    userGradingBar: {
      backgroundColor: "#C5D9CF",
      borderRadius: theme.spacing(1),
      padding: "6px 0px 6px 5px",
      width: `${(userScore / maximunValue) * 100}%`,
    },
    graderGradingBar: {
      backgroundColor: "#9ABDAC",
      borderRadius: theme.spacing(1),
      padding: "6px 0px 6px 5px",
      width: `${(officialScore / maximunValue) * 100}%`,
    },
  }));

export default useStyles;
