import {
  Button,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { LoadingButton } from "src/grading/components";
import RFModal from "src/grading/components/RFModal/RFModal";
import { useResetDemoTestsMutation } from "src/store/reducers/demoTests/demoTestsSlice";
import {
  Organization,
  useLazyGetOrganizationsQuery,
} from "src/store/reducers/organizations/organizationsApiSlice";
import theme from "src/theme";
import * as Yup from "yup";
import useStyles from "./ResetDemoTestsModal.styles";

interface ResetDemoTestsModalProps {
  open: boolean;
  onClose: () => void;
}

export interface ResetDemoTestsFormValues {
  organizationId: string;
}

const validationSchema = Yup.object().shape({
  organizationId: Yup.string().required("Organization Id is required"),
});

const ResetDemoTestsModal = (props: ResetDemoTestsModalProps) => {
  const classes = useStyles();

  const [organizations, setOrganizations] = useState<Organization[]>([]);

  const [getOrganizations] = useLazyGetOrganizationsQuery();

  const [resetDemoTests, { isLoading }] = useResetDemoTestsMutation();

  useEffect(() => {
    const fetchData = async () => {
      const { organizations } = await getOrganizations().unwrap();

      setOrganizations(organizations);
    };

    fetchData();
  }, [getOrganizations]);

  const handleClose = () => {
    setValues({ organizationId: "" });
    props.onClose();
  };

  const handleResetDemoTests = async (values: ResetDemoTestsFormValues) => {
    const result = await resetDemoTests(values).unwrap();

    if (result.success) {
      handleClose();
    }
  };

  const formik = useFormik({
    initialValues: {
      organizationId: "",
    },
    validationSchema,
    onSubmit: async (values: ResetDemoTestsFormValues) => {
      await handleResetDemoTests(values);
      formik.resetForm();
    },
  });

  const { values, setFieldValue, setValues, handleSubmit, errors } = formik;

  return (
    <RFModal
      open={props.open}
      boxProps={{ className: classes.container }}
      onClose={handleClose}
    >
      <Grid
        container
        component={"form"}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleSubmit();
        }}
      >
        <Typography variant="h5">Reset Demo Tests</Typography>

        <Grid container item marginTop={theme.spacing(2)} spacing={2}>
          <Grid item xs={12}>
            <InputLabel className={classes.inputLabel}>Organization</InputLabel>

            <Select
              className={classes.input}
              variant="outlined"
              fullWidth
              value={values.organizationId}
              onChange={(e) => setFieldValue("organizationId", e.target.value)}
            >
              {organizations.map((o) => (
                <MenuItem key={o.id} value={o.id}>
                  {o.id}
                </MenuItem>
              ))}
            </Select>

            {errors?.organizationId && (
              <FormHelperText
                style={{ marginLeft: 14, color: "red", fontSize: "14px" }}
                error
              >
                {errors?.organizationId}
              </FormHelperText>
            )}
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          justifyContent={"space-between"}
          marginTop={theme.spacing(4)}
        >
          <Grid item>
            <Button
              color="secondary"
              className={` ${classes.button} ${classes.backButton}`}
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <LoadingButton
              variant="contained"
              color="primary"
              className={classes.button}
              loading={isLoading}
              label="Reset"
              type="submit"
            />
          </Grid>
        </Grid>
      </Grid>
    </RFModal>
  );
};

export default ResetDemoTestsModal;
