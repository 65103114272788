import { Button, Grid, Paper, Typography, useMediaQuery } from "@mui/material";
import { Profile } from "@readyfit-common/models";
import { useState } from "react";
import GradingHeader from "src/grading/components/GradingHeader/GradingHeader";
import LiveTestStepper from "src/grading/components/LiveTestStepper/LiveTestStepper";
import Stopwatch from "src/grading/components/Stopwatch/Stopwatch";
import { DisplayTimeFromSeconds } from "src/grading/utils";
import { getExerciseIndex } from "src/grading/utils/testHelpers";
import { useAppSelector } from "src/store/hooks";
import {
  selectCurrentLiveTest,
  selectLiveTestStep,
  selectSelectedAthletes,
} from "src/store/reducers/liveTest/liveTestSlice";
import theme from "src/theme";
import GradeAthleteCard from "../GradeAthleteCard/GradeAthleteCard";
import TestRulesModal from "../TestRulesModal/TestRulesModal";
import useStyles from "./GradeLiveTest.styles";

const GradeLiveTest = ({
  users,
  setCanNavigateAway,
}: {
  users: Profile[];
  setCanNavigateAway: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const classes = useStyles();
  const liveTest = useAppSelector(selectCurrentLiveTest);
  const liveTestStep = useAppSelector(selectLiveTestStep);
  const selectedAthletes = useAppSelector(selectSelectedAthletes);
  const [openTestRulesModal, setOpenTestRulesModal] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const exerciseStepIndex = getExerciseIndex(liveTestStep, liveTest?.steps);
  const exerciseStep = liveTestStep - 1;

  const exerciseLength =
    liveTest?.steps[0].exerciseType === "bodyComp"
      ? liveTest?.steps.length - 1
      : liveTest?.steps.length;

  const currentExercise = liveTest?.steps[exerciseStepIndex];

  const choicedExercise = currentExercise?.choices
    ? currentExercise.choices[0]
    : currentExercise;

  const exerciseName = currentExercise?.choices
    ? `${currentExercise.choices[0].displayName} or ${currentExercise.choices[1].displayName}`
    : currentExercise?.displayName;

  return (
    <Grid container position={"relative"}>
      <Grid
        container
        className={classes.headerContainer}
        component={Paper}
        elevation={1}
      >
        <Grid item container xs={12} className={classes.titleContainer}>
          <GradingHeader
            withBreadcrumbs
            containerStyles={{
              marginTop: 0,
              marginBottom: theme.spacing(2),
            }}
          />
          <Typography variant="h3">{exerciseName}</Typography>

          {isSmallScreen ? (
            <Grid item={isSmallScreen} container={isSmallScreen} marginTop={1}>
              <Typography className={classes.stepCount} variant="subtitle1">
                Exercise {exerciseStep} of {exerciseLength}
              </Typography>

              <Grid item marginLeft={"auto"} marginRight={1}>
                <LiveTestStepper
                  athletes={users}
                  setCanNavigateAway={setCanNavigateAway}
                  mini={true}
                />
              </Grid>
            </Grid>
          ) : (
            <>
              <Typography className={classes.stepCount} variant="subtitle1">
                Exercise {exerciseStep} of {exerciseLength}
              </Typography>

              <Grid item marginLeft={"auto"} marginRight={1}>
                <LiveTestStepper
                  athletes={users}
                  setCanNavigateAway={setCanNavigateAway}
                  mini={true}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid item container xs={12} className={classes.bannerContainer}>
          <Grid
            container
            item
            flex={1}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            className={classes.divider}
          >
            <Typography
              color={theme.palette.primary.contrastText}
              variant={"h2"}
              textTransform={"uppercase"}
            >
              {DisplayTimeFromSeconds(
                choicedExercise?.testing?.maxDurationSeconds || 0,
              )}
            </Typography>
            <Typography variant={"subtitle1"} color={"#B2B8BC"}>
              Minutes
            </Typography>
          </Grid>

          <Grid
            container
            item
            flex={1}
            alignItems={"center"}
            style={{ padding: theme.spacing(1) }}
          >
            <Button
              className={classes.rulesButton}
              variant="contained"
              onClick={() => setOpenTestRulesModal(true)}
            >
              See Test Rules
            </Button>
          </Grid>

          <Grid container item xs={4} flex={1}>
            <Stopwatch />
          </Grid>

          <Grid
            container
            item
            xs={2}
            flex={1}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            color={"white"}
            textAlign={"center"}
            padding={1}
          >
            <Typography
              variant="caption"
              textTransform={"uppercase"}
              color={"white"}
              fontSize={13}
              fontWeight={"bold"}
            >
              Rest Standards
            </Typography>
            <Typography
              variant="caption"
              textTransform={"uppercase"}
              color={"white"}
              fontSize={14}
            >
              {`${DisplayTimeFromSeconds(
                choicedExercise?.testing?.restTime?.minSeconds || 0,
              )}-${DisplayTimeFromSeconds(
                choicedExercise?.testing?.restTime?.maxSeconds || 0,
              )} Minutes`}
              {/* {currentExercise?.} */}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container xs={12} className={classes.athletesContainer}>
        {selectedAthletes?.map((athlete, i) => {
          return (
            currentExercise && (
              <GradeAthleteCard
                key={`${athlete.id}-${liveTestStep}`}
                athlete={athlete}
              />
            )
          );
        })}
      </Grid>

      {currentExercise && (
        <TestRulesModal
          onClose={() => setOpenTestRulesModal(false)}
          exercise={
            currentExercise?.choices
              ? currentExercise.choices[0]
              : currentExercise
          }
          open={openTestRulesModal}
        />
      )}
    </Grid>
  );
};

export default GradeLiveTest;
