import { Grid, GridProps } from "@mui/material";
import useStyles from "./GradingBox.styles";

const GradingBox = {
  // const classes = useStyles();
  FirstRowContent: (props: GridProps) => {
    const classes = useStyles();
    return (
      <Grid item container className={classes.athleteFirstRow} {...props}>
        {props.children}
      </Grid>
    );
  },

  SecondRowContent: (props: GridProps) => {
    const classes = useStyles();
    return (
      <Grid
        item
        container
        className={`${classes.athletesSecondRow} ${props.className}`}
        {...props}
      >
        {props.children}
      </Grid>
    );
  },
  Container: (props: GridProps) => {
    const classes = useStyles();
    return (
      <Grid
        item
        container
        xs={12}
        className={`${classes.athletesContainer}`}
        {...props}
      >
        {props.children}
      </Grid>
    );
  },
};

export default GradingBox;
