import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: theme.spacing(1),
    border: "1px solid #DBDBDB",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1),
    width: theme.spacing(12),
    height: "fit-content",
    "&:hover": {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
  testLogo: {
    objectFit: "contain",
    widht: "100%",
    height: "100%",
  },
  titleContainer: {
    marginLeft: theme.spacing(4),
    alignItems: "center",
    // backgroundColor: "red",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: "80%",
  },
  buttonContainer: {
    backgroundColor: "black",
    borderRadius: "50%",
    height: theme.spacing(7),
    width: theme.spacing(7),
    position: "relative",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  buttonIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#fff",
  },
  cellTitle: {
    position: "absolute",
    left: "50%",
    top: theme.spacing(1),
    transform: "translateX(-50%)",
    WebkitTransform: "translateX(-50%)",
    [theme.breakpoints.down("md")]: {
      top: 0,
    },
  },
  cellCaption: {
    position: "absolute",
    left: "50%",
    top: theme.spacing(3),
    transform: "translateX(-50%)",
    WebkitTransform: "translateX(-50%)",
    textAlign: "center",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(2),
    },
  },
}));

export default useStyles;
