import { Grid, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { LoadingButton } from "src/grading/components";
import AthleteIcon from "src/grading/components/AthleteIcon/AthleteIcon";
import {
  Member,
  Role,
  Unit,
  useAdmitMemberMutation,
} from "src/store/reducers/organizations/organizationsApiSlice";
import useStyles from "./MembersTableColumns.styles";

export type AthleteRow = {
  row: Member;
  setRows?: React.Dispatch<React.SetStateAction<Member[]>>;
};

const AthleteColumn = ({ row }: AthleteRow) => {
  const classes = useStyles();
  return (
    <div className={classes.profileIconContainer}>
      {/* <div className={classes.profileIcon} /> */}
      <AthleteIcon profile={row} />
      <Grid container flex={1} className={classes.profileText}>
        <Typography variant="subtitle1">Athlete</Typography>
        <Typography variant="h4" fontWeight={"bold"} whiteSpace={"initial"}>
          {row.firstName && row.lastName
            ? row.firstName + " " + row.lastName
            : row.email}
        </Typography>
      </Grid>
    </div>
  );
};

const MemberActionButton = ({ row, setRows }: AthleteRow) => {
  const classes = useStyles();

  const history = useHistory();

  const [membershipStatus, setMembershipStatus] = useState(
    row.membershipStatus,
  );

  const [admitMember, { isLoading }] = useAdmitMemberMutation();

  const handleClick = async (status: "approved" | "rejected") => {
    if (membershipStatus === "approved") {
      history.push(`/grading/organization/members/${row.memberId}`);
      return;
    }

    const admitResult = await admitMember({
      organizationId: row.organizationId,
      memberId: row.memberId,
      membershipStatus: status,
    }).unwrap();

    if (admitResult.success) {
      if (status === "rejected" && setRows) {
        setRows((prev) =>
          prev.filter((member) => member.memberId !== row.memberId),
        );
      }

      setMembershipStatus(status);
    }
  };

  return (
    <Grid
      container
      className={classes.memberButtonBorder}
      alignItems={"center"}
    >
      {membershipStatus === "approved" ? (
        <LoadingButton
          variant="contained"
          size="small"
          className={classes.memberButton}
          onClick={() => handleClick("approved")}
          fullWidth
          loading={isLoading}
          label={"Details"}
        />
      ) : (
        <Grid container gap={1}>
          <LoadingButton
            variant="contained"
            size="small"
            className={classes.admitButton}
            onClick={() => handleClick("approved")}
            fullWidth
            loading={isLoading}
            label={"Approve"}
          />

          {/* In case this is needed in the future */}
          <LoadingButton
            variant="contained"
            size="small"
            className={classes.memberButton}
            onClick={() => handleClick("rejected")}
            fullWidth
            loading={isLoading}
            label={"Deny"}
          />
        </Grid>
      )}
    </Grid>
  );
};

const MembersTableColumns = (
  setRows: React.Dispatch<React.SetStateAction<Member[]>>,
): GridColDef[] => {
  return [
    {
      field: "athlete",
      headerName: "Athlete",
      flex: 1,
      editable: false,
      sortable: true,
      filterable: false,
      renderCell: ({ row }: AthleteRow) => <AthleteColumn row={row} />,
    },
    {
      field: "role",
      headerName: "Role",
      headerAlign: "center",
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params) => (
        <div>
          <Typography variant="subtitle1">Role</Typography>
          {params.row.roles.map((role: Role) => (
            <Typography key={role.id} variant="h4" fontWeight={"bold"}>
              {role.description}
            </Typography>
          ))}
        </div>
      ),
    },
    {
      field: "unit",
      headerName: "Unit",
      headerAlign: "center",
      flex: 1,
      editable: false,
      sortable: true,
      renderCell: (params) => (
        <div>
          <Typography variant="subtitle1">Unit</Typography>
          {params.row.units.map((unit: Unit) => (
            <Typography key={unit.id} variant="h4" fontWeight={"bold"}>
              {unit.name}
            </Typography>
          ))}
        </div>
      ),
    },
    {
      field: "action",
      type: "actions",
      headerName: "Action",
      headerAlign: "center",
      width: 175,
      maxWidth: 175,
      editable: false,
      sortable: false,
      filterable: false,
      renderCell: ({ row }: AthleteRow) => (
        <MemberActionButton row={row} setRows={setRows} />
      ),
    },
  ];
};

export default MembersTableColumns;
