import { Card, Grid, Typography } from "@mui/material";
import { isTimeExerciseInRep } from "@readyfit-common/models";
import { useState } from "react";
import useStepMetadata from "src/grading/hooks/useStepMetadata";
import { timeFromString } from "src/grading/utils";
import { useLazyGetFileUrlQuery } from "src/store/reducers/documents/documentsApiSlice";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import { RepTimeGradeResult } from "../../models";
import useStyles from "./ResultsTimeCard.styles";

type ResultsTimeCardProps = RepTimeGradeResult & {
  fileKey: string | undefined;
  step: number;
  metadata?: any;
  score?: number;
  maxScore?: number;
  testScore?: TestScore;
};

export function ResultsTimeCard({
  discrepancies,
  fileKey,
  graderTime,
  notes,
  userResult,
  accuracyScore,
  step,
  metadata,
  score,
  maxScore,
  testScore,
}: ResultsTimeCardProps) {
  const [videoUrl, setVideoUrl] = useState<URL | "pending" | "not_started">(
    "not_started",
  );
  const userScore = userResult.reps;
  const officialScore = graderTime
    ? `${graderTime.minutes}:${graderTime.seconds.toString().padStart(2, "0")}`
    : "00:00";

  const userScoreToTime = timeFromString(userScore);
  const userScoreTotalSeconds = userScoreToTime
    ? userScoreToTime.minutes * 60 + userScoreToTime.seconds
    : 0;

  const officialScoreToTime = timeFromString(officialScore);
  const officialScoreTotalSeconds = officialScoreToTime
    ? officialScoreToTime.minutes * 60 + officialScoreToTime.seconds
    : 0;

  const { stepMetadata } = useStepMetadata(step);
  const maximunValue =
    stepMetadata?.testing?.userEnteredResult?.maximumValue ?? "0:00";
  const maximunValueToTime = timeFromString(maximunValue.toString());
  const maximunValueTotalSeconds = maximunValueToTime
    ? maximunValueToTime.minutes * 60 + maximunValueToTime.seconds
    : 0;

  const classes = useStyles({
    userScore: userScoreTotalSeconds,
    officialScore: officialScoreTotalSeconds,
    maximunValue: maximunValueTotalSeconds,
  })();

  const [getFileUrl] = useLazyGetFileUrlQuery();

  if (videoUrl === "not_started") {
    setVideoUrl("pending");
    getFileUrl(`${fileKey}?ext=mp4`)
      .unwrap()
      .then((data) => setVideoUrl(new URL(data.url)));
  }

  return (
    <Grid container spacing={2} xs={12}>
      <Card className={classes.gradingReviewCard}>
        <Grid container xs={12} mx={4} my={3} gap={1} flexDirection={"row"}>
          <Grid
            container
            item
            flexDirection={"column"}
            xs={7}
            justifyContent={"center"}
          >
            <Typography variant="h4">
              {userResult?.name || metadata?.name}
            </Typography>
          </Grid>

          <Grid item flexDirection={"column"} mr={1.5}>
            <Typography variant="subtitle1">
              {(stepMetadata?.testing &&
                isTimeExerciseInRep(stepMetadata?.testing) &&
                "Grader Time") ||
                "Grader Distance"}
            </Typography>
            <Typography variant="caption" fontSize={"18px"}>
              {officialScore}
            </Typography>
          </Grid>

          <Grid item flexDirection={"column"} mr={1.5}>
            <Typography variant="subtitle1">Score</Typography>

            <Typography variant="caption" fontSize={"18px"}>
              {testScore?.exercises[step].score}
              <span style={{ color: "#8F8F8F" }}>
                /{testScore?.exercises[step].maxScore}
              </span>
            </Typography>
          </Grid>

          <Grid item flexDirection={"column"}>
            <Typography variant="subtitle1">Ranking</Typography>

            <Typography variant="caption" fontSize={"18px"}>
              {testScore?.exercises[step]?.grade ||
                testScore?.exercises[step].grade ||
                "N/A"}
            </Typography>
          </Grid>

          {/* <Grid item container flexDirection={"column"}>
                <Typography variant="subtitle1" textTransform={"uppercase"}>
                  {(stepMetadata?.testing &&
                    isTimeExerciseInRep(stepMetadata?.testing) &&
                    "User Time Count") ||
                    "User Distance Count"}
                </Typography>

                <Typography variant="h4" className={classes.userGradingBar}>
                  {userScore}
                </Typography>
              </Grid> */}

          {/* <Grid item container flexDirection={"column"}>
                <Typography variant="subtitle1" textTransform={"uppercase"}>
                  {(stepMetadata?.testing &&
                    isTimeExerciseInRep(stepMetadata?.testing) &&
                    "Grader Time Count") ||
                    "Grader Distance Count"}
                </Typography>

                <Typography variant="h4" className={classes.graderGradingBar}>
                  {officialScore}
                </Typography>
              </Grid> */}

          {/* <Grid item flexDirection={"column"}>
            <Typography variant="subtitle1">Total Score</Typography>

            <Typography variant="h1">
              {test?.exercises[step].score}
              <span style={{ color: "#8F8F8F" }}>
                /{test?.exercises[step].maxScore}
              </span>
            </Typography>
          </Grid> */}
        </Grid>

        {/* <CardContent>
        <div className="grading-review-info">
          <div className="grading-step-item">
            <span>User time</span>
            <span>
              <b>{userScore}</b>
            </span>
          </div>
          <div className="grading-step-item">
            <span>Grader time</span>
            <span>
              <b>{officialScore}</b>
            </span>
          </div>
        </div>
        <br />
        <b>Notes</b>
        <br />
        {notes}
      </CardContent> */}
        {/* <CardActions>
        <Button size="small" color="primary" onClick={regradeClick}>
          Re-grade
        </Button>
      </CardActions> */}
      </Card>
    </Grid>
  );
}
