import { Grid } from "@mui/material";
import React, { ReactNode } from "react";

interface GradeTestLayoutInterface {
  children: ReactNode;
  exerciseContent?: ReactNode;
  stepperContent?: ReactNode | JSX.Element;
}

const GradeTestLayout = ({
  children,
  stepperContent,
}: GradeTestLayoutInterface) => {
  return (
    <Grid container xs={12} height={"50%"} justifyContent={"center"} gap={1}>
      <Grid item lg={8}>
        {children}
      </Grid>

      <Grid item lg={3.5}>
        {stepperContent}
      </Grid>
    </Grid>
  );
};

export default GradeTestLayout;
