import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { GarminAuth } from "./devices/garmin";
import { GradingHome } from "./grading/home";
import LandingPage from "./grading/pages/LandingPage/LandingPage";
import theme from "./theme";

function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/">
              <Redirect to="/grading" />
            </Route>
            <Route path="/grading" component={GradingHome} />
            <Route path="/app/devices/garmin" component={GarminAuth} />
            <Route
              path="/organization/:organizationId"
              component={LandingPage}
            />
          </Switch>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
