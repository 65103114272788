import { useSelector } from "react-redux";
import { selectCurrentPermissions } from "src/store/reducers/auth/authSlice";

export const usePermissions = () => {
  const permissions = useSelector(selectCurrentPermissions) ?? [];

  const hasPermissions = (action: string, subject: string) => {
    for (const permission of permissions) {
      if (permission.action === action && permission.subject === subject)
        return true;
    }

    return false;
  };

  return { hasPermissions };
};
