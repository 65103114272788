export type Point = {
  ele: number;
  lat: number;
  lon: number;
  time: Date;
};

export type Split = {
  splitDistanceKm: number;
  splitTimeSeconds: number;
  totalDistanceKm: number;
};

export function CalculateTerminalPos(
  positions: number[][],
  endDistanceKm: number,
): number[] {
  if (positions.length > 1) {
    let prevLat = positions[0][0];
    let prevLon = positions[0][1];
    let distanceKm = 0;
    let index = 1;
    for (; index < positions.length; index++) {
      const pos = positions[index];
      distanceKm += GetDistanceFromLatLonInKm(prevLat, prevLon, pos[0], pos[1]);
      prevLat = pos[0];
      prevLon = pos[1];
      if (distanceKm >= endDistanceKm) {
        return [index, distanceKm];
      }
    }

    return [index, distanceKm];
  }

  return [0, 0];
}

export function CalculateSplits(
  positions: Point[],
  splitDistanceKm: number,
): Split[] {
  const splits: Split[] = [];
  if (positions.length > 1) {
    let prevLat = positions[0].lat;
    let prevLon = positions[0].lon;
    let lastSplitStart = positions[0].time;
    let lastSplitDistanceKm = 0;
    let index = 1;
    for (; index < positions.length; index++) {
      const pos = positions[index];
      lastSplitDistanceKm += GetDistanceFromLatLonInKm(
        prevLat,
        prevLon,
        pos.lat,
        pos.lon,
      );
      prevLat = pos.lat;
      prevLon = pos.lon;
      if (lastSplitDistanceKm >= splitDistanceKm) {
        splits.push({
          splitDistanceKm: lastSplitDistanceKm,
          splitTimeSeconds:
            (pos.time.valueOf() - lastSplitStart.valueOf()) / 1000,
          totalDistanceKm:
            splits.length > 0
              ? splits[splits.length - 1].totalDistanceKm + lastSplitDistanceKm
              : lastSplitDistanceKm,
        });
        lastSplitDistanceKm = lastSplitDistanceKm - splitDistanceKm;
        lastSplitStart = pos.time;
      }
    }

    if (lastSplitDistanceKm > 0) {
      splits.push({
        splitDistanceKm: lastSplitDistanceKm,
        splitTimeSeconds:
          (positions[positions.length - 1].time.valueOf() -
            lastSplitStart.valueOf()) /
          1000,
        totalDistanceKm:
          splits.length > 0
            ? splits[splits.length - 1].totalDistanceKm + lastSplitDistanceKm
            : lastSplitDistanceKm,
      });
    }
  }

  return splits;
}

export function GetDistanceFromLatLonInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number,
) {
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
}

function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

export function meterToMile(meters: number): number {
  return meters * 0.000621371;
}
export function kmToMile(km: number): number {
  return meterToMile(km * 1000);
}

export type CardioUnits = "miles" | "meters";
const unitMapping = new Map<CardioUnits, Map<CardioUnits, number>>([
  ["miles", new Map<CardioUnits, number>([["meters", 1609.344]])],
  ["meters", new Map<CardioUnits, number>([["miles", 0.00062137119224]])],
]);

export const convertDistance = (
  distance: number,
  units: CardioUnits,
  desiredUnits: CardioUnits,
): number => {
  const conversion = unitMapping.get(units)?.get(desiredUnits) ?? 1;
  return distance * conversion;
};
