import {
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useEffect, useState } from "react";
import { ExerciseFormat } from "src/store/reducers/tests/testsApiSlice";

type ExerciseChecklistProps = {
  stepMetadata: ExerciseFormat | undefined;
  exerciseName: string;
  onChange: (selections: string[]) => void;
  selections?: string[];
};

export function ExerciseChecklist(props: ExerciseChecklistProps) {
  const [criteria, setCriteria] = useState<string[]>([]);
  const [criteriaSelection, setCriteriaSelection] = useState<boolean[]>([]);

  if (criteria.length === 0) {
    if (
      props.stepMetadata !== undefined &&
      props.stepMetadata?.info?.gradingCriteria !== undefined
    ) {
      setCriteria(props.stepMetadata.info.gradingCriteria);
      setCriteriaSelection(
        Array.from(
          { length: props.stepMetadata.info.gradingCriteria.length },
          (_, i) => false,
        ),
      );
    }
  }

  useEffect(() => {
    setCriteriaSelection(
      criteria.map((_, i) => {
        if (props.selections === undefined) return false;
        return props.selections.includes(criteria[i]);
      }),
    );
  }, [criteria, props.selections]);

  return (
    <List className="grading-checklist">
      {criteria.map((crit, i) => (
        <ListItem key={i} className="grading-checkbox">
          <ListItemButton
            role={undefined}
            dense
            onClick={() => {
              const checkState = [...criteriaSelection];
              checkState[i] = !checkState[i];
              setCriteriaSelection(checkState);
              props.onChange(
                checkState
                  .map((val, i) => (val ? criteria[i] : ""))
                  .filter((str) => str !== ""),
              );
            }}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={criteriaSelection[i]}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemText primary={crit} />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );
}
