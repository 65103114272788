import { Card, Grid, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { CardioApprovalGradeResult } from "src/grading/models";
import { GetRestTime } from "src/grading/utils";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import useStyles from "./ResultsApprovalCardioCard.styles";

type ResultsApprovalCardProps = CardioApprovalGradeResult & {
  fileKey: string | undefined;
  step: number;
  startTime?: number;
  endTime?: number;
  metadata?: any;
  grade?: string;
  score?: number;
  maxScore?: number;
  testScore?: TestScore;
};

export function ResultsApprovalCardio(props: ResultsApprovalCardProps) {
  const classes = useStyles();

  // const distanceInMiles = props?.userResult?.distance;
  const startTime = props?.userResult?.startTime || props?.startTime;
  const endTime = props?.userResult?.endTime || props?.endTime || Date.now();
  const runTime = GetRestTime(startTime, endTime);

  // const approved = props?.approved || props?.grade !== "Fail";

  return (
    <Grid container spacing={2} xs={12}>
      <Card className={classes.gradingReviewCard}>
        <Grid container xs={12} mx={4} my={3} gap={1} flexDirection={"row"}>
          <Grid
            container
            item
            flexDirection={"column"}
            xs={7}
            justifyContent={"center"}
          >
            <Typography variant="h4">
              {props?.userResult?.name || props?.metadata?.name}
            </Typography>
          </Grid>

          <Grid item flexDirection={"column"} mr={1.5}>
            <Typography variant="subtitle1">Run Time</Typography>
            <Typography variant="caption" fontSize={"18px"}>
              {runTime}
            </Typography>
          </Grid>
          <Grid item flexDirection={"column"} mr={1.5}>
            <Typography variant="subtitle1">Score</Typography>

            <Typography variant="caption" fontSize={"18px"}>
              {props?.testScore?.exercises[props?.step].score?.toString()}
              <span style={{ color: "#8F8F8F" }}>
                /{props?.testScore?.exercises[props?.step].maxScore}
              </span>
            </Typography>
          </Grid>
          <Grid item flexDirection={"column"}>
            <Typography variant="subtitle1">Ranking</Typography>

            <Typography variant="caption" fontSize={"18px"}>
              {props?.testScore?.exercises[props?.step]?.grade ||
                props?.testScore?.exercises[props?.step].grade ||
                "N/A"}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
}

const avatarStyles = (backgroundColor: string) =>
  makeStyles((theme) =>
    createStyles({
      root: {
        backgroundColor,
      },
    }),
  );
