import { Close } from "@mui/icons-material";
import { Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Profile } from "@readyfit-common/models";
import { useRef, useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import RFModal from "src/grading/components/RFModal/RFModal";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  LiveTestGradeResult,
  LiveTestSigningDetails,
  selectLiveTestGradeResults,
  selectLiveTestScores,
  setAthletesLiveTestGradeResults,
  useSignLiveTestMutation,
} from "src/store/reducers/liveTest/liveTestSlice";
import LiveTestAgreementButtons from "../../LiveTestAgreementButtons/LiveTestAgreementButtons";
import useStyles from "./SignModal.styles";

interface SignModalProps {
  open: boolean;
  onClose: () => void;
  profile: Profile;
  signingAs?: "athlete" | "grader";
  signatureDetails?: LiveTestSigningDetails;
  gradeResultIndexToEdit: number;
  athleteLiveGradeResult?: LiveTestGradeResult;
  athleteId: string;
}

const SignModal = ({
  profile,
  signingAs,
  signatureDetails,
  onClose,
  gradeResultIndexToEdit,
  athleteLiveGradeResult,
  athleteId,
  ...props
}: SignModalProps) => {
  const classes = useStyles();
  const canvasRef = useRef<SignatureCanvas>(null);
  const [enableSaveButton, setEnableSaveButton] = useState(false);
  const [status, setStatus] = useState<boolean | undefined>(undefined);
  const liveTestGradeResults = useAppSelector(selectLiveTestGradeResults);
  const dispatch = useAppDispatch();
  const [disagreementReason, setDisagreementReason] = useState(
    athleteLiveGradeResult?.athleteSignatureDetails?.disagreementReason,
  );
  const liveTestScore = useAppSelector(selectLiveTestScores);
  const [signLiveTest, { isError }] = useSignLiveTestMutation();

  const handleSaveSignature = async (
    signingAs: "athlete" | "grader" = "athlete",
  ) => {
    if (canvasRef?.current && liveTestScore) {
      const dataUrl = canvasRef.current.toDataURL();
      const response = await fetch(dataUrl);
      const blob = await response.blob();

      const updatedResults = liveTestGradeResults.map((result, index) => {
        if (index === gradeResultIndexToEdit) {
          return {
            ...result,
            [`${signingAs}SignatureDetails`]: {
              agreed: status,
              disagreementReason,
              signature: window.URL.createObjectURL(blob),
            },
          };
        }
        return result;
      });

      dispatch(
        setAthletesLiveTestGradeResults({
          athleteLiveTestGradeResults: updatedResults,
        }),
      );

      const result = await signLiveTest({
        agreed: status as boolean,
        liveTestScoreId: liveTestScore?.id,
        signature: blob,
        type: signingAs,
        userId: athleteId,
        ...(disagreementReason && !status && { disagreementReason }),
      }).unwrap();

      if (!isError && result.success) {
        onClose();
      }
    }
  };

  return (
    <RFModal
      onClose={onClose}
      {...props}
      boxProps={{
        className: classes.container,
      }}
    >
      <Grid container>
        <Grid item container alignItems={"center"}>
          <Typography variant="h5">
            {profile?.firstName} {profile?.lastName} Sign
          </Typography>
          <IconButton className={classes.closeIconButton} onClick={onClose}>
            <Close className={classes.closeIcon} />
          </IconButton>
        </Grid>

        <Grid
          container
          item
          marginTop={2}
          spacing={2}
          justifyContent={"center"}
        >
          <Grid item container marginTop={3}>
            <Typography
              variant="button"
              fontSize={"18px"}
              color={"#666666"}
              textTransform={"none"}
            >
              Content
            </Typography>

            <Typography
              variant="caption"
              color={"#666666"}
              fontWeight={"400"}
              marginTop={1}
            >
              {signingAs === "athlete"
                ? `
                  I affirm that the results of my physical fitness test are
                  accurate and correct. These results, once certified, will
                  become a part of my official fitness record. If I disagree
                  with the grade given for my performance I may disagree and
                  request a new test at a later date.`
                : `
                  I affirm that the results of this fitness test are accurate
                  and correct. I certify that the test was taken without
                  assistance, with integrity, following all required test rules
                  and guidelines.
                  `}
            </Typography>
          </Grid>

          <Grid item container marginTop={3}>
            {signingAs === "athlete" && (
              <>
                <Typography
                  variant="button"
                  fontSize={"18px"}
                  color={"#666666"}
                  textTransform={"none"}
                >
                  Agreement
                </Typography>
                <LiveTestAgreementButtons
                  onClick={(newStatus: boolean) => {}}
                  status={status}
                  setStatus={setStatus}
                />

                {status === false && (
                  <Grid item container xs={12} marginTop={3}>
                    <Typography
                      variant="button"
                      fontSize={"18px"}
                      color={"#666666"}
                    >
                      Justify
                    </Typography>

                    <Grid item container xs={12}>
                      <TextField
                        multiline
                        fullWidth
                        variant="outlined"
                        placeholder="Justify your disagreement."
                        rows={1}
                        InputProps={{
                          style: {
                            borderRadius: "8px",
                            marginTop: "8px",
                          },
                        }}
                        value={disagreementReason}
                        onChange={(event) =>
                          setDisagreementReason(event.target.value)
                        }
                        disabled={Boolean(signatureDetails?.signature)}
                      />
                    </Grid>
                  </Grid>
                )}
              </>
            )}

            {status !== undefined && (
              <Grid item container xs={12} marginTop={3}>
                <Typography
                  variant="button"
                  fontSize={"18px"}
                  color={"#666666"}
                  textTransform={"none"}
                >
                  Signature
                </Typography>

                {!signatureDetails?.signature && (
                  <Typography
                    variant="subtitle1"
                    color={"#666666"}
                    textTransform={"uppercase"}
                    border={"1px solid #000"}
                    padding={1}
                    marginLeft={"auto"}
                    onClick={() => {
                      canvasRef.current?.clear();
                      setEnableSaveButton(false);
                    }}
                    className={classes.canvasClearButton}
                  >
                    Clear
                  </Typography>
                )}

                <Grid item container xs={12}>
                  {signatureDetails?.signature ? (
                    <img
                      src={signatureDetails?.signature}
                      alt="signature"
                      width={"100%"}
                      style={{
                        objectFit: "contain",
                        borderBottom: "1px solid #000",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        height: "200px",
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <SignatureCanvas
                        canvasProps={{
                          style: {
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            borderBottom: "1px solid #000",
                          },
                        }}
                        ref={canvasRef}
                        onBegin={() => setEnableSaveButton(true)}
                      />
                    </div>
                  )}

                  {/* <Typography
                    variant="caption"
                    color={"#666666"}
                    marginTop={1}
                    marginLeft={"auto"}
                    marginRight={"auto"}
                  >
                    Athlete Name
                  </Typography> */}
                </Grid>

                <Button
                  onClick={() => handleSaveSignature(signingAs)}
                  variant="contained"
                  className={classes.button}
                  disabled={
                    !enableSaveButton || Boolean(signatureDetails?.signature)
                  }
                >
                  Save
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </RFModal>
  );
};

export default SignModal;
