import { RegisterDemoTestsFormValues } from "src/grading/pages/Admin/DemoTests/RegisterDemoTestsModal/RegisterDemoTestsModal";
import { ResetDemoTestsFormValues } from "src/grading/pages/Admin/DemoTests/ResetDemoTestsModal/ResetDemoTestsModal";
import { apiSlice } from "src/store/apiSlice";
import { TestScore } from "../scores/scoresApiSlice";

export interface DemoTest {
  id: string;
  organizationId: string;
  score?: TestScore;
}

type RemoveDemoTestsFormValues = RegisterDemoTestsFormValues;

export const demoTestsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDemoTests: builder.query<
      { demoTests: DemoTest[] },
      { organizationId?: string }
    >({
      query: ({ organizationId }) => {
        let url = "/api/grading/demo-scores?";
        if (organizationId) url += `&organizationId=${organizationId}`;
        return {
          url,
          method: "GET",
        };
      },
      transformResponse: (response: { data: { demoTests: DemoTest[] } }) =>
        response.data,
    }),
    registerDemoTests: builder.mutation<
      { success: boolean },
      RegisterDemoTestsFormValues
    >({
      query: (payload) => ({
        url: "/api/grading/demo-scores",
        method: "POST",
        body: payload,
      }),
    }),
    resetDemoTests: builder.mutation<
      { success: boolean },
      ResetDemoTestsFormValues
    >({
      query: (payload) => ({
        url: "/api/grading/demo-scores/reset",
        method: "PATCH",
        body: payload,
      }),
    }),
    removeDemoTests: builder.mutation<
      { success: boolean },
      RemoveDemoTestsFormValues
    >({
      query: (payload) => ({
        url: "/api/grading/demo-scores",
        method: "DELETE",
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetDemoTestsQuery,
  useLazyGetDemoTestsQuery,
  useRegisterDemoTestsMutation,
  useResetDemoTestsMutation,
  useRemoveDemoTestsMutation,
} = demoTestsApiSlice;
