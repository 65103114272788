import { Button, Grid, Paper, Typography } from "@mui/material";
import { ApiError, Profile } from "@readyfit-common/models";
import { useHistory, useLocation } from "react-router-dom";
import {
  getExerciseStepNumber,
  handleEmptyGrades,
} from "src/grading/utils/testHelpers";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  LiveTestStep,
  regradingStepComplete,
  selectCurrentLiveTest,
  selectCurrentLiveTestInfo,
  selectLiveTestScores,
  selectLiveTestState,
  selectLiveTestStep,
  selectRegradingLiveStep,
  selectSelectedAthleteToSign,
  selectSelectedAthletes,
  setAthletesLiveTestGradeResults,
  setCurrentLiveTestAthletesResultRanges,
  setLiveTestScores,
  setLiveTestStep,
  useGetResultRangesMutation,
  useScoreLiveTestMutation,
} from "src/store/reducers/liveTest/liveTestSlice";
import {
  addBreadcrumb,
  setSnackbar,
} from "src/store/reducers/navigation/navigationSlice";
import LoadingButton from "../LoadingButton/LoadingButton";
import useStyles from "./LiveTestStepper.styles";

interface LiveTestStepperProps {
  athletes?: Profile[];
  setCanNavigateAway?: React.Dispatch<React.SetStateAction<boolean>>;
  mini?: boolean;
  yOffset?: number;
}

const LiveTestStepper = ({
  athletes,
  setCanNavigateAway,
  mini,
  yOffset,
}: LiveTestStepperProps) => {
  const classes = useStyles({ mini: mini || false })();
  const currentLiveTest = useAppSelector(selectCurrentLiveTest);
  const currentLiveTestInfo = useAppSelector(selectCurrentLiveTestInfo);
  const liveTestStep = useAppSelector(selectLiveTestStep);
  const selectedAthletes = useAppSelector(selectSelectedAthletes);
  const regradingLiveStep = useAppSelector(selectRegradingLiveStep);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const [liveTestScoreSubmitRequest] = useScoreLiveTestMutation();
  const [getResultRanges, { isLoading: isLoadingResultRanges }] =
    useGetResultRangesMutation();
  const liveTestScore = useAppSelector(selectLiveTestScores);

  const testSteps = getExerciseStepNumber(currentLiveTest?.steps || []);
  const isOverviewPage = liveTestStep === LiveTestStep.TEST_OVERVIEW;
  const isResultsOverviewPage =
    LiveTestStep.GRADING_STEPS + testSteps === liveTestStep;
  const isSignPage = LiveTestStep.SIGN_STEP + testSteps === liveTestStep;
  const isCertifyPage = LiveTestStep.CERTIFIY_STEP + testSteps === liveTestStep;
  const selectedAthleteToSign = useAppSelector(selectSelectedAthleteToSign);

  const liveTestState = useAppSelector(selectLiveTestState);

  const isGradeLastExercisePage =
    liveTestStep + 1 === LiveTestStep.GRADING_STEPS + testSteps;

  const isCertifyReviewPage = location.pathname.includes(
    "/certified/live-test/",
  );

  const isGradingPage =
    liveTestStep > LiveTestStep.TEST_OVERVIEW &&
    liveTestStep < LiveTestStep.GRADING_STEPS + testSteps;

  const previousPath = location.pathname
    .split("/")
    .slice(0, location.pathname.split("/").length - 1)
    .join("/");

  const handleGoBack = () => {
    if (liveTestStep === LiveTestStep.SELECT_ATHLETE) {
      history.push("/grading/live");
    }

    if (isResultsOverviewPage) {
      history.replace(previousPath);
    }

    if (isSignPage) {
      history.replace(`${previousPath}`);
    }

    if (isCertifyPage) {
      history.replace(`${previousPath}/overview`);
      // MINUS 2 so we don't go back to the sign page
      dispatch(setLiveTestStep(liveTestStep - 2));
      return;
    }

    if (isCertifyReviewPage) {
      history.goBack();
      return;
    }

    dispatch(setLiveTestStep(liveTestStep - 1));
  };

  const handleNext = async () => {
    if (regradingLiveStep) {
      const result = await liveTestScoreSubmitRequest({
        testId: currentLiveTest?.testId as string,
        testVersion: currentLiveTest?.version as number,
        testTitle: currentLiveTestInfo?.displayName as string,
        branch: currentLiveTestInfo?.branch as string,
        tests: liveTestState.athletesLiveTestGradeResults,
        ...(liveTestScore?.id && {
          id: liveTestScore.id,
        }),
        ...(currentLiveTest?.properties && {
          properties: {
            variant: currentLiveTest?.properties?.[0]?.options?.[0]
              .value as string,
            // variant: "seal",
          },
        }),
      }).unwrap();

      dispatch(setLiveTestScores(result.data));
      dispatch(regradingStepComplete());
      window.scrollTo(0, 0);
      return;
    }

    if (liveTestStep === LiveTestStep.SELECT_ATHLETE) {
      dispatch(
        addBreadcrumb({
          label: "Select Athletes",
          index: LiveTestStep.SELECT_ATHLETE,
        }),
      );
    }

    if (isOverviewPage) {
      // GET STANDARDS PER EXERCISE PER ATHLETE
      const resultRanges = await getResultRanges({
        testId: currentLiveTest?.testId as string,
        testVersion: currentLiveTest?.version as number,
        athletes: selectedAthletes,
        ...(currentLiveTest?.properties && {
          properties: {
            // @TODO This should be a union of all possible variants
            variant: currentLiveTest?.properties?.[0]?.options?.[0]
              .value as string,
            // variant: "seal",
          },
        }),
      }).unwrap();
      dispatch(
        setCurrentLiveTestAthletesResultRanges(resultRanges.data.athletes),
      );
    }

    if (isGradeLastExercisePage) {
      const updatedLiveTestGradeResults =
        await handleEmptyGrades(liveTestState);
      dispatch(
        setAthletesLiveTestGradeResults({
          athleteLiveTestGradeResults: updatedLiveTestGradeResults,
        }),
      );

      try {
        const result = await liveTestScoreSubmitRequest({
          testId: currentLiveTest?.testId as string,
          testVersion: currentLiveTest?.version as number,
          testTitle: currentLiveTestInfo?.displayName as string,
          branch: currentLiveTestInfo?.branch as string,
          tests: updatedLiveTestGradeResults,
          ...(liveTestScore?.id && {
            id: liveTestScore.id,
            // certified: "pending",
          }),
          ...(currentLiveTest?.properties && {
            properties: {
              variant: currentLiveTest?.properties?.[0]?.options?.[0]
                .value as string,
              // variant: "seal",
            },
          }),
        }).unwrap();

        dispatch(setLiveTestScores(result.data));

        history.replace(`${location.pathname}/overview`);

        dispatch(setLiveTestStep(liveTestStep + 1));
        window.scrollTo(0, 0);
        return;
      } catch (error: any) {
        alert(`Error getting live test scores: , ${error?.data?.message}`);
        return;
      }
    }

    if (isSignPage) {
      history.replace(`${previousPath}`);
      dispatch(setLiveTestStep(liveTestStep - 1));
      return;
    }

    if (isResultsOverviewPage) {
      history.replace(`${previousPath}/certify`);
      // PLUS 2 so we skip the sign page and get to the certify page
      dispatch(setLiveTestStep(liveTestStep + 2));
      return;
    }

    if (isCertifyPage) {
      try {
        if (setCanNavigateAway) setCanNavigateAway(true);

        const result = await liveTestScoreSubmitRequest({
          testId: currentLiveTest?.testId as string,
          testVersion: currentLiveTest?.version as number,
          testTitle: currentLiveTestInfo?.displayName as string,
          tests: liveTestState.athletesLiveTestGradeResults,
          branch: currentLiveTestInfo?.branch as string,
          certified: "true",
          ...(liveTestScore?.id && {
            id: liveTestScore.id,
          }),
          ...(currentLiveTest?.properties && {
            properties: {
              variant: currentLiveTest?.properties?.[0]?.options?.[0]
                .value as string,
              // variant: "seal",
            },
          }),
        }).unwrap();

        if (result.success) {
          history.push("/grading/live");
          dispatch(
            setSnackbar({
              open: true,
              message: "Test Certified Successfully!",
            }),
          );
        }

        return;
      } catch (error: unknown) {
        const typedError = error as ApiError;
        if (error) {
          console.log("error", error);
          alert(
            `Error getting live test scores: , ${typedError?.data?.message}`,
          );
        }
        return;
      }
    }

    dispatch(setLiveTestStep(liveTestStep + 1));
    window.scrollTo(0, 0);
  };

  const checkIfAllTestsWereSigned = (): boolean => {
    if (!isResultsOverviewPage || isSignPage) {
      return false;
    }
    return liveTestState.athletesLiveTestGradeResults.some(
      (athlete) =>
        !athlete.athleteSignatureDetails?.signature ||
        !athlete.graderSignatureDetails?.signature,
    );
  };

  // const checkIfExercisedWasGradedForAllAthletes = (): boolean => {
  //   if (!isGradingPage) {
  //     return false;
  //   }
  //   return liveTestState.athletesLiveTestGradeResults.some(
  //     (athleteGradeResult) =>
  //       athleteGradeResult.gradeResults?.[
  //         liveTestStep - LiveTestStep.GRADING_STEPS
  //       ] === undefined,
  //   );
  // };

  // console.log(
  //   "graded for everyone? ",
  //   checkIfExercisedWasGradedForAllAthletes(),
  // );

  const checkIfGraderAndAthleteSignaturesArePresent = (): boolean => {
    if (!isSignPage) {
      return false;
    }

    return liveTestState.athletesLiveTestGradeResults.some(
      (athlete) =>
        (!athlete.athleteSignatureDetails?.signature ||
          !athlete.graderSignatureDetails?.signature) &&
        athlete.profile.id === selectedAthleteToSign?.id,
    );
  };

  return mini ? (
    <Grid container item>
      <Grid container item marginLeft={"auto"} width={"fit-content"}>
        <Button
          color="secondary"
          className={` ${classes.button} ${classes.backButton}`}
          onClick={handleGoBack}
        >
          {isSignPage ? "Close" : "Back"}
        </Button>

        <LoadingButton
          variant="contained"
          color="primary"
          disabled={
            selectedAthletes?.length === 0 ||
            checkIfAllTestsWereSigned() ||
            checkIfGraderAndAthleteSignaturesArePresent()
          }
          label={
            regradingLiveStep
              ? "Save"
              : isSignPage
              ? "Submit"
              : isCertifyPage
              ? "Certify"
              : isCertifyReviewPage
              ? "Export"
              : "Next"
          }
          loading={isLoadingResultRanges || false}
          className={`${classes.button} ${classes.nextButton}`}
          onClick={handleNext}
        />
      </Grid>
    </Grid>
  ) : (
    <Grid
      container
      item
      xs={12}
      component={Paper}
      elevation={1}
      className={classes.stepperContainer}
      alignItems={"center"}
      zIndex={-999999999}
    >
      {liveTestStep === LiveTestStep.SELECT_ATHLETE && athletes && (
        <Typography variant="caption">
          {selectedAthletes?.length} of {athletes?.length} selected
        </Typography>
      )}

      <Grid container item marginLeft={"auto"} width={"fit-content"}>
        <Button
          color="secondary"
          className={` ${classes.button} ${classes.backButton}`}
          onClick={handleGoBack}
        >
          {isSignPage ? "Close" : "Back"}
        </Button>

        <LoadingButton
          variant="contained"
          color="primary"
          disabled={
            selectedAthletes?.length === 0 || checkIfAllTestsWereSigned()
          }
          label={
            regradingLiveStep
              ? "Save"
              : isSignPage
              ? "Submit"
              : isCertifyPage
              ? "Certify"
              : "Next"
          }
          loading={isLoadingResultRanges || false}
          className={`${classes.button} ${classes.nextButton}`}
          onClick={handleNext}
        />
      </Grid>
    </Grid>
  );
};

export default LiveTestStepper;
