import { Grid, Paper, Typography } from "@mui/material";
import { Profile } from "@readyfit-common/models";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import theme from "src/theme";
import {
  GradeResult,
  isApprovalGrade,
  isCardioApprovalGrade,
  isPassFailGrade,
  isRepGrade,
  isTimeGrade,
} from "../../models";
import ResultsApprovalCard from "../ResultsApprovalCard/ResultsApprovalCard";
import { ResultsApprovalCardio } from "../ResultsApprovalCardioCard/ResultsApprovalCardioCard";
import { ResultsRepsCard } from "../ResultsRepsCard/ResultsRepsCard";
import { ResultsTimeCard } from "../ResultsTimeCard/ResultsTimeCard";
import useStyles from "./GradeTestResult.styles";

type GradeTestResultProps = {
  grades: GradeResult[];
  test: TestScore;
  setStep: (step: number) => void;
  userProfile: Profile;
  testScore?: TestScore;
};

// eslint-disable-next-line react-hooks/rules-of-hooks
export function GradeTestResult({
  grades,
  test,
  setStep,
  userProfile,
  testScore,
}: GradeTestResultProps) {
  const classes = useStyles();
  return (
    <Grid container lg={12}>
      <Grid item xs={12} mt={1}>
        <Typography variant="h2">Test Results</Typography>
      </Grid>
      {/* Overview Container */}

      <Grid container item flex={1}>
        <Grid
          mt={5}
          mb={2}
          container
          xs={11.5}
          padding={5}
          bgcolor={theme.palette.primary.light}
          borderRadius={2}
          component={Paper}
        >
          <Grid item xs={6}>
            <div className={classes.profileIconContainer}>
              <div className={classes.profileIcon} />
              <div className={classes.profileText}>
                <Typography variant="subtitle1">Name</Typography>
                <Typography variant="h4" fontWeight={"bold"}>
                  {userProfile?.lastName}, {userProfile?.firstName}
                </Typography>
              </div>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.profileIconContainer}>
              <Grid className={classes.profileText} padding={2}>
                <Typography variant="subtitle1">Unit</Typography>
                <Typography variant="h4" fontWeight={"bold"}>
                  {userProfile?.branchOfService ?? "N/A"}
                </Typography>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>

      {grades.map((grade, idx) => {
        if (isRepGrade(grade)) {
          return (
            <ResultsRepsCard
              key={idx}
              {...grade}
              fileKey={grade.userResult.fileKey as string}
              step={idx}
              testScore={testScore}
            />
          );
        } else if (isTimeGrade(grade)) {
          return (
            <ResultsTimeCard
              key={idx}
              {...grade}
              fileKey={grade.userResult.fileKey as string}
              step={idx}
              testScore={testScore}
            />
          );
        } else if (
          isCardioApprovalGrade(grade) &&
          grade.userResult?.name !== "Body Composition"
        ) {
          return (
            <ResultsApprovalCardio
              key={idx}
              {...grade}
              fileKey={grade.userResult.fileKey as string}
              step={idx}
              testScore={testScore}
            />
          );
        } else if (isPassFailGrade(grade)) {
          return (
            <ResultsApprovalCard
              key={idx}
              {...grade}
              fileKey={grade.userResult.fileKey as string}
              step={idx}
              testScore={testScore}
            />
          );
        } else {
          return <></>;
        }
      })}
    </Grid>
  );
}
