import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
  badge: {
    padding: `0 ${theme.spacing(1)}`,
    backgroundColor: theme.palette.primary.main,
    color: "white",
    width: "fit-content",
    maxWidth: "fit-content",
    borderRadius: theme.spacing(2),
  },
  container: {
    marginTop: theme.spacing(3),
    borderRadius: theme.spacing(1),
    border: `1px solid #DBDBDB`,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    overflow: "hidden",
  },
  cardHeader: {
    backgroundColor: "#F2F2F2",
    padding: theme.spacing(1),
    height: "56px",
    borderBottom: theme.spacing(1),
    borderColor: "#DBDBDB",
    flexWrap: "nowrap",
    alignItems: "center",
  },
  addButtonContainer: {
    backgroundColor: "white",
    height: "40px",
    marginTop: "-.3rem",
    borderRadius: "10px",
    marginLeft: theme.spacing(1),
    padding: ".2rem",
    justifyContent: "center",
    alignItems: "center",
  },
  addButton: {
    minWidth: 0,
    height: "32px",
    width: "42px",
    backgroundColor: "#F2F2F2",
    boxShadow: "none",
    "&:hover": {
      backgroundColor: "#F2F2F2",
    },
  },
  addIcon: { height: "1rem", width: "1rem", fill: "black" },
  circularProgress: {
    marginTop: "-.2rem",
    marginLeft: ".2rem",
    borderRadius: "50%",
    boxShadow: "inset 0 0 0 .2rem #d1d1d1",
  },
  checkboxContainer: {
    marginTop: theme.spacing(1),
  },
  checkbox: {
    alignSelf: "center",
    marginRight: "1.2rem",
    color: "#C2C2C2",
  },
  checkboxLabel: {
    justifyContent: "space-between",
    width: "100%",
    height: "40px",
    alignItems: "center",
  },
  label: {
    justifyContent: "center",
    width: "100%",
    height: "48px",
    alignItems: "center",
    paddingLeft: theme.spacing(2),
  },
  modalContainer: {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-25%, -50%)",
    width: "45%",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.secondary.light}`,
    borderRadius: theme.spacing(1),
    boxshadow: "-2px 2px 4px 0px rgba(0, 0, 0, 0.15)",
  },
  modalCloseIconButton: {
    marginLeft: "auto",
  },
  modalInputLabel: {
    fontSize: "12px",
  },
  modalInput: {
    borderRadius: theme.spacing(1),
  },
  modalButton: {
    width: theme.spacing(21),
    height: theme.spacing(5),
    textTransform: "uppercase",
    fontWeight: "bold",
    borderRadius: theme.spacing(1),
  },
  modalBackButton: {
    backgroundColor: "#fff",
    color: "black",
    "&:hover": {
      backgroundColor: "#fff",
      color: "black",
      opacity: 0.6,
    },
  },
}));

export default useStyles;
