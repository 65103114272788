import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import authReducer from "./reducers/auth/authSlice";
import gradingReducer from "./reducers/grading/gradingSlice";
import liveTestSlice from "./reducers/liveTest/liveTestSlice";
import navigationReducer from "./reducers/navigation/navigationSlice";
import organizationSlice from "./reducers/organizations/organizationSlice";

export const rootStore = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
    auth: authReducer,
    navigation: navigationReducer,
    grading: gradingReducer,
    liveTest: liveTestSlice,
    organization: organizationSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
  devTools: true,
});

export type RootState = ReturnType<typeof rootStore.getState>;

export type AppDispatch = typeof rootStore.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
