import {
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { Alert, AlertTitle } from "@mui/material";
import { useTheme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import React, { FormEvent, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { EmailIcon, PasswordIcon } from "src/grading/assets";
import { InputError } from "src/grading/components/InputError";
import LoadingButton from "src/grading/components/LoadingButton/LoadingButton";
// import TestService from "../../tests";
import "./Login.css";
import { allowedRoles, checkRoles } from "src/grading/utils/permissionsHelpers";
import {
  useLazyGetPermissionsQuery,
  useLazyGetUserQuery,
  useLoginMutation,
  useResetPasswordMutation,
} from "src/store/reducers/auth/authApiSlice";
import {
  logOut,
  selectCurrentUser,
  setCredentials,
  setPermissions,
  setUser,
} from "src/store/reducers/auth/authSlice";
import loginValidationSchema from "./LoginValidation";

interface LoginProps {
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const STEP = {
  LOGIN: "LOGIN",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  SIGN_UP: "SIGN_UP",
};

const useStyles = makeStyles((theme: Theme) => ({
  backgroundImage: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
}));

const Login = ({ error, setError }: LoginProps) => {
  const theme = useTheme();
  const [step, setStep] = useState(STEP.LOGIN);
  const classes = useStyles();
  const history = useHistory();

  const user = useSelector(selectCurrentUser);

  const [getUser] = useLazyGetUserQuery();
  const [getPermissions] = useLazyGetPermissionsQuery();
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();
  const [forgotPassword, { isLoading: isResetPasswordLoading }] =
    useResetPasswordMutation();
  const dispatch = useDispatch();

  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");

  const handleLogin = async (email: string, password: string) => {
    try {
      dispatch(setUser({ user: undefined }));
      const loginResponse = await login({ email, password }).unwrap();
      dispatch(setCredentials({ ...loginResponse }));
      const {
        data: { profile },
      } = await getUser();
      const {
        data: { permissions },
      } = await getPermissions();
      if (checkRoles(allowedRoles, profile?.roles)) {
        dispatch(setUser({ user: profile }));
        dispatch(setPermissions({ permissions: permissions }));

        if (profile.roles.includes("admins")) {
          history.push("/grading/admin");
        } else {
          history.push("/grading/pending");
        }
      } else {
        dispatch(logOut({}));
        setError("You are not authorized to access this application");
      }
    } catch (error: any) {
      if (error.status < 500) {
        setError(error.data.message);
      } else {
        setError("Something went wrong.");
      }
      dispatch(logOut({}));
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      code: "",
      newPassword: "",
      validateNewPassword: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      handleLogin(values.email, values.password);
    },
  });

  const { touched, values, errors, handleSubmit, handleChange } = formik;

  const getTitle = () => {
    switch (step) {
      case STEP.LOGIN:
        return "Admin Login";
      case STEP.FORGOT_PASSWORD:
        return "Forgot your password?";
      case STEP.SIGN_UP:
        return "Sign Up";
      case STEP.RESET_PASSWORD:
        return "Password reset";
      default:
        return "";
    }
  };

  const handleForgotPassword = async (email: string) => {
    try {
      const response = await forgotPassword({ email }).unwrap();

      if (response.success) {
        setStep(STEP.RESET_PASSWORD);
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };

  const handleResetPassword = async (code: string, newPassword: string) => {
    try {
      const response = await forgotPassword({
        code,
        password: newPassword,
        email: values.email,
      }).unwrap();

      if (response.success) {
        setStep(STEP.LOGIN);
      }
    } catch (error: any) {
      console.log("error", error);
    }
  };

  return (
    <Grid
      container
      height={"100%"}
      maxHeight={"100vh"}
      component={"form"}
      onSubmit={(e: FormEvent) => {
        if (step === STEP.FORGOT_PASSWORD) {
          handleForgotPassword(values.email);
        }

        if (step === STEP.RESET_PASSWORD) {
          handleResetPassword(values.code, values.newPassword);
        }

        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Grid container item xs={12} sm={6} md={6} lg={6} flex={1}>
        <Grid
          flex={1}
          display={"flex"}
          flexDirection={"column"}
          alignItems={"center"}
        >
          <img className="logo" alt="ReadyFit Logo" src={"/logo.png"} />
          <Grid width={"20rem"} flex={1}>
            <Typography variant="h5" mb={5}>
              {getTitle()}
            </Typography>
            {user === undefined ? (
              <Spinner
                animation="border"
                style={{
                  left: "50%",
                  top: "50%",
                  position: "absolute",
                  marginLeft: -8,
                }}
              />
            ) : user === null && step === STEP.LOGIN ? (
              <Grid
                display={"flex"}
                flex={1}
                flexDirection={"column"}
                height={"100%"}
              >
                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange("email")}
                  style={{ paddingBottom: "20px" }}
                  InputProps={{
                    className: "text-field",
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  placeholder="Enter your email here"
                  error={InputError("email", touched, errors).error}
                  helperText={InputError("email", touched, errors).helperText}
                />
                <br />
                <TextField
                  label="Password"
                  type="password"
                  value={values.password}
                  onChange={handleChange("password")}
                  style={{ paddingBottom: "20px" }}
                  InputProps={{
                    className: "text-field",
                    endAdornment: (
                      <InputAdornment position="end">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  placeholder="Enter your password here"
                />
                <br />
                <Typography
                  variant="body1"
                  textAlign={"right"}
                  fontWeight={"bold"}
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    setStep(STEP.FORGOT_PASSWORD);
                    setError("");
                  }}
                >
                  Forgot your password?
                </Typography>

                <Typography />
                <LoadingButton
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  className="sign-in-button"
                  type="submit"
                  onClick={() => handleSubmit()}
                  label="Sign in"
                  sx={{ marginTop: theme.spacing(8) }}
                  loading={isLoginLoading}
                  disabled={
                    !values.email ||
                    !values.password ||
                    values.email.includes("@") === false
                  }
                />
                {error.length > 0 && (
                  <Alert
                    severity="error"
                    style={{ marginTop: theme.spacing(2) }}
                  >
                    <AlertTitle>Error</AlertTitle>
                    {error}
                  </Alert>
                )}

                <Typography
                  variant="body1"
                  textAlign={"center"}
                  // mb={"72px"}
                  mt={theme.spacing(1)}
                  mb={16}
                  onClick={() => setStep(STEP.RESET_PASSWORD)}
                >
                  Don&#39;t have an account?{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    Request One
                  </span>
                </Typography>
              </Grid>
            ) : user === null && step === STEP.FORGOT_PASSWORD ? (
              <Grid
                display={"flex"}
                flex={1}
                flexDirection={"column"}
                height={"100%"}
              >
                <Typography variant="caption" mb={"1rem"}>
                  Enter your email address and we’ll send you a link to reset
                  your password.
                </Typography>

                <TextField
                  label="Email"
                  name="email"
                  type="email"
                  value={values.email}
                  onChange={handleChange("email")}
                  style={{ paddingBottom: "20px" }}
                  InputProps={{
                    className: "text-field",
                    endAdornment: (
                      <InputAdornment position="end">
                        <EmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  placeholder="Enter your email here"
                  error={InputError("email", touched, errors).error}
                  helperText={InputError("email", touched, errors).helperText}
                />
                <br />

                <LoadingButton
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  className="sign-in-button"
                  type="submit"
                  onClick={() => handleSubmit()}
                  sx={{ marginTop: "1rem" }}
                  label="Reset my password"
                  loading={isResetPasswordLoading}
                />

                <Typography
                  variant="body1"
                  textAlign={"right"}
                  mt={"0.5rem"}
                  onClick={() => setStep(STEP.FORGOT_PASSWORD)}
                >
                  Need help?{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    Contact us.
                  </span>
                </Typography>

                {error.length > 0 && (
                  <Alert
                    severity="error"
                    style={{ marginTop: theme.spacing(2) }}
                  >
                    <AlertTitle>Error</AlertTitle>
                    {error}
                  </Alert>
                )}

                <Typography
                  variant="body1"
                  textAlign={"left"}
                  mt={"auto"}
                  pb={10}
                  sx={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "bold",
                  }}
                  onClick={() => setStep(STEP.LOGIN)}
                >
                  Back to login
                </Typography>
              </Grid>
            ) : user === null && step === STEP.RESET_PASSWORD ? (
              <Grid
                display={"flex"}
                flex={1}
                flexDirection={"column"}
                height={"100%"}
              >
                <Typography variant="caption" mb={"1rem"}>
                  We have sent a password reset code by email to {values.email}.
                  Enter it below to reset your password.
                </Typography>
                <TextField
                  label="Code"
                  name="code"
                  type="text"
                  value={values.code}
                  onChange={handleChange("code")}
                  style={{ paddingBottom: "20px" }}
                  InputProps={{
                    className: "text-field",
                  }}
                  fullWidth
                  placeholder="Enter your code here"
                  error={InputError("code", touched, errors).error}
                  helperText={InputError("code", touched, errors).helperText}
                />
                <br />
                <TextField
                  name="newPassword"
                  label="New Password"
                  type="password"
                  value={values.newPassword}
                  onChange={handleChange("newPassword")}
                  style={{ paddingBottom: "20px" }}
                  InputProps={{
                    className: "text-field",
                    endAdornment: (
                      <InputAdornment position="end">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  placeholder="Enter your new password here"
                />
                <br />

                <TextField
                  name="validateNewPassword"
                  label="Enter New Password Again"
                  type="password"
                  value={values.validateNewPassword}
                  onChange={handleChange("validateNewPassword")}
                  InputProps={{
                    className: "text-field",
                    endAdornment: (
                      <InputAdornment position="end">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  placeholder="Enter your new password again"
                />
                <br />

                <Typography />
                <LoadingButton
                  variant="contained"
                  color="primary"
                  size="small"
                  fullWidth
                  className="sign-in-button"
                  type="submit"
                  onClick={() => handleSubmit()}
                  label="Change Password"
                  sx={{ marginTop: theme.spacing(4) }}
                  loading={isResetPasswordLoading}
                  disabled={
                    isResetPasswordLoading ||
                    !values.code ||
                    !values.newPassword ||
                    values.newPassword !== values.validateNewPassword
                  }
                />
                {error.length > 0 && (
                  <Alert
                    severity="error"
                    style={{ marginTop: theme.spacing(2) }}
                  >
                    <AlertTitle>Error</AlertTitle>
                    {error}
                  </Alert>
                )}

                <Typography
                  variant="body1"
                  textAlign={"left"}
                  mb={theme.spacing(2)}
                  mt={"auto"}
                  pb={10}
                  // onClick={() => setStep(STEP.RESET_PASSWORD)}
                >
                  Need help?{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    Contact us.
                  </span>
                </Typography>
              </Grid>
            ) : (
              <div>Welcome, {user?.email}</div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        item
        sm={6}
        md={6}
        lg={6}
        className={classes.backgroundImage}
      >
        <Paper
          style={{
            backgroundImage: `url(/background-image.png)`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "left top",
            width: "100%",
            height: "100%",
          }}
        />
      </Grid>
    </Grid>
  );
};

export default Login;
