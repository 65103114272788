export type OrganizationInfo = {
  id: string;
  name: string;
  branch: string;
};

export type RecruitmentProcess = {
  userId: string;
  status: "qualified" | "not qualified" | "pending";
  preQualify: {
    [key: string]: boolean;
  };
  fitnessTest: {
    id: string;
    branch: string;
    name: string;
    assigned: boolean;
    date: string;
    score: number | "-";
    maxScore: number | "-";
    status: "pass" | "fail" | "pending";
    grades: string[];
  };
  training: {
    id: string;
    name: string;
    coachId: string;
    coachName: string;
    assigned: boolean;
    completed: boolean;
  };
  qualifiedForService: {
    [key: string]: boolean;
  };
};

export type Profile = {
  id: string;

  firstName: string;
  lastName: string;
  email: string;
  displayName?: string;
  profileVisibility?: string;
  branchOfService?: string;
  height?: string;
  weight?: string;
  birthday?: string;
  gender?: Gender;
  neckCircumference?: string;
  abdomenCircumference?: string;
  hipCircumference?: string;
  waistCircumference?: string;

  shippingAddress?: Address;

  roles: string[];
  organization: OrganizationInfo;

  recruitmentProcess?: RecruitmentProcess;
};

export type Gender = "Male" | "Female" | "–" | "";
export type BinaryGender = Extract<Gender, "Male" | "Female">;
export type Gendered<T> = { Male: T; Female: T };

export type Address = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  state: string;
  postalCode: string;
};

export const genderOptions = ["Male", "Female", "-"];
