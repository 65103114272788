import { Grid, Typography } from "@mui/material";
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import useStyles from "./TestProgressChart.styles";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top" as const,
    },
    // title: {
    //   display: true,
    //   text: "Chart.js Line Chart",
    // },
  },
};

interface TestProgressChartProps {
  scores: TestScore[];
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

function calculateAverage(numbers: number[]): number {
  if (numbers.length === 0) return 0; // Avoid division by zero
  const sum = numbers.reduce((acc, current) => acc + current, 0);
  return Number((sum / numbers.length).toFixed(2));
}

const TestProgressChart = ({ scores }: TestProgressChartProps) => {
  const classes = useStyles();

  const labels = scores.map((score) => {
    const date = new Date(Number(score.timestamp));
    return [
      date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      }),
      score.testTitle,
    ];
  });

  const averageScore = calculateAverage(scores.map((score) => score.score));

  const data = {
    labels,
    datasets: [
      {
        label: "My Results",
        data: scores.map((score) => score.score),
        borderColor: "#6FA088",
        backgroundColor: "#436052",
      },
    ],
  };

  return (
    <Grid item xs={12}>
      <Grid container item>
        <Typography variant="h5">Fitness testing</Typography>

        <Grid item marginLeft={"auto"}>
          <Grid
            container
            alignItems={"center"}
            className={classes.averageScore}
          >
            <Typography variant="subtitle1" color={"#666666"}>
              Average Score
            </Typography>
            <Typography variant="subtitle1" color={"black"}>
              {averageScore}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Line options={options} data={data} />
    </Grid>
  );
};

export default TestProgressChart;
