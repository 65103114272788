import { Button, Grid, Typography } from "@mui/material";
import {
  ExerciseResultMetadata,
  Profile,
  isCardio,
  isRepForTime,
  isReps,
} from "@readyfit-common/models";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  ApprovalGradeResult,
  CardioApprovalGradeResult,
  GradeResult,
  RepCountGradeResult,
  RepTimeGradeResult,
} from "src/grading/models";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import {
  selectGradingPrevStep,
  selectGradingStep,
  setPrevStep,
} from "src/store/reducers/grading/gradingSlice";
import { TestScore } from "src/store/reducers/scores/scoresApiSlice";
import { TestStepDefinitionFormat } from "src/store/reducers/tests/testsApiSlice";
import LoadingButton from "../../LoadingButton/LoadingButton";
import ApprovalStepper from "../ApprovalStepper/ApprovalStepper";
import CardioStepper from "../CardioStepper/CardioStepper";
import OverviewStepper from "../OverviewStepper/OverviewStepper";
import RepsStepper from "../RepsStepper/RepsStepper";
import { isPassFailType } from "../RepsStepper/StepperInput";
import ResultsStepper from "../ResultsStepper/ResultsStepper";
import ReviewStepper from "../ReviewStepper/ReviewStepper";
import useStyles from "./ProgressStepper.styles";

interface ProgressStepperProps {
  test: TestScore;
  changeStep: (newStep: number) => void;
  onTestCertify: (results: GradeResult[], uid: string) => Promise<void>;
  gradeResult: GradeResult[];
  stepMetadata: TestStepDefinitionFormat | undefined;
  onGrade: (stepGrade: GradeResult) => void;
  currentGrade: GradeResult | undefined;
  prevEnd: number | null; // The end time of the previous step, for rest time displays
  testScore: TestScore | undefined;
  graderProfile?: Profile;
  overallNotes: string | undefined;
  setOverallNotes: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ProgressStepper = ({
  test,
  changeStep,
  onTestCertify,
  stepMetadata,
  gradeResult,
  onGrade,
  currentGrade,
  prevEnd,
  testScore,
  graderProfile,
  overallNotes,
  setOverallNotes,
}: ProgressStepperProps) => {
  const classes = useStyles();
  const match = useRouteMatch<{ userId: string; testId: string }>();
  const history = useHistory();
  const step = useAppSelector(selectGradingStep);
  const result = test?.exercises[step];
  const [exerciseType, setExerciseType] = useState<
    "reps" | "cardio" | "passFail"
  >("reps");
  const [updatedCurrentGrade, setUpdatedCurrentGrade] = useState<
    | RepTimeGradeResult
    | RepCountGradeResult
    | CardioApprovalGradeResult
    | ApprovalGradeResult
    | undefined
  >(undefined);
  const isResultStepper = step > test.exercises.length;
  const prevStep = useAppSelector(selectGradingPrevStep);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isPassFailType(stepMetadata?.exerciseType)) {
      setExerciseType("passFail");
      setUpdatedCurrentGrade({
        userResult: {
          ...result?.metadata,
          graderNotes: currentGrade?.userResult?.graderNotes,
          exerciseType: stepMetadata?.exerciseType,
          passFail: (currentGrade as ApprovalGradeResult)?.userResult?.passFail,
        },
        discrepancies: currentGrade?.discrepancies,
        notes: "",
        approved: (currentGrade as ApprovalGradeResult)?.approved,
        exceededRestTime: (currentGrade as ApprovalGradeResult)
          ?.exceededRestTime,
      } as ApprovalGradeResult);
    }
    if (
      result &&
      isReps(result.metadata) &&
      !isPassFailType(stepMetadata?.exerciseType)
    ) {
      setExerciseType("reps");
      if (step > -1 && step < test.exercises.length && result?.metadata) {
        if (isRepForTime(result?.metadata as ExerciseResultMetadata)) {
          setUpdatedCurrentGrade({
            userResult: {
              ...result?.metadata,
              graderNotes: currentGrade?.userResult?.graderNotes,
            },
            notes: "",
            graderTime: (currentGrade as RepTimeGradeResult)?.graderTime,
            discrepancies: currentGrade?.discrepancies,
            exceededRestTime: (currentGrade as RepTimeGradeResult)
              ?.exceededRestTime,
          } as RepTimeGradeResult);
        } else {
          setUpdatedCurrentGrade({
            userResult: {
              ...result?.metadata,
              graderNotes: currentGrade?.userResult?.graderNotes,
            },
            notes: "",
            graderCount: (currentGrade as RepCountGradeResult)?.graderCount,
            discrepancies: currentGrade?.discrepancies,
            exceededRestTime: (currentGrade as RepCountGradeResult)
              ?.exceededRestTime,
          } as RepCountGradeResult);
        }
      }
    } else {
      if (result && isCardio(result?.metadata)) {
        setExerciseType("cardio");
        setUpdatedCurrentGrade({
          userResult: result?.metadata,
          notes: "",
          approved: (currentGrade as CardioApprovalGradeResult)?.approved,
          exceededRestTime: (currentGrade as CardioApprovalGradeResult)
            ?.exceededRestTime,
          discrepancies: currentGrade?.discrepancies,
        } as CardioApprovalGradeResult);
      }
    }
  }, [
    result,
    step,
    test.exercises.length,
    currentGrade,
    stepMetadata?.exerciseType,
  ]);

  return (
    <Grid container flexDirection={"column"} sx={{ position: "relative" }}>
      <Grid
        container
        p={4}
        bgcolor={"#F0F0F0"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Typography variant="h5">
          {step === -1
            ? "Overview"
            : step === test.exercises.length
            ? "Review"
            : isResultStepper
            ? "Certified"
            : "Grade"}
        </Typography>

        {step > 0 && step <= test.exercises.length && (
          <Typography className={classes.stepCount} variant="subtitle1">
            Step {step} of {test.exercises.length + 1}
          </Typography>
        )}
      </Grid>
      {step === -1 && <OverviewStepper test={test} />}

      {/* FIGURE OUT WHAT NEEDS TO BE OUTPUTTED HERE BASED ON EXERCISE TYPE */}
      {step > -1 &&
        step < test.exercises.length &&
        updatedCurrentGrade !== undefined && (
          <>
            {exerciseType === "reps" ? (
              <RepsStepper
                onGrade={onGrade}
                currentGrade={updatedCurrentGrade}
                stepMetadata={stepMetadata}
                key={step}
                prevEnd={prevEnd}
              />
            ) : exerciseType === "cardio" ? (
              <CardioStepper
                onGrade={onGrade}
                currentGrade={updatedCurrentGrade as CardioApprovalGradeResult}
                stepMetadata={stepMetadata}
                key={step}
                prevEnd={prevEnd}
              />
            ) : exerciseType === "passFail" ? (
              <ApprovalStepper
                onGrade={onGrade}
                currentGrade={updatedCurrentGrade as ApprovalGradeResult}
                stepMetadata={stepMetadata}
                key={step}
                prevEnd={prevEnd}
              />
            ) : null}
          </>
        )}

      {step > -1 && testScore && step === test.exercises.length && (
        <ReviewStepper
          key={step}
          testScore={testScore}
          setOverallNotes={setOverallNotes}
          overallNotes={overallNotes}
        />
      )}

      {step > -1 && testScore && step > test.exercises.length && (
        <ResultsStepper graderProfile={graderProfile} testScore={testScore} />
      )}

      <Grid
        item
        container
        marginTop={"auto"}
        padding={1}
        borderTop={`1px solid #B9B9B9`}
        style={{ alignItems: "center", justifyContent: "center" }}
        sx={{ bottom: 0 }}
        // position={"absolute"}
      >
        {isResultStepper ? (
          <Button
            className={`${classes.button} ${
              step > -1 && classes.doubleButton
            } `}
            variant="contained"
            color="secondary"
            disabled={step === -1}
            onClick={() => console.log("export")}
          >
            Export
          </Button>
        ) : step !== -1 ? (
          <Button
            className={`${classes.button} ${
              step > -1 && classes.doubleButton
            } `}
            // variant="contained"
            color="secondary"
            disabled={step === -1}
            onClick={() => changeStep(step - 1)}
            classes={classes.nextButton}
          >
            Back
          </Button>
        ) : null}
        <LoadingButton
          className={`${classes.button} ${step > -1 && classes.doubleButton} `}
          variant="contained"
          color="primary"
          disabled={
            step !== -1 &&
            step !== test.exercises.length &&
            !gradeResult[step] &&
            !isResultStepper
          }
          onClick={async () => {
            if (step === test.exercises.length) {
              await onTestCertify(gradeResult, match.params.userId);
              changeStep(step + 1);
            } else if (isResultStepper) {
              history.push("/grading/pending");
            } else {
              if (prevStep > -1) {
                changeStep(prevStep);
                dispatch(setPrevStep(-1));
                return;
              }
              changeStep(step + 1);
            }
          }}
          label={
            step === test.exercises.length - 1
              ? "Review"
              : step === test.exercises.length
              ? "Certify Test"
              : isResultStepper
              ? "Close"
              : step === -1
              ? "Start"
              : "Next"
          }
          sx={{ backgroundColor: "#242424", color: "#fff" }}
        />
      </Grid>
    </Grid>
  );
};

export default ProgressStepper;
