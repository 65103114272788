import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = (showDrawer: boolean) =>
  makeStyles((theme: Theme) => ({
    topLink: {
      minHeight: "3rem",
    },
    dropdownLink: {
      paddingLeft: theme.spacing(10),
    },
    selectedLink: {
      background: theme.palette.primary.main,
    },
    link: {
      textDecoration: "none",
      color: theme.palette.common.white,
      "& .MuiTypography-root": {
        fontSize: "0.875rem",
      },
      borderRadius: theme.spacing(1),
    },
    listIcon: {
      color: theme.palette.common.white,
      minWidth: showDrawer ? "2.8rem" : 0,
      paddingLeft: "1.5rem",
      [theme.breakpoints.up("md")]: {
        paddingLeft: "1.2rem",
      },
      [theme.breakpoints.down("md")]: {
        paddingLeft: "0.75rem",
      },
    },
    listText: {
      display: "block",
      color: theme.palette.common.white,
    },
    listIconWidth: {
      minWidth: 0,
    },
  }));

export default useStyles;
